import { ReferenceInput, SelectInput } from "react-admin";
import { useRecoilState } from "recoil";
import { persistentGlobalDrivingSchoolFilterState } from "../persistent_state";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useEffect } from "react";

/**
 * Filter for driving schools which keeps its value when switching lists.
 */
export function GlobalDrivingSchoolFilter({ source }: { source: string; alwaysOn?: boolean }) {
  const [filterValue, setFilterValue] = useRecoilState(persistentGlobalDrivingSchoolFilterState);
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(search);
    const filterParam = params.get("filter");
    const filter = filterParam ? JSON.parse(filterParam) : false;
    if (!filter) {
      params.set("filter", JSON.stringify({ [source]: filterValue || undefined }));
      navigate(`${pathname}?${params}`, { replace: true });
    } else if (!Object.keys(filter).includes(source)) {
      filter[source] = filterValue || undefined;
      params.set("filter", JSON.stringify(filter));
      navigate(`${pathname}?${params}`, { replace: true });
    } else {
      setFilterValue(filter[source] || "");
    }
  }, []);

  return (
    <ReferenceInput
      source={source}
      reference="drivingSchools"
      page={1}
      perPage={999}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput
        label="Fahrschule"
        emptyText={<i>alle</i>}
        defaultValue={filterValue}
        value={filterValue}
        onChange={(event) => setFilterValue(event.target.value)}
      />
    </ReferenceInput>
  );
}
