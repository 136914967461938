import { useNotify } from "react-admin";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import * as React from "react";
import { ReactNode } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { DialogCloseButton } from "./DialogCloseButton";

export function SimpleConfirmDialogButton({
  disabled,
  buttonTitle,
  dialogTitle,
  action,
  actionDisabled,
  dialogCtaTitle,
  content,
  icon,
}: {
  disabled?: boolean;
  buttonTitle: ReactNode | string;
  dialogTitle: string;
  action: () => void;
  actionDisabled?: boolean;
  dialogCtaTitle: string;
  content: ReactNode | string;
  icon?: ReactNode;
}) {
  if (disabled === undefined) disabled = false;
  if (actionDisabled === undefined) actionDisabled = false;

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const onClose = () => setOpen(false);

  const actionWrapper = async () => {
    setLoading(true);
    try {
      await action();
      notify("Aktion erfolgreich ausgeführt!", { type: "success" });
    } catch (e) {
      console.error(e);
      notify("Fehler: " + (e as Error).message, { type: "error" });
    } finally {
      setLoading(false);
    }
    setOpen(false);
  };
  const onClick = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };
  return (
    <>
      {(icon && buttonTitle === "" && (
        <IconButton onClick={onClick} disabled={disabled}>
          {icon}
        </IconButton>
      )) || (
        <Button startIcon={icon} disabled={disabled} onClick={onClick}>
          {buttonTitle}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={(_, __) => {
          // prevent closing the dialog when the action is still running
          if (loading) return;
          onClose();
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogCloseButton onClose={onClose} disabled={loading} />
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" onClick={actionWrapper} loading={loading} disabled={actionDisabled}>
            {dialogCtaTitle}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
