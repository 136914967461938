import { RecordContextProvider, useGetOne, useListContext, useRecordContext } from "react-admin";
import { InAppNotification } from "../providers/inAppNotificationsProvider";
import { Spinner } from "../misc/Spinner";
import { Avatar, Divider, List, ListItem, Typography } from "@mui/material";
import { Column } from "../misc/Column";
import { autovioColors } from "../misc/backofficeTheme";
import { Row } from "../misc/Row";
import { Fragment } from "react";

const _avatarSize = 48;
const _textWidth = 260;

export function InAppNotificationsList() {
  const { data, isLoading, total } = useListContext<InAppNotification>();
  if (isLoading) {
    return <Spinner style={{ margin: "20px" }} />;
  }
  if (!data || data.length === 0 || total === 0) {
    return (
      <Typography
        variant="body2"
        style={{
          marginTop: "20px",
          height: "40px",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        Keine In-App-Benachrichtiungen vorhanden.
      </Typography>
    );
  }
  const divider = <Divider style={{ maxWidth: `${20 + _avatarSize + 15 + _textWidth + 20}px`, margin: "0 auto" }} />;
  return (
    <List>
      {data.map((inAppNotification, index) => (
        <Fragment key={index}>
          {index === 0 && divider}
          <RecordContextProvider value={inAppNotification}>
            <_InAppNotificationsListItem />
          </RecordContextProvider>
          {divider}
        </Fragment>
      ))}
    </List>
  );
}

function _InAppNotificationsListItem() {
  const inAppNotification = useRecordContext<InAppNotification>();
  const { data: sender } = useGetOne(
    "users",
    { id: inAppNotification?.senderUid },
    { enabled: !!inAppNotification?.senderUid },
  );
  return (
    <>
      <ListItem disablePadding>
        <div style={{ position: "relative", margin: "0 auto" }}>
          <Row alignItems="center" style={{ height: "95px" }}>
            {inAppNotification.status?.state !== "read" ? <_UnreadIndicator /> : <div style={{ minWidth: "20px" }} />}
            <Avatar src={sender?.avatarUrl} style={{ width: "48px", height: "48px", marginRight: "15px" }} />
            <Column style={{ width: "200px", minWidth: "260px" }}>
              <Typography
                variant="body1"
                style={{
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: autovioColors.black,
                  height: "20px",
                  maxHeight: "20px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {inAppNotification.title}
              </Typography>
              <div style={{ minHeight: "5px" }} />
              <Typography
                variant="body2"
                style={{
                  color: autovioColors.grey,
                  fontSize: "12px",
                  lineHeight: "14px",
                  height: "28px",
                  maxHeight: "28px",
                  overflowWrap: "anywhere",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {inAppNotification.text}
              </Typography>
            </Column>
          </Row>
          <div
            style={{
              position: "absolute",
              right: "20px",
              top: "5px",
              color: autovioColors.grey,
              fontSize: "12px",
              lineHeight: "14px",
            }}
          >
            {inAppNotification.createdAt.toFormat("dd.MM.yyyy")}
          </div>
        </div>
      </ListItem>
    </>
  );
}

function _UnreadIndicator() {
  return (
    <div style={{ paddingLeft: "7px", paddingRight: "7", minWidth: "20px" }}>
      <svg height="6" width="6">
        <circle r="3" cx="3" cy="3" fill={autovioColors.red} />
      </svg>
    </div>
  );
}
