import { Skeleton, Avatar } from "@mui/material";
import { useGetOne } from "react-admin";
import { Student } from "../providers/studentsProvider";

export const StudentAvatar = ({ studentId, size }: { studentId: string; size: "20px" | "40px" }) => {
  const { data, isLoading, error } = useGetOne<Student>("students", { id: studentId });
  if (isLoading) {
    return <Skeleton variant="circular" width={size} height={size} />;
  }
  if (error || !data) {
    return (
      <Avatar sx={{ bgcolor: "red", width: { size }, height: { size } }}>
        <b>!</b>
      </Avatar>
    );
  }
  return <Avatar alt={data.name} src={data.avatarUrl} sx={{ width: size, height: size }} />;
};
