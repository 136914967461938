import { z } from "zod";
import { PostalAddressSchema } from "./PostalAddress";
import { ExamTypeEnum } from "./ExamType";

const ExamLocationWeekdaysEnum = z.enum(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]);

export const ExamLocationSchema = z.object({
  uid: z.string().nonempty(),
  name: z.string(),
  postalAddress: PostalAddressSchema,
  types: z.array(ExamTypeEnum),
  theoryExamDaysInAdvance: z.optional(z.number().int().positive()),
  practicalExamDaysInAdvance: z.optional(z.number().int().positive()),
  theoryExamWeekdays: z.optional(z.array(ExamLocationWeekdaysEnum)),
  practicalExamWeekdays: z.optional(z.array(ExamLocationWeekdaysEnum)),
  deleted: z.optional(z.boolean()).transform((val) => !!val),
});
export type ExamLocation = { id: string } & z.infer<typeof ExamLocationSchema>;
