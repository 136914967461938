import { Box, BoxProps, Divider, IconButton, Typography } from "@mui/material";
import { firebaseApp } from "../firebase";

import FirestoreIcon from "@mui/icons-material/LocalFireDepartment";
import { CopyToClipboardButton } from "../buttons/CopyToClipboardButton";

type IdBarProps = {
  id: string;
  url: string;
} & BoxProps;

const IdBar = (props: IdBarProps) => {
  const { id, url, ...boxProps } = props;
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        border: "1px solid lightgrey",
        borderRadius: "4px",
      }}
      {...boxProps}
    >
      <Typography color="dimgrey" variant="body2" noWrap px="10px">
        {id}
      </Typography>
      <Divider orientation="vertical" flexItem />
      <CopyToClipboardButton data={id} />
      <IconButton onClick={() => open(url, "_blank")}>
        <FirestoreIcon />
      </IconButton>
    </Box>
  );
};

export const EventIdBar = (props: { id: string } & BoxProps) => {
  const { id, ...boxProps } = props;
  const { projectId } = firebaseApp.options;
  const path = `calendar_events/${id}`;
  const url = `https://console.firebase.google.com/project/${projectId}/firestore/data/${path.replaceAll("/", "~2F")}`;
  return <IdBar id={props.id} url={url} {...boxProps} />;
};
