import { BookedQuote } from "../providers/bookedQuotesProvider";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ChangeIcon from "@mui/icons-material/CurrencyExchange";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { catalogProvider } from "../providers/catalogProvider";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import { areSetsEqual, postCondition, reportError } from "../backoffice.utils";
import { studentsProvider } from "../providers/studentsProvider";
import { useDialog } from "../hooks/useDialog";
import { ChooseDrivingLicenseClassList } from "./ChangeBundleButton";
import { DialogCloseButton } from "../misc/DialogCloseButton";

/**
 * Allows to change the driving license class.
 */
export function ChangeDrivingLicenseClassButton() {
  const bookedQuote = useRecordContext() as BookedQuote;
  const { dialogProps, openDialog } = useDialog();

  return (
    <>
      <Button startIcon={<ChangeIcon />} onClick={openDialog} disabled={!bookedQuote}>
        Klasse/Schlüsselzahl ändern
      </Button>
      <ChangeDrivingLicenseClassDialog bookedQuote={bookedQuote} {...dialogProps} />
    </>
  );
}

interface ChangeDrivingLicenseClassDialogProps {
  open: boolean;
  onClose: () => void;
  bookedQuote: BookedQuote;
}

function ChangeDrivingLicenseClassDialog({ open, onClose, bookedQuote }: ChangeDrivingLicenseClassDialogProps) {
  const [selectedDrivingLicenseClasses, setSelectedDrivingLicenseClasses] = useState(bookedQuote.drivingLicenseClasses);
  const notify = useNotify();
  const refresh = useRefresh();
  const [saving, setSaving] = useState(false);
  const changeDrivingLicenseClasses = async () => {
    setSaving(true);
    try {
      const changes = bookedQuote.drivingLicenseClasses
        .map((a, i) => [a, selectedDrivingLicenseClasses[i]])
        .filter(([a, b]) => a !== b);
      if (changes.length === 0) {
        throw new Error("Invalid state: No driving license class change");
      }
      await catalogProvider.changeDrivingLicenseClasses({
        studentId: bookedQuote.studentId,
        quoteId: bookedQuote.id,
        changes: Object.fromEntries(changes),
      });
      await postCondition(async () => {
        const { data: student } = await studentsProvider.getOne("students", { id: bookedQuote.studentId });
        const expectedDrivingLicenseClasses = changes.map(([_, newDrivingLicenseClass]) => newDrivingLicenseClass);
        const actualDrivingLicenseClasses = new Set(student.bookedTrainings.map((it) => it.drivingLicenseClass));
        return expectedDrivingLicenseClasses.every((it) => actualDrivingLicenseClasses.has(it));
      });
      if (changes.length === 1) {
        notify(`Führerscheinklasse erfolgreich von ${changes[0][0]} auf ${changes[0][1]} geändert.`);
      } else {
        notify(`Führerscheinklassen erfolgreich geändert.`);
      }
      refresh();
    } catch (error) {
      reportError("Failed to change driving license classes", error);
      notify("Oje, das hat leider nicht geklappt.", { type: "error" });
    } finally {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Führerscheinklasse/Schlüsselzahl ändern</DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent>
        <ChooseDrivingLicenseClassList
          bookedQuote={bookedQuote}
          selectedDrivingLicenseClasses={selectedDrivingLicenseClasses}
          setSelectedDrivingLicenseClasses={setSelectedDrivingLicenseClasses}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          loading={saving}
          startIcon={<SaveIcon />}
          disabled={areSetsEqual(new Set(bookedQuote.drivingLicenseClasses), new Set(selectedDrivingLicenseClasses))}
          onClick={changeDrivingLicenseClasses}
        >
          Speichern
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
