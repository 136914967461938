import type {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  PaginationPayload,
  SortPayload,
} from "react-admin";
import { FinanceReport, FinanceReportEndpoint } from "../generated/backendClient";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { AbstractProvider } from "./abstractProvider";
import { toSnakeCase } from "../utils/string";
import safeJsonStringify from "safe-json-stringify";

export class FinanceReportProvider extends AbstractProvider<FinanceReport> {
  constructor(private readonly type: "MONTHLY" | "PAYOUT") {
    super();
  }

  async getList(resource: string, { pagination, sort, filter }: GetListParams): Promise<GetListResult<FinanceReport>> {
    try {
      return await fetchFinanceReports(filter, pagination, sort);
    } catch (error) {
      reportError(`Failed to fetch finance reports for filter ${safeJsonStringify(filter)} with error: ${error}`);
      return Promise.reject(error);
    }
  }

  async getManyReference(
    resource: string,
    { id, filter, sort, pagination, target }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<FinanceReport>> {
    filter = { ...filter, [target]: id, type: this.type };
    return this.getList(resource, { filter, sort, pagination });
  }
}

export const monthlyFinanceReportsProvider = new FinanceReportProvider("MONTHLY") as DataProvider;
export const monthlyPayoutReportsProvider = new FinanceReportProvider("PAYOUT") as DataProvider;

async function fetchFinanceReports(
  { drivingSchoolId, type }: { drivingSchoolId?: string; type?: "MONTHLY" | "PAYOUT" },
  { page, perPage }: PaginationPayload,
  sort: SortPayload,
): Promise<GetListResult<FinanceReport>> {
  const backendClient = await getAuthenticatedBackendClient();
  const ordering = `${sort.order === "ASC" ? "" : "-"}${toSnakeCase(sort.field)}`;
  let filter: Parameters<FinanceReportEndpoint["financeReportList"]>[0];
  if (drivingSchoolId) {
    filter = { companyDrivingSchoolId: drivingSchoolId };
  } else {
    filter = {};
  }
  filter.type = type;
  const { count, results, previous, next } = await backendClient.financeReport.financeReportList({
    offset: (page - 1) * perPage,
    ordering,
    limit: perPage,
    ...filter,
  });
  return {
    data: results,
    total: count,
    pageInfo: { hasPreviousPage: !!previous, hasNextPage: !!next },
  };
}
