import { Paper } from "@mui/material";
import { BooleanInput, useRecordContext } from "react-admin";
import { Instructor } from "../providers/instructorsProvider";

export function InstructorGrantsInput() {
  const instructor = useRecordContext<Instructor>();

  return (
    <Paper sx={{ padding: "20px 20px 0 20px" }}>
      <BooleanInput
        label="changePrice (Preisvorschläge können überschrieben werden)"
        source="grants.changePrice"
        disabled={!instructor}
        validate={(value) => {
          if (!value && instructor.roles.includes("drivingSchoolManager")) {
            return "Fahrschulleitern kann die Berechtigung changePrice nicht entzogen werden.";
          }
        }}
      />
      <BooleanInput
        label="drivingSchoolOffboarding (erlaubt Zugriff auf Mein Profil > Fahrschule > Offboarding in der App)"
        source="grants.drivingSchoolOffboarding"
        disabled={!instructor}
      />
      <BooleanInput
        label="viewPerformanceOverview (kann Leistungsübersicht sehen)"
        source="grants.viewPerformanceOverview"
        disabled={!instructor}
      />
    </Paper>
  );
}
