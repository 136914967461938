import { Box, Typography } from "@mui/material";
import { FieldProps, useRecordContext } from "react-admin";
import { AddressDisplay } from "../../misc/AddressDisplay";
import { PostalAddress } from "../../model/PostalAddress";
import { Student } from "../../providers/studentsProvider";

export const InvoiceAddressField = (props: { source: string } & FieldProps) => {
  const { emptyText } = props;
  const student = useRecordContext<Student>();
  const invoiceAddress = student.invoiceAddress;

  if (!invoiceAddress) {
    return <Typography variant="body2">{emptyText}</Typography>;
  }

  if (hasSameInvoiceAddressAndPostalAddress(student)) {
    return (
      <Typography variant="body2" fontStyle="italic">
        Gleiche Adresse wie Wohnanschrift
      </Typography>
    );
  }

  const invoicePostalAddress: PostalAddress = {
    city: invoiceAddress.city,
    street: invoiceAddress.street,
    postalCode: invoiceAddress.postalCode,
  };

  return (
    <Typography component="span" variant="body2">
      <Box>
        {invoiceAddress.firstName} {invoiceAddress.lastName}
      </Box>
      {invoiceAddress.company && <Box>{invoiceAddress.company}</Box>}
      <AddressDisplay address={invoicePostalAddress} />
    </Typography>
  );
};

function hasSameInvoiceAddressAndPostalAddress(student: Student) {
  const invoiceAddress = student.invoiceAddress!;
  const postalAddress = student.postalAddress;
  return (
    postalAddress &&
    !invoiceAddress.company &&
    invoiceAddress.firstName === student.firstName &&
    invoiceAddress.lastName === student.lastName &&
    invoiceAddress.street === postalAddress.street &&
    invoiceAddress.postalCode === postalAddress.postalCode &&
    invoiceAddress.city === postalAddress.city
  );
}
