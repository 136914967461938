export function UberlandIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.333 10 7.5 1.667 1.667 10h1.55L0 15h5.833v3.333h3.334V15H15l-3.217-5h1.55m3.45 0h1.55L12.5 1.667l-1.992 2.841 4.425 6.325h-1.625L15.992 15H20l-3.217-5m-5.95 5.833h3.334v2.5h-3.334v-2.5z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}
