import type {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  PaginationPayload,
  RaRecord,
  SortPayload,
} from "react-admin";
import { DateTime } from "luxon";
import {
  Invoice as InvoiceDto,
  InvoiceEndpoint,
  InvoiceStateEnum,
  UserEndpoint,
  type PaymentModeEnum,
} from "../generated/backendClient";
import { AbstractProvider } from "./abstractProvider";
import { toSnakeCase } from "../utils/string";
import { getAuthenticatedBackendClient } from "../api/backend.api";

export interface Invoice extends RaRecord {
  id: string;
  nr: string;
  type: string;
  createdAt: DateTime;
  total?: number;
  payment_amount?: number;
  refunded_amount?: number;
  payment_mode: PaymentModeEnum | null;
  status: InvoiceStateEnum;
  downloadUrl: string | null;
  payload: InvoiceDto;
}

export class InvoicesProvider extends AbstractProvider<Invoice | UserBalanceItem> {
  async getOne(resource: string, { id }: GetOneParams): Promise<GetOneResult<Invoice | UserBalanceItem>> {
    if (resource === "b2cInvoices" || resource === "b2bInvoices") {
      const backendClient = await getAuthenticatedBackendClient();
      const invoice = await backendClient.invoice.invoiceRetrieve({ id });
      return { data: invoiceDtoToInvoice(invoice) };
    }
    throw new Error("Not implemented");
  }

  async getMany(resource: string, { ids }: GetManyParams): Promise<GetManyResult<Invoice | UserBalanceItem>> {
    const data: Array<Invoice | UserBalanceItem> = [];
    for (const id of ids) {
      const { data: record } = await this.getOne(resource, { id });
      data.push(record);
    }
    return { data };
  }

  async getList(resource: string, { pagination, sort, filter }: GetListParams): Promise<GetListResult<Invoice>> {
    return await fetchInvoices(filter, pagination, sort);
  }

  async getManyReference(
    resource: string,
    { id, target, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Invoice | UserBalanceItem>> {
    if (resource === "studentBalance") {
      if (target !== "studentId") {
        throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentId"`);
      }
      return await fetchStudentBalance({ studentId: id + "" });
    }
    if (resource === "b2bInvoices") {
      if (target !== "drivingSchoolId") {
        throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "drivingSchoolId"`);
      }
      filter = { ...filter, drivingSchoolId: id };
    } else if (resource === "b2cInvoices") {
      if (target !== "studentId") {
        throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentId"`);
      }
      filter = { ...filter, studentId: id };
    }
    return this.getList(resource, { filter, sort, pagination });
  }
}

export const invoicesProvider = new InvoicesProvider() as DataProvider;

export type UserBalanceItem = Awaited<ReturnType<UserEndpoint["userBalanceOverviewRetrieve"]>>[0];
export type UserBalanceItemWithBalance = UserBalanceItem & { accuBalance: number; balanceChange: number };

async function fetchStudentBalance({
  studentId,
}: {
  studentId: string;
}): Promise<GetListResult<UserBalanceItemWithBalance>> {
  const client = await getAuthenticatedBackendClient();
  const results = (await client.user.userBalanceOverviewRetrieve({ id: studentId })).filter(
    // hide refunded prepaid invoices (they are not relevant for the balance overview, says ecki)
    (item) => !(item.invoice_type === "PREPAID_CREDITS" && item.invoice_state === "REFUNDED"),
  );

  // calculate the accumulated balance over time
  let accuBalance = 0;
  const resultsWithBalance: UserBalanceItemWithBalance[] = [];
  for (let i = results.length - 1; i >= 0; i--) {
    const balanceChange = parseFloat(results[i].balance_change.amount) * 100;
    accuBalance += balanceChange;
    resultsWithBalance.unshift({ ...results[i], accuBalance: accuBalance, balanceChange: balanceChange });
  }
  return {
    data: resultsWithBalance,
    total: results.length,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    },
  };
}

async function fetchInvoices(
  { drivingSchoolId, studentId, state }: { drivingSchoolId?: string; studentId?: string; state?: InvoiceStateEnum },
  { page, perPage }: PaginationPayload,
  sort: SortPayload,
): Promise<GetListResult<Invoice>> {
  const ordering = `${sort.order === "ASC" ? "" : "-"}${toSnakeCase(sort.field)}`;
  if (drivingSchoolId && studentId) {
    throw new Error("drivingSchoolId and student are are mutually exclusive");
  } else if (!drivingSchoolId && !studentId) {
    throw new Error("Neither drivingSchoolId nor studentId given");
  }

  let filter: Parameters<InvoiceEndpoint["invoiceList"]>[0];
  if (drivingSchoolId) {
    filter = { recipientCompanyDrivingSchoolId: drivingSchoolId, type: "B2B" };
  } else {
    filter = { recipientFirebaseAuthUsersId: studentId };
  }
  if (state) {
    filter = { ...filter, state };
  }

  const backendClient = await getAuthenticatedBackendClient();
  const { count, results, previous, next } = await backendClient.invoice.invoiceList({
    offset: (page - 1) * perPage,
    ordering,
    limit: perPage,
    ...filter,
  });

  return {
    data: results.map(invoiceDtoToInvoice),
    total: count,
    pageInfo: { hasPreviousPage: !!previous, hasNextPage: !!next },
  };
}

function invoiceDtoToInvoice(invoiceDTO: InvoiceDto): Invoice {
  const {
    id,
    calculation_number,
    type,
    created_at,
    pdf,
    total_gross,
    state,
    driving_lesson_type,
    payment_amount,
    refunded_amount,
    payment_mode,
  } = invoiceDTO;
  let localizedType: string = type;
  if (type === "BASE_FEE") {
    localizedType = "für Grundgebühr";
  } else if (type === "TEACHING_MATERIAL") {
    localizedType = "für Lehrmittel";
  } else if (type === "DRIVING_LESSON_INVOICE") {
    if (driving_lesson_type === "normal") {
      localizedType = "für Fahrstunde";
    } else if (driving_lesson_type === "ueberlandfahrt") {
      localizedType = "für Überlandfahrt";
    } else if (driving_lesson_type === "autobahnfahrt") {
      localizedType = "für Autobahnfahrt";
    } else if (driving_lesson_type === "nachtfahrt") {
      localizedType = "für Nachfahrt";
    } else if (driving_lesson_type === "praktischePruefung") {
      localizedType = "für Fahrprüfung";
    } else if (driving_lesson_type === "schaltkompetenz") {
      localizedType = "für Schaltkompetenz-Testfahrt";
    } else if (driving_lesson_type === "theoretischePruefung") {
      localizedType = "für Theorieprüfung";
    }
  } else if (type === "REMOTE_LESSON") {
    localizedType = "für Zoom-Meeting";
  } else if (type === "AUTHORITY_FEE") {
    localizedType = "für Prüfungsgebühr";
  } else if (type === "CREDIT_NOTE") {
    localizedType = "Erstattung";
  } else if (type === "PREPAID_CREDITS") {
    localizedType = "Vorauszahlung";
  }
  return {
    id,
    nr: calculation_number ?? id ?? "???",
    type: localizedType,
    createdAt: DateTime.fromISO(created_at),
    total: total_gross.amount ? parseFloat(total_gross.amount) * 100 : undefined,
    payment_amount: payment_amount.amount ? parseFloat(payment_amount.amount) * 100 : undefined,
    refunded_amount: refunded_amount.amount ? parseFloat(refunded_amount.amount) * 100 : undefined,
    payment_mode: payment_mode,
    status: state,
    downloadUrl: pdf,
    payload: invoiceDTO,
    accuBalance: 0,
  };
}
