import { TypographyProps } from "@mui/material";
import { RecordContextProvider, TextField, useGetOne } from "react-admin";
import { Instructor } from "../providers/instructorsProvider";

type InstructorDisplayNameProps = {
  instructorId: string;
  source?: string;
} & Omit<TypographyProps, "textAlign">;

export function InstructorDisplayName({ instructorId, source, ...props }: InstructorDisplayNameProps) {
  const { data: instructor } = useGetOne<Instructor>("instructors", { id: instructorId });
  return (
    <RecordContextProvider value={instructor}>
      <TextField source={source ?? "name"} overflow={"hidden"} {...props} />
    </RecordContextProvider>
  );
}
