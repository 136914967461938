import { httpsCallable } from "firebase/functions";
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { functions } from "../firebase";

export interface BasePayment {
  readonly id: string;
  readonly source: "stripe";
  readonly amount: number;
  readonly amountReceived: number;
  readonly description: string;
  readonly invoiceId?: string;
  readonly quoteId?: string;
  readonly calendarEventUid?: string;
  readonly paymentIntentId?: string;
  readonly createdAt: string;
  readonly startingBalance?: number;
  readonly endingBalance?: number;
  readonly paidApplicationFeeAmount: number;
  readonly stripePaymentIntent?: {
    invoice: string | null;
    status: string;
    last_payment_error: null | {
      code: string; // <-- See https://stripe.com/docs/error-codes
    };
    metadata?: {
      invoice_type?: string;
      retryOf?: string;
    };
  };
}

export interface DrivingLessonPayment extends BasePayment {
  readonly type: "driving_lesson";
  readonly calendarEventUid: string;
}

export interface RemoteLessonPayment extends BasePayment {
  readonly type: "remote_lesson";
  readonly calendarEventUid: string;
}

export interface BaseFeePayment extends BasePayment {
  readonly type: "base_fee";
}

export interface AuthorityFeePayment extends BasePayment {
  readonly type: "authority_fee";
  readonly calendarEventUid: string;
}

export interface InternalPayment extends BasePayment {
  readonly type: "internal";
}

export interface DisputeInformation {
  readonly id: string;
  readonly status: "won" | "lost" | "submitted" | "unsubmitted" | "expired";
  readonly reason: string;
}

export type PaymentStatus =
  | "unknown"
  | "canceled"
  | "processing"
  | "requires_action"
  | "requires_capture"
  | "requires_confirmation"
  | "requires_payment_method"
  | "succeeded"
  | "disputed"
  | "refunded"
  | "retried";

export type LessonPayment = DrivingLessonPayment | RemoteLessonPayment;
export type Payment = (BaseFeePayment | LessonPayment | AuthorityFeePayment | InternalPayment) & {
  status: PaymentStatus;
};

export class PaymentsProvider {
  async getList(_resource: "payments", _params: GetListParams): Promise<GetListResult<Payment>> {
    throw new Error("not implemented");
  }

  async getOne(_resource: "payments", _params: GetOneParams<Payment>): Promise<GetOneResult<Payment>> {
    throw new Error("not implemented");
  }

  async getMany(_resource: "payments", _params: GetManyParams): Promise<GetManyResult<Payment>> {
    throw new Error("not implemented");
  }

  async getManyReference(
    _resource: "payments",
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Payment>> {
    const { target, id } = params;
    if (target !== "userUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "userUid"`);
    }
    const getManyRef = httpsCallable<{ id: Identifier; target: string }, Payment[]>(
      functions,
      "api/backoffice/payments/getManyReference",
    );
    const { data: payments } = await getManyRef(params);
    console.info(`Retrieved ${payments.length} payment(s) for student ${id}`);
    return {
      data: payments.map((it) => ({ ...it, id: it.paymentIntentId ?? "unknown" })),
      total: payments.length,
    };
  }

  async update(_resource: "payments", _params: UpdateParams<Payment>): Promise<UpdateResult<Payment>> {
    throw new Error("not implemented");
  }

  async updateMany(_resource: "payments", _params: UpdateManyParams<Payment>): Promise<UpdateManyResult<Payment>> {
    throw new Error("not implemented");
  }

  async create(_resource: "payments", _params: CreateParams<Payment>): Promise<CreateResult<Payment>> {
    throw new Error("not implemented");
  }

  async delete(_resource: "payments", _params: DeleteParams<Payment>): Promise<DeleteResult<Payment>> {
    throw new Error("not implemented");
  }

  async deleteMany(_resource: "payments", _params: DeleteManyParams<Payment>): Promise<DeleteManyResult<Payment>> {
    throw new Error("not implemented");
  }
}

export const paymentsProvider = new PaymentsProvider();
