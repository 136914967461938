import { z } from "zod";

export const WorkflowSchema = z.object({
  type: z.string().nonempty(),
  assignedTo: z.array(z.string().nonempty()).default([]),
  drivingSchoolUid: z.string().nonempty(),
  finished: z.boolean(),
  tasks: z.record(
    z.string().nonempty(),
    z.object({
      assignedTo: z.array(z.string()).default([]),
      isDone: z.boolean(),
      title: z.string(),
      uid: z.string().nonempty(),
    }),
  ),
  taskTree: z.array(
    z.object({
      uid: z.string().nonempty(),
      subtasks: z.array(
        z.object({
          uid: z.string().nonempty(),
        }),
      ),
    }),
  ),
});
