import { GetListParams, GetListResult, GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import { Student, studentsProvider } from "./studentsProvider";
import { AbstractProvider } from "./abstractProvider";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";

type DunningResource =
  | "studentsWithNegativeBalance"
  | "candidatesForBlocking"
  | "candidatesForFirstDunningNotice"
  | "candidatesForFirstDunningNoticeParkingLot"
  | "candidatesForSecondDunningNotice"
  | "candidatesForSecondDunningNoticeParkingLot"
  | "candidatesForHandoverToPairFinance"
  | "candidatesForHandoverToPairFinanceParkingLot"
  | "studentsHandedOverToPairFinance"
  | "candidatesForReactivation";

class DunningProvider extends AbstractProvider<Student> {
  async getList(
    resource: DunningResource,
    { filter, sort, pagination }: GetListParams,
  ): Promise<GetListResult<Student>> {
    if (resource === "studentsWithNegativeBalance") {
      const filterKeys = Object.keys(filter);
      if (
        filterKeys.length !== 1 ||
        filterKeys[0] !== "drivingSchoolId" ||
        typeof filter.drivingSchoolId !== "string"
      ) {
        throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"drivingSchoolId":"..."}`);
      }
      if (restrictAccessToDrivingSchoolIds && !restrictAccessToDrivingSchoolIds.includes(filter.drivingSchoolId)) {
        throw new Error("Access denied");
      }
      filter = { ...filter, balance: (balance: Student["balance"]) => balance < 0 };
    } else if (resource === "candidatesForBlocking") {
      filter = { status: "active", ...filter, balance: (balance: Student["balance"]) => balance < 0 };
    } else if (
      resource === "candidatesForFirstDunningNotice" ||
      resource === "candidatesForFirstDunningNoticeParkingLot"
    ) {
      const paused = resource === "candidatesForFirstDunningNoticeParkingLot";
      filter = {
        status: (status: Student["status"]) => status !== "active",
        ...filter, // <-- might overwrite the status filter in the line above
        balance: (balance: Student["balance"]) => balance < 0,
        dunningProcess: (dunningProcess: Student["dunningProcess"]) =>
          (!dunningProcess && !paused) ||
          (dunningProcess &&
            dunningProcess.paused === paused &&
            (dunningProcess.status === "warningSent" || dunningProcess.status === "paymentReminderSent")),
      };
    } else if (
      resource === "candidatesForSecondDunningNotice" ||
      resource === "candidatesForSecondDunningNoticeParkingLot"
    ) {
      const paused = resource === "candidatesForSecondDunningNoticeParkingLot";
      filter = {
        ...filter,
        balance: (balance: Student["balance"]) => balance < 0,
        dunningProcess: (dunningProcess: Student["dunningProcess"]) =>
          dunningProcess &&
          dunningProcess.paused === paused &&
          dunningProcess.outstandingAmount > 0 &&
          dunningProcess.status === "firstDunningNoticeSent",
      };
    } else if (
      resource === "candidatesForHandoverToPairFinance" ||
      resource === "candidatesForHandoverToPairFinanceParkingLot"
    ) {
      const paused = resource === "candidatesForHandoverToPairFinanceParkingLot";
      filter = {
        ...filter,
        balance: (balance: Student["balance"]) => balance < 0,
        dunningProcess: (dunningProcess: Student["dunningProcess"]) =>
          dunningProcess &&
          dunningProcess.paused === paused &&
          dunningProcess.outstandingAmount > 0 &&
          dunningProcess.status === "secondDunningNoticeSent",
      };
    } else if (resource === "studentsHandedOverToPairFinance") {
      filter = {
        ...filter,
        dunningProcess: (dunningProcess: Student["dunningProcess"]) =>
          dunningProcess?.status === "handedOverToPairFinance",
      };
    } else if (resource === "candidatesForReactivation") {
      filter = {
        ...filter,
        status: "outstandingPayments",
        __record__: ({ balance, dunningProcess }: Student) =>
          balance >= 0 || (dunningProcess && dunningProcess.outstandingAmount === 0),
      };
    } else {
      throw new Error(`Unexpected resource: ${JSON.stringify(resource)}`);
    }
    return studentsProvider.getList("students", { filter, sort, pagination });
  }

  async getManyReference(
    resource: DunningResource,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Student>> {
    // We ignore params.pagination here and always return all records ...
    const { target, id, filter, sort } = params;
    if (target !== "drivingSchoolId") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "drivingSchoolId"`);
    }
    if (Object.keys(filter).length > 0) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {}}`);
    }
    return this.getList(resource, { filter: { drivingSchoolId: id }, sort, pagination: {} as any });
  }
}

export const dunningProvider = new DunningProvider();
