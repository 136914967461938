import { NumberInput, Validator, required } from "react-admin";
import { preventValueChangeOnWheel } from "../backoffice.utils";
import { isMoney, Money } from "../model/Money";
import { KeyboardEventHandler, useMemo } from "react";

export function MoneyInput({
  label,
  source,
  validate,
  autoFocus,
  onKeyDown,
  onBlur,
}: {
  label: string;
  source: string;
  validate?: Validator | Array<Validator>;
  autoFocus?: boolean;
  onKeyDown?: KeyboardEventHandler;
  onBlur?: (event: any) => void;
}) {
  validate = useMemo(() => {
    let validators: Array<Validator>;
    if (!validate) {
      validators = [validatePrecision];
    } else if (Array.isArray(validate)) {
      validators = [...validate, validatePrecision];
    } else {
      validators = [validate, validatePrecision];
    }
    // Validator functions should not see a Money object, but the actual amount ...
    return validators.map((validatorFunction) => {
      if (validatorFunction === required()) {
        return validatorFunction;
      }
      return (money: Money, values: any, props: any) => validatorFunction(money.amount, values, props);
    });
  }, [validate]);

  return (
    <NumberInput
      label={`${label} (in €)`}
      inputRef={preventValueChangeOnWheel}
      source={source}
      validate={validate}
      format={convertCentsOrMoneyToString}
      parse={convertNumberOrStringToMoney}
      autoFocus={autoFocus}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
}

const validatePrecision = (amount: undefined | number) => {
  if (typeof amount !== "number" || isNaN(amount)) {
    return undefined;
  }
  return Math.round(amount * 1000) % 10 !== 0 ? "Darf nur zwei Nachkommastellen haben." : undefined;
};

function convertCentsOrMoneyToString(x: any): string {
  if (typeof x === "number" && isFinite(x)) {
    return (x / 100).toString();
  }
  if (isMoney(x) && isFinite(x.amount)) {
    x.amount.toString();
  }
  return "";
}

function convertNumberOrStringToMoney(x: number | string): undefined | Money {
  if (typeof x === "number") {
    x = x.toString();
  }
  if (!x) {
    return undefined;
  }
  const amount = parseFloat(x);
  if (isNaN(amount)) {
    return undefined;
  }
  return { amount, currency: "EUR" };
}
