import { httpsCallable } from "firebase/functions";
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { functions } from "../firebase";

export interface Balance {
  id: string;
  amount: number;
  transactions: BalanceTransaction[];
}

export interface BalanceTransaction {
  readonly id: string;
  readonly type: string; // <-- See https://stripe.com/docs/api/balance_transactions/object#balance_transaction_object-type
  readonly amount: number;
  readonly endingBalance: number;
  readonly createdAt: string;
  readonly createdByUserUid?: string;
  readonly createdByName?: string;
  readonly description: string | null;
  readonly invoiceId: string | null;
}

export class BalanceProvider {
  getList(_resource: "balances", _params: GetListParams): Promise<GetListResult<Balance>> {
    throw new Error("not implemented");
  }

  async getOne(_resource: "balances", _params: GetOneParams<Balance>): Promise<GetOneResult<Balance>> {
    throw new Error("not implemented");
  }

  async getMany(_resource: "balances", _params: GetManyParams): Promise<GetManyResult<Balance>> {
    throw new Error("not implemented");
  }

  async getManyReference(
    _resource: "balances",
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Balance>> {
    const { target, id } = params;
    if (target !== "userUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "userUid"`);
    }
    const getManyRef = httpsCallable<{ id: Identifier; target: string }, Balance[]>(
      functions,
      "api/backoffice/payments/balances/getManyReference",
    );
    const { data } = await getManyRef(params);
    if (data.length !== 1) {
      throw new Error(`Unexpected balances data.length: ${data.length} -- expected: 1`);
    }
    console.info(`Retrieved ${data[0].transactions.length} balance transaction(s) for student ${id}`);
    return { data, total: 1 };
  }

  async update(_resource: "balances", _params: UpdateParams<Balance>): Promise<UpdateResult<Balance>> {
    throw new Error("not implemented");
  }

  async updateMany(_resource: "balances", _params: UpdateManyParams<Balance>): Promise<UpdateManyResult<Balance>> {
    throw new Error("not implemented");
  }

  async create(_resource: "balances", _params: CreateParams<Balance>): Promise<CreateResult<Balance>> {
    throw new Error("not implemented");
  }

  async delete(_resource: "balances", _params: DeleteParams<Balance>): Promise<DeleteResult<Balance>> {
    throw new Error("not implemented");
  }

  async deleteMany(_resource: "balances", _params: DeleteManyParams<Balance>): Promise<DeleteManyResult<Balance>> {
    throw new Error("not implemented");
  }
}

export const balanceProvider = new BalanceProvider();

export async function updateBalanceAmount(
  userUid: string,
  data: { amountDelta: number; description: string },
): Promise<void> {
  const updateRef = httpsCallable<
    {
      userUid: string;
      amountDelta: number;
      description: string;
    },
    Balance
  >(functions, "api/backoffice/payments/balances/update");
  await updateRef({ userUid, ...data });
}
