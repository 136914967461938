import { Skeleton, Typography } from "@mui/material";
import { useGetOne } from "react-admin";
import { reportError } from "../backoffice.utils";
import { Student } from "../providers/studentsProvider";

type BookedTrainingClassDisplayProps = {
  studentId: string;
  bookedTrainingId: string;
};

export const BookedTrainingClassDisplay = ({ studentId, bookedTrainingId }: BookedTrainingClassDisplayProps) => {
  const { data: student, isLoading, error } = useGetOne<Student>("students", { id: studentId });
  if (isLoading) {
    return <Skeleton variant="text" />;
  }
  if (error || !student) {
    reportError(`Failed to load student ${studentId}`, error ?? undefined);
    return <Typography color="error.main">Fehler</Typography>;
  }
  const bookedTraining = student.bookedTrainings.find((bt) => bt.id === bookedTrainingId);
  if (!bookedTraining) {
    reportError(`Booked training ${bookedTrainingId} of student ${studentId} not found`);
    return <Typography color="error.main">Fehler</Typography>;
  }

  return <Typography variant="body2">Klasse {bookedTraining.drivingLicenseClass}</Typography>;
};
