import { useRef, useState } from "react";
import { DialogProps } from "../misc/DialogProps";

export function useDialog(preventRowClick?: () => () => void) {
  const [counter, setCounter] = useState(0);
  const allowRowClickRef = useRef<undefined | (() => void)>();

  // Return a new key whenever the dialog is closed, to ensure
  // that no state of a previously opened dialog is reused ...
  const key = counter;
  const open = counter % 2 === 1;
  const openDialog = () =>
    setCounter((value) => {
      if (value % 2 === 1) {
        // The dialog is already open.
        return value;
      }
      allowRowClickRef.current = preventRowClick?.();
      return value + 1;
    });
  const closeDialog = () =>
    setCounter((value) => {
      if (value % 2 === 0) {
        // The dialog is already closed.
        return value;
      }
      allowRowClickRef.current?.();
      return value + 1;
    });
  const dialogProps: DialogProps = { key, open, onClose: closeDialog };

  return { dialogProps, dialogOpen: open, openDialog, closeDialog };
}
