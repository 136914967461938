import type {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { z } from "zod";
import { applyFilter, applyPagination, applySort, zodDateTime } from "../backoffice.utils";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";

const TheoryExamSimulationResultSchema = z
  .object({
    errorPoints: z.number().int().nonnegative(),
    passed: z.boolean(),
    timestamp: zodDateTime(),
  })
  .transform((val) => ({
    id: val.timestamp.toMillis(), // <-- because every RaRecord needs an id property
    ...val,
  }));

export type TheoryExamSimulationResult = z.infer<typeof TheoryExamSimulationResultSchema>;

const ResultsDocSchema = z.object({ results: z.array(TheoryExamSimulationResultSchema) });

class TheoryExamSimulationResultsProvider {
  async create(_resource: string, _params: CreateParams): Promise<CreateResult<TheoryExamSimulationResult>> {
    throw new Error("not implemented");
  }

  async delete(
    _resource: string,
    _params: DeleteParams<TheoryExamSimulationResult>,
  ): Promise<DeleteResult<TheoryExamSimulationResult>> {
    throw new Error("not implemented");
  }

  async deleteMany(
    _resource: string,
    _params: DeleteManyParams<TheoryExamSimulationResult>,
  ): Promise<DeleteManyResult<TheoryExamSimulationResult>> {
    throw new Error("not implemented");
  }

  async getList(_resource: string, _params: GetListParams): Promise<GetListResult<TheoryExamSimulationResult>> {
    throw new Error("not implemented");
  }

  async getMany(_resource: string, _params: GetManyParams): Promise<GetManyResult<TheoryExamSimulationResult>> {
    throw new Error("not implemented");
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<TheoryExamSimulationResult>> {
    if (target !== "studentId") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentId"`);
    }
    const data = (await getDoc(doc(firestore, `/users/${id}/theory_learning/results`))).data();
    const results = data ? ResultsDocSchema.parse(data).results : [];
    return applyPagination(applySort(applyFilter(results, filter), sort), pagination);
  }

  async getOne(_resource: string, _params: GetOneParams): Promise<GetOneResult<TheoryExamSimulationResult>> {
    throw new Error("not implemented");
  }

  async update(_resource: string, _params: UpdateParams): Promise<UpdateResult<TheoryExamSimulationResult>> {
    throw new Error("not implemented");
  }

  async updateMany(
    _resource: string,
    _params: UpdateManyParams,
  ): Promise<UpdateManyResult<TheoryExamSimulationResult>> {
    throw new Error("not implemented");
  }
}

export const theoryExamSimulationResultsProvider = new TheoryExamSimulationResultsProvider();
