import ErrorIcon from "@mui/icons-material/Error";
import { Typography } from "@mui/material";
import { FieldProps, LinearProgress, useGetOne, useRecordContext } from "react-admin";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { Student } from "../providers/studentsProvider";

type StudentBranchFieldProps = {
  drivingSchoolId: string;
} & FieldProps;

export const StudentBranchField = ({ drivingSchoolId, emptyText }: StudentBranchFieldProps) => {
  const student = useRecordContext<Student>();
  const { data: drivingSchool, isLoading, error } = useGetOne<DrivingSchool>("drivingSchools", { id: drivingSchoolId });

  if (!student || isLoading) {
    return <LinearProgress />;
  }
  if (error || !drivingSchool) {
    return <ErrorIcon role="presentation" color="error" fontSize="small" />;
  }

  const assignedBranchId = student.branchId;
  if (!assignedBranchId) {
    return (
      <Typography component="span" variant="body2">
        {emptyText || "Nicht zugewiesen"}
      </Typography>
    );
  }

  const branch = drivingSchool.branches.find((branch) => branch.uid === assignedBranchId);
  if (!branch) {
    return <ErrorIcon role="presentation" color="error" fontSize="small" />;
  }

  return (
    <Typography component="span" variant="body2">
      {branch.name}
    </Typography>
  );
};
