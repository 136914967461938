import type { Student } from "../providers/studentsProvider";
import { Dialog, DialogTitle } from "@mui/material";
import type { DialogProps } from "../misc/DialogProps";
import { StudentAdministrativeOnboardingForm } from "../details/student/StudentAdministrativeOnboardingForm";
import { StudentAdministrativeOnboardingForChangersForm } from "../details/student/StudentAdministrativeOnboardingForChangersForm";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export function LicenseApplicationDialog({ student, ...dialogProps }: { student: Student } & DialogProps) {
  const { administrativeOnboardingState, administrativeOnboardingForChangersState } = student;

  let title: string;
  if (administrativeOnboardingState) {
    title = "Führerscheinantrag";
  } else if (administrativeOnboardingForChangersState) {
    title = "Fahrschulwechsel";
  } else {
    return null;
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogCloseButton onClose={dialogProps.onClose} />
      {administrativeOnboardingState && <StudentAdministrativeOnboardingForm onSave={dialogProps.onClose} />}
      {administrativeOnboardingForChangersState && (
        <StudentAdministrativeOnboardingForChangersForm onSave={dialogProps.onClose} />
      )}
    </Dialog>
  );
}
