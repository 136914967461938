/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckStripeCheckoutSessionRequest } from '../models/CheckStripeCheckoutSessionRequest';
import type { GetCurrentPaymentMethodResponse } from '../models/GetCurrentPaymentMethodResponse';
import type { GetSignUpOverviewDataRequest } from '../models/GetSignUpOverviewDataRequest';
import type { GetSignUpPersonalDataFormDataRequest } from '../models/GetSignUpPersonalDataFormDataRequest';
import type { GetSignUpUserCheckoutDataRequest } from '../models/GetSignUpUserCheckoutDataRequest';
import type { GetStripeSetupCheckoutSessionUrlRequest } from '../models/GetStripeSetupCheckoutSessionUrlRequest';
import type { OnboardingState } from '../models/OnboardingState';
import type { PatchedOnboardingState } from '../models/PatchedOnboardingState';
import type { SetupCheckoutSessionResponse } from '../models/SetupCheckoutSessionResponse';
import type { SignUpOverviewData } from '../models/SignUpOverviewData';
import type { SignUpPersonalDataFormData } from '../models/SignUpPersonalDataFormData';
import type { SignUpUserCheckoutData } from '../models/SignUpUserCheckoutData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WebAppEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns OnboardingState
   * @throws ApiError
   */
  public webAppOnboardingStateRetrieve({
    userFirebaseAuthUsersId,
  }: {
    /**
     * A unique value identifying this onboarding state.
     */
    userFirebaseAuthUsersId: string,
  }): CancelablePromise<OnboardingState> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/web-app/onboarding-state/{user__firebase_auth_users__id}/',
      path: {
        'user__firebase_auth_users__id': userFirebaseAuthUsersId,
      },
    });
  }

  /**
   * @returns OnboardingState
   * @throws ApiError
   */
  public webAppOnboardingStateUpdate({
    userFirebaseAuthUsersId,
    requestBody,
  }: {
    /**
     * A unique value identifying this onboarding state.
     */
    userFirebaseAuthUsersId: string,
    requestBody?: OnboardingState,
  }): CancelablePromise<OnboardingState> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/web-app/onboarding-state/{user__firebase_auth_users__id}/',
      path: {
        'user__firebase_auth_users__id': userFirebaseAuthUsersId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns OnboardingState
   * @throws ApiError
   */
  public webAppOnboardingStatePartialUpdate({
    userFirebaseAuthUsersId,
    requestBody,
  }: {
    /**
     * A unique value identifying this onboarding state.
     */
    userFirebaseAuthUsersId: string,
    requestBody?: PatchedOnboardingState,
  }): CancelablePromise<OnboardingState> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/web-app/onboarding-state/{user__firebase_auth_users__id}/',
      path: {
        'user__firebase_auth_users__id': userFirebaseAuthUsersId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SignUpUserCheckoutData
   * @throws ApiError
   */
  public webAppSignUpUserGetCheckoutDataCreate({
    requestBody,
  }: {
    requestBody: GetSignUpUserCheckoutDataRequest,
  }): CancelablePromise<SignUpUserCheckoutData> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/web-app/sign-up-user/get-checkout-data/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SignUpOverviewData
   * @throws ApiError
   */
  public webAppSignUpGetOverviewDataCreate({
    requestBody,
  }: {
    requestBody: GetSignUpOverviewDataRequest,
  }): CancelablePromise<SignUpOverviewData> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/web-app/sign-up/get-overview-data/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SignUpPersonalDataFormData
   * @throws ApiError
   */
  public webAppSignUpGetPersonalDataFormDataCreate({
    requestBody,
  }: {
    requestBody: GetSignUpPersonalDataFormDataRequest,
  }): CancelablePromise<SignUpPersonalDataFormData> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/web-app/sign-up/get-personal-data-form-data/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any No response body
   * @throws ApiError
   */
  public webAppStripeSetupCheckStripeCheckoutSessionCreate({
    requestBody,
  }: {
    requestBody: CheckStripeCheckoutSessionRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/web-app/stripe-setup/check-stripe-checkout-session/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns GetCurrentPaymentMethodResponse
   * @throws ApiError
   */
  public webAppStripeSetupCurrentPaymentMethodRetrieve(): CancelablePromise<GetCurrentPaymentMethodResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/web-app/stripe-setup/current-payment-method/',
    });
  }

  /**
   * @returns SetupCheckoutSessionResponse
   * @throws ApiError
   */
  public webAppStripeSetupSetupStripeCheckoutSessionCreate({
    requestBody,
  }: {
    requestBody: GetStripeSetupCheckoutSessionUrlRequest,
  }): CancelablePromise<SetupCheckoutSessionResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/web-app/stripe-setup/setup-stripe-checkout-session/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any No response body
   * @throws ApiError
   */
  public webAppStripeSetupSyncPaymentMethodToUserCreate(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/web-app/stripe-setup/sync-payment-method-to-user/',
    });
  }

}
