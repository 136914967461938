import { atom, atomFamily } from "recoil";
import { recoilPersist } from "recoil-persist";
import { PaginationPayload, SortPayload } from "react-admin";

const { persistAtom } = recoilPersist();

export interface AutovioContext {
  drivingSchoolId?: string;
}

export const persistentAutovioContext = atom<AutovioContext>({
  key: "autovioContext",
  default: {},
  effects: [persistAtom],
});

export const persistentGlobalDrivingSchoolFilterState = atom<string>({
  key: "globalDrivingSchoolFilterState",
  default: "",
  effects: [persistAtom],
});

export const persistentPaginationStateFamily = atomFamily<Omit<PaginationPayload, "page">, string>({
  key: "paginationState",
  default: { perPage: 25 },
  effects: [persistAtom],
});

export const persistentSortStateFamily = atomFamily<SortPayload | undefined, string>({
  key: "sortState",
  default: undefined,
  effects: [persistAtom],
});
