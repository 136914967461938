export function ReportIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 15.825V13l7-7 4 4 7-7v2.825l-7 7-4-4-7 7zM19 21V11l2-2v12h-2zM7 21v-6l2-2v8H7zm4 0v-8l2 2.025V21h-2zm4 0v-5.975l2-2V21h-2zM3 21v-2l2-2v4H3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
