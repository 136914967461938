import { BooleanInput, EditBase, Form, FormDataConsumer, SaveButton, SelectInput } from "react-admin";
import { approvalStatusChoices } from "../../model/approvalStatusChoices";
import { ExaminationAssignmentReceivedAtInput } from "../../inputs/ExaminationAssignmentExpiresAtInput";
import { ExaminationAssignmentExpiresAtInput } from "../../inputs/ExaminationAssignmentReceivedAtInput";
import { DialogActions, DialogContent } from "@mui/material";

export function StudentAdministrativeOnboardingForChangersForm({ onSave }: { onSave: () => void }) {
  return (
    <EditBase redirect={false} mutationMode="pessimistic" mutationOptions={{ onSuccess: onSave }}>
      <Form shouldUnregister warnWhenUnsavedChanges>
        <DialogContent sx={{ width: "360px" }}>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.administrativeOnboardingForChangersState.changeApproved === "pending") {
                return (
                  <>
                    <BooleanInput
                      label="Alte Fahrschule gekündigt"
                      source="administrativeOnboardingForChangersState.oldContractTerminated"
                    />
                    <BooleanInput
                      label="Ausbildungsnachweis liegt vor"
                      source="administrativeOnboardingForChangersState.trainingCertificateSent"
                    />
                    <BooleanInput
                      label="Bei Behörde angezeigt"
                      source="administrativeOnboardingForChangersState.changeSubmitted"
                    />
                  </>
                );
              }
            }}
          </FormDataConsumer>
          <SelectInput
            sx={{ width: "15em" }}
            label="Status"
            source="administrativeOnboardingForChangersState.changeApproved"
            choices={approvalStatusChoices}
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.administrativeOnboardingForChangersState.changeApproved === "approved" && (
                <>
                  <ExaminationAssignmentReceivedAtInput />
                  <ExaminationAssignmentExpiresAtInput />
                </>
              )
            }
          </FormDataConsumer>
        </DialogContent>
        <DialogActions>
          <SaveButton />
        </DialogActions>
      </Form>
    </EditBase>
  );
}
