/**
 * The message of a `LocalizedError` should be shown to the user,
 * the `LocalizedError` should not be reported to Sentry.
 */
export class LocalizedError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, LocalizedError.prototype);
  }
}
