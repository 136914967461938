import { doc, getDoc } from "firebase/firestore";
import { GetOneParams, GetOneResult } from "react-admin";
import { ZodError, z } from "zod";
import { firestore } from "../firebase";

const PreferredDurationsPerDrivingLessonTypeSchema = z.object({
  normal: z.array(z.number().int().nonnegative()),
});

const RecommendationSettingsSchema = z.object({
  maxDrivingLessonsPerWeek: z.number().int().nonnegative(),
  maxDaysBookableInAdvance: z.number().int().nonnegative(),
  pauseBetweenDrivingLessonsMins: z.number().int().nonnegative(),
  preferredDurationMinsPerDrivingLessonType: PreferredDurationsPerDrivingLessonTypeSchema,
});

export type RecommendationSettings = z.infer<typeof RecommendationSettingsSchema> & { id: string };

class InstructorRecommendationSettingsProvider {
  async getOne(_: string, { id }: GetOneParams): Promise<GetOneResult<RecommendationSettings>> {
    const snapshot = await getDoc(doc(firestore, `/users/${id}/preferences/recommendation_settings`));
    try {
      const recommendationSettings = RecommendationSettingsSchema.parse(snapshot.data());
      return { data: { id, ...recommendationSettings } };
    } catch (error) {
      const { name, message } = error as ZodError;
      return Promise.reject(
        new Error(`Failed to parse recommendation settings for instructor ${id}: ${name} ${message}`),
      );
    }
  }
}

export const instructorRecommendationSettingsProvider = new InstructorRecommendationSettingsProvider();
