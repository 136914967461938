import { Tabs } from "../../misc/Tabs";
import { Tab } from "react-admin";
import { StudentNotesList } from "./StudentNotesList";
import { StudentDocumentsList } from "./StudentDocumentsList";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { autovioColors, overviewCardStyle } from "../../misc/backofficeTheme";
import { useRef } from "react";

export function StudentNotesAndDocuments() {
  const { green, greenUltraLight } = autovioColors;
  const ref = useRef<{ showForm: () => void }>();

  return (
    <Box sx={{ ...overviewCardStyle, marginTop: 1 }}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 2.5, paddingBottom: 2 }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: 15, marginBottom: "10px" }}>Notizen & Dokumente</Typography>
        <IconButton
          sx={{ background: greenUltraLight, color: green, width: 30, height: 30 }}
          onClick={() => ref.current?.showForm()}
        >
          <AddIcon style={{ fontSize: 20 }} />
        </IconButton>
      </Box>
      <Tabs sx={{ mx: "20px" }} syncWithLocation={false} fullWidth>
        <Tab label="Notizen">
          <StudentNotesList ref={ref} />
        </Tab>
        <Tab label="Dokumente">
          <StudentDocumentsList ref={ref} />
        </Tab>
      </Tabs>
    </Box>
  );
}
