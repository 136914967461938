import { Avatar, Box, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import {
  FunctionField,
  Link,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  Tab,
  TextField,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { grants } from "../backoffice.access_control";
import { OpenInFirestoreButton } from "../buttons/OpenInFirestoreButton";
import { OpenInHubspotButton } from "../buttons/OpenInHubspotButton";
import { OpenInStripeButton } from "../buttons/OpenInStripeButton";
import { CommaSeparatedFieldList } from "../misc/CommaSeparatedFieldList";
import { StudentDrivingLessonsList } from "./student/StudentDrivingLessonsList";
import { StudentTheoryLessonsList } from "./student/StudentTheoryLessonsList";
import { autovioColors } from "../misc/backofficeTheme";
import { Student } from "../providers/studentsProvider";
import { StudentBalanceList } from "./student/StudentBalanceList";
import { ChangeInstructorsLink } from "../links/ChangeInstructorsLink";
import { ChangeStatusLink } from "../links/ChangeStatusLink";
import { StudentCredits } from "./student/StudentCredits";
import { StudentAdminForm } from "./student/StudentAdminForm";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { StudentNotesAndDocuments } from "./student/StudentNotesAndDocuments";
import { StudentOverviewTab } from "./student/StudentOverviewTab";
import { StudentBookedBundles } from "./student/StudentBookedBundles";
import { StudentInvoicesList } from "./student/StudentInvoicesList";
import { Tabs } from "../misc/Tabs";
import { ShowBase } from "../misc/ShowBase";
import { Row } from "../misc/Row";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { PageTitle } from "../misc/PageTitle";
import { MoneyField } from "../fields/MoneyField";
import { MenuTab } from "../misc/MenuTab";
import { MenuTabItem } from "../misc/MenuTabItem";
import EditPaymentStrategyDialogButton, { PaymentStrategyChoices } from "../buttons/EditPaymentStrategyDialogButton";
import { useEffect, useState } from "react";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { StudentProfileTab } from "./student/StudentProfileTab";
import { StudentStickyNotesList } from "./student/StudentStickyNotesList";
import { StudentInAppNotificationsList } from "./student/StudentInAppNotificationsList";
import { StudentBranchField } from "../fields/StudentBranchField";
import { SetStudentBranchButton } from "../buttons/SetStudentBranchButton";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";

export function StudentDetails() {
  const location = useLocation();
  if (location.pathname.endsWith("/packages")) {
    return (
      <ShowBase>
        <Typography sx={{ fontWeight: "bold", fontSize: 24, marginBottom: 2 }}>Gebuchte Pakete</Typography>
        <StudentBookedBundles />
      </ShowBase>
    );
  }
  return (
    <ShowBase>
      <StudentDetailsTabs />
      {/** Add some space at the bottom of the page ... */}
      <Box height="20px" />
    </ShowBase>
  );
}

function StudentButtons() {
  const student = useRecordContext<Student>();
  const { greyUltraLight, green } = autovioColors;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${student.phoneNumber}`;
  };

  const handleEmailClick = () => {
    const url = `https://app.hubspot.com/contacts/19951845/record/0-1/${student.hubspotContactId}/?interaction=email`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Row sx={{ display: "block" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <IconButton sx={{ background: greyUltraLight, color: green }} onClick={handlePhoneClick}>
          <LocalPhoneIcon style={{ fontSize: 20 }} />
        </IconButton>
        <IconButton sx={{ background: greyUltraLight, color: green }} onClick={handleEmailClick}>
          <AlternateEmailIcon sx={{ fontSize: 20 }} />
        </IconButton>
        {grants.includes("admin") && (
          <IconButton sx={{ background: greyUltraLight, color: green }} onClick={handleClick}>
            <MoreVertIcon sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ width: 220 }}>
        {grants.includes("viewStripe") && (
          <MenuItem onClick={handleClose}>
            <OpenInStripeButton />
          </MenuItem>
        )}

        {grants.includes("viewHubspot") && (
          <MenuItem onClick={handleClose}>
            <OpenInHubspotButton showTitle />
          </MenuItem>
        )}

        {grants.includes("viewFirestore") && (
          <MenuItem onClick={handleClose}>
            <OpenInFirestoreButton showTitle />
          </MenuItem>
        )}
      </Menu>
    </Row>
  );
}

function StudentDetailsHeader({ student }: { student: Student }) {
  function _renderPaymentMethod(student: Student) {
    const { paymentMethod } = student;
    switch (paymentMethod) {
      case "card":
        return "Kreditkarte";
      case "sepa":
        return "Lastschrift";
      default:
        return "Keine Angabe";
    }
  }

  const role = student.bookedTrainings.every((it) => it.bundleName.includes("ASF"))
    ? "ASF-Kurs-Teilnehmer"
    : "Fahrschüler";

  return (
    <Row sx={{ my: "20px" }} spacing="50px" alignItems="center">
      <Row spacing="20px">
        <Avatar src={student.avatarUrl} sx={{ width: "60px", height: "60px" }} />
        <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
          <PageTitle>{student.name}</PageTitle>
          <Typography sx={{ marginTop: "-5px", color: autovioColors.grey, fontSize: "14px" }}>{role}</Typography>
        </Box>
      </Row>
      <BranchField />
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        <Typography color={autovioColors.grey} variant="caption">
          Fahrlehrer (<ChangeInstructorsLink label="Zuweisung ändern" />)
        </Typography>
        <ReferenceArrayField label="Fahrlehrer" reference="instructors" source="instructorIds">
          <CommaSeparatedFieldList>
            <ReferenceField source="id" reference="instructors" />
          </CommaSeparatedFieldList>
        </ReferenceArrayField>
      </Stack>
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        <Typography color={autovioColors.grey} variant="caption">
          Status (<ChangeStatusLink label={"Status ändern"} />)
        </Typography>
        <TextField label="Status" source="verboseStatus" />
      </Stack>
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        {grants.includes("viewDunning") && (
          <>
            <Typography color={autovioColors.grey} variant="caption">
              Saldo
            </Typography>
            <Link to={`/students/${student.id}/balance`}>
              <MoneyField source="balance" redForNegativeValues />
            </Link>
          </>
        )}
      </Stack>
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        <Typography color={autovioColors.grey} variant="caption">
          Zahlungsmodus
        </Typography>
        <Row spacing={1}>
          <SelectField label="Name" source="paymentStrategy" choices={PaymentStrategyChoices} />
          {grants.includes("editStudentPaymentStrategy") && <EditPaymentStrategyDialogButton />}
        </Row>
      </Stack>
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        {grants.includes("viewDunning") && student.paymentStrategy !== "purchaseOnAccount" && (
          <>
            <Typography color={autovioColors.grey} variant="caption">
              Zahlungsmethode
            </Typography>
            <FunctionField render={_renderPaymentMethod} />
          </>
        )}
      </Stack>
    </Row>
  );
}

function BranchField() {
  const student = useRecordContext();
  const { data: drivingSchool } = useGetOne<DrivingSchool>("drivingSchools", { id: student.drivingSchoolId });
  if (drivingSchool && drivingSchool?.branches?.length > 1) {
    return (
      <Row>
        <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
          <Typography color={autovioColors.grey} variant="caption">
            Filiale
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StudentBranchField drivingSchoolId={student.drivingSchoolId} />
          </Box>
        </Stack>
        <SetStudentBranchButton />
      </Row>
    );
  }

  return null;
}

function StudentDetailsTabs() {
  const student = useRecordContext<Student>();
  const [autovioContext, setAutovioContext] = useAutovioContext();
  useEffect(() => {
    if (student && student.drivingSchoolId !== autovioContext.drivingSchoolId) {
      setAutovioContext({ drivingSchoolId: student.drivingSchoolId });
    }
  }, [autovioContext.drivingSchoolId, student?.id]);

  if (!student) {
    return <LoadingIndicator />;
  }

  return (
    <Tabs
      sx={{ borderRadius: "12px", border: "1px solid #e5e5e5", padding: "25px 30px" }}
      header={<StudentDetailsHeader student={student} />}
      additionalButtons={<StudentButtons />}
      rightColumn={<StudentNotesAndDocuments />}
      separateCards
    >
      <Tab label="Überblick" /* no path attribute here, so the tab is shown by default */>
        <StudentOverviewTab student={student} />
      </Tab>
      <Tab label="Stammdaten" path="profile">
        <StudentProfileTab />
      </Tab>
      <Tab label="Theorie" path="theoryLessons">
        <StudentTheoryLessonsList />
      </Tab>
      <Tab label="Fahrstunden" path="drivingLessons">
        <StudentDrivingLessonsList />
      </Tab>
      <MenuTab label="Bezahlung">
        {grants.includes("viewBackendAccounts") && (
          <MenuTabItem label="Guthaben & Prämien" path="accounts">
            <StudentCredits />
          </MenuTabItem>
        )}
        {grants.includes("viewStudentBalance") && (
          <MenuTabItem label="Saldo" path="balance">
            <StudentBalanceList />
          </MenuTabItem>
        )}
        <MenuTabItem label="Rechnungen" path="invoices">
          <StudentInvoicesList />
        </MenuTabItem>
      </MenuTab>
      {grants.includes("admin") && (
        <Tab label="Admin" path="admin">
          <StudentAdminForm />
          <Row spacing={1}>
            <Box>
              <h4 style={{ marginTop: 0 }}>Sticky Notes</h4>
              <StudentStickyNotesList />
            </Box>
            <Box>
              <h4 style={{ marginTop: 0 }}>In-App-Benachrichtigungen</h4>
              <StudentInAppNotificationsList />
            </Box>
          </Row>
        </Tab>
      )}
    </Tabs>
  );
}
