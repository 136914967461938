import { IconButton } from "@mui/material";
import Icon from "@mui/icons-material/Queue";
import { Link } from "react-router-dom";
import { autovioColors } from "../misc/backofficeTheme";
import { useCreatePath, useRecordContext, useResourceContext } from "react-admin";
import { stringify } from "query-string";

export function CloneIconButton() {
  const resource = useResourceContext();
  const record = useRecordContext();
  const createPath = useCreatePath();

  if (!record) {
    return null;
  }

  const { id: _, ...recordWithoutId } = record;

  return (
    <IconButton
      component={Link}
      to={{
        pathname: createPath({ resource, type: "create" }),
        search: stringify({ source: JSON.stringify(recordWithoutId) }),
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <Icon sx={{ fill: autovioColors.green }} />
    </IconButton>
  );
}
