import { useAuthProvider } from "react-admin";
import { FirebaseAuthProvider } from "../providers/firebaseAuthProvider";
import { Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

export function AccessDeniedPage() {
  const de = (() => {
    try {
      for (const language of navigator.languages) {
        if (language.startsWith("de")) {
          return true;
        } else if (language.startsWith("en")) {
          return false;
        }
      }
    } catch {
      return false;
    }
    return false;
  })();
  const authProvider = useAuthProvider() as FirebaseAuthProvider;
  return (
    <>
      <h1 style={{ margin: "1em", position: "absolute", top: 0, left: 0 }}>{"AUTOVIO\xA0Backoffice"}</h1>
      <div className="centered">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div className="error" style={{ fontWeight: "bold", fontSize: "2em", marginBottom: "20px" }}>
            {de ? "Keine Berechtigung" : "Access denied"}
          </div>
          <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => authProvider.logout()}>
            Logout
          </Button>
        </div>
      </div>
    </>
  );
}
