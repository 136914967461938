import { useCallback, useMemo } from "react";
import { ReferenceManyField, useGetManyReference, useGetRecordId, useListContext } from "react-admin";
import { Avatar, Box, Card, LinearProgress, Typography } from "@mui/material";
import { z } from "zod";
import { TheoryExamWorkflow } from "../../model/TheoryExamWorkflow";
import keyBy from "lodash/keyBy";
import { Student } from "../../providers/studentsProvider";
import { autovioColors } from "../../misc/backofficeTheme";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";
import { useSetRecoilState } from "recoil";
import { WorkflowDialog, workflowShownInWorkflowDialogState } from "../../misc/WorkflowDialog";
import { DateTime } from "luxon";
import { formatCalendarWeek } from "../../utils/calendar";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { ListCheckIcon } from "../../icons/ListCheckIcon";
import useResizeObserver from "use-resize-observer";
import { PreWorkflowDialog, studentShownInPreWorkflowDialogState } from "../../misc/PreWorkflowDialog";
import { PageTitle } from "../../misc/PageTitle";

export function DrivingSchoolTheoryExamWorkflowsBoard() {
  return (
    <ReferenceManyField
      reference="students"
      target="drivingSchoolId"
      filter={{ status: "active", hasPassedTheoryExam: false }}
      perPage={9999}
    >
      <_DrivingSchoolTheoryExamWorkflowsBoard />
    </ReferenceManyField>
  );
}

const StageEnum = z.enum([
  "Bereit?",
  "Warte auf Terminwunsch vom Fahrschüler",
  "Prüfung organisieren",
  "Prüfung geplant",
]);
type Stage = z.infer<typeof StageEnum>;

interface CardData {
  student: Student;
  workflow: undefined | TheoryExamWorkflow;
  date: undefined | DateTime;
}

type BoardData = Record<Stage, Array<CardData>>;

function _DrivingSchoolTheoryExamWorkflowsBoard() {
  const drivingSchoolId = useGetRecordId();
  const { data: students, isLoading: isLoading1 } = useListContext<Student>();
  const { data: workflows, isLoading: isLoading2 } = useGetManyReference("workflows", {
    target: "drivingSchoolUid",
    id: drivingSchoolId,
    filter: {
      type: "theoryExamSignUp" satisfies TheoryExamWorkflow["type"],
      finished: false,
    },
    pagination: { page: 1, perPage: 9999 },
  });
  const isLoading = isLoading1 || isLoading2;
  const boardData = useMemo(() => {
    const boardData = Object.fromEntries(StageEnum.options.map((stage) => [stage, [] as Array<CardData>])) as BoardData;
    if (!(students && workflows)) {
      return boardData;
    }
    const workflowsByStudentId = keyBy(workflows, (workflow: TheoryExamWorkflow) => workflow.workflowData.studentUid);
    const addCardToStage = (stage: Stage, student: Student, workflow?: TheoryExamWorkflow) => {
      const card: CardData = {
        student,
        workflow,
        date: workflow ? DateTime.fromFormat(workflow.workflowData.preferredDates[0], "yyyy-MM-dd") : undefined,
      };
      const cards = boardData[stage];
      const { date } = card;
      if (date) {
        // Insert card int the correct position (cards should be sorted by calendar week) ...
        const i = cards.findIndex((it) => it.date! > date);
        if (i >= 0) {
          cards.splice(i, 0, card);
        } else {
          cards.push(card);
        }
      } else {
        cards.push(card);
      }
    };
    for (const student of students) {
      const workflow = workflowsByStudentId[student.id];
      if (workflow?.tasks["time_proposal_sent"].isDone) {
        addCardToStage("Prüfung geplant", student, workflow);
      } else if (workflow) {
        addCardToStage("Prüfung organisieren", student, workflow);
      } else if (student.isReadyForTheoryExam) {
        addCardToStage("Warte auf Terminwunsch vom Fahrschüler", student);
      } else if (student.maybeReadyForTheoryExam) {
        addCardToStage("Bereit?", student);
      }
    }
    return boardData;
  }, [students, workflows]);
  const { ref: columnHeaderRef, height: columnHeaderHeight } = useResizeObserver({ box: "border-box" });

  return (
    // Because each column has a margin of 5px to the left and to right, but we don't want that margin
    // on the left of the first column/the right of the last column, we use a margin of -5px here ...
    <div style={{ marginLeft: "-5px", marginRight: "-5px" }}>
      <PageTitle>Theorieprüfungen</PageTitle>
      <Box display="flex" sx={{ mt: "10px" }}>
        {StageEnum.options.map((stage) => (
          <_Column
            key={stage}
            stage={stage}
            // Give each column header the same height. Use the height of the column with the longest title as reference ...
            headerHeight={stage === "Warte auf Terminwunsch vom Fahrschüler" ? undefined : columnHeaderHeight}
            headerRef={stage === "Warte auf Terminwunsch vom Fahrschüler" ? columnHeaderRef : undefined}
            data={boardData[stage]}
          />
        ))}
      </Box>
      <PreWorkflowDialog />
      <WorkflowDialog />
      {isLoading && <LoadingIndicator />}
    </div>
  );
}

function _Column({
  stage,
  headerHeight,
  headerRef,
  data,
}: {
  stage: Stage;
  headerHeight?: number;
  headerRef?: React.Ref<any>;
  data: Array<CardData>;
}) {
  return (
    <Box
      sx={{
        m: "0 5px",
        borderRadius: "8px",
        flex: 1,
        paddingTop: "15px",
        paddingBottom: "15px",
        bgcolor: autovioColors.greyUltraLight,
      }}
    >
      <Typography
        ref={headerRef}
        align="center"
        variant="subtitle1"
        sx={{
          color: autovioColors.grey,
          pb: "10px",
          lineHeight: 1.43,
          ...(headerHeight ? { height: `${headerHeight}px` } : {}),
        }}
      >
        {stage}
      </Typography>
      <Box
        sx={{
          minHeight: "200px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "5px 10px",
          "&.isDraggingOver": {
            bgcolor: autovioColors.greyLight,
          },
        }}
      >
        {data.map((cardData) => (
          <_Card key={cardData.student.id} data={cardData} />
        ))}
      </Box>
    </Box>
  );
}

function _Card({ data: { student, workflow, date } }: { data: CardData }) {
  const numTasks = workflow ? _numTasks(workflow) : 7;
  const numDoneTasks = workflow ? _numDoneTasks(workflow) : 0;
  const setStudentShownInDialog = useSetRecoilState(studentShownInPreWorkflowDialogState);
  const setWorkflowShownInDialog = useSetRecoilState(workflowShownInWorkflowDialogState);

  const handleClick = useCallback(() => {
    if (workflow) {
      setWorkflowShownInDialog(workflow);
    } else {
      setStudentShownInDialog(student);
    }
  }, [workflow]);

  return (
    <Box sx={{ marginBottom: 1, cursor: "pointer" }} onClick={handleClick}>
      <Card
        style={{
          borderRadius: "8px",
          boxShadow: "1px 1px 0 0 rgba(0, 0, 0, 0.15)",
        }}
        elevation={1}
      >
        <Column padding={1}>
          <Row sx={{ mb: "8px" }}>
            <Avatar src={student.avatarUrl} sx={{ height: "20px", width: "20px" }} />
            <Typography component="span" variant="body2" sx={{ ml: "7px" }}>
              {student.name}
            </Typography>
          </Row>
          <LinearProgress
            variant="determinate"
            value={(100 * numDoneTasks) / numTasks}
            color={workflow ? "primary" : "inherit"}
          />
          <Row sx={{ mt: "8px" }}>
            <ListCheckIcon sx={{ mt: "-1px", color: autovioColors.grey, opacity: workflow ? 1 : 0.5 }} />
            <p
              style={{ margin: 0, marginLeft: "5px", fontSize: "12px", color: autovioColors.grey }}
            >{`${numDoneTasks} / ${numTasks}`}</p>
            <div style={{ flex: 1 }} />
            {date && (
              <p style={{ margin: 0, fontSize: "12px", color: autovioColors.grey }}>{formatCalendarWeek(date)}</p>
            )}
          </Row>
        </Column>
      </Card>
    </Box>
  );
}

function _numDoneTasks(workflow: TheoryExamWorkflow): number {
  return Object.values(workflow.tasks).filter((task) => task.isDone).length;
}

function _numTasks(workflow: TheoryExamWorkflow): number {
  return Object.keys(workflow.tasks).length;
}
