import { Error, Layout, LayoutProps, useCheckForApplicationUpdate, useLoading } from "react-admin";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "react-query/devtools";
import { BackofficeAppBar } from "./BackofficeAppBar";
import BackofficeSideBar from "./BackofficeSideBar";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const BackofficeLayout = ({ children, error: errorComponent, className, ...rest }: LayoutProps) => {
  useCheckForApplicationUpdate({
    onNewVersionAvailable: () => {
      // Enforce update on next navigation ...
      window.onNavigation = () => location.reload();
    },
  });
  const { pathname } = useLocation();
  const appBar: any = BackofficeAppBar;
  const sidebar: any = BackofficeSideBar;
  if (className) {
    className += " new-design";
  } else {
    className = "new-design";
  }
  return (
    <>
      <Layout className={className} {...rest} appBar={appBar} sidebar={sidebar} appBarAlwaysOn>
        {pathname !== "/" && (
          <div id="react-admin-title">
            {/* this is a React portal, usually filled by the BackofficeListTitle component */}
          </div>
        )}
        <Sentry.ErrorBoundary
          fallback={({ error, componentStack, resetError }) => (
            <Error
              error={error}
              errorComponent={errorComponent}
              errorInfo={{ componentStack }}
              resetErrorBoundary={resetError}
            />
          )}
        >
          {children}
        </Sentry.ErrorBoundary>
      </Layout>
      {location.hostname === "localhost" && <ReactQueryDevtools initialIsOpen={false} />}
      <LoadingIndicator />
    </>
  );
};

/**
 * Side effects:
 * - update window.loading to reflect the current loading state,
 * - update window.loadingCounter whenever the loading state changes from false to true.
 */
function LoadingIndicator() {
  const loading = useLoading();
  if (!loading) {
    window.loading = false;
    return null;
  }
  if (!window.loading) {
    window.loading = true;
    window.loadingCounter = (window.loadingCounter ?? 0) + 1;
  }
  return (
    <div style={{ position: "fixed", left: "12px", bottom: "6px", zIndex: 999999 }}>
      <CircularProgress id="LoadingIndicator" color="inherit" size="40px" thickness={5} />
    </div>
  );
}
