import { Labeled, Link, RecordContextProvider, useGetMany, useGetManyReference, useNotify } from "react-admin";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { atom, useRecoilState } from "recoil";
import { MouseEventHandler, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Row } from "./Row";
import { StudentAvatar } from "./StudentAvatar";
import { StudentDisplayName } from "./StudentDisplayName";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Student } from "../providers/studentsProvider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSetReadyForTheoryExamMutation } from "../api/backoffice.api";
import { DateTime } from "luxon";
import { TheoryLesson } from "../model/autovioCalendarEvents";
import { Training } from "../providers/catalogProvider";
import { StudentTheoryLearningProgressField } from "../fields/StudentTheoryLearningProgressField";
import { StudentTheoryExamSimulationsField } from "../fields/StudentTheoryExamSimulationsField";
import { Column } from "./Column";
import { DialogCloseButton } from "./DialogCloseButton";
import { autovioColors } from "./backofficeTheme";

export const studentShownInPreWorkflowDialogState = atom<null | Student>({
  key: "studentShownInPreWorkflowDialog",
  default: null,
});

export function PreWorkflowDialog() {
  const notify = useNotify();
  const [student, setStudentShownInDialog] = useRecoilState(studentShownInPreWorkflowDialogState);
  const closeDialog = () => setStudentShownInDialog(null);
  const isDialogOpen = !!student;
  const [saving, setSaving] = useState(false);
  const setReadyForTheoryExamMutation = useSetReadyForTheoryExamMutation();

  const markStudentAsReadyForTheoryExam = async () => {
    if (!student) {
      throw new Error(`Unexpected state: student: ${student}`);
    }
    setSaving(true);
    let success = false;
    try {
      await setReadyForTheoryExamMutation.mutateAsync({ studentUid: student.id, isReady: true });
      success = true;
    } catch (error) {
      console.error(`Failed to mark student ${student.id} as ready for theory exam`, error);
      notify(`Fehler beim Starten der Organisation der Theorieprüfung von ${student.name}.`, { type: "error" });
    }
    if (success) {
      closeDialog();
      notify(`${student.name} wurde erfolgreich benachrichtigt, Terminwünsche anzugeben.`, { type: "success" });
    }
  };

  return (
    <Dialog open={isDialogOpen} onClose={closeDialog}>
      <DialogTitle>Theorieprüfung</DialogTitle>
      {student && <_PopupMenu student={student} closeDialog={closeDialog} />}
      <DialogCloseButton onClose={closeDialog} />
      <DialogContent>{student && <_StudentData student={student} />}</DialogContent>
      <DialogActions>
        {student && !student.isReadyForTheoryExam && (
          <LoadingButton variant="contained" loading={saving} onClick={markStudentAsReadyForTheoryExam}>
            Organisation starten
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

function _StudentData({ student }: { student: Student }) {
  const { data: theoryLessons } = useGetManyReference<TheoryLesson>("theoryLessons", {
    target: "studentUids",
    id: student.id,
    filter: { types: ["TheoryLesson"] },
  });
  const attendedTheoryLessons = theoryLessons?.filter((it) => it.students[student.id]?.attended);
  const { data: trainings } = useGetMany<Training>("trainings", {
    ids: student.bookedTrainings.map((it) => it.trainingId),
  });
  const numberOfRequiredTheoryLessons: undefined | number = useMemo(() => {
    if (trainings && trainings.length === student.bookedTrainings.length) {
      try {
        return _getNumberOfRequiredTheoryLessonsForTheoryExam(trainings);
      } catch (error) {
        console.error(`Failed to compute number of required theory lessons for student ${student.id}`, error);
        return undefined;
      }
    }
  }, [student, trainings]);

  return (
    <RecordContextProvider value={student}>
      <Column spacing={2}>
        <Labeled label="Fahrschüler">
          <Row spacing={1} sx={{ alignItems: "center" }}>
            <StudentAvatar studentId={student.id} size="40px" />
            <Column>
              <StudentDisplayName variant="body1" studentId={student.id} />
              {student.dateOfBirth && (
                <Typography variant="body2">({student.dateOfBirth.toFormat("dd.MM.yyyy")})</Typography>
              )}
            </Column>
            <Link to={`/students/${student.id}`} target="_blank">
              <OpenInNewIcon sx={{ mt: "6px" }} />
            </Link>
            {student?.isSchoolChanger && <span>(Fahrschulwechsler)</span>}
          </Row>
        </Labeled>
        <Labeled label="Prüfauftrag eingegangen am">
          <Typography variant="body2">
            {student.examinationAssignmentReceivedAt &&
              DateTime.fromISO(student.examinationAssignmentReceivedAt).toFormat("dd.MM.yyyy")}
          </Typography>
        </Labeled>
        <Labeled label="Besuchte Theoriestunden">
          <Typography variant="body2">
            {attendedTheoryLessons?.length ?? "..."} / {numberOfRequiredTheoryLessons ?? "..."}
          </Typography>
        </Labeled>
        <Labeled label="Lernstand">
          <StudentTheoryLearningProgressField />
        </Labeled>
        <Labeled label="Prüfungssimulationen">
          <StudentTheoryExamSimulationsField />
        </Labeled>
      </Column>
    </RecordContextProvider>
  );
}

function _PopupMenu({ student, closeDialog }: { student: Student; closeDialog: () => void }) {
  const notify = useNotify();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openMenu: MouseEventHandler<HTMLElement> = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const [saving, setSaving] = useState(false);
  const setReadyForTheoryExamMutation = useSetReadyForTheoryExamMutation();

  const markStudentAsNotReadyForTheoryExam = async () => {
    setSaving(true);
    let success = false;
    try {
      await setReadyForTheoryExamMutation.mutateAsync({ studentUid: student.id, isReady: false });
      success = true;
    } catch (error) {
      console.error(`Failed to mark student ${student.id} as not ready for theory exam`, error);
      notify("Fehler beim Löschen der Anfrage.", { type: "error" });
    } finally {
      closeMenu();
      // [UX] the menu item should stay disabled while the close menu animation is running ...
      setTimeout(() => setSaving(false), 500);
    }
    if (success) {
      closeDialog();
      notify("Anfrage erfolgreich gelöscht.", { type: "success" });
    }
  };

  return (
    <div>
      <IconButton sx={{ position: "absolute", top: "12px", right: "52px" }} onClick={openMenu}>
        <MoreVertIcon sx={{ fill: autovioColors.black }} />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={markStudentAsNotReadyForTheoryExam} disabled={saving}>
          <ListItemIcon>{saving ? <CircularProgress size={24} color="inherit" /> : <DeleteOutlineIcon />}</ListItemIcon>
          <ListItemText>Anfrage löschen</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

type TheoryLessonType =
  | "Theorieunterricht_Grundstoff"
  | "Theorieunterricht_Zusatzstoff_A"
  | "Theorieunterricht_Zusatzstoff_B";

function _getCompulsoryTheoryLessons(training: Training): { [key in TheoryLessonType]?: number } {
  return Object.fromEntries(
    training.compulsoryLessons
      .map(({ productId, count }) => {
        const product = training.products.find((it) => it.id === productId)!;
        return [product.type, count];
      })
      .filter(([type]) => {
        return (
          type === "Theorieunterricht_Grundstoff" ||
          type === "Theorieunterricht_Zusatzstoff_A" ||
          type === "Theorieunterricht_Zusatzstoff_B"
        );
      }),
  );
}

function _getNumberOfRequiredTheoryLessonsForTheoryExam(trainings: Array<Training>): number {
  const accumulatedCompulsoryTheoryLessons: { [key in TheoryLessonType]?: number } = {};
  for (const training of trainings) {
    const compulsoryTheoryLessons = _getCompulsoryTheoryLessons(training);
    for (const [type, n] of Object.entries(compulsoryTheoryLessons) as Array<[TheoryLessonType, number]>) {
      accumulatedCompulsoryTheoryLessons[type] = Math.max(compulsoryTheoryLessons[type] ?? 0, n);
    }
  }
  return Object.values(accumulatedCompulsoryTheoryLessons).reduce((a, b) => a + b, 0);
}
