import { type FieldProps, LinearProgress, Link } from "react-admin";
import { useRecordContext, useReference } from "ra-core";
import get from "lodash/get";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { Typography } from "@mui/material";
import { autovioColors } from "../misc/backofficeTheme";

/**
 * Property source is optional, default source is: "drivingSchoolId".
 */
export function ReferenceDrivingSchoolField(props: FieldProps & { source?: string; link?: true }) {
  const { source, link } = props;
  const record = useRecordContext(props);
  const id = get(record, source ?? "drivingSchoolId");

  if (!id) {
    return "";
  }

  const { referenceRecord: drivingSchool } = useReference<DrivingSchool>({ reference: "drivingSchools", id });

  if (!drivingSchool) {
    return <LinearProgress />;
  }

  const color = drivingSchool.isActive ? undefined : autovioColors.red;
  const name = (
    <Typography component="span" variant="body2" color={color}>
      {drivingSchool.name}
    </Typography>
  );

  if (link) {
    return (
      <Link to={`/drivingSchools/${id}`} color={color} onClick={(event) => event.stopPropagation()}>
        {name}
      </Link>
    );
  }

  return name;
}
