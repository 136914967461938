import type { z } from "zod";
import { t } from "./types";

export const SignatureRequestTypeEnum = t.enum(["ausbildungsnachweis", "schaltnachweis", "teilnahmebescheinigung"]);
export type SignatureRequestType = z.infer<typeof SignatureRequestTypeEnum>;

export const SignatureRequestStateEnum = t.enum(["pending", "completed"]);
export type SignatureRequestState = z.infer<typeof SignatureRequestStateEnum>;

export const SignatureRequestSchema = t.object({
  uid: t.string().nonempty(),
  bookedTrainingId: t.string().optional(),
  instructorUid: t.string().nonempty(),
  studentUid: t.string().nonempty(),
  instructorSignatureCity: t.string(),
  instructorSignedAt: t.dateTime(),
  instructorSignatureStorageRef: t.string(),
  previewStorageRef: t.string(),
  type: SignatureRequestTypeEnum,
  state: SignatureRequestStateEnum,
  studentSignatureCity: t.string().optional(),
  studentSignedAt: t.dateTime().optional(),
  signedDocumentStorageRef: t.string().optional(),
  signedDocumentSize: t.number().optional(),
});

export type SignatureRequest = z.infer<typeof SignatureRequestSchema>;
