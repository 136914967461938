import { BackofficeListTitle } from "../../misc/BackofficeListTitle";
import { Datagrid, List, NumberField, TextField } from "react-admin";
import { MoneyField } from "../../fields/MoneyField";
import { ActivateStudentButton } from "../../buttons/ActivateStudentButton";
import { EmptyState } from "../../misc/EmptyState";
import { MostRecentLessonField } from "../../fields/MostRecentLessonField";
import { Typography } from "@mui/material";
import { ReferenceDrivingSchoolField } from "../../fields/ReferenceDrivingSchoolField";
import { DunningStatusField } from "../../fields/DunningStatusField";
import { GlobalDrivingSchoolFilter } from "../../misc/GlobalDrivingSchoolFilter";
import { useRecoilValue } from "recoil";
import { persistentGlobalDrivingSchoolFilterState } from "../../persistent_state";

export function CandidatesForReactivationList() {
  const drivingSchoolId = useRecoilValue(persistentGlobalDrivingSchoolFilterState);
  return (
    <>
      <Typography>
        Liste aller Fahrschüler, die wegen offenen Zahlungen blockiert sind, aber kein negatives Saldo mehr haben (oder
        wenigstens alle angemahnten Rechnungen beglichen haben).
      </Typography>
      <List
        resource="candidatesForReactivation"
        title={<BackofficeListTitle />}
        filters={[<GlobalDrivingSchoolFilter source="drivingSchoolId" alwaysOn />]}
        sort={{ field: "balance", order: "DESC" }}
        actions={false}
        exporter={false}
        empty={false}
      >
        <Datagrid
          rowClick={(studentId) => `/students/${studentId}/balance`}
          bulkActionButtons={false}
          empty={<EmptyState label="Keine passenden Fahrschüler gefunden." />}
        >
          <TextField label="Name" source="name" />
          {!drivingSchoolId && <ReferenceDrivingSchoolField label="Fahrschule" />}
          <MoneyField label="Saldo" source="balance" redForNegativeValues />
          <TextField label="Status" source="verboseStatus" />
          <NumberField
            label="genommene Fahrstunden"
            source="kpis.finishedDrivingLessonsCount"
            options={{ maximumFractionDigits: 1 }}
          />
          <MostRecentLessonField label="letzte genommene Stunde" />
          <DunningStatusField label="Mahnstatus" />
          <ActivateStudentButton />
        </Datagrid>
      </List>
    </>
  );
}
