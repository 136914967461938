/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedPaymentOptions } from '../models/PatchedPaymentOptions';
import type { PaymentOptions } from '../models/PaymentOptions';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentOptionsEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaymentOptions
   * @throws ApiError
   */
  public paymentOptionsRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this payment options.
     */
    id: string,
  }): CancelablePromise<PaymentOptions> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/payment-options/{id}/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @returns PaymentOptions
   * @throws ApiError
   */
  public paymentOptionsUpdate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this payment options.
     */
    id: string,
    requestBody?: PaymentOptions,
  }): CancelablePromise<PaymentOptions> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/payment-options/{id}/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PaymentOptions
   * @throws ApiError
   */
  public paymentOptionsPartialUpdate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this payment options.
     */
    id: string,
    requestBody?: PatchedPaymentOptions,
  }): CancelablePromise<PaymentOptions> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/payment-options/{id}/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
