import * as React from "react";
import { SvgIcon, SxProps, Theme } from "@mui/material";

export function HubspotIcon({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <SvgIcon sx={sx}>
      <path d="M 18.023438 7.785156 L 18.023438 5.085938 C 18.78125 4.742188 19.269531 4.015625 19.273438 3.210938 L 19.273438 3.148438 C 19.273438 2 18.300781 1.066406 17.101562 1.066406 L 17.039062 1.066406 C 15.839844 1.066406 14.867188 2 14.867188 3.148438 L 14.867188 3.210938 C 14.871094 4.015625 15.359375 4.742188 16.121094 5.085938 L 16.121094 7.785156 C 15.046875 7.941406 14.039062 8.367188 13.195312 9.019531 L 5.46875 3.25 C 5.523438 3.058594 5.550781 2.863281 5.554688 2.664062 C 5.554688 1.371094 4.464844 0.320312 3.113281 0.316406 C 1.761719 0.316406 0.664062 1.363281 0.664062 2.65625 C 0.660156 3.953125 1.753906 5.003906 3.105469 5.003906 C 3.527344 5.003906 3.945312 4.894531 4.308594 4.691406 L 11.921875 10.367188 C 10.523438 12.390625 10.558594 15.03125 12.015625 17.023438 L 9.699219 19.242188 C 9.511719 19.183594 9.320312 19.152344 9.121094 19.152344 C 8.011719 19.152344 7.113281 20.011719 7.117188 21.074219 C 7.117188 22.136719 8.015625 23 9.125 23 C 10.230469 23 11.132812 22.140625 11.132812 21.078125 C 11.128906 20.886719 11.097656 20.703125 11.039062 20.523438 L 13.328125 18.328125 C 15.375 19.835938 18.183594 19.964844 20.375 18.652344 C 22.5625 17.339844 23.667969 14.859375 23.140625 12.433594 C 22.617188 10.011719 20.574219 8.15625 18.023438 7.785156 M 17.074219 16.667969 C 15.921875 16.699219 14.84375 16.128906 14.257812 15.175781 C 13.671875 14.222656 13.671875 13.042969 14.257812 12.09375 C 14.84375 11.140625 15.921875 10.570312 17.074219 10.597656 C 18.777344 10.65625 20.128906 11.996094 20.128906 13.632812 C 20.132812 15.265625 18.78125 16.609375 17.074219 16.667969" />
    </SvgIcon>
  );
}
