import memoizeOne from "memoize-one";
import { simpleRestProvider } from "./simpleRestProvider";
import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  fetchUtils,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  Options,
  PaginationPayload,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";
import { auth } from "../firebase";
import { DrivingLicenseClass } from "../model/DrivingLicenseClass";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { SalesCategoryEnum } from "../generated/backendClient";

const hostname = location.hostname;

const catalogBaseUrl =
  hostname === "localhost" || hostname.indexOf("dev") > 0
    ? "https://catalog.autovio.dev"
    : `https://catalog.autovio.de`;

export const salesCategories: { [id in SalesCategoryEnum]: string } = {
  "initial-signup": "Erstanmeldung",
  transcription: "Umschreibung",
  extension: "Erweiterung",
  "asf-course": "ASF-Kurs",
  "coaching-amaxophobia": "Coaching Fahrangst",
  "coaching-mpu": "Coaching MPU",
};

export const fetchProductTypeChoicesOnce = memoizeOne(async () => {
  try {
    const { body, json } = await fetchUtils.fetchJson(`${catalogBaseUrl}/products/types`);
    if (Array.isArray(json) && json.every((item) => typeof item === "string")) {
      return json.map((productType) => ({ id: productType, name: productType }));
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(`Unexpected response: ${body}`);
    }
  } catch (error) {
    console.error(`Failed to fetch product types`, error);
    fetchProductTypeChoicesOnce.clear();
    return [];
  }
});

export const fetchDrivingLicenseClassChoicesOnce = memoizeOne(async () => {
  try {
    const { body, json } = await fetchUtils.fetchJson(`${catalogBaseUrl}/trainings/driving-license-classes`);
    if (Array.isArray(json) && json.every((item) => typeof item === "string")) {
      return json.map((drivingLicenseClass) => ({ id: drivingLicenseClass, name: drivingLicenseClass }));
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(`Unexpected response: ${body}`);
    }
  } catch (error) {
    console.error(`Failed to fetch driving license classes`, error);
    fetchDrivingLicenseClassChoicesOnce.clear();
    return [];
  }
});

export const fetchBundleTagsOnce = memoizeOne(async () => {
  try {
    const { body, json } = await fetchUtils.fetchJson(`${catalogBaseUrl}/bundles/tags`);
    if (Array.isArray(json) && json.every((item) => typeof item === "string")) {
      return json;
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(`Unexpected response: ${body}`);
    }
  } catch (error) {
    console.error(`Failed to fetch bundle tags`, error);
    fetchBundleTagsOnce.clear();
    return [];
  }
});

async function fetchJsonWithAuth(url: string, options: Options = {}) {
  const jwt = await auth.currentUser?.getIdToken();
  if (!options) {
    options = { headers: new Headers({ Accept: "application/json" }) };
  } else if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  (options?.headers as Headers).set("Authorization", `Bearer ${jwt}`);
  return fetchUtils.fetchJson(url, options);
}

const restProvider = simpleRestProvider(catalogBaseUrl, fetchJsonWithAuth);

export interface Area {
  id: string;
  name: string;
  drivingSchoolId?: string;
  instructorIds?: Array<string>;
  circles: Array<{ lat: number; lng: number; r: number }>;
}

export interface Product {
  id: string;
  name: string;
  type: string;
}

export interface Training {
  id: string;
  name: string;
  drivingLicenseClass: DrivingLicenseClass;
  products: Array<Product>;
  productIds: Array<string>;
  compulsoryLessons: Array<{ productId: string; count: number }>;
}

export interface OfferFeedback {
  id: string;
  feedback: Record<
    string,
    {
      questionText: string;
      answerText: string;
      answerId: string;
      extra?: Record<string, string>;
    }
  >;
  createdAt: string;
}

export type BundleIconType = "bolt" | "savings" | "speed";
export interface Bundle {
  id: string;
  drivingSchoolId: string;
  name: string;
  title: string;
  subtitle?: string;
  description?: string;
  icon?: BundleIconType;
  isDrivingSchoolChange: boolean;
  salesCategory: SalesCategoryEnum;
  areas: Array<Area>;
  areaIds: Array<string>;
  products: Array<Product>;
  productIds: Array<string>;
  trainings: Array<Training>;
  trainingIds: Array<string>;
  baseFee: number;
  teachingMaterialFee?: number;
  authorityFees: Record</* productId: */ string, number>;
  productPrices: Record</* productId: */ string, number>;
  drivingLicenseClasses: Array<string>;
}

export function dtoToRaRecord(resource: string, dto: any) {
  const record = Object.fromEntries(Object.entries(dto));
  if (resource === "trainings") {
    if (dto.products) {
      record.productIds = ((dto.products ?? []) as Array<{ id: string }>).map((product) => product.id);
    } else {
      record.products = [];
      record.productIds = [];
    }
    if (dto.compulsoryLessons) {
      record.compulsoryLessons = Object.entries(dto.compulsoryLessons).map(([productId, count]) => ({
        productId,
        count,
      }));
    }
  } else if (resource === "bundles") {
    if (dto.areas) {
      record.areaIds = ((dto.areas ?? []) as Array<{ id: string }>).map((area) => area.id);
    } else {
      record.areas = [];
      record.areaIds = [];
    }
    if (dto.trainings) {
      record.trainingIds = ((dto.trainings ?? []) as Array<{ id: string }>).map((training) => training.id);
      record.drivingLicenseClasses = dto.trainings.map((training: any) => training.drivingLicenseClass);
    } else {
      record.trainings = [];
      record.trainingIds = [];
      record.drivingLicenseClasses = [];
    }
    if (dto.products) {
      record.productIds = ((dto.products ?? []) as Array<{ id: string }>).map((product) => product.id);
    } else {
      record.products = [];
      record.productIds = [];
    }
    if (dto.baseFee) {
      record.baseFee = dto.baseFee / 100;
    }
    if (dto.teachingMaterialFee) {
      record.teachingMaterialFee = dto.teachingMaterialFee / 100;
    }
    if (dto.productPrices) {
      record.productPrices = Object.fromEntries(
        Object.entries<number>(dto.productPrices).map(([key, cents]) => [key, cents / 100]),
      );
    }
    if (dto.authorityFees) {
      record.authorityFees = Object.fromEntries(
        Object.entries<number>(dto.authorityFees).map(([key, cents]) => [key, cents / 100]),
      );
    }
  }
  return record;
}

export function raRecordToDto(resource: string, record: any) {
  const dto = Object.fromEntries(Object.entries(record));
  if (resource === "trainings") {
    if (record.compulsoryLessons) {
      dto.compulsoryLessons = Object.fromEntries(
        record.compulsoryLessons.map(({ productId, count }: any) => [productId, count]),
      );
    }
    // Don't transfer products back to server ...
    delete dto.products;
  }
  if (resource === "bundles") {
    if (record.baseFee) {
      dto.baseFee = record.baseFee * 100;
    }
    if (record.teachingMaterialFee) {
      dto.teachingMaterialFee = record.teachingMaterialFee * 100;
    }
    if (record.productPrices) {
      dto.productPrices = Object.fromEntries(
        Object.entries<number>(record.productPrices).map(([key, euro]) => [key, Math.round(euro * 100)]),
      );
    }
    if (record.authorityFees) {
      dto.authorityFees = Object.fromEntries(
        Object.entries<number>(record.authorityFees).map(([key, euro]) => [key, Math.round(euro * 100)]),
      );
    }
    // Don't transfer areas, products, or trainings back to server ...
    delete dto.areas;
    delete dto.products;
    delete dto.trainings;
  }
  return dto;
}

export const catalogProvider = {
  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    // Some controllers in the backend do not support range queries,
    // so we have to ignore the pagination.
    if (resource === "offerFeedback" || (resource === "bundles" && params.filter?.drivingLicenseClasses)) {
      params.pagination = {} as any as PaginationPayload;
    }
    if (restrictAccessToDrivingSchoolIds?.length === 1 && (resource === "areas" || resource === "bundles")) {
      if (params.filter) {
        const { drivingSchoolId } = params.filter;
        if (drivingSchoolId) {
          if (drivingSchoolId !== restrictAccessToDrivingSchoolIds[0]) {
            return { data: [], total: 0 };
          }
        } else {
          params.filter.drivingSchoolId = restrictAccessToDrivingSchoolIds[0];
        }
      } else {
        params.filter = { drivingSchoolId: restrictAccessToDrivingSchoolIds[0] };
      }
    }
    const result = (await restProvider.getList(resource, params)) as any;
    result.data = result.data.map((dto: any) => dtoToRaRecord(resource, dto));
    return result;
  },
  async getOne(resource: string, { id }: GetOneParams) {
    const result = (await restProvider.getOne(resource, { id })) as any;
    result.data = dtoToRaRecord(resource, result.data);
    return result;
  },
  async getMany(resource: string, { ids }: GetManyParams) {
    const result = (await restProvider.getMany(resource, { ids })) as any;
    result.data = result.data.map((dto: any) => dtoToRaRecord(resource, dto));
    return result;
  },
  async getManyReference(resource: string, params: GetManyReferenceParams) {
    const result = (await restProvider.getManyReference(resource, params)) as any;
    result.data = result.data.map((dto: any) => dtoToRaRecord(resource, dto));
    return result;
  },
  create(resource: string, { data }: CreateParams) {
    return restProvider.create(resource, { data: raRecordToDto(resource, data) });
  },
  update(resource: string, { id, data }: UpdateParams) {
    return restProvider.update(resource, { id, data: raRecordToDto(resource, data) } as any);
  },
  updateMany(resource: string, { ids, data }: UpdateManyParams) {
    return restProvider.updateMany(resource, { ids, data: raRecordToDto(resource, data) } as any);
  },
  delete(resource: string, { id }: DeleteParams) {
    return restProvider.delete(resource, { id });
  },
  deleteMany(resource: string, { ids }: DeleteManyParams) {
    return restProvider.deleteMany(resource, { ids });
  },
  async getProducts(ids: Array<string>): Promise<Array<Product>> {
    if (ids.length === 0) {
      return [];
    }
    const { data: products } = await restProvider.getMany("products", { ids });
    for (const id of ids) {
      if (!products.some((it) => it.id === id)) {
        throw new Error(`Product ${id} not found in response`);
      }
    }
    return products as Array<Product>;
  },
  async getTrainings(ids: Array<string>): Promise<Array<Training>> {
    if (ids.length === 0) {
      return [];
    }
    const { data: trainings } = await restProvider.getMany("trainings", { ids });
    for (const id of ids) {
      if (!trainings.some((it) => it.id === id)) {
        throw new Error(`Training ${id} not found in response`);
      }
    }
    return trainings as Array<Training>;
  },
  async addBundle(addBundleRequest: { studentId: string; bundleId: string; instructorId: string }) {
    const { status } = await fetchJsonWithAuth(`${catalogBaseUrl}/booked-trainings/add-bundle`, {
      method: "PUT",
      body: JSON.stringify(addBundleRequest),
    });
    if (status !== 200) {
      throw new Error(`Unexpected HTTP response status: ${status}`);
    }
  },
  async changeBundle(changeBundleRequest: { studentId: string; oldQuoteId: string; newBundleId: string }) {
    const { status } = await fetchJsonWithAuth(`${catalogBaseUrl}/booked-trainings/change-bundle`, {
      method: "PUT",
      body: JSON.stringify(changeBundleRequest),
    });
    if (status !== 200) {
      throw new Error(`Unexpected HTTP response status: ${status}`);
    }
  },
  async changeDrivingLicenseClasses(changeDrivingLicenseClassesRequest: {
    studentId: string;
    quoteId: string;
    changes: Partial<Record<DrivingLicenseClass, DrivingLicenseClass>>;
  }) {
    const { status } = await fetchJsonWithAuth(`${catalogBaseUrl}/booked-trainings/change-driving-license-classes`, {
      method: "PUT",
      body: JSON.stringify(changeDrivingLicenseClassesRequest),
    });
    if (status !== 200) {
      throw new Error(`Unexpected HTTP response status: ${status}`);
    }
  },
};
