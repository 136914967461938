import { ApprovalStatusEnum } from "../providers/studentsProvider";

export const approvalStatusChoices = ApprovalStatusEnum.options.map((it) => ({
  id: it,
  name: {
    pending: "ausstehend",
    approved: "genehmigt",
    notApproved: "abgelehnt",
  }[it],
}));
