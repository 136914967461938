export function AutobahnIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m8.333 1.667-1.666 5h2.5v-5h-.834m2.5 0v5h2.5l-1.666-5h-.834M1.667 7.5v.833h1.666v.834H5v-.834h10l.05.834h1.617v-.834h1.666V7.5H1.667m4.166 1.667-3.05 9.166h6.384V9.167H5.833m5 0v9.166h6.384l-3.05-9.166h-3.334z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}
