import { collection, getDocs, query, where } from "firebase/firestore";
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { DrivingLesson } from "../model/autovioCalendarEvents";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { drivingLessonConverter } from "./converter/drivingLessonConverter";
import { commonCalendarEventQueryConstraints } from "../utils/firestore-queries";

class DrivingLessonsProvider {
  async getList(_: string, __: GetListParams): Promise<GetListResult<DrivingLesson>> {
    throw new Error("not implemented");
  }

  async getOne(_: string, __: GetOneParams<DrivingLesson>): Promise<GetOneResult<DrivingLesson>> {
    throw new Error("not implemented");
  }

  async getMany(_: string, __: GetManyParams): Promise<GetManyResult<DrivingLesson>> {
    throw new Error("not implemented");
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<DrivingLesson>> {
    const { includeDeleted, drivingLessonTypes, from, to, ...restFilters } = filter;
    let q = query(
      collection(firestore, `/calendar_events`),
      where("type", "==", "DrivingLesson"),
      where(
        "drivingLessonType",
        "in",
        drivingLessonTypes ?? [
          "normal",
          "ueberlandfahrt",
          "autobahnfahrt",
          "nachtfahrt",
          "praktischePruefung",
          "schaltkompetenz",
        ],
      ),
    );

    q = commonCalendarEventQueryConstraints(q, { target, id, from, to, includeDeleted });

    const snapshot = await getDocs(q);
    const drivingLessons = snapshot.docs.map(drivingLessonConverter.fromFirestore);

    let targetString = "";
    switch (target) {
      case "derived.studentUids":
      case "studentUid":
        targetString = "student";
        break;
      case "drivingSchoolUid":
        targetString = "driving school";
        break;
      default:
        throw new Error(`Unexpected target: ${JSON.stringify(target)}`);
    }
    console.info(`Retrieved ${drivingLessons.length} driving lesson(s) for ${targetString} ${id}`);
    return applyPagination(applySort(applyFilter(drivingLessons, restFilters), sort), pagination);
  }

  async update(_: string, __: UpdateParams<DrivingLesson>): Promise<UpdateResult<DrivingLesson>> {
    throw new Error("not implemented");
  }

  async updateMany(_: string, __: UpdateManyParams<DrivingLesson>): Promise<UpdateManyResult<DrivingLesson>> {
    throw new Error("not implemented");
  }

  async create(_: string, __: CreateParams<DrivingLesson>): Promise<CreateResult<DrivingLesson>> {
    throw new Error("not implemented");
  }

  async delete(_: string, __: DeleteParams<DrivingLesson>): Promise<DeleteResult<DrivingLesson>> {
    throw new Error("not implemented");
  }

  async deleteMany(_: string, __: DeleteManyParams<DrivingLesson>): Promise<DeleteManyResult<DrivingLesson>> {
    throw new Error("not implemented");
  }
}

export const drivingLessonsProvider = new DrivingLessonsProvider();
