/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedTaxConsultantExportList } from '../models/PaginatedTaxConsultantExportList';
import type { TaxConsultantExport } from '../models/TaxConsultantExport';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TaxConsultantExportsEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedTaxConsultantExportList
   * @throws ApiError
   */
  public taxConsultantExportsList({
    companyDrivingSchoolId,
    limit,
    offset,
    ordering,
  }: {
    companyDrivingSchoolId?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string,
  }): CancelablePromise<PaginatedTaxConsultantExportList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/tax-consultant-exports/',
      query: {
        'company__driving_school_id': companyDrivingSchoolId,
        'limit': limit,
        'offset': offset,
        'ordering': ordering,
      },
    });
  }

  /**
   * @returns TaxConsultantExport
   * @throws ApiError
   */
  public taxConsultantExportsRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this tax consultant export.
     */
    id: string,
  }): CancelablePromise<TaxConsultantExport> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/tax-consultant-exports/{id}/',
      path: {
        'id': id,
      },
    });
  }

}
