import { Resource } from "react-admin";
import SchoolIcon from "@mui/icons-material/School";
import { DrivingSchoolRoutes } from "../details/DrivingSchoolRoutes";

export const drivingSchoolsResource = (
  <Resource
    key="drivingSchools"
    name="drivingSchools"
    icon={SchoolIcon}
    edit={DrivingSchoolRoutes}
    options={{ label: "Fahrschulen" }}
    recordRepresentation={(drivingSchool) => drivingSchool.name}
  />
);
