import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { InAppNotificationsList } from "../../lists/InAppNotificationsList";

export function InstructorInAppNotificationsList() {
  return (
    <ReferenceManyField
      label=""
      reference="inAppNotifications"
      target="userUid"
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <InAppNotificationsList />
    </ReferenceManyField>
  );
}
