import { UserBalanceItem } from "../providers/invoicesProvider";
import { MoneyCurrency } from "../misc/Money";
import { InvoiceStateEnum } from "../generated/backendClient";
import { SimpleConfirmDialogButton } from "../misc/SimpleConfirmDialogButton";
import UndoIcon from "@mui/icons-material/Undo";
import { getAuthenticatedBackendClient } from "../api/backend.api";

export function RefundPaymentDialogButton({
  invoiceState,
  invoiceId,
  amount,
}: {
  invoiceState: InvoiceStateEnum;
  invoiceId: string;
  amount: UserBalanceItem["amount"];
}) {
  const rueckerstattenOrAusbuchen = invoiceState === "PAID" ? "Rückerstatten" : "Ausbuchen";
  return (
    <SimpleConfirmDialogButton
      disabled={!["PAID", "PAYMENT_REVOKED", "UNPAID"].includes(invoiceState)}
      buttonTitle={rueckerstattenOrAusbuchen}
      dialogTitle={`${rueckerstattenOrAusbuchen} bestätigen`}
      action={async () => {
        const backendClient = await getAuthenticatedBackendClient();
        await backendClient.invoice.invoiceRefundCreate({ id: invoiceId });
      }}
      dialogCtaTitle={`Jetzt ${rueckerstattenOrAusbuchen}`}
      content={
        <span>
          Sind Sie sicher, dass Sie die Rechnung über
          <b>
            {" "}
            <MoneyCurrency {...amount} />
          </b>{" "}
          {rueckerstattenOrAusbuchen.toLowerCase()} möchten?
        </span>
      }
      icon={<UndoIcon />}
    />
  );
}
