import { z } from "zod";
import { t } from "./types";

const NotificationStatusSchema = z.object({
  state: z.enum(["delivered", "read", "error"]),
  updatedAt: t.dateTime(),
});

const EmailChannelSchema = z.object({
  button: z.object({ label: z.string(), link: z.string().url() }),
  headline: z.string(),
  previewText: z.string(),
  status: z.optional(NotificationStatusSchema),
  subject: z.string(),
  text: z.string(),
  to: z.string().email(),
  type: z.literal("email"),
});

const InAppChannelSchema = z.object({
  deleted: z.boolean(),
  deletedAt: z.optional(t.dateTime()),
  launchUri: z.optional(z.string().url()),
  senderUid: z.optional(t.uid()),
  status: z.optional(NotificationStatusSchema),
  text: z.string(),
  title: z.string(),
  type: z.literal("inApp"),
});

const ARGBColorSchema = z.array(z.number().int().gte(0).lte(255)).length(4);

const StickyChannelSchema = z.object({
  appRoute: z.optional(z.string()),
  backgroundColorARGB: z.optional(ARGBColorSchema),
  ctaLabel: z.optional(z.string()),
  ctaLabelColorARGB: z.optional(ARGBColorSchema),
  deleted: z.boolean(),
  deletedAt: z.optional(z.string()),
  headline: z.string(),
  headlineColorARGB: z.optional(ARGBColorSchema),
  imageUrl: z.optional(z.string().url()),
  imagePadding: z.optional(z.number()),
  status: z.optional(NotificationStatusSchema),
  text: z.string(),
  textColorARGB: z.optional(ARGBColorSchema),
  type: z.literal("sticky"),
  webUrl: z.string().url().optional(),
});

export const NotificationSchema = z.object({
  channels: z.object({
    email: z.optional(EmailChannelSchema),
    inApp: z.optional(InAppChannelSchema),
    sticky: z.optional(StickyChannelSchema),
  }),
  createdAt: t.dateTime(),
  deadline: t.dateTime(),
  locale: z.string(),
});

export type Notification = z.infer<typeof NotificationSchema>;
