import { t } from "./types";
import { AttachmentSchema } from "./Attachment";
import type { z } from "zod";
import { htmlToPlainText } from "../backoffice.utils";

export const NoteSchema = t
  .object({
    studentUid: t.uid(),
    uid: t.string().nonempty(),
    hubspotId: t.string().nonempty(),
    createdAt: t.dateTime(),
    createdByUid: t.string().optional(),
    updatedAt: t.dateTime(),
    body: t.string().nullish(), // <-- might be empty (there are notes with empty body and only attachments)
    attachments: t.optional(t.array(AttachmentSchema)).transform((val) => (val?.length ? val : undefined)),
    createdByName: t.optional(t.string()),
    updatedByName: t.optional(t.string()),
  })
  .transform((val) => {
    const { uid: id, body, ...rest } = val;
    return { id, body: body ? htmlToPlainText(body) : body, ...rest };
  });

export type Note = z.infer<typeof NoteSchema>;
