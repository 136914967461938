import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { RecordContextProvider, useListContext, useRecordContext } from "react-admin";
import { DateField } from "../../fields/DateField";
import { List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { Spinner } from "../../misc/Spinner";
import { StickyNote } from "../../providers/studentStickyNotesProvider";
import { autovioColors } from "../../misc/backofficeTheme";
import { DateTime } from "luxon";

export function StudentStickyNotesList() {
  return (
    <ReferenceManyField
      label=""
      reference="studentStickyNotes"
      target="studentUid"
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <_StudentStickyNotesList />
    </ReferenceManyField>
  );
}

function _StudentStickyNotesList() {
  const { data, isLoading, total } = useListContext<StickyNote>();
  if (isLoading) {
    return <Spinner style={{ margin: "20px" }} />;
  }
  if (!data || data.length === 0 || total === 0) {
    return (
      <Typography
        variant="body2"
        style={{
          marginTop: "20px",
          height: "40px",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        Keine Sticky Notes vorhanden.
      </Typography>
    );
  }
  return (
    <List>
      {data.map((stickyNote) => (
        <RecordContextProvider key={stickyNote.id} value={stickyNote}>
          <_StudentStickyNotesListItem />
        </RecordContextProvider>
      ))}
    </List>
  );
}

function _StudentStickyNotesListItem() {
  const stickyNote = useRecordContext<StickyNote>();
  const backgroundColor = _toCssColor(stickyNote.backgroundColorARGB) ?? autovioColors.white;
  const headlineColor = _toCssColor(stickyNote.headlineColorARGB) ?? autovioColors.black;
  const textColor = _toCssColor(stickyNote.textColorARGB) ?? autovioColors.black;
  const ctaLabelColor = _toCssColor(stickyNote.ctaLabelColorARGB) ?? autovioColors.black;
  const imagePadding = stickyNote.imagePadding ?? 0;
  return (
    <>
      <ListItem
        disablePadding
        style={{
          position: "relative",
          marginTop: 0,
          borderRadius: "10px",
          background: backgroundColor,
        }}
      >
        {stickyNote.imageUrl && (
          <ListItemAvatar>
            {/* See http://jonrohan.codes/fieldnotes/vertically-center-clipped-image/ */}
            <div style={{ height: "80px", overflow: "hidden", borderRadius: "10px", padding: `0 ${imagePadding}px` }}>
              <img
                src={stickyNote.imageUrl}
                width={105 - 2 * imagePadding}
                style={{
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            </div>
          </ListItemAvatar>
        )}
        <ListItemText
          disableTypography
          primary={
            <Stack sx={{ padding: stickyNote.imageUrl ? "10px 20px 10px 0" : "10px 20px" }}>
              <Typography variant="body1" style={{ color: headlineColor, fontWeight: "bold" }}>
                {stickyNote.headline}
              </Typography>
              <Typography variant="body2" style={{ color: textColor }}>
                {stickyNote.text}
              </Typography>
              {stickyNote.ctaLabel && (
                <Typography variant="body2" style={{ color: ctaLabelColor, fontWeight: "bold" }}>
                  {stickyNote.ctaLabel}
                </Typography>
              )}
            </Stack>
          }
        />
        {(stickyNote.deleted || stickyNote.createdAt.plus({ weeks: 2 }) < DateTime.now()) && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255,255,255,0.5)",
            }}
          />
        )}
      </ListItem>
      <Stack
        direction="row"
        style={{
          marginTop: "3px",
          marginBottom: "16px",
          color: "rgba(0,0,0,0.6)",
        }}
      >
        <Typography variant="body2" className="RaLabeled-label">
          {"Erstellt:\u00A0"}
        </Typography>
        <DateField className="RaLabeled-label" source="createdAt" showTime />
        {stickyNote.deleted && (
          <Typography variant="body2" className="RaLabeled-label">
            {", Verworfen:\u00A0"}
          </Typography>
        )}
        {stickyNote.deletedAt && <DateField className="RaLabeled-label" source="deletedAt" showTime />}
      </Stack>
    </>
  );
}

function _toCssColor(argb?: Array<number>): string | undefined {
  if (!argb) {
    return undefined;
  }
  const [a, r, g, b] = argb;
  return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
}
