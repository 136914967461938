/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Invoice } from '../models/Invoice';
import type { InvoiceRetry } from '../models/InvoiceRetry';
import type { PaginatedInvoiceList } from '../models/PaginatedInvoiceList';
import type { PartialRefund } from '../models/PartialRefund';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvoiceEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedInvoiceList
   * @throws ApiError
   */
  public invoiceList({
    hasTriedSpendingPrepaidCredits,
    issuerCompanyDrivingSchoolId,
    limit,
    offset,
    ordering,
    recipientFirebaseAuthUsersId,
    recipientId,
    recipientCompanyDrivingSchoolId,
    showDisputeFees,
    state,
    type,
  }: {
    hasTriedSpendingPrepaidCredits?: boolean,
    issuerCompanyDrivingSchoolId?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string,
    recipientFirebaseAuthUsersId?: string,
    recipientId?: string,
    recipientCompanyDrivingSchoolId?: string,
    showDisputeFees?: boolean,
    /**
     * * `NEW` - NEW
     * * `HAS_BASE_DATA` - HAS_BASE_DATA
     * * `HAS_POSITIONS` - HAS_POSITIONS
     * * `HAS_RECIPIENT_ADDRESS` - HAS_RECIPIENT_ADDRESS
     * * `IS_REAL_INVOICE` - IS_REAL_INVOICE
     * * `HAS_CREDITS_APPLIED` - HAS_CREDITS_APPLIED
     * * `HAS_FREE_CREDITS_APPLIED` - HAS_FREE_CREDITS_APPLIED
     * * `HAS_START_CREDITS_APPLIED` - HAS_START_CREDITS_APPLIED
     * * `HAS_COMPENSATION_APPLIED` - HAS_COMPENSATION_APPLIED
     * * `HAS_PREPAID_CREDITS_APPLIED` - HAS_PREPAID_CREDITS_APPLIED
     * * `ERROR_HAS_NO_ADDRESS` - ERROR_HAS_NO_ADDRESS
     * * `HAS_CALCULATION_NUMBER` - HAS_CALCULATION_NUMBER
     * * `B2B_INVOICING_CHECKED` - B2B_INVOICING_CHECKED
     * * `WAITING_FOR_B2B_TRANSFER` - WAITING_FOR_B2B_TRANSFER
     * * `PDF_RENDERED` - PDF_RENDERED
     * * `UNPAID` - UNPAID
     * * `UNPAID_WITH_PREPAID_CREDITS` - UNPAID_WITH_PREPAID_CREDITS
     * * `AWAITING_PAYMENT` - AWAITING_PAYMENT
     * * `PAID` - PAID
     * * `PAYMENT_REVOKED` - PAYMENT_REVOKED
     * * `REFUNDED` - REFUNDED
     * * `PARTIALLY_REFUNDED` - PARTIALLY_REFUNDED
     * * `UNKNOWN_FROM_MIGRATION` - UNKNOWN_FROM_MIGRATION
     * * `WAITING_FOR_PRE_INVOICE_PAYMENT` - WAITING_FOR_PRE_INVOICE_PAYMENT
     * * `WAITING_FOR_CUSTOMER_CONFIRMATION` - WAITING_FOR_CUSTOMER_CONFIRMATION
     * * `CANCELED` - CANCELED
     * * `PRE_INVOICE_PAYMENT_SUCCESSFUL` - PRE_INVOICE_PAYMENT_SUCCESSFUL
     * * `TO_BE_MANUALLY_REFUNDED` - TO_BE_MANUALLY_REFUNDED
     * * `NEEDS_RECIPIENT_ADDRESS` - NEEDS_RECIPIENT_ADDRESS
     */
    state?: 'AWAITING_PAYMENT' | 'B2B_INVOICING_CHECKED' | 'CANCELED' | 'ERROR_HAS_NO_ADDRESS' | 'HAS_BASE_DATA' | 'HAS_CALCULATION_NUMBER' | 'HAS_COMPENSATION_APPLIED' | 'HAS_CREDITS_APPLIED' | 'HAS_FREE_CREDITS_APPLIED' | 'HAS_POSITIONS' | 'HAS_PREPAID_CREDITS_APPLIED' | 'HAS_RECIPIENT_ADDRESS' | 'HAS_START_CREDITS_APPLIED' | 'IS_REAL_INVOICE' | 'NEEDS_RECIPIENT_ADDRESS' | 'NEW' | 'PAID' | 'PARTIALLY_REFUNDED' | 'PAYMENT_REVOKED' | 'PDF_RENDERED' | 'PRE_INVOICE_PAYMENT_SUCCESSFUL' | 'REFUNDED' | 'TO_BE_MANUALLY_REFUNDED' | 'UNKNOWN_FROM_MIGRATION' | 'UNPAID' | 'UNPAID_WITH_PREPAID_CREDITS' | 'WAITING_FOR_B2B_TRANSFER' | 'WAITING_FOR_CUSTOMER_CONFIRMATION' | 'WAITING_FOR_PRE_INVOICE_PAYMENT',
    /**
     * * `PREPAID_CREDITS` - PREPAID_CREDITS
     * * `DRIVING_LESSON_INVOICE` - DRIVING_LESSON_INVOICE
     * * `REMOTE_LESSON` - REMOTE_LESSON
     * * `THEORY_LESSON` - THEORY_LESSON
     * * `AUTHORITY_FEE` - AUTHORITY_FEE
     * * `CREDIT_NOTE` - CREDIT_NOTE
     * * `TEACHING_MATERIAL` - TEACHING_MATERIAL
     * * `BASE_FEE` - BASE_FEE
     * * `B2B` - B2B
     * * `LEGACY_B2B` - LEGACY_B2B
     * * `LEGACY_B2C` - LEGACY_B2C
     * * `VR_EQUIPMENT_FEE` - VR_EQUIPMENT_FEE
     * * `BASE_FEE_ADDITION` - BASE_FEE_ADDITION
     * * `MISCELLANEOUS` - MISCELLANEOUS
     * * `PAYOUT_ADVANCE_RECEIPT` - PAYOUT_ADVANCE_RECEIPT
     * * `B2B_PAYOUT_ADVANCE_RECEIPT` - B2B_PAYOUT_ADVANCE_RECEIPT
     * * `ASF_COURSE_FEE` - ASF_COURSE_FEE
     */
    type?: 'ASF_COURSE_FEE' | 'AUTHORITY_FEE' | 'B2B' | 'B2B_PAYOUT_ADVANCE_RECEIPT' | 'BASE_FEE' | 'BASE_FEE_ADDITION' | 'CREDIT_NOTE' | 'DRIVING_LESSON_INVOICE' | 'LEGACY_B2B' | 'LEGACY_B2C' | 'MISCELLANEOUS' | 'PAYOUT_ADVANCE_RECEIPT' | 'PREPAID_CREDITS' | 'REMOTE_LESSON' | 'TEACHING_MATERIAL' | 'THEORY_LESSON' | 'VR_EQUIPMENT_FEE',
  }): CancelablePromise<PaginatedInvoiceList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/invoice/',
      query: {
        'has_tried_spending_prepaid_credits': hasTriedSpendingPrepaidCredits,
        'issuer_company__driving_school_id': issuerCompanyDrivingSchoolId,
        'limit': limit,
        'offset': offset,
        'ordering': ordering,
        'recipient__firebase_auth_users__id': recipientFirebaseAuthUsersId,
        'recipient__id': recipientId,
        'recipient_company__driving_school_id': recipientCompanyDrivingSchoolId,
        'show_dispute_fees': showDisputeFees,
        'state': state,
        'type': type,
      },
    });
  }

  /**
   * @returns Invoice
   * @throws ApiError
   */
  public invoiceRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this invoice.
     */
    id: string,
  }): CancelablePromise<Invoice> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/invoice/{id}/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @returns Invoice
   * @throws ApiError
   */
  public invoiceRefundCreate({
    id,
  }: {
    /**
     * A UUID string identifying this invoice.
     */
    id: string,
  }): CancelablePromise<Invoice> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/invoice/{id}/refund/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @returns Invoice
   * @throws ApiError
   */
  public invoiceRefundPartiallyCreate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this invoice.
     */
    id: string,
    requestBody: PartialRefund,
  }): CancelablePromise<Invoice> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/invoice/{id}/refund-partially/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Invoice
   * @throws ApiError
   */
  public invoiceRetryPaymentCreate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this invoice.
     */
    id: string,
    requestBody?: InvoiceRetry,
  }): CancelablePromise<Invoice> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/invoice/{id}/retry-payment/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Invoice
   * @throws ApiError
   */
  public invoiceUpdateAddressOnPdfCreate({
    id,
  }: {
    /**
     * A UUID string identifying this invoice.
     */
    id: string,
  }): CancelablePromise<Invoice> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/invoice/{id}/update-address-on-pdf/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public invoiceIsProcessingRetrieve(): CancelablePromise<{
    processing: boolean;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/invoice/is-processing/',
    });
  }

}
