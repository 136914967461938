import { collection, getDocs, query, where } from "firebase/firestore";
import { GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { firestore } from "../firebase";
import { TheoryExam, TheoryLesson } from "../model/autovioCalendarEvents";
import { theoryLessonConverter } from "./converter/theoryLessonConverter";
import { theoryExamConverter } from "./converter/theoryExamConverter";
import { commonCalendarEventQueryConstraints } from "../utils/firestore-queries";
import { AbstractProvider } from "./abstractProvider";

class TheoryLessonsProvider extends AbstractProvider<TheoryLesson | TheoryExam> {
  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<TheoryLesson | TheoryExam>> {
    const { from, to, types, ...restFilters } = filter;
    const fetchTypes: Set<"TheoryLesson" | "TheoryExam"> = types
      ? new Set(types)
      : new Set(["TheoryLesson", "TheoryExam"]);

    if (fetchTypes.size === 0) {
      throw Error("No type requested, must be one of 'TheoryLesson' and 'TheoryExam' or both.");
    } else if (!fetchTypes.has("TheoryExam") && !fetchTypes.has("TheoryLesson")) {
      throw Error(`Unknown types requested: ${fetchTypes}`);
    }

    let theoryLessons: TheoryLesson[] = [];
    if (fetchTypes.has("TheoryLesson")) {
      let q = query(
        collection(firestore, `/calendar_events`),
        where("type", "==", "TheoryLesson"),
        where(`deleted`, "==", false),
      );
      q = commonCalendarEventQueryConstraints(q, { target, id, from, to });
      const snapshot1 = await getDocs(q);
      theoryLessons = snapshot1.docs.map(theoryLessonConverter.fromFirestore);
    }

    let theoryExams: TheoryExam[] = [];
    if (fetchTypes.has("TheoryExam")) {
      let q = query(
        collection(firestore, `/calendar_events`),
        where("drivingLessonType", "==", "theoretischePruefung"),
        where(`deleted`, "==", false),
      );
      q = commonCalendarEventQueryConstraints(q, { target, id, from, to });
      const snapshot2 = await getDocs(q);
      theoryExams = snapshot2.docs.map(theoryExamConverter.fromFirestore);
    }

    let targetString = "";
    switch (target) {
      case "derived.studentUids":
      case "studentUid":
      case "studentUids":
        targetString = "student";
        break;
      case "drivingSchoolUid":
        targetString = "driving school";
        break;
      default:
        throw new Error(`Unexpected target: ${JSON.stringify(target)}`);
    }
    console.info(`Retrieved ${theoryLessons.length} theory lesson(s) for ${targetString} ${id}`);
    console.info(`Retrieved ${theoryExams.length} theory exam(s) for ${targetString} ${id}`);
    const data = (theoryLessons as Array<TheoryLesson | TheoryExam>).concat(theoryExams);
    return applyPagination(applySort(applyFilter(data, restFilters), sort), pagination);
  }
}

export const theoryLessonsProvider = new TheoryLessonsProvider();
