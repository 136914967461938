import * as React from "react";
import get from "lodash/get";
import Chip, { ChipProps } from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useRecordContext } from "ra-core";
import { FieldProps, sanitizeFieldRestProps } from "react-admin";

export interface ChipsFieldProps extends FieldProps, Omit<ChipProps, "label"> {
  source: string;
}

function _ChipsField(props: ChipsFieldProps) {
  const { className, classes: classesOverride, source, emptyText, ...rest } = props;
  const record = useRecordContext(props);
  const value = get(record, source) ?? [];

  if (!(Array.isArray(value) && value.every((item) => typeof item === "string"))) {
    throw new Error(`ChipsField requires a value of type Array<string> but received: ${JSON.stringify(value)}`);
  }

  if (value.length === 0 && emptyText) {
    return (
      <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
        {emptyText}
      </Typography>
    );
  }

  return (
    <>
      {value.map((item: string) => (
        <Chip key={item} label={item} style={{ marginRight: "0.5em" }} {...sanitizeFieldRestProps(rest)} />
      ))}
    </>
  );
}

export const ChipsField = React.memo(_ChipsField);

ChipsField.displayName = "ChipsField";
