import { Link, Typography } from "@mui/material";
import get from "lodash/get";
import { FieldProps, useRecordContext } from "react-admin";

export function PhoneNumberField(props: FieldProps & { source: string }) {
  const { source } = props;
  const record = useRecordContext(props);
  if (!record) return null;
  const value = get(record, source);
  if (!value || value === "") {
    return (
      <Typography component="span" variant="body2">
        {props.emptyText ?? "Nicht hinterlegt"}
      </Typography>
    );
  }

  return (
    <Typography variant="body2">
      <Link href={`tel:${value}`} underline="none" aria-label="Telefonnummer anrufen">
        {value}
      </Link>
    </Typography>
  );
}
