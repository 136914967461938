import { BookedQuote } from "../providers/bookedQuotesProvider";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useRecordContext } from "react-admin";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import { PricesOverview } from "../misc/PricesOverview";
import { useDialog } from "../hooks/useDialog";
import { DialogProps } from "../misc/DialogProps";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export function ShowPricesButton() {
  const bookedQuote = useRecordContext() as BookedQuote;
  const { dialogProps, openDialog } = useDialog();

  return (
    <>
      <Button startIcon={<EyeIcon />} onClick={openDialog} disabled={!bookedQuote}>
        Preise anzeigen
      </Button>
      <ShowPricesDialog bookedQuote={bookedQuote} {...dialogProps} />
    </>
  );
}

interface ShowPricesDialog extends DialogProps {
  bookedQuote: BookedQuote;
}

function ShowPricesDialog({ bookedQuote, open, onClose }: ShowPricesDialog) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Preise für {bookedQuote.bundleName}</DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent>
        <PricesOverview bookedQuote={bookedQuote} />
      </DialogContent>
    </Dialog>
  );
}
