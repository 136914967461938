import EditIcon from "@mui/icons-material/EditOutlined";
import { autovioColors } from "../misc/backofficeTheme";
import { Form, required, SaveButton, SelectInput, useNotify, useRefresh } from "react-admin";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Student } from "../providers/studentsProvider";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { PaymentStrategyEnum } from "../generated/backendClient";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { useState } from "react";

export type FrontendPaymentStrategyEnum = "upfrontPayment" | "payAsYouDrive" | "purchaseOnAccount";

export const PaymentStrategyChoices: { id: FrontendPaymentStrategyEnum; name: string }[] = [
  { id: "upfrontPayment", name: "nur Vorauszahlung" },
  { id: "payAsYouDrive", name: "Pay As You Drive" },
  { id: "purchaseOnAccount", name: "per Rechnung" },
];

export const frontendToBackend: { [k in FrontendPaymentStrategyEnum]: PaymentStrategyEnum } = {
  upfrontPayment: "upfront_payment",
  payAsYouDrive: "pay_as_you_drive",
  purchaseOnAccount: "purchase_on_account",
};

function EditPaymentStrategyDialogButton() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const onClose = () => setOpen(false);

  const onSubmit = async (data: Partial<Student>) => {
    setLoading(true);
    try {
      if (!data.paymentStrategy || !data.id) {
        throw new Error("Incomplete data");
      }
      const backendValue = frontendToBackend[data.paymentStrategy];
      const backendClient = await getAuthenticatedBackendClient();
      await backendClient.user.userSetPaymentStrategyCreate({
        id: data.id,
        requestBody: { payment_strategy: backendValue },
      });
      // wait for the backend to write to the firestore database
      await new Promise((resolve) => setTimeout(resolve, 2000));
      refresh(); // reload from firestore
      notify("Zahlungsmodus erfolgreich geändert.", { type: "success" });
    } catch (e) {
      console.error(e);
      notify("Fehler: " + (e as Error).message, { type: "error" });
    } finally {
      setLoading(false);
    }
    setOpen(false);
  };
  const onClick = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };
  return (
    <>
      <IconButton onClick={onClick} style={{ marginTop: "-20px" }}>
        <EditIcon style={{ fill: autovioColors.green }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={(_, __) => {
          // prevent closing the dialog when the action is still running
          if (loading) return;
          onClose();
        }}
      >
        <DialogTitle>Zahlungsmodus ändern</DialogTitle>
        <DialogCloseButton onClose={onClose} />
        <Form warnWhenUnsavedChanges onSubmit={onSubmit}>
          <DialogContent sx={{ width: "360px" }}>
            <SelectInput
              sx={{ width: "15em" }}
              label="Payment Strategie"
              source="paymentStrategy"
              choices={PaymentStrategyChoices}
              validate={required()}
            />
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

export default EditPaymentStrategyDialogButton;
