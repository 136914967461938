import Typography, { TypographyProps } from "@mui/material/Typography";
import { Identifier, LinearProgress, useGetOne } from "react-admin";

type UserDisplayNameProps = { userId: string; component?: string } & TypographyProps;

export function UserDisplayName(props: UserDisplayNameProps) {
  const { userId, ...typographyProps } = props;
  const { data: user } = useGetOne<{ id: Identifier; name: string }>("users", { id: userId });

  if (!user) {
    return <LinearProgress style={{ maxWidth: "8em" }} />;
  }

  return <Typography {...typographyProps}>{user.name}</Typography>;
}
