/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutovioPayout } from '../models/AutovioPayout';
import type { PaginatedAutovioPayoutList } from '../models/PaginatedAutovioPayoutList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PayoutEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedAutovioPayoutList
   * @throws ApiError
   */
  public payoutList({
    companyDrivingSchoolId,
    companyId,
    limit,
    offset,
    ordering,
    state,
  }: {
    companyDrivingSchoolId?: string,
    companyId?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string,
    /**
     * * `NEW` - NEW
     * * `AUTOMATIC_STRIPE_PAYOUT` - AUTOMATIC_STRIPE_PAYOUT
     * * `AUTOMATIC_AUTOVIO_PAYOUT_ITEMS_CREATED` - AUTOMATIC_AUTOVIO_PAYOUT_ITEMS_CREATED
     * * `AUTOMATIC_AMOUNT_CALCULATED` - AUTOMATIC_AMOUNT_CALCULATED
     * * `AUTOMATIC_VERIFIED` - AUTOMATIC_VERIFIED
     * * `MANUAL_STRIPE_PAYOUT` - MANUAL_STRIPE_PAYOUT
     * * `INVOICES_COLLECTED` - MANUAL_INVOICES_COLLECTED
     * * `MANUAL_AMOUNT_CALCULATED` - MANUAL_AMOUNT_CALCULATED
     * * `MANUAL_ERROR_CREATING_PAYOUT` - MANUAL_ERROR_CREATING_PAYOUT
     * * `STRIPE_PAYOUT_CREATED` - MANUAL_STRIPE_PAYOUT_CREATED
     * * `STRIPE_PAYOUT_CREATION_OMITTED` - MANUAL_STRIPE_PAYOUT_CREATION_OMITTED
     * * `PDF_RENDERED` - PDF_RENDERED
     * * `PUBLISHED` - PUBLISHED
     */
    state?: 'AUTOMATIC_AMOUNT_CALCULATED' | 'AUTOMATIC_AUTOVIO_PAYOUT_ITEMS_CREATED' | 'AUTOMATIC_STRIPE_PAYOUT' | 'AUTOMATIC_VERIFIED' | 'INVOICES_COLLECTED' | 'MANUAL_AMOUNT_CALCULATED' | 'MANUAL_ERROR_CREATING_PAYOUT' | 'MANUAL_STRIPE_PAYOUT' | 'NEW' | 'PDF_RENDERED' | 'PUBLISHED' | 'STRIPE_PAYOUT_CREATED' | 'STRIPE_PAYOUT_CREATION_OMITTED',
  }): CancelablePromise<PaginatedAutovioPayoutList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/payout/',
      query: {
        'company__driving_school_id': companyDrivingSchoolId,
        'company__id': companyId,
        'limit': limit,
        'offset': offset,
        'ordering': ordering,
        'state': state,
      },
    });
  }

  /**
   * @returns AutovioPayout
   * @throws ApiError
   */
  public payoutRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this autovio payout.
     */
    id: string,
  }): CancelablePromise<AutovioPayout> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/payout/{id}/',
      path: {
        'id': id,
      },
    });
  }

}
