import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Typography } from "@mui/material";
import { Datagrid, FunctionField, ReferenceOneField } from "react-admin";
import { grants } from "../../backoffice.access_control";
import { DunningButtons } from "../../buttons/DunningButtons";
import { RefundPaymentDialogButton } from "../../buttons/RefundInvoiceDialogButton";
import { DateField } from "../../fields/DateField";
import { LinkToStripeField } from "../../fields/LinkToStripeField";
import { MoneyCurrencyField, MoneyField } from "../../fields/MoneyField";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { EmptyState } from "../../misc/EmptyState";
import { MoneyCurrency } from "../../misc/Money";
import { Row } from "../../misc/Row";
import { UserBalanceItem, UserBalanceItemWithBalance } from "../../providers/invoicesProvider";

export function StudentBalanceList() {
  return (
    <>
      <Row sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography variant="h5">Aktueller Saldo:</Typography>
          <MoneyField variant="h5" source="balance" redForNegativeValues />
        </Box>
        <DunningButtons />
      </Row>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <ReferenceOneField reference="backendUser" target="id" source="id">
          <>
            <Typography variant="body1">In Transit:</Typography>
            <MoneyCurrencyField variant="body1" source="payload.balance.in_transit" />
          </>
        </ReferenceOneField>
      </Box>
      <ReferenceManyField label="" reference="studentBalance" target="studentId">
        <Datagrid bulkActionButtons={false} empty={<EmptyState label="Keine Zahlungen/Gutschriften vorhanden." />}>
          <DateField label="Datum" source="created_at" showTime sortable={false} />
          <FunctionField
            label="Beschreibung"
            source="description"
            render={(userBalanceItem: UserBalanceItemWithBalance) => {
              const [first, ...rest] = userBalanceItem.description.split("\n");
              return (
                <>
                  <Typography variant="body2">{first}</Typography>
                  {rest.map((line, index) => (
                    <Typography key={index} variant="body2" color="textSecondary">
                      {line}
                    </Typography>
                  ))}
                </>
              );
            }}
          />
          <FunctionField
            label="Betrag"
            sortable={false}
            textAlign="right"
            render={(userBalanceItem: UserBalanceItemWithBalance) => {
              return <MoneyCurrency {...userBalanceItem.amount} />;
            }}
          />
          <FunctionField
            label="Änderung"
            sortable={false}
            textAlign="right"
            render={(userBalanceItem: UserBalanceItemWithBalance) => {
              if (!userBalanceItem.balance_change || parseFloat(userBalanceItem.balance_change.amount) === 0) {
                return;
              }
              return <MoneyCurrency showPlus greenForPositiveValues {...userBalanceItem.balance_change} />;
            }}
          />
          <FunctionField
            label="Saldo"
            sortable={false}
            textAlign="right"
            render={(userBalanceItem: UserBalanceItemWithBalance) => {
              if (!userBalanceItem.balance_change) {
                return;
              }
              return <MoneyCurrency greenForPositiveValues cents={userBalanceItem.accuBalance} />;
            }}
          />
          <FunctionField
            label=""
            sortable={false}
            render={(userBalanceItem: UserBalanceItem) => {
              if (userBalanceItem.type !== "INVOICE") {
                return;
              }
              const { pdf_url } = userBalanceItem ?? {};
              if (!pdf_url) {
                return;
              }
              return (
                <Button
                  startIcon={<OpenInNewIcon />}
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    open(await pdf_url, "_blank");
                  }}
                >
                  Öffnen
                </Button>
              );
            }}
          />
          {grants.includes("refundPayments") && (
            <FunctionField
              label=""
              sortable={false}
              render={(userBalanceItem: UserBalanceItem) => {
                if (
                  !userBalanceItem ||
                  userBalanceItem.type !== "INVOICE" ||
                  !userBalanceItem.invoice_state ||
                  !userBalanceItem.invoice_id ||
                  !userBalanceItem.amount
                ) {
                  return null;
                }
                return (
                  <RefundPaymentDialogButton
                    invoiceState={userBalanceItem.invoice_state}
                    invoiceId={userBalanceItem.invoice_id}
                    amount={userBalanceItem.amount}
                  />
                );
              }}
            />
          )}
          {grants.includes("viewStripe") && <LinkToStripeField label="" source="payment_intent_id" />}
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}
