import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { TheoryExam } from "../../model/autovioCalendarEvents";
import { autovioCalendarEventConverter } from "./autovioCalendarEventConverter";

export const theoryExamConverter = {
  toFirestore: (_: TheoryExam): DocumentData => {
    throw new Error("Not implemented");
  },

  fromFirestore: (doc: QueryDocumentSnapshot): TheoryExam => {
    const result = autovioCalendarEventConverter.fromFirestore(doc);
    if (result.type === "TheoryExam") {
      return result;
    }
    throw Error(`Document ${doc.id} is not a theory exam`);
  },
};
