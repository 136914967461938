import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RawOffIcon from "@mui/icons-material/RawOff";
import RawOnIcon from "@mui/icons-material/RawOn";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { type ReactNode, useState } from "react";
import {
  Datagrid,
  FunctionField,
  LinearProgress,
  Link,
  ListContextProvider,
  ReferenceField,
  TextField,
  useGetOne,
  type UseGetOneHookValue,
} from "react-admin";
import { grants } from "../backoffice.access_control";
import { DateField, formatDateDiff } from "../fields/DateField";
import { MoneyField } from "../fields/MoneyField.js";
import { formatPercentage } from "../fields/PercentageField";
import { Column } from "../misc/Column";
import { DialogProps } from "../misc/DialogProps";
import { InstructorAvatar } from "../misc/InstructorAvatar";
import { Money } from "../misc/Money";
import { Row } from "../misc/Row";
import { StudentAvatar } from "../misc/StudentAvatar";
import type { AutovioPayoutItemRecord } from "../providers/autovioPayoutItemProvider.js";
import { CreditNote } from "../providers/creditNotesProvider";
import { Instructor } from "../providers/instructorsProvider";
import { Invoice } from "../providers/invoicesProvider";
import { PerformanceOverviewRecord } from "../providers/performanceOverviewProvider";
import { Student } from "../providers/studentsProvider";
import { listContextFromArray } from "../utils/listContextFromArray.js";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { autovioColors } from "../misc/backofficeTheme";

interface PerformanceOverviewRecordDialogProps extends DialogProps {
  record: PerformanceOverviewRecord;
}

export function PerformanceOverviewRecordDialog({ open, onClose, record }: PerformanceOverviewRecordDialogProps) {
  const [showRawEntry, setShowRawEntry] = useState(false);
  const showPayoutInformation = grants.includes("viewPayoutInformation");

  return (
    <Dialog open={open} onClose={onClose} maxWidth={showRawEntry ? "xl" : "md"} fullWidth>
      <DialogTitle>
        <Column>
          <Typography variant="h6">{record.description}</Typography>
          <Typography variant="body1" color="text.secondary">
            {`${record.serviceAt.toLocaleString(DateTime.DATE_FULL, {
              locale: "de",
            })} (${formatDateDiff(DateTime.now(), record.serviceAt)}) • ${record.serviceAt.toLocaleString(
              DateTime.TIME_SIMPLE,
              { locale: "de" },
            )} Uhr`}
          </Typography>
        </Column>
      </DialogTitle>
      {grants.includes("admin") && (
        <IconButton
          sx={{ position: "absolute", top: "12px", right: "52px" }}
          onClick={() => setShowRawEntry(!showRawEntry)}
        >
          {showRawEntry ? (
            <RawOffIcon sx={{ fill: autovioColors.black }} />
          ) : (
            <RawOnIcon sx={{ fill: autovioColors.black }} />
          )}
        </IconButton>
      )}
      <DialogCloseButton onClose={onClose} />
      <DialogContent>
        {showRawEntry ? (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <_Table record={record} />
            </Grid>
            <Grid item xs={6}>
              <pre style={{ height: "auto", maxHeight: "500px", overflow: "auto" }}>
                {JSON.stringify(record.rawEntry, null, 2)}
              </pre>
            </Grid>
          </Grid>
        ) : (
          <_Table record={record} />
        )}
        {showPayoutInformation && <_PayoutItemsTable record={record} />}
      </DialogContent>
    </Dialog>
  );
}

function _PayoutItemsTable({ record }: { record: PerformanceOverviewRecord }) {
  if (!record.autovioPayoutItems.length) {
    return null;
  }
  const data: Record<string, AutovioPayoutItemRecord & { hookValue?: UseGetOneHookValue<Invoice | CreditNote> }> = {};
  for (const item of record.autovioPayoutItems) {
    if (!data[item.id]) {
      let object: UseGetOneHookValue<Invoice | CreditNote> | undefined = undefined;
      if (item.invoiceId) {
        object = useGetOne<Invoice>("b2cInvoices", { id: item.invoiceId });
      }
      if (item.reportingCategory === "refund" && item.creditNoteId) {
        object = useGetOne<Invoice>("b2cCreditNotes", { id: item.creditNoteId });
      }
      data[item.id] = { ...item, hookValue: object };
    }
  }
  return (
    <div>
      <h2>Auszahlungshistorie</h2>
      <p>Die folgende Liste zeigt in welchen Auszahlungen diese Leistung abgerechnet wurde.</p>
      <ListContextProvider value={listContextFromArray(Object.values(data))}>
        <Datagrid bulkActionButtons={false}>
          <ReferenceField source="payoutId" reference="autovioPayouts" label="Datum">
            <DateField source="payoutDate" showDate />
          </ReferenceField>
          <ReferenceField source="payoutId" reference="autovioPayouts" label="Auszahlungshöhe">
            <MoneyField source="calculatedPayoutAmount" />
          </ReferenceField>
          <TextField source="reportingCategoryText" label="Kategorie" />
          <FunctionField
            render={(
              record: AutovioPayoutItemRecord & { hookValue?: UseGetOneHookValue<Invoice | CreditNote> | undefined },
            ) => {
              if (record.hookValue?.isLoading) {
                return <LinearProgress />;
              }
              if (record.hookValue?.data) {
                return (
                  <div>
                    {record.hookValue.data.nr}
                    {record.hookValue.data.downloadUrl && (
                      <Link to={record.hookValue.data.downloadUrl} target="_blank">
                        <OpenInNewIcon />
                      </Link>
                    )}
                  </div>
                );
              }
              return record.invoiceId;
            }}
            label="Rechnung/Gutschrift"
          />
          <MoneyField source="payoutAmount" label="Betrag" />
        </Datagrid>
      </ListContextProvider>
    </div>
  );
}

function _Table({ record }: { record: PerformanceOverviewRecord }) {
  const { data: instructor } = useGetOne<Instructor>(
    "instructors",
    { id: record.instructorId },
    { enabled: !!record.instructorId },
  );
  const { data: student } = useGetOne<Student>("students", { id: record.studentId }, { enabled: !!record.studentId });
  return (
    <table style={{ borderSpacing: "4px" }}>
      <tbody>
        {record.instructorId && (
          <_TableRow
            label="Fahrlehrer"
            avatar={<InstructorAvatar instructorId={record.instructorId} size="40px" />}
            text={instructor?.name}
            link={`/instructors/${record.instructorId}`}
          />
        )}
        {record.studentId && (
          <_TableRow
            label="Fahrschüler"
            avatar={<StudentAvatar studentId={record.studentId} size="40px" />}
            text={student?.name}
            link={`/students/${record.studentId}`}
          />
        )}
        <_InvoiceDetails record={record} />
      </tbody>
    </table>
  );
}

function _InvoiceDetails({ record }: { record: PerformanceOverviewRecord }) {
  const { data: invoice } = useGetOne<Invoice>("b2cInvoices", { id: record.invoiceId });
  const { data: creditNote } = record.creditNoteId
    ? useGetOne<CreditNote>("b2cCreditNotes", { id: record.creditNoteId })
    : { data: null };
  const { data: b2bInvoice } = record.b2bInvoiceId
    ? useGetOne<Invoice>("b2bInvoices", { id: record.b2bInvoiceId })
    : { data: null };
  const { data: b2bCreditNote } = record.b2bCreditNoteId
    ? useGetOne<Invoice>("b2bCreditNotes", { id: record.b2bCreditNoteId })
    : { data: null };
  return (
    <>
      <_TableRow label="Partner-Rechnungsnummer" text={record.invoiceNumber} link={invoice?.downloadUrl} />
      {record.creditNoteId && (
        <_TableRow label="Partner-Gutschrift" text={record.creditNoteNumber} link={creditNote?.downloadUrl} />
      )}
      {record.b2bInvoiceId && (
        <_TableRow label="AUTOVIO-Rechnung" text={record.b2bInvoiceNumber} link={b2bInvoice?.downloadUrl} />
      )}
      {record.b2bCreditNoteId && (
        <_TableRow label="AUTOVIO-Gutschrift" text={record.b2bCreditNoteNumber} link={b2bCreditNote?.downloadUrl} />
      )}
      <_TableRow label="Rechnungsbetrag (brutto)" text={<Money value={record.invoiceTotalGross} />} />
      <_TableRow label="Rechnungsbetrag (netto)" text={<Money value={record.invoiceTotalNet} />} />
      {typeof record.valueAddedTax === "number" && (
        <_TableRow label="Mehrwertsteuer" text={formatPercentage(record.valueAddedTax)} />
      )}
      {record.partnerTurnoverAmountGross && (
        <_TableRow label="Partner-Umsatzanteil (brutto)" text={<Money value={record.partnerTurnoverAmountGross} />} />
      )}
      {record.partnerTurnoverAmountNet && (
        <_TableRow label="Partner-Umsatzanteil (netto)" text={<Money value={record.partnerTurnoverAmountNet} />} />
      )}
      {record.compensationGross && grants.includes("viewPerformanceOverviewCompensation") && (
        <_TableRow label="Partner-Rabattausgleich (brutto)" text={<Money value={record.compensationGross} />} />
      )}
      {record.compensationNet && grants.includes("viewPerformanceOverviewCompensation") && (
        <_TableRow label="Partner-Rabattausgleich (netto)" text={<Money value={record.compensationNet} />} />
      )}
      {record.applicationFeeAmountGross && (
        <_TableRow label="AUTOVIO-Umsatzanteil (brutto)" text={<Money value={record.applicationFeeAmountGross} />} />
      )}
      {record.applicationFeeAmountNet && (
        <_TableRow label="AUTOVIO-Umsatzanteil (netto)" text={<Money value={record.applicationFeeAmountNet} />} />
      )}
      {typeof record.autovioTakeRate === "number" && grants.includes("viewPerformanceOverviewTakeRate") && (
        <_TableRow label="AUTOVIO Take Rate" text={formatPercentage(record.autovioTakeRate)} />
      )}
    </>
  );
}

function _TableRow({
  label,
  avatar,
  text,
  link,
}: {
  label: string;
  avatar?: ReactNode;
  text: ReactNode;
  link?: string | null;
}) {
  return (
    <tr>
      <th align="left" style={{ verticalAlign: "middle" }}>
        {label}:
      </th>
      <td>
        <Row spacing={1} sx={{ alignItems: "center" }}>
          {avatar || <div style={{ width: "40px" }} />}
          <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
            {text}
          </Typography>
          {link && (
            <Link to={link} target="_blank">
              <OpenInNewIcon sx={{ mt: "6px" }} />
            </Link>
          )}
        </Row>
      </td>
    </tr>
  );
}
