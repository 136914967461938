import { DateTime } from "luxon";
import type {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  PaginationPayload,
  RaRecord,
  SortPayload,
} from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import {
  CreditNote as CreditNoteDto,
  type CreditNoteEndpoint,
  type CreditNoteStateEnum,
} from "../generated/backendClient";
import { toSnakeCase } from "../utils/string";
import { AbstractProvider } from "./abstractProvider";

export interface CreditNote extends RaRecord {
  id: string;
  nr: string;
  type: string;
  createdAt: DateTime;
  total?: number;
  status: CreditNoteStateEnum;
  downloadUrl: string | null;
  payload: CreditNoteDto;
}

export class CreditNoteProvider extends AbstractProvider<CreditNote> {
  async getOne(resource: string, { id }: GetOneParams): Promise<GetOneResult<CreditNote>> {
    if (resource === "b2cCreditNotes" || resource === "b2bCreditNotes") {
      const backendClient = await getAuthenticatedBackendClient();
      const creditNote = await backendClient.creditNote.creditNoteRetrieve({ id });
      return { data: creditNoteDtoToCreditNote(creditNote) };
    }
    throw new Error("Not implemented");
  }

  async getMany(resource: string, { ids }: GetManyParams): Promise<GetManyResult<CreditNote>> {
    const data: Array<CreditNote> = [];
    for (const id of ids) {
      const { data: record } = await this.getOne(resource, { id });
      data.push(record);
    }
    return { data };
  }

  async getList(resource: string, { pagination, sort, filter }: GetListParams): Promise<GetListResult<CreditNote>> {
    return await fetchCreditNotes(filter, pagination, sort);
  }

  async getManyReference(
    resource: string,
    { id, target, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<CreditNote>> {
    if (resource === "b2bCreditNotes") {
      if (target !== "drivingSchoolId") {
        throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "drivingSchoolId"`);
      }
      filter = { ...filter, drivingSchoolId: id };
    } else if (resource === "b2cCreditNotes") {
      if (target !== "studentId") {
        throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentId"`);
      }
      filter = { ...filter, studentId: id };
    }
    return this.getList(resource, { filter, sort, pagination });
  }
}

export const creditNotesProvider = new CreditNoteProvider() as DataProvider;

async function fetchCreditNotes(
  { drivingSchoolId, studentId }: { drivingSchoolId?: string; studentId?: string },
  { page, perPage }: PaginationPayload,
  sort: SortPayload,
): Promise<GetListResult<CreditNote>> {
  const ordering = `${sort.order === "ASC" ? "" : "-"}${toSnakeCase(sort.field)}`;
  if (drivingSchoolId && studentId) {
    throw new Error("drivingSchoolId and student are are mutually exclusive");
  } else if (!drivingSchoolId && !studentId) {
    throw new Error("Neither drivingSchoolId nor studentId given");
  }

  let filter: Parameters<CreditNoteEndpoint["creditNoteList"]>[0];
  if (drivingSchoolId) {
    filter = { recipientCompanyDrivingSchoolId: drivingSchoolId, type: "B2B" };
  } else {
    filter = { recipientFirebaseAuthUsersId: studentId };
  }

  const backendClient = await getAuthenticatedBackendClient();
  const { count, results, previous, next } = await backendClient.creditNote.creditNoteList({
    offset: (page - 1) * perPage,
    ordering,
    limit: perPage,
    ...filter,
  });

  return {
    data: results.map(creditNoteDtoToCreditNote),
    total: count,
    pageInfo: { hasPreviousPage: !!previous, hasNextPage: !!next },
  };
}

function creditNoteDtoToCreditNote(creditNoteDto: CreditNoteDto): CreditNote {
  const { id, calculation_number, type, created_at, pdf, total_gross, state } = creditNoteDto;
  let localizedType: string = type;
  if (type === "BASE_FEE") {
    localizedType = "für Grundgebühr";
  } else if (type === "TEACHING_MATERIAL") {
    localizedType = "für Lehrmittel";
  } else if (type === "DRIVING_LESSON_INVOICE") {
    localizedType = "für Fahrstunde";
  } else if (type === "REMOTE_LESSON") {
    localizedType = "für Zoom-Meeting";
  } else if (type === "AUTHORITY_FEE") {
    localizedType = "für Prüfungsgebühr";
  } else if (type === "CREDIT_NOTE") {
    localizedType = "Erstattung";
  } else if (type === "PREPAID_CREDITS") {
    localizedType = "Vorauszahlung";
  }
  return {
    id,
    nr: calculation_number ?? id ?? "???",
    type: localizedType,
    createdAt: DateTime.fromISO(created_at),
    total: total_gross.amount ? parseFloat(total_gross.amount) * 100 : undefined,
    status: state,
    downloadUrl: pdf,
    payload: creditNoteDto,
    accuBalance: 0,
  };
}
