import { useGetManyReference } from "react-admin";
import { autovioColors } from "../../misc/backofficeTheme";
import { Student } from "../../providers/studentsProvider";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box, Typography } from "@mui/material";
import { formatDateTime, calendarEventColor } from "../../utils/calendar";
import { Spinner } from "../../misc/Spinner";
import { StudentActivityDto } from "../../providers/studentActivitiesProvider";

export function ActivitiesTimeline({
  student,
  pagination = { page: 1, perPage: 5 },
}: {
  student: Student;
  pagination?: { page: number; perPage: number };
}) {
  const { grey } = autovioColors;
  const { data } = useGetManyReference("studentActivities", {
    target: "studentUid",
    id: student.id,
    pagination,
    sort: { field: "dateTime", order: "DESC" },
  });

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      {!data && (
        <div className="centered">
          <Spinner style={{ marginTop: "60px" }} />
        </div>
      )}

      <Timeline sx={{ marginTop: -2 }}>
        {data &&
          data?.map((activity, index) => (
            <TimelineItem sx={{ "::before": { content: "none" }, minHeight: 60 }}>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    background: _activityColor(activity),
                    border: "4px solid #f2f2f2",
                    boxShadow: "0px 0px 0px",
                    width: 20,
                    height: 20,
                    margin: 0,
                    marginTop: 1,
                  }}
                />
                {data.length !== index + 1 && (
                  <TimelineConnector
                    sx={{ "&.MuiTimelineConnector-root": { background: "#f2f2f2", width: 4, marginBottom: -1 } }}
                  />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Box sx={{ flex: 3 }}>
                  <Typography sx={{ fontSize: 14 }}>{activity.label}</Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: grey,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: 200,
                    }}
                  >
                    {formatDateTime(activity.dateTime, true)}{" "}
                    {activity.calendarEvent?.duration?.minutes
                      ? ` • ${activity.calendarEvent?.duration?.minutes} Min.`
                      : null}
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>

      {data && data.length === 0 && (
        <Typography sx={{ color: grey, marginTop: -2, fontSize: 14 }}>Noch keine Aktivität</Typography>
      )}
    </Box>
  );
}

function _activityColor(activity: StudentActivityDto) {
  const { label } = activity;
  if (label.startsWith("Kurzfristig abgesagt:") || label.startsWith("Abgesagt:") || label.startsWith("No-Show:")) {
    return autovioColors.red;
  }
  return calendarEventColor(activity.calendarEvent);
}
