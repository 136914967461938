import type { Vehicle } from "../providers/resourceProviders";
import { CarIcon } from "../icons/CarIcon";
import { Create, Edit, RecordContextProvider, Resource, useGetOne, useGetRecordId } from "react-admin";
import { useLocation } from "react-router-dom";
import { CarForm } from "../details/vehicles/CarForm";
import { MotorcycleForm } from "../details/vehicles/MotorcycleForm";
import { TrailerForm } from "../details/vehicles/TrailerForm";
import { DrivingSchoolFleet } from "../details/drivingSchool/DrivingSchoolFleet";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { PageTitle } from "../misc/PageTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";

function VehiclesList() {
  const [{ drivingSchoolId }] = useAutovioContext();

  if (!drivingSchoolId) {
    return (
      <>
        <PageTitle>Fuhrpark</PageTitle>
        <div className="centered">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className="error" style={{ fontWeight: "bold", fontSize: "2em" }}>
              Keine Fahrschule ausgewählt
            </div>
          </div>
        </div>
      </>
    );
  }

  const { data: drivingSchool } = useGetOne("drivingSchools", { id: drivingSchoolId });

  if (!drivingSchool) {
    return null;
  }

  return (
    <RecordContextProvider value={drivingSchool}>
      <DrivingSchoolFleet />
    </RecordContextProvider>
  );
}

function VehicleCreate() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  let type = searchParams.get("type") as Vehicle["type"];
  if (!type) {
    const source = JSON.parse(searchParams.get("source") as string);
    type = source.type;
  }
  let title: string;
  switch (type) {
    case "car":
      title = "Neues Auto";
      break;
    case "motorcycle":
      title = "Neues Motorrad";
      break;
    case "trailer":
      title = "Neuer Anhänger";
      break;
  }

  return (
    <Create title={<PageTitle>{title}</PageTitle>}>
      <VehicleForm type={type} mode="create" />
    </Create>
  );
}

function VehicleEdit() {
  const id = useGetRecordId();
  const { data: vehicle } = useGetOne("vehicles", { id });

  if (!vehicle) {
    return null;
  }

  return (
    <Edit mutationMode="pessimistic" title={<BackofficeRecordTitle />}>
      <VehicleForm type={vehicle.type} mode="edit" />
    </Edit>
  );
}

function VehicleForm({ type, mode }: { type: "car" | "motorcycle" | "trailer"; mode: "create" | "edit" }) {
  switch (type) {
    case "car":
      return <CarForm mode={mode} />;
    case "motorcycle":
      return <MotorcycleForm mode={mode} />;
    case "trailer":
      return <TrailerForm mode={mode} />;
  }
}

export const vehiclesResource = (
  <Resource
    key="vehicles"
    name="vehicles"
    icon={CarIcon}
    list={VehiclesList}
    create={VehicleCreate}
    edit={VehicleEdit}
    options={{ label: "Fuhrpark" }}
    recordRepresentation={(vehicle: Vehicle) => vehicle.name}
  />
);
