import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import {
  fetchAutovioEmployeesOnce,
  fetchFirebaseAuthUser,
  FirebaseAuthUser,
  setUserRoles,
} from "../api/backoffice.api";
import { AbstractProvider } from "./abstractProvider";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";

export interface AutovioEmployee {
  readonly id: string;
  readonly displayName: string;
  readonly avatarUrl?: string;
  readonly roles: Array<string>;
  readonly entitledForDrivingSchools: Array<string>;
}

function firebaseAuthUserToAutovioEmployee(user: FirebaseAuthUser): AutovioEmployee {
  const email = user.providerData.find((it) => it.email)?.email;
  return {
    id: user.uid,
    displayName: user.displayName || user.providerData.find((it) => it.displayName)?.displayName || email || user.uid,
    avatarUrl: user.providerData.find((it) => it.photoURL)?.photoURL,
    roles: user.customClaims?.roles ?? [].sort(),
    entitledForDrivingSchools: user.customClaims?.entitledForDrivingSchools ?? [],
  };
}

class AutovioEmployeesProvider extends AbstractProvider<AutovioEmployee> {
  async getOne(_resource: string, { id }: GetOneParams): Promise<GetOneResult<AutovioEmployee>> {
    const record = await fetchFirebaseAuthUser(id);
    return { data: firebaseAuthUserToAutovioEmployee(record) };
  }

  async getMany(resource: string, { ids }: GetManyParams): Promise<GetManyResult<AutovioEmployee>> {
    return { data: [(await this.getOne(resource, { id: ids[0] })).data] };
  }

  async getList(
    _resource: string,
    { sort, filter, pagination }: GetListParams,
  ): Promise<GetListResult<AutovioEmployee>> {
    const records = (await fetchAutovioEmployeesOnce()).map(firebaseAuthUserToAutovioEmployee);
    return applyPagination(applySort(applyFilter(records, filter), sort), pagination);
  }

  async update(resource: string, { id, data }: UpdateParams<AutovioEmployee>): Promise<UpdateResult<AutovioEmployee>> {
    if (!Array.isArray(data.roles)) {
      throw new Error("data.roles must be an array");
    }
    await setUserRoles(id.toString(), data.roles, data.entitledForDrivingSchools);
    return await this.getOne(resource, { id });
  }
}

export const autovioEmployeesProvider = new AutovioEmployeesProvider();
