import { query, where, Timestamp, Query } from "firebase/firestore";
import { DateTime } from "luxon";
import { Identifier } from "react-admin";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";

export const commonCalendarEventQueryConstraints = (
  existingQuery: Query,
  filters: { target: string; id: Identifier; from?: string; to?: string; includeDeleted?: boolean },
) => {
  const { from, to, includeDeleted, target, id } = filters;
  let q = existingQuery;

  if (target !== "drivingSchoolUid" && restrictAccessToDrivingSchoolIds) {
    if (restrictAccessToDrivingSchoolIds.length === 1) {
      q = query(q, where("drivingSchoolUid", "==", restrictAccessToDrivingSchoolIds[0]));
    } else {
      q = query(q, where("drivingSchoolUid", "in", restrictAccessToDrivingSchoolIds));
    }
  }

  if (from) {
    q = query(q, where("start", ">=", Timestamp.fromDate(DateTime.fromISO(from).toJSDate())));
  }
  if (to) {
    q = query(q, where("start", "<=", Timestamp.fromDate(DateTime.fromISO(to).toJSDate())));
  }

  if (includeDeleted) {
    q = query(q, where("derived.isReplaced", "==", false));
  } else {
    q = query(q, where("deleted", "==", false));
  }

  switch (target) {
    case "derived.studentUids":
    case "studentUid":
    case "studentUids":
      q = query(q, where(`derived.studentUids`, "array-contains", id));
      break;
    case "drivingSchoolUid":
      if (restrictAccessToDrivingSchoolIds && !restrictAccessToDrivingSchoolIds.includes(id as string)) {
        throw new Error(`Access to calendar events of driving school ${id} denied`);
      }
      q = query(q, where("drivingSchoolUid", "==", id));
      break;
    default:
      throw new Error(`Unexpected target: ${JSON.stringify(target)}`);
  }
  return q;
};
