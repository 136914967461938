import * as React from "react";
import { Button, DeleteButton, SaveButton, Toolbar, useRecordContext } from "react-admin";
import { recoverResource } from "../providers/resourceProviders";

export function RecoverToolbar(props: any) {
  const record = useRecordContext();
  const deleted = !!record?.deletedAt;
  return (
    <Toolbar style={{ display: "flex", justifyContent: "space-between" }} {...props}>
      {!deleted ? <SaveButton /> : <></>}
      {deleted ? <RecoverButton id={record.id} /> : <DeleteButton />}
    </Toolbar>
  );
}

function RecoverButton(props: { id: string | number }) {
  return (
    <Button
      label="Recover"
      onClick={async () => {
        await recoverResource({ id: props.id });
        window.location.reload();
      }}
    />
  );
}
