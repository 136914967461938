import { FieldProps, useRecordContext } from "react-admin";
import get from "lodash/get";
import { autovioColors } from "../misc/backofficeTheme";
import type { PaymentStatus } from "../providers/performanceOverviewProvider";
import { Box, Typography } from "@mui/material";

export const PAYMENT_STATUS_LABEL_AND_COLOR: { [status in PaymentStatus]: { label: string; color: string } } = {
  UNPAID: { label: "⏳ unbezahlt", color: autovioColors.yellowUltraLight },
  AWAITING_PAYMENT: { label: "⏳ in Arbeit", color: autovioColors.greyUltraLight },
  PAID: { label: "✔ bezahlt", color: autovioColors.greenLight },
  PAYMENT_REVOKED: { label: "⚠ angefochten", color: autovioColors.orange },
  PARTIALLY_REFUNDED: { label: "teilweise erstattet", color: autovioColors.yellowUltraLight },
  REFUNDED: { label: "↶ erstattet", color: autovioColors.greyUltraLight },
  VOIDED: { label: "✖ storniert", color: autovioColors.greyUltraLight },
  UNKNOWN: { label: "? unbekannt", color: autovioColors.orange },
};

export function PaymentStatusField(props: FieldProps & { source: string }) {
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }
  const value = get(record, props.source);

  if (!value) {
    return null;
  }

  const { label, color } = PAYMENT_STATUS_LABEL_AND_COLOR[value as PaymentStatus] ?? {
    label: `⚠ ${value}`,
    color: autovioColors.yellowUltraLight,
  };

  return (
    <Box style={{ backgroundColor: color, padding: "0 10px", borderRadius: "5px" }}>
      <Typography variant="overline" style={{ whiteSpace: "nowrap" }}>
        {label}
      </Typography>
    </Box>
  );
}
