import { useEffect, useState } from "react";

interface DelayedProps {
  readonly ms: number;
  readonly children?: React.ReactNode;
}

export const Delayed: React.FC<DelayedProps> = (props) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, props.ms);
    return () => clearTimeout(timer);
  }, [props.ms]);

  return <>{isShown && props.children}</>;
};
