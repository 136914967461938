import { useMemo } from "react";
import { ReferenceManyField, useGetOne, useListContext } from "react-admin";
import { Box, Card, LinearProgress, Typography } from "@mui/material";
import { z } from "zod";
import { PracticalExamWorkflow } from "../../model/PracticalExamWorkflow";
import { autovioColors } from "../../misc/backofficeTheme";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";
import { useSetRecoilState } from "recoil";
import { WorkflowDialog, workflowShownInWorkflowDialogState } from "../../misc/WorkflowDialog";
import { DateTime } from "luxon";
import { formatCalendarWeek } from "../../utils/calendar";
import { StudentAvatar } from "../../misc/StudentAvatar";
import { StudentDisplayName } from "../../misc/StudentDisplayName";
import { Student } from "../../providers/studentsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { ListCheckIcon } from "../../icons/ListCheckIcon";
import useResizeObserver from "use-resize-observer";
import { isForMotorcycle, isForTrailer } from "../../model/DrivingLicenseClass";
import { MotorcycleIcon } from "../../icons/MotorcycleIcon";
import { CarIcon } from "../../icons/CarIcon";
import { TrailerIcon } from "../../icons/TrailerIcon";
import { PageTitle } from "../../misc/PageTitle";

export function DrivingSchoolPracticalExamWorkflowsBoard() {
  return (
    <ReferenceManyField
      reference="workflows"
      target="drivingSchoolUid"
      filter={{ type: "practicalExamSignUp" satisfies PracticalExamWorkflow["type"], finished: false }}
      perPage={9999}
    >
      <_DrivingSchoolPracticalExamWorkflowsBoard />
    </ReferenceManyField>
  );
}

const StageEnum = z.enum([
  "Eignung prüfen",
  "Prüfungstermin organisieren",
  "Warte auf Bestätigung vom Terminvorschlag",
  "Ausbildungsnachweis erstellen",
  "Bereit",
]);
type Stage = z.infer<typeof StageEnum>;

type CardData = PracticalExamWorkflow & { _date: DateTime };

type BoardData = Record<Stage, Array<CardData>>;

function _DrivingSchoolPracticalExamWorkflowsBoard() {
  const { data: workflows, isLoading } = useListContext<PracticalExamWorkflow>();
  const boardData = useMemo(() => {
    const boardData = Object.fromEntries(
      StageEnum.options.map((stage) => [stage, [] as Array<PracticalExamWorkflow>]),
    ) as BoardData;
    if (!workflows) {
      return boardData;
    }
    const addCardToStage = (stage: Stage, workflow: PracticalExamWorkflow) => {
      const card: CardData = {
        ...workflow,
        _date: DateTime.fromFormat(workflow.workflowData.preferredDates[0], "yyyy-MM-dd"),
      };
      const cards = boardData[stage];
      const { _date } = card;
      // Insert card int the correct position (cards should be sorted by calendar week) ...
      const i = cards.findIndex((it) => it._date > _date);
      if (i >= 0) {
        cards.splice(i, 0, card);
      } else {
        cards.push(card);
      }
    };
    for (const workflow of workflows) {
      if (_numDoneTasks(workflow) === _numTasks(workflow)) {
        addCardToStage("Bereit", workflow);
      } else if (workflow.tasks["time_approved_by_student"]?.isDone) {
        addCardToStage("Ausbildungsnachweis erstellen", workflow);
      } else if (workflow.tasks["time_proposal_sent"]?.isDone) {
        addCardToStage("Warte auf Bestätigung vom Terminvorschlag", workflow);
      } else if (workflow.tasks["student_approved_for_exam"]?.isDone) {
        addCardToStage("Prüfungstermin organisieren", workflow);
      } else {
        addCardToStage("Eignung prüfen", workflow);
      }
    }
    return boardData;
  }, [workflows]);
  const { ref: columnHeaderRef, height: columnHeaderHeight } = useResizeObserver({ box: "border-box" });

  return (
    // Because each column has a margin of 5px to the left and to right, but we don't want that margin
    // on the left of the first column/the right of the last column, we use a margin of -5px here ...
    <div style={{ marginLeft: "-5px", marginRight: "-5px" }}>
      <PageTitle>Praktische Prüfungen</PageTitle>
      <Box display="flex" sx={{ mt: "10px" }}>
        {StageEnum.options.map((stage) => (
          <_Column
            key={stage}
            stage={stage}
            // Give each column header the same height. Use the height of the column with the longest title as reference ...
            headerHeight={stage === "Warte auf Bestätigung vom Terminvorschlag" ? undefined : columnHeaderHeight}
            headerRef={stage === "Warte auf Bestätigung vom Terminvorschlag" ? columnHeaderRef : undefined}
            data={boardData[stage]}
          />
        ))}
      </Box>
      <WorkflowDialog />
      {isLoading && <LoadingIndicator />}
    </div>
  );
}

function _Column({
  stage,
  headerHeight,
  headerRef,
  data,
}: {
  stage: Stage;
  headerHeight?: number;
  headerRef?: React.Ref<any>;
  data: Array<CardData>;
}) {
  return (
    <Box
      sx={{
        m: "0 5px",
        borderRadius: "8px",
        flex: 1,
        paddingTop: "15px",
        paddingBottom: "15px",
        bgcolor: autovioColors.greyUltraLight,
      }}
    >
      <Typography
        ref={headerRef}
        align="center"
        variant="subtitle1"
        sx={{
          color: autovioColors.grey,
          pb: "10px",
          lineHeight: 1.43,
          ...(headerHeight ? { height: `${headerHeight}px` } : {}),
        }}
      >
        {stage}
      </Typography>
      <Box
        sx={{
          minHeight: "200px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "5px 10px",
          "&.isDraggingOver": {
            bgcolor: autovioColors.greyLight,
          },
        }}
      >
        {data.map((workflow) => (
          <_Card key={workflow.id} workflow={workflow} />
        ))}
      </Box>
    </Box>
  );
}

function _Card({ workflow }: { workflow: CardData }) {
  const setWorkflowShownInDialog = useSetRecoilState(workflowShownInWorkflowDialogState);
  const numDoneTasks = _numDoneTasks(workflow);
  const numTasks = _numTasks(workflow);
  const { data: student } = useGetOne<Student>("students", { id: workflow.workflowData.studentUid });
  const bookedTraining = student?.bookedTrainings.find((it) => it.id === workflow.workflowData.bookedTrainingId);

  return (
    <Box sx={{ marginBottom: 1, cursor: "pointer" }} onClick={() => setWorkflowShownInDialog(workflow)}>
      <Card
        style={{
          borderRadius: "8px",
          boxShadow: "1px 1px 0 0 rgba(0, 0, 0, 0.15)",
        }}
        elevation={1}
      >
        <Column padding={1}>
          <Row sx={{ mb: "8px" }}>
            <StudentAvatar studentId={workflow.workflowData.studentUid} size="20px" />
            <StudentDisplayName sx={{ ml: "7px" }} studentId={workflow.workflowData.studentUid} />
            <div style={{ flex: 1 }} />
          </Row>
          <LinearProgress variant="determinate" value={(100 * numDoneTasks) / numTasks} />
          <Row sx={{ mt: "8px" }}>
            <ListCheckIcon sx={{ mt: "-1px", color: autovioColors.grey }} />
            <p
              style={{ margin: 0, marginLeft: "5px", fontSize: "12px", color: autovioColors.grey }}
            >{`${numDoneTasks} / ${numTasks}`}</p>
            {bookedTraining && isForMotorcycle(bookedTraining) && (
              <MotorcycleIcon
                sx={{ ml: "15px", mt: "-1px", width: "18px", height: "18px", color: autovioColors.grey }}
              />
            )}
            {bookedTraining && isForTrailer(bookedTraining) && (
              <TrailerIcon sx={{ ml: "15px", mt: "-1px", width: "18px", height: "18px", color: autovioColors.grey }} />
            )}
            {bookedTraining && !(isForMotorcycle(bookedTraining) || isForTrailer(bookedTraining)) && (
              <CarIcon sx={{ ml: "15px", mt: "-1px", width: "18px", height: "18px", color: autovioColors.grey }} />
            )}
            {bookedTraining && (
              <p style={{ margin: 0, marginLeft: "5px", fontSize: "12px", color: autovioColors.grey }}>
                {bookedTraining.drivingLicenseClass}
              </p>
            )}
            <div style={{ flex: 1 }} />
            <p style={{ margin: 0, fontSize: "12px", color: autovioColors.grey }}>
              {formatCalendarWeek(workflow._date)}
            </p>
          </Row>
        </Column>
      </Card>
    </Box>
  );
}

function _numDoneTasks(workflow: PracticalExamWorkflow): number {
  return Object.values(workflow.tasks).filter((task) => task.isDone).length;
}

function _numTasks(workflow: PracticalExamWorkflow): number {
  return Object.keys(workflow.tasks).length;
}
