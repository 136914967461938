import { z } from "zod";

export const StudentStatusEnum = z.enum([
  "active",
  "cancelled",
  "inactive",
  "onHold",
  "outstandingPayments",
  /** Not stored, but derived in _effectiveStatus. */
  "completed",
]);

export type StudentStatus = z.infer<typeof StudentStatusEnum>;
