import { Stack } from "@mui/material";
import { RecordContextProvider, TextField, useGetOne } from "react-admin";
import { VehicleIcon } from "../icons/VehicleIcon";
import { Vehicle } from "../providers/resourceProviders";

type VehicleDisplayProps = {
  vehicleId: string;
  iconSize?: "small" | "medium" | "large";
  iconColor?: string;
  labelColor?: string;
};

export function VehicleDisplay({ vehicleId, iconSize, iconColor, labelColor }: VehicleDisplayProps) {
  const { data: vehicle } = useGetOne<Vehicle>("vehicles", { id: vehicleId });

  if (!vehicle) {
    return null;
  }

  return (
    <RecordContextProvider value={vehicle}>
      <Stack direction="row" spacing="0.5em">
        <VehicleIcon type={vehicle.type} fontSize={iconSize} htmlColor={iconColor} />
        <TextField source="name" overflow={"hidden"} color={labelColor} />
      </Stack>
    </RecordContextProvider>
  );
}
