import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";

export class AbstractProvider<T extends RaRecord> {
  async getList(_: string, __: GetListParams): Promise<GetListResult<T>> {
    throw new Error("Not implemented");
  }

  async getOne(_: string, __: GetOneParams<T>): Promise<GetOneResult<T>> {
    throw new Error("Not implemented");
  }

  async getMany(_: string, __: GetManyParams): Promise<GetManyResult<T>> {
    throw new Error("Not implemented");
  }

  async getManyReference(_: string, __: GetManyReferenceParams): Promise<GetManyReferenceResult<T>> {
    throw new Error("Not implemented");
  }

  async create(_: string, __: CreateParams<any>): Promise<CreateResult<T>> {
    throw new Error("Not implemented");
  }

  async update(_: string, __: UpdateParams<T>): Promise<UpdateResult<T>> {
    throw new Error("Not implemented");
  }

  async updateMany(_: string, __: UpdateManyParams<any>): Promise<UpdateManyResult> {
    throw new Error("Not implemented");
  }

  async delete(_: string, __: DeleteParams): Promise<DeleteResult<T>> {
    throw new Error("Not implemented");
  }

  async deleteMany(_: string, __: DeleteManyParams): Promise<DeleteManyResult> {
    throw new Error("Not implemented");
  }
}
