/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ZendeskEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any No response body
   * @throws ApiError
   */
  public zendeskCreateTicketCreate(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/zendesk/create-ticket/',
    });
  }

}
