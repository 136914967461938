import { Link, Menu, MenuItemLink, useSidebarState } from "react-admin";
import Event from "@mui/icons-material/Event";
import Euro from "@mui/icons-material/Euro";
import { Box, PopoverOrigin, SxProps, Theme } from "@mui/material";
import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import { autovioColors } from "./backofficeTheme";
import { useLocation } from "react-router-dom";
import { InstructorIcon } from "../icons/InstructorIcon";
import { StudentsIcon } from "../icons/StudentsIcon";
import { ExamIcon } from "../icons/ExamIcon";
import { ReportIcon } from "../icons/ReportIcon";
import { FleetIcon } from "../icons/FleetIcon";
import { HomeIcon } from "../icons/HomeIcon";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { grants } from "../backoffice.access_control";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { ArrowLeftIcon } from "@mui/x-date-pickers";

const { green, grey, white } = autovioColors;

export function BackofficeSideBar() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const { pathname } = useLocation();
  const isDashboard = pathname === "/" || /^\/drivingSchools\/([^/]+)$/.test(pathname);
  const isInstructors = pathname.includes("/instructors");
  const isStudents = pathname.includes("/students");
  const isCalendar = pathname.includes("/calendar") && !pathname.includes("/instructors");
  const isReports = pathname.includes("/bi");
  const isExamAssignment = pathname.includes("/examinationAssignments");
  const isTheoryExams = pathname.includes("/theoryExams");
  const isPracticalExams = pathname.includes("/practicalExams");
  const isPerformanceOverview = pathname.includes("/performanceOverview");
  const isOpenInvoices = pathname.includes("/openInvoices");
  const isPayouts = pathname.includes("/payouts");
  const isAdvancePayments = pathname.includes("/advancePayments");
  const isTax = pathname.includes("/tax");
  const isFleet = pathname.includes("/fleet") || pathname.includes("/vehicles");
  const isCourses = pathname.includes("/courses");
  const isAdmin = pathname.includes("/admin");
  const examItems = useMemo(
    () => [
      { name: "Prüfaufträge", to: `/drivingSchools/${drivingSchoolId}/examinationAssignments` },
      { name: "Theorieprüfungen", to: `/drivingSchools/${drivingSchoolId}/theoryExams` },
      { name: "Praktische Prüfungen", to: `/drivingSchools/${drivingSchoolId}/practicalExams` },
    ],
    [drivingSchoolId],
  );
  const financeItems = useMemo(
    () => [
      ...(grants.includes("viewPerformanceOverview")
        ? [
            { name: "Leistungsübersicht", to: `/drivingSchools/${drivingSchoolId}/performanceOverview` },
            { name: "Vorauszahlungen", to: `/drivingSchools/${drivingSchoolId}/advancePayments` },
            { name: "Offene Posten", to: `/drivingSchools/${drivingSchoolId}/openInvoices` },
          ]
        : []),
      ...(grants.includes("viewPayoutInformation")
        ? [
            { name: "Auszahlungen", to: `/drivingSchools/${drivingSchoolId}/payouts` },
            { name: "Steuerbüro-Exports", to: `/drivingSchools/${drivingSchoolId}/tax` },
          ]
        : []),
    ],
    [drivingSchoolId],
  );

  if (!drivingSchoolId) {
    return null;
  }

  return (
    <div id="sidebar" style={{ width: 260, background: "#f8f8f8", paddingLeft: 20, paddingRight: 20 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TopLevelSideBarLink to="/" isSelected={isDashboard} icon={<HomeIcon />}>
          Dashboard
        </TopLevelSideBarLink>

        <TopLevelSideBarLink
          to={`/drivingSchools/${drivingSchoolId}/calendar`}
          isSelected={isCalendar}
          icon={<Event />}
        >
          Kalender
        </TopLevelSideBarLink>

        <TopLevelSideBarLink
          to={`drivingSchools/${drivingSchoolId}/instructors`}
          isSelected={isInstructors}
          icon={<InstructorIcon />}
        >
          Fahrlehrer
        </TopLevelSideBarLink>

        <TopLevelSideBarLink
          to={`/drivingSchools/${drivingSchoolId}/students`}
          isSelected={isStudents}
          icon={<StudentsIcon />}
        >
          Fahrschüler
        </TopLevelSideBarLink>

        <TopLevelSideBarMenu
          label="Prüfungen"
          sx={{
            color: grey,
            display: "flex",
            paddingRight: "12px" /* <-- because the ExpandMoreIcon already has 4px spacing left and right */,
          }}
          icon={<ExamIcon />}
          isSelected={isExamAssignment || isTheoryExams || isPracticalExams}
          menuItems={examItems}
        />

        {/* <TopLevelSideBarLink to="/news" isSelected={isNews} icon={<NewsIcon />}>
        Nachrichten
      </TopLevelSideBarLink> */}

        {financeItems.length > 0 && (
          <TopLevelSideBarMenu
            label="Finanzen"
            sx={{
              color: grey,
              display: "flex",
              paddingRight: "12px" /* <-- because the ExpandMoreIcon already has 4px spacing left and right */,
            }}
            isSelected={isPerformanceOverview || isOpenInvoices || isPayouts || isTax || isAdvancePayments}
            menuItems={financeItems}
            icon={<Euro />}
          />
        )}

        <TopLevelSideBarLink to={`/drivingSchools/${drivingSchoolId}/bi`} isSelected={isReports} icon={<ReportIcon />}>
          Reports
        </TopLevelSideBarLink>

        <TopLevelSideBarMenu
          label="Fahrschule"
          sx={{
            color: grey,
            display: "flex",
            paddingRight: "12px" /* <-- because the ExpandMoreIcon already has 4px spacing left and right */,
          }}
          isSelected={isFleet || isCourses}
          menuItems={[
            { name: "Fuhrpark", to: `/drivingSchools/${drivingSchoolId}/fleet` },
            { name: "Kurse", to: `/drivingSchools/${drivingSchoolId}/courses` },
          ]}
          icon={<FleetIcon />}
        />

        {grants.includes("admin") && (
          <TopLevelSideBarLink
            to={`/drivingSchools/${drivingSchoolId}/admin`}
            isSelected={isAdmin}
            icon={<AdminPanelSettingsIcon />}
          >
            Admin
          </TopLevelSideBarLink>
        )}
      </Box>
    </div>
  );
}

export default BackofficeSideBar;

const TopLevelSideBarLink = (props: {
  sx?: SxProps<Theme>;
  to?: string;
  children: React.ReactNode;
  isHovered?: boolean;
  isSelected?: boolean;
  icon?: JSX.Element;
  onClick?: MouseEventHandler<HTMLElement>;
}) => {
  return (
    <Link
      style={{
        display: "flex",
        alignItems: "center",
        flex: "1 3 1",
        gap: 14,
        marginBottom: 12,
        color: props.isSelected ? white : grey,
      }}
      to={props.to ?? "#"}
      sx={
        [
          {
            "&": {
              p: "10px 30px",
              borderRadius: 8,
              fontWeight: 500, // <-- Poppins Medium
              fontSize: "14px",
              ...(props.isSelected
                ? { color: white, backgroundColor: green }
                : props.isHovered
                ? { color: white, backgroundColor: "rgba(255, 255, 255, 0.1)" }
                : { color: grey }),
              ...(props.sx ?? {}),
            },
          },
          {
            "&:hover": { textDecoration: "none" },
          },
          {
            "&:hover": props.isSelected ? {} : { backgroundColor: "#f5f5f5" },
          },
        ] as any
      }
      onClick={props.onClick}
    >
      {props?.icon || null}
      {props.children}
    </Link>
  );
};

const navMenuAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

const navMenuTransformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};

const TopLevelSideBarMenu = (props: {
  sx: SxProps<Theme>;
  label?: string;
  icon?: JSX.Element;
  menuItems?: any;
  isSelected?: boolean;
}) => {
  const [_, setSidebarOpen] = useSidebarState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl || props.isSelected;
  const toggleMenu: MouseEventHandler<HTMLElement> = (event) => {
    if (props.isSelected) {
      // Do nothing.
      return;
    }
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setSidebarOpen(true); // <-- Hack to prevent that tooltips are rendered in <MenuItemLink> elements
      setAnchorEl(event.currentTarget);
    }
  };
  const closeMenu = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <TopLevelSideBarLink
        sx={props.sx}
        isSelected={!!props?.isSelected}
        onClick={toggleMenu}
        isHovered={open}
        icon={props?.icon}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
          {`${props?.label || ""}\u00A0`}
          <ArrowLeftIcon
            sx={{
              transform: anchorEl ? "rotate(90deg)" : "rotate(270deg)",
              fontSize: 20,
              marginLeft: 0.5,
            }}
          />
        </Box>
      </TopLevelSideBarLink>
      {open ? (
        <Menu
          disableScrollLock
          anchorEl={anchorEl}
          anchorOrigin={navMenuAnchorOrigin}
          transformOrigin={navMenuTransformOrigin}
          open={open}
          onClose={closeMenu}
          style={{ marginTop: -10 }}
          sx={{ "& .MuiMenu-paper": { borderRadius: "4px" } }}
        >
          {(props.menuItems || []).map(({ name, to }: any) => (
            <MenuItemLink
              key={name}
              sx={{ borderRadius: 8 }}
              style={{
                color: grey,
                marginLeft: 54,
                fontSize: 14,
                fontWeight: 500,
                padding: 10,
                paddingLeft: 16,
              }}
              to={to}
              primaryText={name}
            />
          ))}
        </Menu>
      ) : null}
    </>
  );
};
