import { TableauView } from "./TableauView";
import { grants } from "../backoffice.access_control";
import { useEffect } from "react";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { AccessDenied } from "./AccessDenied";

export function BusinessIntelligencePage() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const view: "AUTOVIO" | "DrivingSchool" = drivingSchoolId ? "DrivingSchool" : "AUTOVIO";
  useEffect(() => {
    document.title = view === "AUTOVIO" ? "Business Intelligence" : "Reports";
  }, []);
  if (view === "AUTOVIO" && grants.includes("viewAutovioBusinessIntelligenceData")) {
    return <TableauView view="AUTOVIO-Ramp-Up-AllDashBoards/UtilizationDashboard-Overview" />;
  }
  if (view === "DrivingSchool" && grants.includes("viewDrivingSchoolBusinessIntelligenceData")) {
    return <TableauView view="AUTOVIO-PartnerDetails/Kundenverteilung" drivingSchoolUid={drivingSchoolId} />;
  }
  return <AccessDenied />;
}
