/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEventId } from '../models/CalendarEventId';
import type { ManualInvoice } from '../models/ManualInvoice';
import type { Order } from '../models/Order';
import type { OrderResponse } from '../models/OrderResponse';
import type { PaginatedOrderList } from '../models/PaginatedOrderList';
import type { PaymentSession } from '../models/PaymentSession';
import type { Prepaid } from '../models/Prepaid';
import type { PrepaidCheckout } from '../models/PrepaidCheckout';
import type { Quote } from '../models/Quote';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedOrderList
   * @throws ApiError
   */
  public orderList({
    limit,
    offset,
  }: {
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
  }): CancelablePromise<PaginatedOrderList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/order/',
      query: {
        'limit': limit,
        'offset': offset,
      },
    });
  }

  /**
   * @returns Order
   * @throws ApiError
   */
  public orderCreate({
    requestBody,
  }: {
    requestBody: Order,
  }): CancelablePromise<Order> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/order/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Order
   * @throws ApiError
   */
  public orderRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this order.
     */
    id: string,
  }): CancelablePromise<Order> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/order/{id}/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public orderAsfCourseCreate({
    requestBody,
  }: {
    requestBody: Quote,
  }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/order/asf-course/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public orderBaseFeeCreate({
    requestBody,
  }: {
    requestBody: Quote,
  }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/order/base-fee/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Order
   * @throws ApiError
   */
  public orderManualInvoiceCreate({
    requestBody,
  }: {
    requestBody: ManualInvoice,
  }): CancelablePromise<Order> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/order/manual-invoice/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public orderPaidLessonCreate({
    requestBody,
  }: {
    requestBody: CalendarEventId,
  }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/order/paid-lesson/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * This endpoint is used in the profile to top-up the prepaid credits
   * @returns PaymentSession
   * @throws ApiError
   */
  public orderPrepaidCreate({
    requestBody,
  }: {
    requestBody: Prepaid,
  }): CancelablePromise<PaymentSession> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/order/prepaid/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * This endpoint is used during the sign-up flow for an initial prepaid payment
   * @returns PaymentSession
   * @throws ApiError
   */
  public orderPrepaidCheckoutCreate({
    requestBody,
  }: {
    requestBody: PrepaidCheckout,
  }): CancelablePromise<PaymentSession> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/order/prepaid-checkout/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public orderTeachingMaterialCreate({
    requestBody,
  }: {
    requestBody: Quote,
  }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/order/teaching-material/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
