import { Typography } from "@mui/material";
import get from "lodash/get";
import { sanitizeFieldRestProps, TextFieldProps, useRecordContext } from "react-admin";
import { Money, MoneyCurrency } from "../misc/Money";
import { autovioColors } from "../misc/backofficeTheme";

export function MoneyField(props: TextFieldProps & { hide0?: boolean; redForNegativeValues?: boolean }) {
  const { className, source, hide0, redForNegativeValues, ...rest } = props;
  const record = useRecordContext(props);
  let value = get(record, source ?? "");
  const style = redForNegativeValues && value < 0 ? { color: autovioColors.red } : undefined;

  // If value is a `Money` object, extract the amount ...
  if (value && typeof value === "object" && value.currency === "EUR") {
    value = value.amount * 100;
  }
  if (typeof value !== "number" || !isFinite(value) || (value === 0 && hide0)) {
    return null;
  }

  return (
    <Typography
      component="span"
      variant={props.variant || "body2"}
      className={className}
      style={style}
      {...sanitizeFieldRestProps(rest)}
    >
      <Money cents={value} style={value < 0 ? { color: autovioColors.red } : undefined} />
    </Typography>
  );
}

export function MoneyCurrencyField(
  props: TextFieldProps & { redForNegativeValues?: boolean; greenForPositiveValues?: boolean; showPlus?: boolean },
) {
  const { source, redForNegativeValues, greenForPositiveValues, showPlus } = props;
  const record = useRecordContext(props);
  const value = get(record, source ?? "");
  if (!value || value === "") {
    return <></>;
  }
  return (
    <Typography component="span" variant={props.variant || "body2"}>
      <MoneyCurrency
        greenForPositiveValues={greenForPositiveValues}
        redForNegativeValues={redForNegativeValues}
        showPlus={showPlus}
        {...value}
      />
    </Typography>
  );
}
