import { AbstractProvider } from "./abstractProvider";
import { DateTime } from "luxon";
import { Notification, NotificationSchema } from "../model/Notification";
import { GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";

export type InAppNotification = { id: string; createdAt: DateTime } & Defined<
  Notification["channels"],
  "inApp"
>["inApp"];

class InAppNotificationsProvider extends AbstractProvider<InAppNotification> {
  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<InAppNotification>> {
    if (target !== "userUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "userUid"`);
    }
    const snapshot = await getDocs(
      query(collection(firestore, `/users/${id}/notifications`), where("channels.inApp.deleted", "==", false)),
    );
    const inAppNotifications = snapshot.docs.map((doc) => {
      const notification = NotificationSchema.parse(doc.data());
      const inAppNotification: InAppNotification = {
        id: doc.id,
        createdAt: notification.createdAt,
        ...notification.channels.inApp!,
      };
      return inAppNotification;
    });
    console.info(`Retrieved ${inAppNotifications.length} inApp notification(s) for student ${id}`);
    return applyPagination(applySort(applyFilter(inAppNotifications, filter), sort), pagination);
  }
}

export const inAppNotificationsProvider = new InAppNotificationsProvider();
