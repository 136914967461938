import { Typography } from "@mui/material";
import get from "lodash/get";
import { FieldProps, useRecordContext } from "react-admin";

export function YesNoField(props: { source: string } & FieldProps) {
  const { source } = props;
  const record = useRecordContext(props);
  if (!record) return null;
  const value = get(record, source);
  return (
    <Typography component="span" variant="body2">
      {value ? "ja" : "nein"}
    </Typography>
  );
}
