import * as React from "react";
import get from "lodash/get";
import { useRecordContext } from "ra-core";
import Avatar from "@mui/material/Avatar";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

type AvatarFieldProps = { label?: string | boolean; source: string; sx?: SxProps<Theme> };

export const AvatarField = (props: AvatarFieldProps) => {
  const { source, sx } = props;
  const record = useRecordContext();
  const avatarUrl = get(record, source);

  return <Avatar src={avatarUrl} sx={sx} />;
};
