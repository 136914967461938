import Download from "@mui/icons-material/Download";
import OpenInBrowser from "@mui/icons-material/OpenInBrowser.js";
import { cloneElement, ReactElement } from "react";
import { DatagridConfigurable, FunctionField, List, TextField, useRecordContext } from "react-admin";
import { grants } from "../../backoffice.access_control.js";
import { RecordListButton } from "../../buttons/RecordListButton.js";
import { DateField } from "../../fields/DateField";
import { MoneyField } from "../../fields/MoneyField";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import type { AutovioPayoutRecord } from "../../providers/autovioPayoutProvider.js";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { CopyToClipboardButton } from "../../buttons/CopyToClipboardButton.js";
import { Alert } from "@mui/material";
import { PageTitle } from "../../misc/PageTitle";
import { AccessDenied } from "../../misc/AccessDenied";
import { EmptyState } from "../../misc/EmptyState";

export function PayoutOverview({ className }: { className?: string }) {
  const storeKey = "AutovioPayoutOverview_v1";
  const drivingSchool = useRecordContext<DrivingSchool>();
  const columns: ReactElement[] = [
    ...(grants.includes("admin")
      ? [
          <FunctionField
            label="ID"
            source="id"
            render={(record: AutovioPayoutRecord) => <CopyToClipboardButton data={record.id} />}
          />,
        ]
      : []),
    <DateField label="Auszahlungsdatum" source="payoutDate" sortable={false} />,
    <MoneyField label={"Auszahlungs\u00ADhöhe"} source="calculatedPayoutAmount" sortable={false} />,
    <TextField label="Verwendungszweck" source="statementDescriptor" sortable={false} />,
    <FunctionField
      label="Abgerechnete Leistungen"
      render={(record: AutovioPayoutRecord) => record.items.length}
      sortable={false}
    />,
    <FunctionField
      label="Aktionen"
      render={(_: AutovioPayoutRecord) => {
        return (
          <>
            <RecordListButton source="pdf" title="Öffnen" startIcon={<OpenInBrowser />} />
            <RecordListButton source="pdfDownload" title="Download" startIcon={<Download />} sx={{ marginLeft: 1 }} />
          </>
        );
      }}
      sortable={false}
    />,
  ].map((it, index) => cloneElement(it, { key: index }));

  if (!grants.includes("viewPayoutInformation")) {
    return (
      <>
        <PageTitle>Auszahlungen</PageTitle>
        <AccessDenied />
      </>
    );
  }

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>Auszahlungen</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  const drivingSchoolId = drivingSchool.uid;

  return (
    <>
      <PageTitle>Auszahlungen</PageTitle>
      <Alert severity="info" style={{ margin: "20px 0" }}>
        Hier siehst du alle Auszahlungen, die wir an dich getätigt haben. Klicke auf "Öffnen" oder "Download", um die
        detaillierte Abrechnung zu sehen.
      </Alert>
      <List
        className={className}
        resource={"autovioPayouts"}
        storeKey={storeKey}
        title=" " // <-- prevent that the default list title is rendered
        filter={{ drivingSchoolId }}
        sort={{ field: "payoutDate", order: "DESC" }}
        exporter={false}
        empty={<EmptyState label="Noch keine Auszahlungen vorhanden." />}
      >
        <DatagridConfigurable preferenceKey={storeKey} bulkActionButtons={false}>
          {columns}
        </DatagridConfigurable>
      </List>
    </>
  );
}
