import { fetchProductTypeChoicesOnce, Product } from "../providers/catalogProvider";
import {
  AutocompleteArrayInput,
  CloneButton,
  Create,
  Datagrid,
  Edit,
  List,
  required,
  Resource,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { useEffect, useState } from "react";
import ProductIcon from "@mui/icons-material/Extension";
import { ShowButton } from "../buttons/ShowButton";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { PageTitle } from "../misc/PageTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";

const filters = [<TextInput label="Suche" source="q" alwaysOn resettable />];

function ProductList() {
  return (
    <List title={<BackofficeListTitle />} filters={filters} exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <TextField label="Beschreibung" source="description" />
        <ShowButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
}

function ProductCreate() {
  return (
    <Create title={<PageTitle>Neues Produkt</PageTitle>}>
      <ProductForm mode="create" />
    </Create>
  );
}

function ProductEdit() {
  return (
    <Edit mutationMode="pessimistic" title={<BackofficeRecordTitle />}>
      <ProductForm mode="edit" />
    </Edit>
  );
}

function ProductForm({ mode }: { mode: "create" | "edit" }) {
  const [productTypeChoices, setProductTypeChoices] = useState([] as Array<{ id: string; name: string }>);
  useEffect(() => {
    void fetchProductTypeChoicesOnce().then(setProductTypeChoices);
  }, []);
  return (
    <SimpleForm warnWhenUnsavedChanges>
      <SelectInput label="Typ" source="type" fullWidth choices={productTypeChoices} validate={required()} />
      <TextInput label="Name" source="name" autoFocus={mode === "create"} fullWidth validate={required()} />
      <TextInput label="Beschreibung" source="description" fullWidth />
      <AutocompleteArrayInput
        label="Erforderliche Ressourcen"
        source="requires"
        fullWidth
        defaultValue={["instructor"]}
        choices={[
          { id: "instructor", name: "Fahrlehrer" },
          { id: "motorcycle", name: "Motorrad" },
          { id: "car", name: "Auto" },
          { id: "manual_car", name: "Schaltwagen" },
          { id: "trailer", name: "Anhänger" },
          { id: "location", name: "Unterrichtsraum" },
          { id: "zoom_seat", name: "Zoom-Seat" },
        ]}
      />
    </SimpleForm>
  );
}

export const productsResource = (
  <Resource
    key="products"
    name="products"
    icon={ProductIcon}
    list={ProductList}
    create={ProductCreate}
    edit={ProductEdit}
    options={{ label: "Produkte", createTitle: "Neues Produkt" }}
    recordRepresentation={(product: Product) => product.name}
  />
);
