export function AccessDenied() {
  return (
    <div className="centered" style={{ height: "calc(100% - 100px)" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div className="error" style={{ fontWeight: "bold", fontSize: "2em" }}>
          Keine Berechtigung
        </div>
      </div>
    </div>
  );
}
