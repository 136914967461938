import getFileTypeInfos from "magic-bytes.js";

/**
 * Tries to determine the MIME type based on the first bytes of the given file.
 * If this was not possible, the returned Promise resolves to an empty string.
 */
export async function determineMimeType(file: File): Promise<string> {
  try {
    if (file.size >= 12) {
      const magicBytes = new Uint8Array(await file.slice(0, 12).arrayBuffer());
      const fileTypeInfos = getFileTypeInfos(magicBytes);
      if (fileTypeInfos.length === 1) {
        return fileTypeInfos[0].mime || "";
      }
    }
  } catch {
    // Ignored.
  }
  return "";
}
