import { useGetList } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { useRecoilValue } from "recoil";
import { persistentGlobalDrivingSchoolFilterState } from "../persistent_state";

export function useBalanceSum(resource: "candidatesForBlocking"): number | undefined;
export function useBalanceSum(
  resource: "candidatesForFirstDunningNotice",
  parkingLotResource: "candidatesForFirstDunningNoticeParkingLot",
): number | undefined;
export function useBalanceSum(
  resource: "candidatesForSecondDunningNotice",
  parkingLotResource: "candidatesForSecondDunningNoticeParkingLot",
): number | undefined;
export function useBalanceSum(
  resource: "candidatesForHandoverToPairFinance",
  parkingLotResource: "candidatesForHandoverToPairFinanceParkingLot",
): number | undefined;
export function useBalanceSum(resource: "studentsHandedOverToPairFinance"): number | undefined;
export function useBalanceSum(resource: string, parkingLotResource?: string): number | undefined {
  const drivingSchoolId = useRecoilValue(persistentGlobalDrivingSchoolFilterState);
  const {
    data: students,
    total,
    pageInfo,
  } = useGetList<Student>(resource, {
    filter: drivingSchoolId ? { drivingSchoolId } : {},
    pagination: { page: 1, perPage: 9999 },
  });
  let { data: parkedStudents } = useGetList<Student>(
    parkingLotResource ?? "",
    {
      filter: drivingSchoolId ? { drivingSchoolId } : {},
      pagination: { page: 1, perPage: 9999 },
    },
    {
      enabled: !!parkingLotResource,
    },
  );
  if (!parkingLotResource) {
    parkedStudents = [];
  }
  if (!students || (typeof total === "number" && total > 9999) || pageInfo?.hasNextPage || !parkedStudents) {
    return;
  }
  let sum = 0;
  for (const a of [students, parkedStudents]) {
    for (const student of a) {
      if (student.kpis.mostRecentTheoryLessonDate || student.kpis.mostRecentDrivingLessonDate) {
        sum -= student.balance;
      }
    }
  }
  return sum;
}
