import {
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  Edit,
  FunctionField,
  List,
  maxValue,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  Resource,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { DrivingSchoolInput } from "../inputs/DrivingSchoolInput";
import { PromoCode, promoCodesProvider } from "../providers/promoCodesProvider";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { useMemo } from "react";
import { MoneyInput } from "../inputs/MoneyInput";
import { ShareInput } from "../inputs/ShareInput";
import { DateTime } from "luxon";
import { DateField } from "../fields/DateField";
import { MoneyField } from "../fields/MoneyField";
import { UserDisplayName } from "../misc/UserDisplayName";
import { ShowIconButton } from "../buttons/ShowIconButton";
import { CloneIconButton } from "../buttons/CloneIconButton";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { ReferenceDrivingSchoolField } from "../fields/ReferenceDrivingSchoolField";
import { PageTitle } from "../misc/PageTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";

function PromoCodeList() {
  const filters = useMemo(
    () => [
      // TODO: <TextInput label="Suche" source="q" alwaysOn resettable />, <-- not supported by API endpoint yet
      ...(restrictAccessToDrivingSchoolIds?.length === 1
        ? []
        : [
            <ReferenceInput
              source="drivingSchoolId"
              alwaysOn
              reference="drivingSchools"
              page={1}
              perPage={999}
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput label="Fahrschule" />
            </ReferenceInput>,
          ]),
    ],
    [],
  );

  return (
    <List title={<BackofficeListTitle />} filters={filters} exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Code" source="code" sx={{ whiteSpace: "nowrap" }} />
        {restrictAccessToDrivingSchoolIds?.length !== 1 && (
          <ReferenceDrivingSchoolField label="Fahrschule" source="drivingSchoolId" link />
        )}
        <FunctionField
          label="Status"
          render={(promoCode?: PromoCode) => {
            if (!promoCode) {
              return "";
            } else if (!promoCode.isEnabled) {
              return "deaktiviert";
            } else if (promoCode.numUsed >= promoCode.maxUses) {
              return "alle benutzt";
            } else if (DateTime.now() < promoCode.start) {
              return "startet später";
            } else if (DateTime.now() > promoCode.end) {
              return "abgelaufen";
            } else {
              return "aktiv";
            }
          }}
          sortable={false}
        />
        <MoneyField label="Wert" source="startCredits" />
        <DateField label="Startdatum" source="startDate" />
        <DateField label="Enddatum" source="endDate" />
        <FunctionField
          label="Anzahl"
          render={(promoCode?: PromoCode) => {
            if (!promoCode) {
              return "";
            }
            return `${promoCode.numUsed} / ${promoCode.maxUses}`;
          }}
        />
        <FunctionField
          label="Anteil AUTOVIO"
          render={(promoCode?: PromoCode) => {
            if (!promoCode) {
              return "";
            }
            return `${Math.round(promoCode.autovioShare * 100)}%`;
          }}
        />
        <FunctionField
          label="Erstellt von"
          render={(promoCode?: PromoCode) => {
            if (!promoCode) {
              return "";
            }
            return <UserDisplayName userId={promoCode.createdById} />;
          }}
        />
        <DateField label="Erstellt am" source="createdAt" showDate showTime />
        <ShowIconButton />
        <CloneIconButton />
      </Datagrid>
    </List>
  );
}

function PromoCodeCreate() {
  return (
    <Create title={<PageTitle>Neuer Promo-Code</PageTitle>}>
      <PromoCodeForm mode="create" />
    </Create>
  );
}

function PromoCodeEdit() {
  return (
    <Edit mutationMode="pessimistic" title={<BackofficeRecordTitle />}>
      <PromoCodeForm mode="edit" />
    </Edit>
  );
}

function PromoCodeForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <SimpleForm
      warnWhenUnsavedChanges
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <TextInput
        label="Code"
        source="code"
        autoFocus={mode === "create"}
        validate={[required(), noSimilarPromoCodeExists(mode)]}
      />
      <TextInput label="Beschreibung" source="description" fullWidth />
      <DrivingSchoolInput label="Fahrschule" source="drivingSchoolId" />
      <BooleanInput label="Aktiviert" source="isEnabled" defaultValue={true} />
      <MoneyInput label="Wert" source="startCredits" validate={[required(), minValue(0), maxValue(2000)]} />
      <DateInput label="Startdatum" source="startDate" validate={required()} />
      <DateInput label="Enddatum" source="endDate" validate={required()} />
      <ShareInput label="Share" source="autovioShare" left="Fahrschule" right="AUTOVIO" defaultValue={1} />
      <NumberInput label="Anzahl" source="maxUses" validate={[required(), minValue(1)]} />
    </SimpleForm>
  );
}

function normalizePromoCode(code: string): string {
  return code.toLowerCase().replace(/[^a-z0-9]/g, "");
}

function noSimilarPromoCodeExists(mode: "create" | "edit") {
  return async (value: string, values: FieldValues) => {
    const { drivingSchoolId } = values;
    if (!value) {
      return undefined;
    }
    let { data: conflicting } = await promoCodesProvider.getList("promoCodes", {
      filter: {
        normalizedCode: normalizePromoCode(value),
        ...(drivingSchoolId && { drivingSchoolId }),
      },
      pagination: { page: 1, perPage: mode === "create" ? 1 : 2 },
      sort: { field: "id", order: "ASC" },
    });
    if (mode === "edit") {
      // Ignore the Promo-Code which is currently edited ...
      conflicting = conflicting.filter((promoCode: PromoCode) => promoCode.id !== values.id);
    }
    if (conflicting.length > 0) {
      if (value === conflicting[0].code) {
        if (drivingSchoolId) {
          return "Dieser Code wurde bereists für die ausgewählte Fahrschule verwendet.";
        } else {
          return "Dieser Code wurde bereists verwendet.";
        }
      } else {
        return `Dieser Code kann nicht verwendet werden, da er dem bereits verwendeten Code "${conflicting[0].code}" zu sehr ähnelt.`;
      }
    }
    return undefined;
  };
}

export const promoCodesResource = (
  <Resource
    key="promoCodes"
    name="promoCodes"
    list={PromoCodeList}
    create={PromoCodeCreate}
    edit={PromoCodeEdit}
    options={{ label: "Promo-Codes" }}
    recordRepresentation={(promoCode: PromoCode) => `Promo-Code: ${promoCode.code}`}
  />
);
