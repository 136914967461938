export function NachtIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.14 3.361c.273-.033.48.24.353.48-.66 1.274-1.38 3.594-.047 6.18 1.333 2.594 3.86 3.527 5.52 3.614.273.02.42.333.247.546a6.666 6.666 0 0 1-7.247 2.127c.833-.453 1.413-1.327 1.413-2.34 0-1.22-.82-2.247-1.933-2.567a3.317 3.317 0 0 0-4.067-1.153 6.662 6.662 0 0 1 5.76-6.887zm-4.427 7.94c.866 0 1.6.56 1.88 1.334h.12c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.333 1.333h-2c-1.107 0-2-.893-2-2 0-1.106.893-2 2-2z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}
