import { useEffect, useState } from "react";
import { SelectArrayInput, SelectArrayInputProps } from "react-admin";
import { fetchDrivingLicenseClassChoicesOnce } from "../providers/catalogProvider";

export function SelectDrivingLicenseClassesInput(props: Omit<SelectArrayInputProps, "choices">) {
  const [drivingLicenseClassChoices, setDrivingLicenseClassChoices] = useState(
    [] as Array<{ id: string; name: string }>,
  );
  useEffect(() => {
    void fetchDrivingLicenseClassChoicesOnce().then(setDrivingLicenseClassChoices);
  }, []);
  return <SelectArrayInput className="fix-SelectArrayInput-label" {...props} choices={drivingLicenseClassChoices} />;
}
