import AddIcon from "@mui/icons-material/Add";
import GradingIcon from "@mui/icons-material/Grading";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import { Control, FormProvider, useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import * as backendApi from "../api/backend.api";
import { reportError } from "../backoffice.utils";
import { InvoiceTypeEnum, ManualInvoice, ManualInvoiceProductTypeEnum } from "../generated/backendClient";
import { useDialog } from "../hooks/useDialog";
import { DialogProps } from "../misc/DialogProps";
import { Student } from "../providers/studentsProvider";
import { DialogCloseButton } from "../misc/DialogCloseButton";

const DEFAULT_VAT_PERCENTAGE = 19;

type AvailableInvoiceTypes = Extract<
  InvoiceTypeEnum,
  "VR_EQUIPMENT_FEE" | "BASE_FEE_ADDITION" | "AUTHORITY_FEE" | "MISCELLANEOUS"
>;

type AvailableProductTypes = Extract<
  ManualInvoiceProductTypeEnum,
  | "VR_EQUIPMENT_FEE"
  | "UPGRADE_TRAINING_PACKAGE"
  | "COACHING"
  | "ASF_COURSE_FEE"
  | "FES_COURSE_FEE"
  | "AUTHORITY_FEE_MANUAL"
  | "DRIVING_LESSON_CHARGE_LATER"
  | "REGISTRATION_FEES"
  | "CHANGE_FEES"
  | "TUEV_FEE_MANUAL"
  | "DEKRA_FEE_MANUAL"
  | "THEORY_TEST_MANUAL"
  | "PRACTICAL_TEST_MANUAL"
>;

/**
 * These descriptions match the once in CalculationPositionType.get_description -- see backend/apps/invoice/enums.py
 */
const productTypeToDescriptionMap: { [key in AvailableProductTypes]: string } = {
  VR_EQUIPMENT_FEE: "VR Equipment",
  UPGRADE_TRAINING_PACKAGE: "Upgrade Ausbildungspaket",
  COACHING: "Coaching",
  ASF_COURSE_FEE: "Kurs Gebühr (ASF)",
  FES_COURSE_FEE: "Kurs Gebühr (FES)",
  AUTHORITY_FEE_MANUAL: "Prüfungsgebühr (TÜV/DEKRA)",
  DRIVING_LESSON_CHARGE_LATER: "Nachberechnung Fahrstunde",
  REGISTRATION_FEES: "Anmeldegebühren",
  CHANGE_FEES: "Wechselgebühren",
  TUEV_FEE_MANUAL: "TÜV-Gebühr",
  DEKRA_FEE_MANUAL: "DEKRA-Gebühr",
  THEORY_TEST_MANUAL: "Vorstellung zur theoretischen Prüfung",
  PRACTICAL_TEST_MANUAL: "Vorstellung zur praktischen Prüfung",
};

const productToInvoiceTypeMap: { [key in AvailableProductTypes]: AvailableInvoiceTypes } = {
  VR_EQUIPMENT_FEE: "VR_EQUIPMENT_FEE",
  UPGRADE_TRAINING_PACKAGE: "BASE_FEE_ADDITION",
  COACHING: "BASE_FEE_ADDITION",
  ASF_COURSE_FEE: "BASE_FEE_ADDITION",
  FES_COURSE_FEE: "BASE_FEE_ADDITION",
  AUTHORITY_FEE_MANUAL: "AUTHORITY_FEE",
  DRIVING_LESSON_CHARGE_LATER: "MISCELLANEOUS",
  REGISTRATION_FEES: "AUTHORITY_FEE",
  CHANGE_FEES: "BASE_FEE_ADDITION",
  TUEV_FEE_MANUAL: "AUTHORITY_FEE",
  DEKRA_FEE_MANUAL: "AUTHORITY_FEE",
  THEORY_TEST_MANUAL: "MISCELLANEOUS",
  PRACTICAL_TEST_MANUAL: "MISCELLANEOUS",
};

const productTypeToDefaultVatPercentageMap: { [key in AvailableProductTypes]: number } = {
  VR_EQUIPMENT_FEE: 19,
  UPGRADE_TRAINING_PACKAGE: 19,
  COACHING: 19,
  ASF_COURSE_FEE: 19,
  FES_COURSE_FEE: 19,
  DRIVING_LESSON_CHARGE_LATER: 19,
  AUTHORITY_FEE_MANUAL: 0,
  REGISTRATION_FEES: 0,
  CHANGE_FEES: 19,
  TUEV_FEE_MANUAL: 0,
  DEKRA_FEE_MANUAL: 0,
  THEORY_TEST_MANUAL: 19,
  PRACTICAL_TEST_MANUAL: 19,
};

type FormValues = {
  invoiceType?: AvailableInvoiceTypes;
  products: {
    type: AvailableProductTypes | "";
    price: number;
    vatPercentage?: number;
  }[];
  price: number;
};

export const CreateManualInvoiceButton = () => {
  const { dialogOpen, openDialog, closeDialog } = useDialog();

  return (
    <>
      <Button startIcon={<AddIcon />} variant="outlined" onClick={openDialog}>
        Manuelle Rechnung
      </Button>
      <CreateManualInvoiceDialogs open={dialogOpen} onClose={closeDialog} openDialog={openDialog} />
    </>
  );
};

type CreateManualInvoiceDialogProps = {
  openDialog: () => void;
} & DialogProps;

// Shows the InvoiceFormDialog and a ConfirmDialog afterwards
const CreateManualInvoiceDialogs = ({ open, onClose, openDialog }: CreateManualInvoiceDialogProps) => {
  const { dialogOpen: confirmDialogOpen, openDialog: openConfirmDialog, closeDialog: hideConfirmDialog } = useDialog();
  const form = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      invoiceType: undefined,
      products: [{ type: "", price: 0, vatPercentage: DEFAULT_VAT_PERCENTAGE }],
    },
  });

  return (
    <FormProvider {...form}>
      <InvoiceFormDialog open={open} onClose={onClose} onConfirm={openConfirmDialog} />
      <InvoiceConfirmDialog
        open={confirmDialogOpen}
        onClose={() => {
          form.reset();
          hideConfirmDialog();
        }}
        onBack={() => {
          hideConfirmDialog();
          openDialog();
        }}
      />
    </FormProvider>
  );
};

type InvoiceFormDialogProps = {
  onConfirm: () => void;
} & DialogProps;

const InvoiceFormDialog = ({ open, onClose, onConfirm }: InvoiceFormDialogProps) => {
  const resetAndClose = () => {
    reset();
    onClose();
  };
  const { trigger, reset } = useFormContext<FormValues>();
  return (
    <Dialog open={open} onClose={resetAndClose} maxWidth="md" fullWidth>
      <DialogTitle>Rechnung erstellen und bezahlen</DialogTitle>
      <DialogCloseButton onClose={resetAndClose} />
      <DialogContent>
        <InvoiceFormTable />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={async () => {
            const valid = await trigger();
            if (!valid) return;
            onClose();
            onConfirm();
          }}
        >
          Weiter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PRODUCT_TYPE_CHOICES = Object.entries(productTypeToDescriptionMap).sort((a, b) => a[1].localeCompare(b[1]));

const InvoiceFormTable = () => {
  const { register, control, formState, getValues, setValue } = useFormContext<FormValues>();
  const { fields } = useFieldArray<FormValues>({ name: "products", control });

  return (
    <TableContainer>
      <Table width="100%">
        <TableHead>
          <TableRow>
            <TableCell>Position</TableCell>
            <TableCell align="right">Datum</TableCell>
            <TableCell align="right">Beschreibung*</TableCell>
            <TableCell align="right" sx={{ minWidth: "100px" }}>
              Anzahl
            </TableCell>
            <TableCell align="right" sx={{ minWidth: "100px" }}>
              MwSt
            </TableCell>
            <TableCell align="right">Brutto*</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((product, index) => {
            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right">
                  {DateTime.now().toLocaleString(DateTime.DATE_SHORT, { locale: "de" })}
                </TableCell>
                <TableCell align="right">
                  <FormControl sx={{ minWidth: 250 }}>
                    <Select<typeof product.type>
                      {...register(`products.${index}.type`, { required: true })}
                      defaultValue={product.type}
                      value={getValues(`products.${index}.type`)}
                      onChange={(e) => {
                        const productType = e.target.value as AvailableProductTypes;
                        const vatPercentage =
                          productTypeToDefaultVatPercentageMap[productType] ?? DEFAULT_VAT_PERCENTAGE;
                        setValue(`products.${index}.vatPercentage`, vatPercentage);
                        setValue(`products.${index}.type`, productType);
                      }}
                      id="invoice-type"
                      variant="standard"
                      displayEmpty
                      error={!!formState.errors.products?.[index]?.type}
                      renderValue={(value) => {
                        if (!value)
                          return (
                            <Typography
                              component="span"
                              color={formState.errors.products?.[index]?.type ? "red" : "black"}
                            >
                              Bitte auswählen
                            </Typography>
                          );
                        return productTypeToDescriptionMap[value];
                      }}
                    >
                      {PRODUCT_TYPE_CHOICES.map(([key, description]: [string, string]) => (
                        <MenuItem key={key} value={key}>
                          {description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="right">1</TableCell>
                <TableCell align="right">
                  <FormControl variant="standard">
                    <TextField
                      {...register(`products.${index}.vatPercentage`, { valueAsNumber: true })}
                      id="invoice-vat-percentage"
                      defaultValue={DEFAULT_VAT_PERCENTAGE}
                      variant="standard"
                      sx={{ "& input": { textAlign: "right" } }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell align="right" width="150px">
                  <FormControl variant="standard">
                    <TextField
                      {...register(`products.${index}.price`, {
                        valueAsNumber: true,
                        required: true,
                        validate: (value) => value > 0 || "Muss größer als 0 sein",
                      })}
                      id="invoice-gross-amount"
                      placeholder="inkl. MwSt"
                      variant="standard"
                      error={!!formState.errors.products?.[index]?.price}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                      }}
                      sx={{
                        "& input": {
                          textAlign: "right",
                          color: formState.errors.products?.[index]?.price ? "red" : "black",
                        },
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow sx={{ "& td": { borderBottom: "none" } }}>
            <TableCell colSpan={3} />
            <TableCell sx={{ pb: "5px" }} align="right">
              Netto
            </TableCell>
            <TableCell sx={{ pb: "5px" }} align="right">
              MwSt
            </TableCell>
            <TableCell sx={{ pb: "5px" }} align="right">
              Brutto
            </TableCell>
          </TableRow>
          <Total control={control} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Total = ({ control }: { control: Control<FormValues> }) => {
  const formValues = useWatch({
    name: "products",
    control,
  });
  const total = formValues.reduce((acc, current) => acc + (current.price || 0), 0);
  const vat = formValues.reduce((acc, current) => acc + (current.price * (current.vatPercentage || 0)) / 100, 0) || 0;

  return (
    <TableRow sx={{ "& td": { borderBottom: "none" } }}>
      <TableCell size="small" align="right" colSpan={3}>
        Summe
      </TableCell>
      <TableCell size="small" align="right" sx={{ fontWeight: "bold" }}>
        {formatEuro(total - vat)}
      </TableCell>
      <TableCell size="small" align="right" sx={{ fontWeight: "bold" }}>
        {formatEuro(vat)}
      </TableCell>
      <TableCell size="small" align="right" sx={{ fontWeight: "bold" }}>
        {formatEuro(total)}
      </TableCell>
    </TableRow>
  );
};

type InvoiceConfirmDialogProps = {
  onBack: () => void;
} & DialogProps;

const InvoiceConfirmDialog = ({ open, onClose, onBack }: InvoiceConfirmDialogProps) => {
  const student = useRecordContext<Student>();
  const form = useFormContext<FormValues>();
  const refresh = useRefresh();
  const notify = useNotify();

  const total = form.watch("products").reduce((acc, current) => acc + (current.price || 0), 0);

  const { mutate: createManualInvoice, isLoading } = useMutation("b2cInvoices", backendApi.createManualInvoice, {
    onSuccess: () => {
      onClose();
      refresh();
      return notify(`Rechnung wird erstellt. Dies kann ein wenig dauern.`, { type: "success" });
    },
    onError: (error) => {
      reportError(`Failed to create invoice for student ${student.name} (${student.id})`, error);
      notify(`Rechnung konnte nicht erstellt werden.`, { type: "error" });
    },
  });

  const onSubmit = async (values: FormValues) => {
    try {
      // For now, we only support one product per invoice
      // In the future, the invoice type would be inferred from product types or set via form by the user
      if (values.products.some((product) => !product.type)) {
        throw new Error(`Product type is required, but products given as: ${JSON.stringify(values.products)}`);
      }
      const invoiceType =
        values.invoiceType ?? productToInvoiceTypeMap[values.products[0].type as AvailableProductTypes];
      const invoice: ManualInvoice = {
        user: { id: student.autovioUserId },
        driving_school_id: student.drivingSchoolId,
        invoice_type: invoiceType,
        products: values.products.map((product) => ({
          description: productTypeToDescriptionMap[product.type as AvailableProductTypes],
          gross_per_unit: { amount: product.price.toString(), currency: "EUR" },
          quantity: 1,
          type: product.type as AvailableProductTypes,
          vat_percentage: product.vatPercentage?.toString() ?? DEFAULT_VAT_PERCENTAGE.toString(),
        })),
      };
      return createManualInvoice(invoice);
    } catch (error) {
      reportError(`Failed to create invoice for student ${student.name} (${student.id})`, error);
      notify(`Rechnung konnte nicht erstellt werden.`, { type: "error" });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Rechnung erstellen und bezahlen</DialogTitle>
      <DialogCloseButton onClose={onClose} disabled={isLoading} />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing="1em">
            <DialogContentText>
              Rechnung mit Summe <b>{formatEuro(total)}</b> erstellen und bezahlen?
            </DialogContentText>
            <DialogContentText>
              Es kann ein wenig dauern, bis die neue Rechnung in der Liste zu sehen ist.
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onBack} disabled={isLoading}>
            Zurück
          </Button>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<GradingIcon />}
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
          >
            Erstellen und bezahlen
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

function formatEuro(value: number) {
  return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(value);
}
