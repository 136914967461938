import EyeIcon from "@mui/icons-material/RemoveRedEye";
import { EditButton } from "react-admin";

// We don't use the ShowButton provided by react-admin, because we don't use
// the show view anywhere (we don't like that "/show" is appended to the URL),
// but use the edit view everywhere to show the details of an entity.
export function ShowButton({ label }: { label?: string }) {
  return (
    <EditButton
      icon={<EyeIcon sx={{ ml: 1 }} />}
      label={label === "" ? "" : ((<span style={{ paddingRight: "8px" }}>{label ?? "Anzeigen"}</span>) as any)}
      sx={{ minWidth: "32px", ...(label ? { pr: 1 } : {}) }}
    />
  );
}
