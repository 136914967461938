import { AutovioMap, DrivingSchoolMap } from "./maps";
import { RecordContextProvider, useShowController } from "react-admin";
import { Card } from "@mui/material";
import { ReactElement } from "react";
import { PageTitle } from "./PageTitle";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";

export function Dashboard(): ReactElement | null {
  const [{ drivingSchoolId }] = useAutovioContext();
  const { record: drivingSchool } = useShowController({
    resource: "drivingSchools",
    id: drivingSchoolId,
    queryOptions: { enabled: !!drivingSchoolId },
  });

  if (!drivingSchoolId) {
    if (restrictAccessToDrivingSchoolIds) {
      return (
        <div className="centered">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            Bitte wähle eine Fahrschule aus.
          </div>
        </div>
      );
    }

    return (
      <>
        <PageTitle>AUTOVIO Fahrschulen</PageTitle>
        <Card sx={{ mt: "10px", height: "calc(100% - 66px)" }}>
          <AutovioMap />
        </Card>
      </>
    );
  }

  if (!drivingSchool) {
    return null;
  }

  return (
    <RecordContextProvider value={drivingSchool}>
      <PageTitle>Verteilung Fahrschüler</PageTitle>
      <Card>
        <div style={{ padding: "20px" }}>
          <DrivingSchoolMap />
        </div>
      </Card>
    </RecordContextProvider>
  );
}
