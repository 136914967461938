export function toSnakeCase(str: string): string {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
}

export function capitalize(s: string): string {
  s = s.trim();
  if (s) {
    s = s.charAt(0).toUpperCase() + s.substring(1);
  }
  return s;
}

export function uncapitalize(s: string): string {
  s = s.trim();
  if (s) {
    s = s.charAt(0).toLowerCase() + s.substring(1);
  }
  return s;
}
