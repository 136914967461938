import * as React from "react";
import { Children, cloneElement, isValidElement, ReactElement, ReactNode, useState } from "react";
import { SxProps } from "@mui/system";
import { styled } from "@mui/material/styles";
import { Box, Card, Tabs, TabsProps } from "@mui/material";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import { OptionalRecordContextProvider, RaRecord, Tab, TabProps, useRecordContext } from "react-admin";
import { Column } from "./Column";
import { Row } from "./Row";
import { MenuTab, MenuTabElement } from "./MenuTab";
import { MenuTabItemElement } from "./MenuTabItem";
import { fullWidthTabsStyle } from "./backofficeTheme";

type TabElement = ReactElement<TabProps, typeof Tab>;

export interface _NewDesignTabbedShowLayoutProps {
  /** Displayed above tabs. */
  header?: ReactNode;
  /** Displayed on the right side of the tab headers. */
  additionalButtons?: ReactNode;
  /** Displayed on the right side of the tab content. */
  rightColumn?: ReactNode;
  className?: string;
  record?: RaRecord;
  rootPath?: string;
  sx?: SxProps;
  value?: any;
  separateCards?: boolean;
}

export type NewDesignTabbedShowLayoutProps =
  | (_NewDesignTabbedShowLayoutProps & {
      syncWithLocation: true;
      tabs: Array<TabElement | MenuTabElement | false | undefined | null>;
    })
  | (_NewDesignTabbedShowLayoutProps & {
      syncWithLocation?: false;
      tabs: Array<TabElement | false | undefined | null>;
    });

/**
 * Copied and adapted from react-admin `TabbedShowLayout` component.
 */
export const NewDesignTabbedShowLayout = (props: NewDesignTabbedShowLayoutProps & { fullWidth?: boolean }) => {
  const {
    sx,
    header,
    additionalButtons,
    tabs: tabs_,
    rightColumn,
    className,
    syncWithLocation = true,
    value,
    separateCards,
    fullWidth,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const tabs = (Children.toArray(tabs_).filter(isValidElement) as Array<TabElement | MenuTabElement>).map((tab) => {
    if (isTabElement(tab)) {
      if (syncWithLocation) {
        return cloneElement(tab, {
          replace: true, // <-- this will be passed to the rendered Link component
          ...(fullWidth ? { sx: { flex: 1 } } : {}),
        } as any) as TabElement;
      } else {
        return cloneElement(tab, {
          syncWithLocation: false,
          ...(fullWidth ? { sx: { flex: 1 } } : {}),
        }) as TabElement;
      }
    } else {
      return tab;
    }
  });
  const [tabValue, setTabValue] = useState("");

  const handleTabChange = (_event: React.SyntheticEvent, value: any): void => {
    if (!syncWithLocation) {
      setTabValue(value);
    }
  };

  if (!record) {
    return null;
  }

  const tabsAndButtons = (
    <Row justifyContent="space-between">
      <TabbedShowLayoutTabs
        onChange={handleTabChange}
        syncWithLocation={syncWithLocation}
        value={tabValue}
        sx={{
          ...(fullWidth ? fullWidthTabsStyle : {}),
          "& .MuiTabs-indicator": { display: "none" },
        }}
      >
        {tabs}
      </TabbedShowLayoutTabs>
      {additionalButtons}
    </Row>
  );

  let body = separateCards ? (
    syncWithLocation ? (
      <Card sx={{ background: "transparent" }}>
        <div className={TabbedShowLayoutClasses.content}>
          <Outlet />
        </div>
      </Card>
    ) : (
      <Card>
        <div className={TabbedShowLayoutClasses.content}>
          {tabs.map((tab, i) => (tabValue || 0) === i && cloneElement(tab as TabElement, { context: "content" }))}
        </div>
      </Card>
    )
  ) : syncWithLocation ? (
    <Card sx={sx}>
      {header || <Box height="20px" />}
      {tabsAndButtons}
      <div className={TabbedShowLayoutClasses.content}>
        <Outlet />
      </div>
    </Card>
  ) : (
    <Card sx={sx}>
      {header}
      {tabsAndButtons}
      <div className={TabbedShowLayoutClasses.content}>
        {tabs.map((tab, i) => (tabValue || 0) === i && cloneElement(tab as TabElement, { context: "content" }))}
      </div>
    </Card>
  );

  if (separateCards) {
    const headerCard = (
      <Card sx={sx}>
        {header || <Box height="20px" />}
        {tabsAndButtons}
      </Card>
    );
    body = (
      <Column>
        {headerCard}
        <Box height="20px" />
        {rightColumn && (
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "70% calc(30% - 15px)",
              gridColumnGap: "15px",
            }}
          >
            <div>{body}</div>
            <div>{rightColumn}</div>
          </div>
        )}
        {!rightColumn && body}
      </Column>
    );
  }

  const tabElements = tabs.filter(isTabElement);
  const menuTabElements = tabs.filter(isMenuTabElement);

  return (
    <OptionalRecordContextProvider value={props.record}>
      <Root className={className} {...sanitizeRestProps(rest)}>
        {syncWithLocation ? (
          <Routes>
            <Route path="/*" element={body}>
              {tabElements.map((tab, index) => (
                <Route
                  key={`Tab ${index}`}
                  path={tab.props.path ?? ""}
                  element={cloneElement(tab, { context: "content" })}
                />
              ))}
              {menuTabElements
                .map((menuTab) =>
                  menuTab.props.children
                    .filter(isMenuTabItemElement)
                    .map((menuTabItem) => (
                      <Route
                        key={menuTabItem.props.path}
                        path={menuTabItem.props.path}
                        element={cloneElement(menuTabItem, { context: "content" })}
                      />
                    )),
                )
                .flat()}
            </Route>
          </Routes>
        ) : (
          body
        )}
      </Root>
    </OptionalRecordContextProvider>
  );
};

const PREFIX = "RaTabbedShowLayout";

export const TabbedShowLayoutClasses = {
  content: `${PREFIX}-content`,
};

const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  flex: 1,
  [`& .${TabbedShowLayoutClasses.content}`]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}));

const sanitizeRestProps = ({ record, resource, initialValues, staticContext, translate, tabs, ...rest }: any) => rest;

function isTabElement(tab: TabElement | MenuTabElement): tab is TabElement {
  return tab.type === Tab;
}

function isMenuTabElement(tab: TabElement | MenuTabElement): tab is MenuTabElement {
  return tab.type === MenuTab;
}

function isMenuTabItemElement(x: MenuTabItemElement | false): x is MenuTabItemElement {
  return !!x;
}

interface TabbedShowLayoutTabsProps extends TabsProps {
  children: Array<TabElement | MenuTabElement>;
  syncWithLocation?: boolean;
}

function TabbedShowLayoutTabs({ children, syncWithLocation, value, ...rest }: TabbedShowLayoutTabsProps) {
  const params = useParams();
  if (syncWithLocation) {
    const path = params["*"];
    value = params["*"]; // <-- this is only correct for <Tab> elements
    // Use the index as value if path points to a <MenuTabItem> element ...
    Children.forEach(children, (child, index) => {
      if (child && isValidElement(child) && isMenuTabElement(child)) {
        for (const menuTabItem of child.props.children.filter(isMenuTabItemElement)) {
          if (menuTabItem.props.path === path) {
            value = index;
            return;
          }
        }
      }
    });
  }

  return (
    <Tabs indicatorColor="primary" value={value} {...rest}>
      {Children.map(children, (tab, index) => {
        if (!tab || !isValidElement(tab)) return null;
        const value = syncWithLocation ? (isTabElement(tab) ? tab.props.path ?? "" : index) : index > 0 ? index : "";
        if (isTabElement(tab)) {
          return cloneElement(tab, {
            context: "header",
            value,
            syncWithLocation,
          });
        } else {
          return cloneElement(tab, { value });
        }
      })}
    </Tabs>
  );
}
