import { DateInput, maxValue, minValue } from "react-admin";

export function ExaminationAssignmentExpiresAtInput() {
  return (
    <DateInput
      sx={{ width: "15em" }}
      label="Prüfauftrag gültig bis"
      source="examinationAssignmentExpiresAt"
      validate={[minValue("2020-01-01"), maxValue("2099-12-31")]}
    />
  );
}
