export function SchalterIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.667 4.167H3.333v-2.5h3.334v2.5M3.333 18.333h3.334v-2.5H3.333v2.5m8.334-16.666H8.333v2.5h3.334v-2.5M8.333 18.333h3.334v-2.5H8.333v2.5m5-16.666v2.5h3.334v-2.5h-3.334m.834 7.5h-3.334V5.833H9.167v3.334H5.833V5.833H4.167v8.334h1.666v-3.334h3.334v3.334h1.666v-3.334h5v-5h-1.666v3.334z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}
