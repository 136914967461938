import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { diff } from "deep-object-diff";
import { useMemo, useState } from "react";
import { grants } from "../../backoffice.access_control";
import { AutovioCalendarEvent, isPaidLesson, isTheoryExam, isTheoryLesson } from "../../model/autovioCalendarEvents";
import { AutovioCalendarEventHistory } from "../../providers/calendarEventHistoryProvider";
import { calendarEventTitle, formatDateTime } from "../../utils/calendar";
import { EventIdBar } from "../IdBar";
import { EventInfoCard } from "./EventInfoCard";
import { autovioColors } from "../backofficeTheme";
import { DateTime } from "luxon";
import { UserDisplayName } from "../UserDisplayName";
import { useGetOne } from "react-admin";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { CancelAppointmentDialog } from "./CancelAppointmentDialog";
import { useDialog } from "../../hooks/useDialog";
import { DeleteInvitationDialog } from "./DeleteInvitationDialog";
import { DialogCloseButton } from "../DialogCloseButton";

export const useEventDialog = () => {
  return {
    canBeOpenedInDialog: (event?: AutovioCalendarEvent): boolean => {
      switch (event?.type) {
        case "ASFCourseSession":
        case "DrivingLesson":
        case "TheoryLesson":
        case "TheoryExam":
        case "Other":
          return true;
        default:
          return false;
      }
    },
  };
};

type EventDialogProps = {
  event: AutovioCalendarEvent;
  isOpen: boolean;
  onClose: () => void;
};

export const EventDialog = ({ event, isOpen, onClose }: EventDialogProps) => {
  const { dialogOpen: isDeleteDialogOpen, openDialog: openDeleteDialog, closeDialog: closeDeleteDialog } = useDialog();
  const { dialogOpen: isCancelDialogOpen, openDialog: openCancelDialog, closeDialog: closeCancelDialog } = useDialog();
  const showDeleteButton = (isTheoryExam(event) || isPaidLesson(event)) && event.student.rsvp === "pending";
  const showCancelButton =
    isTheoryLesson(event) || ((isTheoryExam(event) || isPaidLesson(event)) && event.student.rsvp === "accepted");

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          <EventDialogHeader event={event} />
        </DialogTitle>
        <DialogCloseButton onClose={onClose} />
        <DialogContent sx={{ p: 0 }}>
          <EventDialogBody event={event} />
        </DialogContent>
        <DialogActions>
          {showDeleteButton && (
            <Button
              variant="outlined"
              sx={{ height: "40px" /* ... same height as other buttons in dialog. */ }}
              startIcon={<DeleteIcon />}
              onClick={openDeleteDialog}
            >
              Einladung löschen
            </Button>
          )}
          {showCancelButton && (
            <Button
              variant="outlined"
              sx={{ height: "40px" /* ... same height as other buttons in dialog. */ }}
              startIcon={<CancelIcon />}
              onClick={openCancelDialog}
            >
              Termin absagen
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <DeleteInvitationDialog
        isOpen={isDeleteDialogOpen}
        invitation={event}
        onClose={(invitationDeleted) => {
          closeDeleteDialog();
          if (invitationDeleted) {
            onClose();
          }
        }}
      />
      <CancelAppointmentDialog
        isOpen={isCancelDialogOpen}
        calendarEvent={event}
        onClose={(appointmentCancelled) => {
          closeCancelDialog();
          if (appointmentCancelled) {
            onClose();
          }
        }}
      />
    </>
  );
};

const EventDialogHeader = (props: { event: AutovioCalendarEvent }) => {
  const invitationPrefix = props.event.student?.rsvp === "pending" ? "Einladung - " : "";
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
      <span>{invitationPrefix + calendarEventTitle(props.event)}</span>
      {grants.includes("viewFirestore") && <EventIdBar id={props.event.id} />}
    </Stack>
  );
};

const EventDialogBody = ({ event }: { event: AutovioCalendarEvent }) => {
  return (
    <Stack direction="column" spacing="1em">
      <ShortEventHistory event={event} />
      <Box px="1.5em">
        <EventInfoCard event={event} showStartedAtEndedAt={grants.includes("viewAdditionalEventDetails")} isMainCard />
      </Box>
      {event.updatedAt && (
        <Box px="1.5em" py="1em" bgcolor="#FAFAFA">
          <EventChangeLog event={event} />
        </Box>
      )}
    </Stack>
  );
};

const ShortEventHistory = ({ event }: { event: AutovioCalendarEvent }) => {
  const createdAt = event.createdAt;
  const { data: createdBy } = useGetOne("users", { id: event.createdBy }, { enabled: !!event.createdBy });
  const createdByStudent = event.createdBy === event.student?.uid;
  const bookedAt = event.student?.bookedAt;
  const declinedAt = event.student?.declinedAt;
  const canceledByStudentAt = event.student?.canceledAt;
  const cancelReason = event.student?.cancelReason;
  const deletedAt = event.deletedAt;
  const deletedByUserUid = event.deletedBy;
  const deleteReason = event.deletionReason;
  const studentId = event.student?.uid;
  const { data: student } = useGetOne("students", { id: studentId }, { enabled: !!studentId });
  const { data: deletedBy } = useGetOne("users", { id: event.deletedBy }, { enabled: !!deletedByUserUid });

  return (
    <Box px="1.5em">
      <Typography>
        Erstellt: {formatDateTime(createdAt)} von {createdBy?.name ?? "..."}
      </Typography>
      {createdByStudent && (
        <Typography>
          Gebucht: {formatDateTime(createdAt)} von {createdBy?.name ?? "..."}
        </Typography>
      )}
      {!createdByStudent && bookedAt && (
        <Typography>
          Gebucht: {formatDateTime(bookedAt)} von {student?.name ?? "..."}
        </Typography>
      )}
      {declinedAt && (
        <Typography>
          Abgelehnt: {formatDateTime(declinedAt)} von {student?.name ?? "..."}
        </Typography>
      )}
      {canceledByStudentAt && (
        <Typography>
          Abgesagt: {formatDateTime(canceledByStudentAt)} von {student?.name ?? "..."}
          {cancelReason && `, Grund: ${cancelReason}`}
        </Typography>
      )}
      {deletedAt && (
        <Typography>
          Abgesagt: {formatDateTime(deletedAt)} von {deletedBy?.name ?? "..."}
          {deleteReason && `, Grund: ${deleteReason}`}
        </Typography>
      )}
    </Box>
  );
};

const EventChangeLog = ({ event }: { event: AutovioCalendarEvent }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data: history } = useGetOne<AutovioCalendarEventHistory>(
    "calendarEventHistory",
    { id: event.id, meta: { drivingSchoolId: event.drivingSchoolId } },
    { enabled: !!event.updatedAt },
  );

  if (!event.updatedAt) {
    return null;
  }

  const { events, changes } = history ?? {};
  const originalEvent = events?.at(-1);

  return (
    <Stack direction="column" spacing="1em">
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ cursor: "pointer" }}
        onClick={() => setIsExpanded((value) => !value)}
      >
        <Stack direction="column">
          {!changes && <Typography variant="h6">Lade Änderungen ...</Typography>}
          {changes && changes.length === 1 && <Typography variant="h6">1 Änderung</Typography>}
          {changes && changes.length > 1 && <Typography variant="h6">{changes.length} Änderungen</Typography>}
        </Stack>
        <Box>
          <IconButton>{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
        </Box>
      </Stack>
      <Collapse in={isExpanded} enter={false} exit={false} unmountOnExit>
        <Stack direction="column" spacing="1em">
          {changes?.flatMap(([oldEvent, newEvent]) => {
            const bookedAt = oldEvent.student?.bookedAt;
            return [
              <EventChangeLogEntry key={event.id} oldEvent={oldEvent} newEvent={newEvent} />,
              ...(bookedAt && bookedAt > oldEvent.createdAt && bookedAt < newEvent.createdAt
                ? [<EventChangeLogAction what="Gebucht" at={bookedAt} by={oldEvent.student?.uid} />]
                : []),
            ];
          })}
          {originalEvent && originalEvent.createdBy === originalEvent.student?.uid && (
            <EventChangeLogAction what="Erstellt/Gebucht" at={originalEvent.createdAt} by={originalEvent.createdBy} />
          )}
          {originalEvent && originalEvent.createdBy !== originalEvent.student?.uid && (
            <EventChangeLogAction what="Erstellt" at={originalEvent.createdAt} by={originalEvent.createdBy} />
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const EventChangeLogEntry = (props: { oldEvent: AutovioCalendarEvent; newEvent: AutovioCalendarEvent }) => {
  const { oldEvent, newEvent } = props;

  const changedKeys = useMemo(() => {
    const eventDiff = diff(oldEvent, newEvent);
    const changedKeys = Object.keys(eventDiff);

    // Determine if the event duration has changed
    const oldDuration = oldEvent.end.diff(oldEvent.start);
    const newDuration = newEvent.end.diff(newEvent.start);
    if (!oldDuration.equals(newDuration)) {
      changedKeys.push("duration");
    }

    // Determine if the booked training has changed
    const oldBookedTrainingId = oldEvent.student?.bookedTrainingId;
    const newBookedTrainingId = newEvent.student?.bookedTrainingId;
    if (oldBookedTrainingId !== newBookedTrainingId) {
      changedKeys.push("bookedTrainingId");
    }

    return changedKeys;
  }, [oldEvent.id, newEvent.id]);

  return (
    <Card sx={newEvent.editReason ? { bgcolor: autovioColors.redUltraLight } : {}}>
      <Stack direction="column">
        <Typography variant="body1" px="16px" py="10px">
          Geändert am {<b>{formatDateTime(newEvent.updatedAt ?? newEvent.createdAt)}</b>} von{" "}
          {
            <UserDisplayName
              component="span"
              userId={newEvent.updatedBy ?? newEvent.createdBy}
              variant="body1"
              fontWeight="bold"
            />
          }
          {newEvent.editReason?.trim() ? (
            <>
              {" (Grund: "}
              <b>{newEvent.editReason.trim()}</b>
              {")"}
            </>
          ) : null}
        </Typography>
        <Divider />
        <Stack direction="row" spacing="24px" p="16px">
          <Box flex="1">
            <EventInfoCard
              event={oldEvent}
              highlightedProperties={changedKeys}
              highlightColor="#c79200"
              chip={<Chip label="Vorher" size="small" sx={{ bgcolor: "#fff0c8" }} />}
            />
          </Box>
          <div style={{ borderLeft: "1px solid #E3E3E7", flex: 0 }} />
          <Box flex="1">
            <EventInfoCard
              event={newEvent}
              highlightedProperties={changedKeys}
              highlightColor="#12873b"
              chip={<Chip label="Nachher" size="small" sx={{ bgcolor: "#d3f9e0" }} />}
            />
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

const EventChangeLogAction = ({ what, at, by }: { what?: string; at: DateTime; by: string }) => {
  return (
    <Card>
      <Typography variant="body1" px="16px" py="10px">
        {what} am {<b>{formatDateTime(at)}</b>} von{" "}
        {<UserDisplayName component="span" userId={by} variant="body1" fontWeight="bold" />}
      </Typography>
    </Card>
  );
};
