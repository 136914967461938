import { Typography } from "@mui/material";
import get from "lodash/get";
import { sanitizeFieldRestProps, TextFieldProps, useRecordContext } from "react-admin";

export function PercentageField(props: TextFieldProps & { hide0?: boolean }) {
  const { className, source, hide0, ...rest } = props;
  const record = useRecordContext(props);
  const value = get(record, source ?? "");

  if (typeof value !== "number" || !isFinite(value) || (value === 0 && hide0)) {
    return null;
  }

  return (
    <Typography
      component="span"
      variant={props.variant || "body2"}
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {formatPercentage(value)}
    </Typography>
  );
}

const _percentageFormatter = new Intl.NumberFormat("de-DE", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
});

export function formatPercentage(percent: number): string {
  return _percentageFormatter.format(percent / 100);
}
