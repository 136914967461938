import polyglotI18nProvider from "ra-i18n-polyglot";
import de from "ra-language-german";
import en from "ra-language-english";

de.ra.action.search = "Suche";
de.ra.action.add_filter = "Filter";
en.ra.action.add_filter = "Filter";
de.ra.action.remove_all_filters = "Aktuellen Filter zurücksetzen";
en.ra.action.remove_all_filters = "Reset current filter";
de.ra.action.select_columns = "Spalten";
de.ra.action.clear_array_input = "Liste löschen";
de.ra.message.clear_array_input = "Wirklich alle Einträge löschen?";
de.ra.saved_queries = {
  label: "Gespeicherte Filter",
  query_name: "Name des Filters",
  new_label: "Aktuellen Filter speichern ...",
  new_dialog_title: "Aktuellen Filter speichern als",
  remove_label: "Gespeicherten Filter löschen",
  remove_label_with_name: 'Filter "%{name}" löschen',
  remove_dialog_title: "Filter löschen?",
  remove_message: "Sind Sie sicher, dass Sie den gespeichert Filter löschen möchten?",
  help: "Filtern Sie die Liste und speichern Sie Filter für später",
};
en.ra.saved_queries = {
  label: "Saved filters",
  query_name: "Name of filter",
  new_label: "Save current filter ...",
  new_dialog_title: "Save current filter as",
  remove_label: "Delete saved filter",
  remove_label_with_name: 'Delete filter "%{name}"',
  remove_dialog_title: "Delete filter?",
  remove_message: "Are you sure you want to delete the saved filter ?",
  help: "Filter the list and save the filter for later",
};

const l10n: { [locale: string]: any } = { de, en };

export const i18nProvider = polyglotI18nProvider(
  /* getMessages: */ (locale) => l10n[locale],
  /* initialLocale: */ "de",
  /* availableLocales: */ [
    { locale: "de", name: "Deutsch" },
    { locale: "en", name: "English" },
  ],
);
