import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button } from "@mui/material";
import { DateTime } from "luxon";
import { Datagrid, FunctionField, TextField } from "react-admin";
import { grants } from "../backoffice.access_control";
import { PartialRefundPaymentDialogButton } from "../buttons/PartialRefundInvoiceDialogButton";
import { DateField } from "../fields/DateField";
import { InvoiceStateBadge } from "../fields/InvoiceStateField";
import { LinkToStripeField } from "../fields/LinkToStripeField";
import { MoneyField } from "../fields/MoneyField";
import { RetryPaymentButton } from "../buttons/RetryPaymentButton";
import { Invoice } from "../providers/invoicesProvider";
import type { Student } from "../providers/studentsProvider.js";
import { formatDateTime } from "../utils/calendar";

export function InvoicesList({ student }: { student: Student }) {
  // All columns are marked as not sortable, because sort order must always be: createdAt DESC ...
  return (
    <Datagrid
      bulkActionButtons={false}
      empty={<span style={{ opacity: 0.5 }}>Keine Rechnungen im neuen Rechnungssystem vorhanden</span>}
    >
      <TextField label="Rechnungs-Nr." source="nr" noWrap sortable={false} />
      <FunctionField
        render={(invoice: Invoice) => (
          <>
            {invoice.payload.positions.map((p) => (
              <div key={p.id}>{p.description}</div>
            ))}
          </>
        )}
        label="Leistungen"
        sortable={false}
      />
      <DateField label="Rechnungs&shy;stellung" source="createdAt" sortable={false} />
      <FunctionField
        label="Leistungs&shy;zeitpunkt"
        sortable={false}
        render={(invoice: Invoice) => {
          const dateTimeArray = invoice.payload.positions
            .map((p) => (p.service_at ? formatDateTime(DateTime.fromISO(p.service_at)) : null))
            .filter((e) => !!e)?.[0]
            ?.split(",");
          const date = dateTimeArray?.[0];
          const time = dateTimeArray?.[1];
          return (
            <>
              <Box>{date}</Box>
              <Box>{time}</Box>
            </>
          );
        }}
      />
      <MoneyField label="Betrag" source="total" textAlign="right" sortable={false} />
      <MoneyField label="Offen" source="payment_amount" textAlign="right" sortable={false} />
      <MoneyField label="Erstattet" source="refunded_amount" textAlign="right" sortable={false} />
      <FunctionField
        label="Status"
        source="status"
        render={(invoice: Invoice) => <InvoiceStateBadge invoice={invoice.payload} />}
      />
      <FunctionField
        label=""
        sortable={false}
        render={(invoice: Invoice) => {
          const pdf_url = invoice.downloadUrl;
          if (!pdf_url) {
            return;
          }
          return (
            <Button
              startIcon={<OpenInNewIcon />}
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                open(await pdf_url, "_blank");
              }}
            >
              Öffnen
            </Button>
          );
        }}
      />
      <FunctionField
        label=""
        sortable={false}
        render={(invoice: Invoice) => {
          if (!grants.includes("retryPayments")) {
            return;
          }
          return (
            <RetryPaymentButton
              student={student}
              invoiceState={invoice.payload.state}
              invoiceId={invoice.payload.id}
              paymentAmount={invoice.payload.payment_amount}
              description={invoice.payload.positions.map((p) => p.description).join(", ")}
            />
          );
        }}
      />
      {grants.includes("refundPayments") && (
        <FunctionField
          label=""
          sortable={false}
          render={(invoice: Invoice) => <PartialRefundPaymentDialogButton invoice={invoice.payload} />}
        />
      )}
      {grants.includes("viewStripe") && <LinkToStripeField label="" source="id" sortable={false} />}
    </Datagrid>
  );
}
