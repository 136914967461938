import { Button, Typography, type SxProps, type Theme } from "@mui/material";
import type { ReactNode } from "react";
import { useRecordContext, type FieldProps } from "react-admin";

export function RecordListButton({
  source,
  title,
  startIcon,
  sx,
  emptyText,
}: FieldProps & { source: string; title: string; startIcon?: ReactNode; sx?: SxProps<Theme> }) {
  if (!source) {
    throw new Error("source is required");
  }
  const record = useRecordContext();
  const href = record[source];
  if (!href) {
    if (emptyText) {
      return <Typography>{emptyText}</Typography>;
    }
    return null;
  }
  return (
    <Button href={href} target="_blank" startIcon={startIcon} sx={sx} rel="noopener noreferrer" variant="text">
      {title}
    </Button>
  );
}
