export function InvoiceIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 16.5a2.17 2.17 0 0 1-1.594-.656 2.17 2.17 0 0 1-.656-1.594V12H4.5V1.5l1.125 1.125L6.75 1.5l1.125 1.125L9 1.5l1.125 1.125L11.25 1.5l1.125 1.125L13.5 1.5l1.125 1.125L15.75 1.5v12.75a2.17 2.17 0 0 1-.656 1.594 2.17 2.17 0 0 1-1.594.656h-9zm9-1.5c.213 0 .39-.072.534-.216a.724.724 0 0 0 .216-.534V3.75H6V12h6.75v2.25c0 .213.072.39.216.534A.724.724 0 0 0 13.5 15zM6.75 6.75v-1.5h4.5v1.5h-4.5zm0 2.25V7.5h4.5V9h-4.5zm6-2.25a.724.724 0 0 1-.534-.216A.724.724 0 0 1 12 6c0-.213.072-.39.216-.535a.725.725 0 0 1 .534-.215c.213 0 .39.072.534.215A.726.726 0 0 1 13.5 6c0 .213-.072.39-.216.534a.724.724 0 0 1-.534.216zm0 2.25a.724.724 0 0 1-.534-.216A.724.724 0 0 1 12 8.25c0-.213.072-.39.216-.535a.725.725 0 0 1 .534-.215c.213 0 .39.072.534.215a.726.726 0 0 1 .216.535c0 .213-.072.39-.216.534A.724.724 0 0 1 12.75 9zM4.5 15h6.75v-1.5h-7.5v.75c0 .213.072.39.215.534A.726.726 0 0 0 4.5 15zm-.75 0v-1.5V15z"
        fill="#9B9B9B"
        fillRule="evenodd"
      />
    </svg>
  );
}
