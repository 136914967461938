import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import EventIcon from "@mui/icons-material/Event";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import NotesIcon from "@mui/icons-material/Notes";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StartIcon from "@mui/icons-material/Start";
import { Box, Checkbox, CircularProgress, Collapse, Stack, Tooltip, TooltipProps, Typography } from "@mui/material";
import { captureMessage } from "@sentry/react";
import { useEffect, useMemo } from "react";
import {
  EditBase,
  Form,
  Link,
  SelectInput,
  useCreatePath,
  useGetMany,
  useGetOne,
  useNotify,
  useRecordContext,
} from "react-admin";
import { reportError } from "../../backoffice.utils";
import { AddStudentToTheoryLessonButton } from "../../buttons/AddStudentToTheoryLessonButton";
import { renderLessonStatus } from "../../details/instructor/InstructorAppointmentsList";
import {
  ASFCourseSession,
  ASFCourseSessionAttendance,
  AutovioCalendarEvent,
  DrivingLesson,
  isASFCourseSession,
  isDrivingLesson,
  isOtherEvent,
  isPracticalExam,
  isTheoryExam,
  isTheoryLesson,
  OtherEvent,
  PracticalExam,
  TheoryExam,
  TheoryLesson,
  TheoryLessonStudentAttendance,
} from "../../model/autovioCalendarEvents";
import {
  calendarEventTitle,
  formatDateTime,
  formatDateTimeRange,
  formatTime,
  renderTheoryLessonSlots,
} from "../../utils/calendar";
import { AddressDisplay } from "../AddressDisplay";
import { autovioColors } from "../backofficeTheme";
import { BookedTrainingClassDisplay } from "../BookedTrainingClassDisplay";
import { EventIdBar } from "../IdBar";
import { InstructorAvatar } from "../InstructorAvatar";
import { Money } from "../Money";
import { StudentAvatar } from "../StudentAvatar";
import { StudentDisplayName } from "../StudentDisplayName";
import { VehicleDisplay } from "../VehicleDisplay";
import { TransitionGroup } from "react-transition-group";
import { Student } from "../../providers/studentsProvider";
import { grants } from "../../backoffice.access_control";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { RemoveSelectedStudentsFromTheoryLessonButton } from "../../buttons/RemoveSelectedStudentsFromTheoryLessonButton";
import { DateTime } from "luxon";
import { Row } from "../Row";
import { calendarEventsProvider } from "../../providers/calendarEventsProvider";
import { useQueryClient } from "react-query";

// TODO: Refactor into a separate component for the header box and for the changelog entries
type EventInfoCardProps = {
  chip?: React.ReactNode;
  highlightColor?: string;
  highlightedProperties?: string[];
  showIdBar?: boolean;
  showStartedAtEndedAt?: boolean;
  isMainCard?: boolean;
};

export const EventInfoCard = (props: { event: AutovioCalendarEvent } & EventInfoCardProps) => {
  const { event, ...restProps } = props;
  if (isDrivingLesson(event)) {
    return <DrivingLessonEventInfoCard event={event} {...restProps} />;
  }
  if (isTheoryLesson(event)) {
    return <TheoryLessonEventInfoCard event={event} {...restProps} />;
  }
  if (isASFCourseSession(event)) {
    return <ASFCourseSessionEventInfoCard event={event} {...restProps} />;
  }
  if (isOtherEvent(event)) {
    return <OtherEventInfoCard event={event} {...restProps} />;
  }
  if (isTheoryExam(event)) {
    return <TheoryExamEventInfoCard event={event} {...restProps} />;
  }
  useEffect(() => {
    if (event) {
      reportError(`Unsupported event of type: ${event.type}`);
    }
  }, [event]);
  return <Typography color="error">Fehler beim Anzeigen</Typography>;
};

const DrivingLessonEventInfoCard = (props: { event: DrivingLesson } & EventInfoCardProps) => {
  const createPath = useCreatePath();
  return (
    <EventInfoCardBody event={props.event} showStatus={props.isMainCard} chip={props.chip} showIdBar={props.showIdBar}>
      {props.isMainCard && (
        <EventInfoRow
          isHighlighted={props.highlightedProperties?.includes("student")}
          highlightColor={props.highlightColor}
          tooltip="Fahrschüler"
        >
          <Link
            to={createPath({ resource: "students", id: props.event.student.uid, type: "edit" })}
            style={{ display: "flex", color: autovioColors.green, textDecoration: "none" }}
          >
            <StudentAvatar studentId={props.event.student.uid} size="20px" />
            <StudentDisplayName sx={{ ml: ".5em" }} studentId={props.event.student.uid} />
          </Link>
        </EventInfoRow>
      )}
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("start")}
        highlightColor={props.highlightColor}
        tooltip="Beginn & Ende"
      >
        <EventIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">
          {`${formatDateTime(props.event.start)} - ${formatTime(props.event.end)} Uhr`}
        </Typography>
      </EventInfoRow>
      <Stack direction="row" spacing="30px">
        <EventInfoRow
          isHighlighted={props.highlightedProperties?.includes("duration")}
          highlightColor={props.highlightColor}
          tooltip="Dauer"
        >
          <ScheduleIcon fontSize="small" sx={{ color: "gray" }} />
          <Typography variant="body2" noWrap>
            {`${Math.round(props.event.end.diff(props.event.start, "minutes").minutes)} min.`}
          </Typography>
        </EventInfoRow>
        {props.showStartedAtEndedAt && (
          <>
            <EventInfoRow tooltip="Gestartet um" tooltipPlacement="bottom">
              <StartIcon fontSize="small" sx={{ color: "gray" }} />
              <Typography variant="body2" noWrap>
                {props.event.startedAt ? formatTime(props.event.startedAt) : "Nicht gestartet"}
              </Typography>
            </EventInfoRow>
            <EventInfoRow tooltip="Beendet um" tooltipPlacement="bottom">
              <KeyboardTabIcon fontSize="small" sx={{ color: "gray" }} />
              <Typography variant="body2" noWrap>
                {props.event.endedAt ? formatTime(props.event.endedAt) : "Nicht beendet"}
              </Typography>
            </EventInfoRow>
          </>
        )}
      </Stack>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("bufferTimes")}
        highlightColor={props.highlightColor}
        tooltip="Transferzeiten"
      >
        <MoreTimeIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2" noWrap>
          Anfahrt {props.event.bufferTimes?.preparationTime ?? 0} min. • Abfahrt{" "}
          {props.event.bufferTimes?.wrapUpTime ?? 0} min.
        </Typography>
      </EventInfoRow>
      {props.event.student.bookedTrainingId && (
        <EventInfoRow
          isHighlighted={props.highlightedProperties?.includes("bookedTrainingId")}
          highlightColor={props.highlightColor}
          tooltip="Zugewiesene Ausbildung"
        >
          <CardMembershipOutlinedIcon fontSize="small" sx={{ color: "gray" }} />
          <BookedTrainingClassDisplay
            studentId={props.event.student.uid}
            bookedTrainingId={props.event.student.bookedTrainingId}
          />
        </EventInfoRow>
      )}
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("startLocation")}
        tooltip="Ort der Fahrstunde"
        highlightColor={props.highlightColor}
      >
        <PlaceOutlinedIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">
          <AddressDisplay address={props.event.startLocation} oneLine />
        </Typography>
      </EventInfoRow>
      {props.event.resources?.map((resourceId) => (
        <VehicleDisplay
          vehicleId={resourceId}
          iconSize="small"
          iconColor="gray"
          labelColor={props.highlightedProperties?.includes("resources") ? props.highlightColor : undefined}
          key={resourceId}
        />
      ))}
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("price")}
        highlightColor={props.highlightColor}
        tooltip="Preis"
      >
        <EuroRoundedIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2" noWrap>
          <Money cents={props.event.price.studentPrice.gross} /> (Einnahmen:{" "}
          <Money cents={props.event.price.instructorPrice.gross} />)
        </Typography>
      </EventInfoRow>
      {isPracticalExam(props.event) && (
        <EventInfoRow>
          <ExamResultForm exam={props.event} />
        </EventInfoRow>
      )}
      <EventExtensions event={props.event} />
    </EventInfoCardBody>
  );
};

const TheoryLessonEventInfoCard = (props: { event: TheoryLesson } & EventInfoCardProps) => {
  return (
    <EventInfoCardBody event={props.event} chip={props.chip} showIdBar={props.showIdBar}>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("theoryUnit")}
        highlightColor={props.highlightColor}
        tooltip="Lektionsnummer"
      >
        <FormatListNumberedIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">Lektion {props.event.theoryUnit}</Typography>
      </EventInfoRow>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("start")}
        highlightColor={props.highlightColor}
        tooltip="Datum"
      >
        <EventIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">{formatDateTimeRange(props.event.start, props.event.end)}</Typography>
      </EventInfoRow>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("bufferTimes")}
        highlightColor={props.highlightColor}
        tooltip="Transferzeiten"
      >
        <MoreTimeIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2" noWrap>
          {`Vorbereitungszeit ${props.event.bufferTimes?.preparationTime ?? 0} min.`}
          {" • "}
          {`Nachbereitungszeit ${props.event.bufferTimes?.wrapUpTime ?? 0} min.`}
        </Typography>
      </EventInfoRow>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("location")}
        tooltip="Ort des Theorieunterrichts"
        highlightColor={props.highlightColor}
      >
        <PlaceOutlinedIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">
          {props.event.location.type === "PostalAddress" && <AddressDisplay address={props.event.location} oneLine />}
          {props.event.location.type === "OnlineMeeting" && (
            <Link to={props.event.location.url} target="_blank">
              Online
            </Link>
          )}
        </Typography>
      </EventInfoRow>
      <EventInfoRow tooltip="Anzahl Buchungen / Maximale Teilnehmerzahl">
        <PeopleOutlineIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">{renderTheoryLessonSlots(props.event)} Fahrschüler</Typography>
      </EventInfoRow>
      {props.isMainCard && <AttendeesList event={props.event} />}
    </EventInfoCardBody>
  );
};

const AttendeesList = (props: { event: TheoryLesson | ASFCourseSession }) => {
  const createPath = useCreatePath();
  // We fetch the given event here via useGetOne so that we rerender whenever the event changes ...
  const { data } = useGetOne<TheoryLesson | ASFCourseSession>("calendarEvents", { id: props.event.id });
  const event = data ?? props.event;
  const attendanceByStudentId: Record<string, TheoryLessonStudentAttendance | ASFCourseSessionAttendance> =
    event.students || event.participants;
  const {
    data: students,
    isLoading: studentsIsLoading,
    error: studentsError,
  } = useGetMany<Student>("students", {
    ids: Object.keys(attendanceByStudentId),
    meta: { drivingSchoolId: event.drivingSchoolId },
  });
  const attendanceSortedByName: Array<[string, TheoryLessonStudentAttendance | ASFCourseSessionAttendance]> =
    useMemo(() => {
      const entries = Object.entries(attendanceByStudentId);
      if (!students || studentsIsLoading || studentsError) {
        return entries;
      }
      return entries.sort(([uid1], [uid2]) => {
        const student1 = students.find((student) => student.id === uid1);
        const student2 = students.find((student) => student.id === uid2);
        if (!student1 || !student2) return 0;
        return student1.name.localeCompare(student2.name);
      });
    }, [attendanceByStudentId, students]);
  const canAddStudents = isTheoryLesson(event) && grants.includes("addStudentToTheoryLesson");
  const canRemoveStudents =
    isTheoryLesson(event) && grants.includes("removeStudentFromTheoryLesson") && DateTime.now() < event.start;
  const formProps = useForm<{ selectedStudents: Array<string> }>({ defaultValues: { selectedStudents: [] } });

  return (
    <FormProvider {...formProps}>
      <Stack direction="column" spacing="10px">
        <TransitionGroup component="div" style={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
          {attendanceSortedByName.map(([uid, attendance]) => {
            return (
              <Collapse key={uid} unmountOnExit>
                <EventInfoRow>
                  {canRemoveStudents && (
                    <Checkbox
                      {...formProps.register("selectedStudents")}
                      value={uid}
                      size="small"
                      sx={{ ml: "calc(-20px - 0.5em)", p: 0 }}
                    />
                  )}
                  <Link
                    to={createPath({ resource: "students", id: uid, type: "edit" })}
                    style={{ display: "flex", color: autovioColors.green, textDecoration: "none" }}
                  >
                    <StudentAvatar studentId={uid} size="20px" />
                    <StudentDisplayName sx={{ ml: ".5em" }} studentId={uid} />
                  </Link>
                  <Typography variant="body2">
                    {" ("}
                    {(() => {
                      if (attendance.attended) {
                        return "teilgenommen";
                      } else if (attendance.noShow) {
                        return "nicht erschienen";
                      } else if (isASFCourseSession(event) || attendance.rsvp === "accepted") {
                        return "gebucht";
                      } else if (attendance.rsvp === "rejected") {
                        return "abgesagt";
                      } else {
                        // Should never happen
                        captureMessage(
                          `Theory lesson ${
                            event.id
                          } has unexpected TheoryLessonStudentAttendance for student ${uid}: ${JSON.stringify(
                            attendance,
                          )}`,
                          "error",
                        );
                        return "???";
                      }
                    })()}
                    {")"}
                  </Typography>
                </EventInfoRow>
              </Collapse>
            );
          })}
        </TransitionGroup>
        {canRemoveStudents && attendanceSortedByName.length > 0 && (
          <RemoveSelectedStudentsFromTheoryLessonButton sx={{ mt: "10px" }} theoryLesson={event} />
        )}
        {canAddStudents && <AddStudentToTheoryLessonButton sx={{ mt: "10px" }} theoryLesson={event} />}
      </Stack>
    </FormProvider>
  );
};

const OtherEventInfoCard = (props: { event: OtherEvent } & EventInfoCardProps) => {
  return (
    <EventInfoCardBody event={props.event} chip={props.chip} showIdBar={props.showIdBar}>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("start")}
        highlightColor={props.highlightColor}
        tooltip="Datum"
      >
        <EventIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">{formatDateTimeRange(props.event.start, props.event.end)}</Typography>
      </EventInfoRow>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("bufferTimes")}
        highlightColor={props.highlightColor}
        tooltip="Transferzeiten"
      >
        <MoreTimeIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2" noWrap>
          Pufferzeiten: davor {props.event.bufferTimes?.preparationTime ?? 0} min., danach{" "}
          {props.event.bufferTimes?.wrapUpTime ?? 0} min.
        </Typography>
      </EventInfoRow>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("notes")}
        highlightColor={props.highlightColor}
        tooltip="Notiz"
      >
        <NotesIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">{props.event.notes}</Typography>
      </EventInfoRow>
    </EventInfoCardBody>
  );
};

const ASFCourseSessionEventInfoCard = (props: { event: ASFCourseSession } & EventInfoCardProps) => {
  const { event } = props;
  return (
    <EventInfoCardBody event={props.event} chip={props.chip} showIdBar={props.showIdBar}>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("start")}
        highlightColor={props.highlightColor}
        tooltip="Datum"
      >
        <EventIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">{formatDateTimeRange(props.event.start, props.event.end)}</Typography>
      </EventInfoRow>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("bufferTimes")}
        highlightColor={props.highlightColor}
        tooltip="Transferzeiten"
      >
        <MoreTimeIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2" noWrap>
          {`Vorbereitungszeit ${props.event.bufferTimes?.preparationTime ?? 0} min.`}
          {" • "}
          {`Nachbereitungszeit ${props.event.bufferTimes?.wrapUpTime ?? 0} min.`}
        </Typography>
      </EventInfoRow>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("location")}
        tooltip={`Ort der ${event.sessionType === "fahrprobe" ? "Fahrprobe" : "Gruppensitzung"}`}
        highlightColor={props.highlightColor}
      >
        <PlaceOutlinedIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">
          <AddressDisplay address={event.location} oneLine />
        </Typography>
      </EventInfoRow>
      <EventInfoRow tooltip="Anzahl Teilnehmer">
        <PeopleOutlineIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">{Object.keys(event.participants).length} Teilnehmer</Typography>
      </EventInfoRow>
      {props.isMainCard && <AttendeesList event={props.event} />}
    </EventInfoCardBody>
  );
};

type EventInfoCardBodyProps = {
  event: AutovioCalendarEvent;
  chip?: React.ReactNode;
  children?: React.ReactNode;
  highlightColor?: string;
  highlightedProperties?: string[];
  showIdBar?: boolean;
  showStatus?: boolean;
};

const EventInfoCardBody = (props: EventInfoCardBodyProps) => {
  return (
    <Stack spacing="1em">
      <Stack direction="row" spacing="1em">
        {props.event.instructorId && (
          <InstructorAvatar instructorId={props.event.instructorId} size="40px" withTooltip />
        )}
        <Stack direction="column" spacing="0.5em" width="100%">
          <Stack direction="row" justifyContent="space-between" spacing={"1em"} alignContent="center">
            <Typography
              fontWeight="bold"
              color={props.highlightedProperties?.includes("drivingLessonType") ? props.highlightColor : undefined}
            >
              {calendarEventTitle(props.event)}
            </Typography>
            {props.showStatus && <Typography color="gray">({renderLessonStatus(props.event)})</Typography>}
            <Box flex={1} />
            {props.chip}
          </Stack>
          {props.children}
        </Stack>
      </Stack>
      {props.showIdBar && <EventIdBar id={props.event.id} justifyContent="center" />}
    </Stack>
  );
};

type EventInfoLineProps = {
  isHighlighted?: boolean;
  highlightColor?: string;
  tooltip?: string;
  tooltipPlacement?: TooltipProps["placement"];
  children: React.ReactNode;
};

const EventInfoRow = (props: EventInfoLineProps) => {
  return (
    <Tooltip title={props.tooltip} arrow placement={props.tooltipPlacement ?? "left"}>
      <Stack direction="row" spacing="0.5em" color={props.isHighlighted ? props.highlightColor : undefined}>
        {props.children}
      </Stack>
    </Tooltip>
  );
};

const EventExtensions = (props: { event: DrivingLesson }) => {
  const extensions = props.event.extensions;
  if (!extensions || extensions.length === 0) {
    return null;
  }

  return (
    <Stack spacing="10px">
      <Box height="5px" />
      <Typography variant="body2" fontWeight="bold">
        Verlängerungen
      </Typography>
      <Stack spacing="5px">
        {extensions.map((extension) => {
          const eventDuration = props.event.end.diff(props.event.start, "minutes").minutes;
          const extensionPrice = Math.round(
            (props.event.price.studentPrice.gross * extension.durationInMinutes) / eventDuration,
          );
          const originalEnd = formatTime(extension.newEnd.minus({ minutes: extension.durationInMinutes }));
          const newEnd = formatTime(extension.newEnd);
          return (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" key={extension.extendedAt.toISO()}>
                +{extension.durationInMinutes} min. von {originalEnd} bis {newEnd} (+
                {<Money cents={extensionPrice} />})
              </Typography>
              <Typography variant="body2" color="gray">
                um {formatTime(extension.extendedAt)}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

const TheoryExamEventInfoCard = (props: { event: TheoryExam } & EventInfoCardProps) => {
  const createPath = useCreatePath();

  return (
    <EventInfoCardBody event={props.event} showStatus={props.isMainCard} chip={props.chip} showIdBar={props.showIdBar}>
      {props.isMainCard && (
        <EventInfoRow
          isHighlighted={props.highlightedProperties?.includes("student")}
          highlightColor={props.highlightColor}
          tooltip="Fahrschüler"
        >
          <Link
            to={createPath({ resource: "students", id: props.event.student.uid, type: "edit" })}
            style={{ display: "flex", color: autovioColors.green, textDecoration: "none" }}
          >
            <StudentAvatar studentId={props.event.student.uid} size="20px" />
            <StudentDisplayName sx={{ ml: ".5em" }} studentId={props.event.student.uid} />
          </Link>
        </EventInfoRow>
      )}
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("start")}
        highlightColor={props.highlightColor}
        tooltip="Beginn & Ende"
      >
        <EventIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">
          {`${formatDateTime(props.event.start)} - ${formatTime(props.event.end)} Uhr`}
        </Typography>
      </EventInfoRow>
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("duration")}
        highlightColor={props.highlightColor}
        tooltip="Dauer"
      >
        <ScheduleIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2" noWrap>
          {`${Math.round(props.event.end.diff(props.event.start, "minutes").minutes)} min.`}
        </Typography>
      </EventInfoRow>
      {props.event.student.bookedTrainingId && (
        <EventInfoRow
          isHighlighted={props.highlightedProperties?.includes("bookedTrainingId")}
          highlightColor={props.highlightColor}
          tooltip="Zugewiesene Ausbildung"
        >
          <CardMembershipOutlinedIcon fontSize="small" sx={{ color: "gray" }} />
          <BookedTrainingClassDisplay
            studentId={props.event.student.uid}
            bookedTrainingId={props.event.student.bookedTrainingId}
          />
        </EventInfoRow>
      )}
      <EventInfoRow
        isHighlighted={props.highlightedProperties?.includes("startLocation")}
        tooltip="Ort der Theorieprüfung"
        highlightColor={props.highlightColor}
      >
        <PlaceOutlinedIcon fontSize="small" sx={{ color: "gray" }} />
        <Typography variant="body2">
          <AddressDisplay address={props.event.startLocation} oneLine />
        </Typography>
      </EventInfoRow>
      <EventInfoRow>
        <ExamResultForm exam={props.event} />
      </EventInfoRow>
    </EventInfoCardBody>
  );
};

const ExamResultForm = ({ exam }: { exam: TheoryExam | PracticalExam }) => {
  return (
    <EditBase resource="calendarEvents" id={exam.id}>
      <Form>
        <ExamResultDropdown />
      </Form>
    </EditBase>
  );
};

const ExamResultDropdown = () => {
  const exam = useRecordContext<TheoryExam | PracticalExam>();
  const form = useFormContext();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const source = exam && isTheoryExam(exam) ? "theoryExamResult" : "practicalExamResult";

  return (
    <Row gap={1}>
      <SelectInput
        source={source}
        label="Prüfungsergebnis"
        onChange={(event) => {
          void form.handleSubmit(async (formValues) => {
            try {
              const examResult = formValues[source];
              const t0 = Date.now();
              await calendarEventsProvider.update("calendarEvents", {
                id: exam!.id,
                data: { [source]: examResult },
                previousData: exam,
              });
              // [UX]: Make sure the spinner is displayed at least 500 ms ...
              const delay = Math.min(0, t0 + 500 - Date.now());
              if (delay > 0) {
                await new Promise((resolve) => setTimeout(resolve, delay));
              }
              await queryClient.invalidateQueries(["calendarEvents"]);
              notify("Prüfungsergebnis gespeichert.", { type: "success" });
            } catch (error) {
              console.error("Failed to save examResult", error);
              notify("Fehler beim Speichern des Prüfungserbnisses.", { type: "error" });
            }
          })(event);
        }}
        sx={{ width: 240 }}
        choices={[
          { id: "passed", name: "bestanden" },
          { id: "failed", name: "nicht bestanden" },
        ]}
        disabled={!exam || form.formState.isSubmitting}
      />
      {form.formState.isSubmitting && <CircularProgress size={24} sx={{ mt: "14px" }} />}
    </Row>
  );
};
