import {
  RadioButtonGroupInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { RecoverToolbar } from "../../misc/RecoverToolbar";
import { EntitledUsersInput } from "../../inputs/EntitledUsersInput";
import { EntitledDrivingSchoolsInput } from "../../inputs/EntitledDrivingSchoolsInput";

export function CarForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <SimpleForm warnWhenUnsavedChanges toolbar={<RecoverToolbar />}>
      <TextInput type="hidden" source="type" defaultValue="car" style={{ display: "none" }} />
      <TextInput label="Name" source="name" fullWidth autoFocus={mode === "create"} validate={required()} />
      <TextInput label="Notizen" source="description" fullWidth />
      <EntitledDrivingSchoolsInput />
      <EntitledUsersInput />
      <TextInput label="Kennzeichen" source="car.plateNumber" validate={required()} />
      <ReferenceArrayInput source="car.advancedDriverAssistanceSystemsIds" reference="adas">
        <SelectArrayInput
          label="Assistenzsysteme"
          className="fix-SelectArrayInput-label"
          defaultValue={[]}
          sx={{ minWidth: "337px" }}
        />
      </ReferenceArrayInput>
      <RadioButtonGroupInput
        source="car.gearType"
        label="Typ"
        choices={[
          { id: "electric", name: "Elektrisch" },
          { id: "automatic", name: "Automatik" },
          { id: "manual", name: "Schaltwagen" },
        ]}
      />
    </SimpleForm>
  );
}
