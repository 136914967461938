import { stringify } from "query-string";
import { DataProvider, fetchUtils } from "ra-core";

/**
 * Based on https://github.com/marmelab/react-admin/blob/master/packages/ra-data-simple-rest/src/index.ts,
 * adapted to support queries without range.
 *
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 */
const kebabize = (str: string) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase());
export function simpleRestProvider(apiUrl: string, httpClient = fetchUtils.fetchJson): DataProvider {
  return {
    getList: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query: { [name: string]: string } = {
        sort: JSON.stringify([field, order]),
        filter: JSON.stringify(params.filter),
      };
      const options: any = {};
      if (perPage) {
        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;
        query.range = JSON.stringify([rangeStart, rangeEnd]);
        // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
        options.headers = new Headers({ Range: `${resource}=${rangeStart}-${rangeEnd}` });
      }
      const kebabCaseResource = kebabize(resource);
      const url = `${apiUrl}/${kebabCaseResource}?${stringify(query)}`;
      return httpClient(url, options).then(({ headers, json }) => {
        if (perPage) {
          const contentRange = headers.get("Content-Range");
          if (!contentRange) {
            throw new Error("The Content-Range header is missing in the HTTP Response.");
          }
          return {
            data: json,
            total: parseInt(contentRange.substring(contentRange.indexOf("/") + 1), 10),
          };
        } else {
          return {
            data: json,
            total: (json as Array<any>).length,
          };
        }
      });
    },

    getOne: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
        data: json,
      })),

    getMany: (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      const rangeStart = (page - 1) * perPage;
      const rangeEnd = page * perPage - 1;

      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      const options = {
        // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
        headers: new Headers({
          Range: `${resource}=${rangeStart}-${rangeEnd}`,
        }),
      };

      return httpClient(url, options).then(({ headers, json }) => {
        if (!headers.has("Content-Range")) {
          throw new Error("The Content-Range header is missing in the HTTP Response.");
        }
        return {
          data: json,
          total: parseInt((headers.get("content-range") as string).split("/").pop() as string, 10),
        };
      });
    },

    update: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
          }),
        ),
      ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) =>
      httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json })),

    delete: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "text/plain",
        }),
      }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
            headers: new Headers({
              "Content-Type": "text/plain",
            }),
          }),
        ),
      ).then((responses) => ({
        data: responses.map(({ json }) => json.id),
      })),
  };
}
