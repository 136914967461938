import { FieldProps, useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { Typography } from "@mui/material";

export function DunningStatusField(_: FieldProps) {
  const student = useRecordContext<Student>();
  if (!student) {
    return null;
  }
  const { dunningProcess } = student;
  let dunningStatus: string;
  if (!dunningProcess) {
    dunningStatus = "noch nicht gemahnt";
  } else if (dunningProcess.status === "firstDunningNoticeSent") {
    dunningStatus = "1. Mahnung erhalten";
  } else if (dunningProcess.status === "secondDunningNoticeSent") {
    dunningStatus = "2. Mahnung erhalten";
  } else if (dunningProcess.status === "handedOverToPairFinance") {
    dunningStatus = "Inkasso";
  } else {
    dunningStatus = dunningProcess.status || "Unbekannt";
  }
  return (
    <Typography component="span" variant="body2">
      {dunningStatus}
    </Typography>
  );
}
