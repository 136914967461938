import { collectionGroup, DocumentData, getDocs, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { DateTime } from "luxon";
import { GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import { applyFilter, applySort } from "../backoffice.utils";
import { firestore } from "../firebase";
import { RaRecord } from "react-admin";

export interface PrivateAppointment extends RaRecord {
  id: string;
  start: DateTime;
  end: DateTime;
}

class InstructorPrivateAppointmentsProvider {
  async getManyReference(
    _: string,
    { id, filter, sort }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<PrivateAppointment>> {
    const { from, to, ...otherFilters } = filter;
    const query_ = query(collectionGroup(firestore, "private_appointments"), where("userUid", "==", id));
    const snapshot = await getDocs(query_.withConverter(privateAppointmentsConverter));
    let privateAppointments = snapshot.docs.map((it) => it.data()).flat();
    if (from) {
      const fromConstraint = typeof from === "string" ? DateTime.fromISO(from) : from;
      privateAppointments = privateAppointments.filter((it) => it.end >= fromConstraint);
    }
    if (to) {
      const toConstraint = typeof to === "string" ? DateTime.fromISO(to) : to;
      privateAppointments = privateAppointments.filter((it) => it.start < toConstraint);
    }
    return {
      data: applySort(applyFilter(privateAppointments, { ...otherFilters }), sort),
      total: privateAppointments.length,
    };
  }
}

export const instructorPrivateAppointmentsProvider = new InstructorPrivateAppointmentsProvider();

const privateAppointmentsConverter = {
  toFirestore: (_: PrivateAppointment[]): DocumentData => {
    throw new Error("Not implemented");
  },

  fromFirestore: (doc: QueryDocumentSnapshot): PrivateAppointment[] => {
    const docData = doc.data();
    return docData.data.map(
      (it: { [field: string]: any }): PrivateAppointment => ({
        id: it.uid,
        start: DateTime.fromJSDate(it.start.toDate()),
        end: DateTime.fromJSDate(it.end.toDate()),
      }),
    );
  },
};
