import { SortPayload } from "react-admin";
import { persistentSortStateFamily } from "../persistent_state";
import { useCallback } from "react";
import { useRecoilState } from "recoil";

interface SortHookResult {
  setSortField: (field: SortPayload["field"]) => void;
  setSortOrder: (order: SortPayload["order"]) => void;
  setSort: (sort: SortPayload) => void;
  sort: SortPayload;
}

const defaultSort: SortPayload = { field: "id", order: "DESC" };

export function usePersistentSortState({
  storageKey,
  initialSort,
}: {
  storageKey: string;
  initialSort?: SortPayload;
}): SortHookResult {
  const [persistentSort, setSort] = useRecoilState(persistentSortStateFamily(storageKey));
  const sort = persistentSort ?? initialSort ?? defaultSort;
  const { field, order } = sort;
  const setSortField = useCallback((field: SortPayload["field"]) => setSort({ field, order }), [setSort, order]);
  const setSortOrder = useCallback((order: SortPayload["order"]) => setSort({ field, order }), [setSort, field]);
  return {
    setSort,
    setSortField,
    setSortOrder,
    sort,
  };
}
