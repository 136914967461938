/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FirestoreTriggerEvent } from '../models/FirestoreTriggerEvent';
import type { PaginatedFirestoreTriggerEventList } from '../models/PaginatedFirestoreTriggerEventList';
import type { PatchedFirestoreTriggerEvent } from '../models/PatchedFirestoreTriggerEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FirebaseEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedFirestoreTriggerEventList
   * @throws ApiError
   */
  public firebaseTriggerEventList({
    limit,
    offset,
  }: {
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
  }): CancelablePromise<PaginatedFirestoreTriggerEventList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/firebase/trigger-event/',
      query: {
        'limit': limit,
        'offset': offset,
      },
    });
  }

  /**
   * @returns FirestoreTriggerEvent
   * @throws ApiError
   */
  public firebaseTriggerEventCreate({
    requestBody,
  }: {
    requestBody: FirestoreTriggerEvent,
  }): CancelablePromise<FirestoreTriggerEvent> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/firebase/trigger-event/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns FirestoreTriggerEvent
   * @throws ApiError
   */
  public firebaseTriggerEventRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this firestore trigger event.
     */
    id: string,
  }): CancelablePromise<FirestoreTriggerEvent> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/firebase/trigger-event/{id}/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @returns FirestoreTriggerEvent
   * @throws ApiError
   */
  public firebaseTriggerEventUpdate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this firestore trigger event.
     */
    id: string,
    requestBody: FirestoreTriggerEvent,
  }): CancelablePromise<FirestoreTriggerEvent> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/firebase/trigger-event/{id}/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns FirestoreTriggerEvent
   * @throws ApiError
   */
  public firebaseTriggerEventPartialUpdate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this firestore trigger event.
     */
    id: string,
    requestBody?: PatchedFirestoreTriggerEvent,
  }): CancelablePromise<FirestoreTriggerEvent> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/firebase/trigger-event/{id}/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public firebaseTriggerEventDestroy({
    id,
  }: {
    /**
     * A UUID string identifying this firestore trigger event.
     */
    id: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/firebase/trigger-event/{id}/',
      path: {
        'id': id,
      },
    });
  }

}
