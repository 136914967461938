import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDialog } from "../hooks/useDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { required, TextInput, useNotify } from "react-admin";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { DialogProps } from "../misc/DialogProps";
import { Row } from "../misc/Row";
import { useMutation, useQueryClient } from "react-query";
import { serverAPI } from "../api/server.api";
import { reportError } from "../backoffice.utils";
import { v4 as randomUuid } from "uuid";
import { DialogCloseButton } from "../misc/DialogCloseButton";

interface GiftVoucherFormValues {
  idempotencyKey: string;
  amount: 25 | 50 | 75 | 100 | 150 | 200;
  messageToStudent: string;
  noteForInstructor: string;
}

interface GiftVoucherButtonProps {
  studentId: string;
}

export function GiftVoucherButton({ studentId }: GiftVoucherButtonProps) {
  const { dialogProps, openDialog, closeDialog } = useDialog();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { mutateAsync: sendShoppingVoucher_ } = useMutation(
    (formValues: GiftVoucherFormValues) => serverAPI.sendShoppingVoucher({ studentId, ...formValues, queryClient }),
    {
      onSuccess: async () => {
        notify("Gutschein erfolgreich verschenkt.", { type: "success" });
        closeDialog();
      },
      onError: (error) => {
        reportError("Failed to send gift voucher", error);
        notify("Oje, es ist ein Fehler beim Verschenken des Gutscheins aufgetreten. Bitte wende dich an die Technik.", {
          type: "error",
        });
      },
    },
  );

  return (
    <>
      <Button startIcon={<AddIcon />} variant="outlined" onClick={openDialog}>
        Gutschein verschenken
      </Button>
      <GiftVoucherDialog {...dialogProps} onSubmit={sendShoppingVoucher_} />
    </>
  );
}

function GiftVoucherDialog(
  props: DialogProps & {
    onSubmit: (formValues: GiftVoucherFormValues) => Promise<void>;
  },
) {
  const { onSubmit, ...dialogProps } = props;
  const formProps = useForm<GiftVoucherFormValues>({
    defaultValues: {
      idempotencyKey: randomUuid(),
      amount: 50,
      messageToStudent: "",
      noteForInstructor: "",
    },
  });
  return (
    <FormProvider {...formProps}>
      <DialogTitle>Gutschein verschenken</DialogTitle>
      <DialogCloseButton onClose={dialogProps.onClose} />
      <Dialog {...dialogProps}>
        <DialogContent>
          <GiftVoucherForm />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={formProps.formState.isSubmitting}
            disabled={formProps.formState.isSubmitting}
            onClick={formProps.handleSubmit(onSubmit)}
          >
            Jetzt Gutschein verschenken
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

function GiftVoucherForm() {
  const selectedAmount = useWatch({ name: "amount" }) as GiftVoucherFormValues["amount"];
  const { setValue } = useFormContext();
  return (
    <Stack gap="5px">
      <FormControl sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }}>
        <InputLabel shrink>Wert</InputLabel>
        <Row sx={{ mt: 1.5, mb: 3 }} spacing={1}>
          {[25, 50, 75, 100, 150, 200].map((amount) => (
            <Button
              key={amount}
              variant={selectedAmount === amount ? "contained" : "outlined"}
              onClick={() => setValue("amount", amount as GiftVoucherFormValues["amount"])}
            >
              {amount} €
            </Button>
          ))}
        </Row>
      </FormControl>
      <TextInput source="messageToStudent" label="Nachricht an Fahrschüler" multiline validate={required()} />
      <TextInput source="noteForInstructor" label="Notiz für Fahrlehrer/Backoffice" multiline validate={required()} />
    </Stack>
  );
}
