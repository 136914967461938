import { Typography } from "@mui/material";
import { TheoryExamSimulationResult } from "../providers/theoryExamSimulationResultsProvider";
import { useGetManyReference, useGetRecordId } from "react-admin";
import { TheoryExamSimulationsChart } from "../details/student/TheoryExamSimulationsChart";

const chartItemCount = 10; // <-- number of most recent simulation results shown in the bar chart

export function StudentTheoryExamSimulationsField() {
  const studentId = useGetRecordId();
  const { data, total } = useGetManyReference<TheoryExamSimulationResult>("theoryExamSimulationResults", {
    target: "studentId",
    id: studentId,
    pagination: { page: 1, perPage: chartItemCount },
    sort: { field: "timestamp", order: "DESC" }, // ... so that the most recent results are on the first page
  });
  if (!data || typeof total !== "number") {
    return null;
  }
  if (total === 0) {
    return <Typography variant="body2">Noch keine Prüfungssimulation absolviert</Typography>;
  }
  return (
    <>
      <TheoryExamSimulationsChart chartItemCount={chartItemCount} data={data} />
    </>
  );
}
