import { z } from "zod";

export const CustomizationFlagEnum = z.enum([
  "asfCourses",
  "carSharing",
  "paymentAndInvoicing",
  "reports",
  "studentAdministrativeOnboarding",
  "zoomMeetings",
  "googleCalendarSync",
  "theoryLearning",
  "pickupService",
  "pickupFromHomeAddress",
  "cancellationPolicyPerLesson",
  "newStudentsCanBookDrivingLessons",
  "referralProgram",
]);
export type CustomizationFlag = z.infer<typeof CustomizationFlagEnum>;

export const ALL_CUSTOMIZATION_FLAGS = CustomizationFlagEnum.options;
