import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Stack,
  TextField as MuiTextField,
  Avatar,
  SxProps,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Student } from "../providers/studentsProvider";
import { useEffect, useState } from "react";

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Identifier, useGetManyReference } from "react-admin";

export const StudentSelect = (props: {
  inputRef?: React.Ref<HTMLInputElement>;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange: (student: Student | null) => void;
  onBlur?: () => void;
  sx?: SxProps;
  size: "small" | "medium";
  drivingSchoolId?: Identifier;
  instructorId?: Identifier;
  label: string;
  error?: false | string;
  filterStudents?: (student: Student) => boolean;
}) => {
  const { open, setOpen, value, setValue, inputValue, setInputValue, loading, options } = useAutocompleteSearch(
    props.drivingSchoolId,
    props.instructorId,
    { filterFn: props.filterStudents },
  );
  return (
    <FormControl>
      <Autocomplete
        disabled={props.disabled}
        sx={props.sx}
        size={props.size}
        blurOnSelect
        openOnFocus
        autoHighlight
        clearOnEscape
        value={value}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        loadingText="Lädt..."
        noOptionsText="Keine Ergebnisse"
        openText="Öffnen"
        clearText="Löschen"
        closeText="Schließen"
        onChange={(_, value) => {
          props.onChange(value?.student ?? null);
          return setValue(value);
        }}
        onBlur={props.onBlur}
        renderOption={(props, option, { inputValue }) => {
          // Render the results with match highlighting
          // The highlighted parts are shown in regular font weight, the rest in bold
          const matches = match(option.name, inputValue, { insideWords: true });
          const parts = parse(option.name, matches);

          return (
            <li {...props} key={option.id}>
              <Stack direction="row" spacing={1}>
                <Avatar src={option.student.avatarUrl} sx={{ height: 24, width: 24 }} />
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 400 : 700,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </Stack>
            </li>
          );
        }}
        renderInput={(params) => (
          <SearchInput autoFocus={props.autoFocus} label={props.label} error={props.error} {...params} />
        )}
      />
      {props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </FormControl>
  );
};

function SearchInput(
  props: {
    label: string;
    error?: false | string;
    autoFocus?: boolean;
  } & AutocompleteRenderInputParams,
) {
  return (
    <MuiTextField
      {...props}
      autoFocus={props.autoFocus}
      label={props.label}
      size={props.size}
      InputProps={{
        ...props.InputProps,
      }}
      error={!!props.error}
    />
  );
}
interface SearchOption {
  id: string;
  name: string;
  student: Student;
}

function useAutocompleteSearch(
  drivingSchoolId?: Identifier,
  instructorId?: Identifier,
  params?: { filterFn?: (student: Student) => boolean },
): {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: SearchOption | null;
  setValue: (value: SearchOption | null) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  loading: boolean;
  options: SearchOption[];
} {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<SearchOption | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<SearchOption[]>([]);
  const { data: students, isLoading: loading } = useGetManyReference<Student>("students", {
    target: instructorId
      ? "instructorIds"
      : drivingSchoolId
      ? "drivingSchoolId"
      : (() => {
          throw new Error("Either instructorId or drivingSchoolId must be given");
        })(),
    id: instructorId || drivingSchoolId,
    filter: { status: "active" },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 9999 },
  });

  useEffect(() => {
    if (loading || !students || students?.length === 0) {
      return;
    }
    const filteredStudents = params?.filterFn ? students.filter(params.filterFn) : students;
    setOptions(filteredStudents.map((it) => ({ id: it.id, name: it.name, student: it })));
  }, [loading]);

  return { open, setOpen, value, setValue, inputValue, setInputValue, loading, options };
}
