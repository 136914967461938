import { ReactElement } from "react";
import { MenuItem as MuiMenuItem } from "@mui/material";
import { useLinkClickHandler, useParams } from "react-router-dom";

export interface MenuTabItemProps {
  label: string;
  path: string;
  context?: "menuitem" | "content";
  closeMenu?: () => void;
  children: ReactElement;
}

export function MenuTabItem({ label, path, context, closeMenu, children }: MenuTabItemProps) {
  if (context === "menuitem") {
    return <_MenuTabItem label={label} path={path} closeMenu={closeMenu} />;
  }
  return children;
}

export type MenuTabItemElement = React.ReactElement<MenuTabItemProps, typeof MenuTabItem>;

function _MenuTabItem({ label, path, closeMenu }: { label: string; path: string; closeMenu?: () => void }) {
  const params = useParams();
  const currentPath = params["*"];
  const goToPath = useLinkClickHandler<HTMLLIElement>(path, { replace: true });
  return (
    <MuiMenuItem
      selected={currentPath === path}
      onClick={(event) => {
        closeMenu?.();
        goToPath(event);
      }}
    >
      {label}
    </MuiMenuItem>
  );
}
