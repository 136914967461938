import * as React from "react";
import { CommonInputProps, useInput } from "react-admin";
import { MuiChipsInput } from "mui-chips-input";

type ChipsInputProps = CommonInputProps & {
  knownValues: Array<string>;
};

export function ChipsInput(props: ChipsInputProps) {
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput(props);

  const { value } = field;
  if (value && !Array.isArray(value)) {
    throw new Error(`ChipsInput requires an array value but received: ${JSON.stringify(value)}`);
  }

  return (
    <MuiChipsInput
      {...field}
      label={isRequired ? props.label + "*" : props.label}
      fullWidth={props.fullWidth}
      error={(isTouched || isSubmitted) && invalid}
      helperText={error?.message}
    />
  );
}
