import { EditBase, NumberInput, SaveButton, SimpleForm, Toolbar } from "react-admin";
import { FeatureTogglesInput } from "../../inputs/FeatureTogglesInput";

export function StudentAdminForm() {
  return (
    <EditBase redirect={false}>
      <SimpleForm
        sx={{ padding: "1em 0" }}
        shouldUnregister
        warnWhenUnsavedChanges
        toolbar={
          <Toolbar style={{ padding: 0 }}>
            <SaveButton />
          </Toolbar>
        }
      >
        <NumberInput source="applicationFeePercentage" required />
        <h4 style={{ marginTop: 0 }}>Feature Toggles</h4>
        <FeatureTogglesInput />
      </SimpleForm>
    </EditBase>
  );
}
