/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinanceReport } from '../models/FinanceReport';
import type { PaginatedFinanceReportList } from '../models/PaginatedFinanceReportList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FinanceReportEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedFinanceReportList
   * @throws ApiError
   */
  public financeReportList({
    companyDrivingSchoolId,
    companyId,
    limit,
    offset,
    ordering,
    type,
  }: {
    companyDrivingSchoolId?: string,
    companyId?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string,
    /**
     * * `MONTHLY` - MONTHLY
     * * `PAYOUT` - PAYOUT
     * * `HQ` - HQ
     */
    type?: 'HQ' | 'MONTHLY' | 'PAYOUT',
  }): CancelablePromise<PaginatedFinanceReportList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/finance-report/',
      query: {
        'company__driving_school_id': companyDrivingSchoolId,
        'company__id': companyId,
        'limit': limit,
        'offset': offset,
        'ordering': ordering,
        'type': type,
      },
    });
  }

  /**
   * @returns FinanceReport
   * @throws ApiError
   */
  public financeReportRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this finance report.
     */
    id: string,
  }): CancelablePromise<FinanceReport> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/finance-report/{id}/',
      path: {
        'id': id,
      },
    });
  }

}
