import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";

export class TrackRunningQueriesWrapper<ResourceType extends string = string> implements DataProvider<ResourceType> {
  public numRunningQueries = 0;

  constructor(private wrapped: DataProvider<ResourceType>) {}

  async getList(resource: ResourceType, params: GetListParams) {
    this.numRunningQueries += 1;
    try {
      const result = await this.wrapped.getList(resource, params);
      return result;
    } finally {
      this.numRunningQueries -= 1;
    }
  }

  async getOne(resource: ResourceType, params: GetOneParams) {
    this.numRunningQueries += 1;
    try {
      const result = await this.wrapped.getOne(resource, params);
      return result;
    } finally {
      this.numRunningQueries -= 1;
    }
  }

  async getMany(resource: ResourceType, params: GetManyParams) {
    this.numRunningQueries += 1;
    try {
      const result = await this.wrapped.getMany(resource, params);
      return result;
    } finally {
      this.numRunningQueries -= 1;
    }
  }

  async getManyReference(resource: ResourceType, params: GetManyReferenceParams) {
    this.numRunningQueries += 1;
    try {
      const result = await this.wrapped.getManyReference(resource, params);
      return result;
    } finally {
      this.numRunningQueries -= 1;
    }
  }

  create(resource: ResourceType, params: CreateParams) {
    return this.wrapped.create(resource, params);
  }

  update(resource: ResourceType, params: UpdateParams) {
    return this.wrapped.update(resource, params);
  }

  updateMany(resource: ResourceType, params: UpdateManyParams) {
    return this.wrapped.updateMany(resource, params);
  }

  delete(resource: ResourceType, params: DeleteParams) {
    return this.wrapped.delete(resource, params);
  }

  deleteMany(resource: ResourceType, params: DeleteManyParams) {
    return this.wrapped.deleteMany(resource, params);
  }
}
