import FaceIcon from "@mui/icons-material/Face";
import SchoolIcon from "@mui/icons-material/School";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useEffect, useRef, useState } from "react";
import { PaginationPayload, useDataProvider, useRedirect } from "react-admin";
import { useHotkeys } from "react-hotkeys-hook";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { Instructor, isInstructor } from "../providers/instructorsProvider";
import { Student, isStudent } from "../providers/studentsProvider";
import { autovioColors } from "./backofficeTheme";

export default function GlobalSearchBox() {
  const { open, setOpen, value, setValue, inputValue, setInputValue, loading, options } = useAutocompleteSearch();
  const redirect = useRedirect();

  return (
    <Autocomplete
      openOnFocus
      blurOnSelect
      autoHighlight
      clearOnEscape
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      loadingText="Lädt..."
      noOptionsText="Keine Ergebnisse"
      openText="Öffnen"
      clearText="Löschen"
      closeText="Schließen"
      groupBy={(option) => option.group}
      onChange={(_, value, reason) => {
        if (value && reason === "selectOption") {
          redirect("edit", value.resource, value.id);
          setValue(null);
          setInputValue("");
        } else {
          setValue(value);
        }
      }}
      renderOption={(props, option, { inputValue }) => {
        // Render the results with match highlighting
        // The highlighted parts are shown in regular font weight, the rest in bold
        const matches = match(option.name, inputValue, { insideWords: true });
        const parts = parse(option.name, matches);

        const icon =
          option.group === "Fahrschulen" ? (
            <SchoolIcon htmlColor={autovioColors.grey} />
          ) : (
            <FaceIcon htmlColor={autovioColors.grey} />
          );
        return (
          <li {...props} key={option.id}>
            <Stack direction="row" spacing={1}>
              {icon}
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 400 : 700,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </Stack>
          </li>
        );
      }}
      renderInput={(params) => <SearchInput {...params} loading={loading} />}
    />
  );
}

function SearchInput(props: AutocompleteRenderInputParams & { loading: boolean }) {
  const { loading, ...params } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const userAgent = navigator.userAgent;
  const isMacOS = /Mac OS X/.test(userAgent);
  useHotkeys("ctrl+f", () => inputRef.current?.focus(), { preventDefault: true });
  useHotkeys("esc", () => inputRef.current?.blur(), { enableOnFormTags: true });
  const { greyUltraLight, greyLight, grey } = autovioColors;
  const searchFieldPlaceholder = (
    <InputAdornment position="start" sx={{ color: grey, paddingLeft: 1 }}>
      Suche{" "}
      <Chip
        sx={{
          background: greyUltraLight,
          color: greyLight,
          marginLeft: 1,
          fontWeight: "bold",
          fontSize: 10,
          height: 25,
        }}
        label={isMacOS ? "CTRL + F" : "STRG + F"}
      />
    </InputAdornment>
  );
  const [placeholder, setPlaceholder] = useState<JSX.Element | string>(searchFieldPlaceholder);

  const handleFocus = () => {
    setPlaceholder("");
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setPlaceholder(searchFieldPlaceholder);
    }
  };

  return (
    <TextField
      {...params}
      sx={{
        border: "1px solid #d8d8d8 !important",
        borderRadius: 8,
        "& .MuiInputBase-input::placeholder": {
          color: "transparent",
        },
        ":hover": {
          border: "1px solid #9b9b9b !important",
        },
      }}
      label=""
      placeholder="Suche"
      inputRef={inputRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      size="small"
      InputProps={{
        ...params.InputProps,
        startAdornment: <>{placeholder}</>,
        endAdornment: (
          <>
            {loading ? (
              <InputAdornment position="end">
                <CircularProgress color="inherit" size={20} sx={{ marginRight: -40 }} />
              </InputAdornment>
            ) : (
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    backgroundColor: autovioColors.green,
                    color: autovioColors.white,
                    height: 32,
                    width: 32,
                    marginRight: -40,
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
}

function useAutocompleteSearch(): {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: SearchOption | null;
  setValue: (value: SearchOption | null) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  loading: boolean;
  options: SearchOption[];
} {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<SearchOption | null>(null);
  const [inputValue, setInputValue] = useState("");

  const [options, setOptions] = useState<SearchOption[]>([]);
  const loading = open && options.length === 0;

  const dataProvider = useDataProvider();

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    void (async () => {
      const [students, instructors, drivingSchools] = await Promise.all([
        dataProvider.getList<Student>("students", {
          pagination: {} as PaginationPayload,
          sort: { field: "name", order: "ASC" },
          filter: {},
        }),
        dataProvider.getList<Instructor>("instructors", {
          pagination: {} as PaginationPayload,
          sort: { field: "name", order: "ASC" },
          filter: {},
        }),
        dataProvider.getList<DrivingSchool>("drivingSchools", {
          pagination: {} as PaginationPayload,
          sort: { field: "name", order: "ASC" },
          filter: {},
        }),
      ]);

      if (active) {
        setOptions([...students.data, ...instructors.data, ...drivingSchools.data].map(_recordToOption));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return { open, setOpen, value, setValue, inputValue, setInputValue, loading, options };
}

interface SearchOption {
  id: string;
  resource: string;
  name: string;
  group: string;
}

function _recordToOption(record: Student | Instructor | DrivingSchool): SearchOption {
  let resource: string;
  let group: string;
  if (isStudent(record)) {
    resource = "students";
    group = "Fahrschüler";
  } else if (isInstructor(record)) {
    resource = "instructors";
    group = "Fahrlehrer";
  } else {
    resource = "drivingSchools";
    group = "Fahrschulen";
  }

  return { id: record.id.toString(), resource, name: record.name as string, group };
}
