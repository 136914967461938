import { Card } from "@mui/material";
import { DateTime } from "luxon";
import { autovioColors } from "../../misc/backofficeTheme";
import { TheoryExamSimulationResult } from "../../providers/theoryExamSimulationResultsProvider";
import { useRecharts } from "../../hooks/useRecharts";

const errorPointsCap = 20; // <-- don't show bars higher than 20 error points

type DataPoint = {
  green: number;
  red: number;
} & ({ timestamp: DateTime; errorPoints: number; noData?: never } | { noData: true });

/**
 * To give the bar either green or red color, we use a stacked bar char:
 * - if the simulation result is passed, the property `green` of the DataPoint
 *   is set to the number of error points and the property `red` is set to 0
 *   => this will show a green bar,
 * - if the simulation result is failed, the property `green` of the DataPoint
 *   is set to 0 and the property `red` is set to the number of error points
 *   => this will show a red bar,
 */
export function TheoryExamSimulationsChart({
  chartItemCount,
  data,
}: {
  chartItemCount: number;
  data: Array<TheoryExamSimulationResult>;
}) {
  const dataPoints: Array<DataPoint> = data
    .sort((a, b) => a.timestamp.toMillis() - b.timestamp.toMillis())
    .map(({ passed, errorPoints, timestamp }) => ({
      green: passed ? (errorPoints === 0 ? 0.3333 /* <-- to show a small green line */ : errorPoints) : 0,
      red: passed ? 0 : Math.min(errorPoints, errorPointsCap),
      timestamp,
      errorPoints,
    }));
  if (dataPoints.length < chartItemCount) {
    for (let i = dataPoints.length; i < chartItemCount; ++i) {
      dataPoints.push({ noData: true, green: 0, red: 0 });
    }
  }
  const { Bar, BarChart, Tooltip, XAxis, YAxis } = useRecharts();
  if (!(Bar && BarChart && Tooltip && XAxis && YAxis)) {
    return null;
  }

  return (
    <BarChart width={350} height={200} data={dataPoints}>
      <XAxis dataKey="none" label={{ value: "Fehlerpunkte: Prüfungssimulationen" }} />
      <Bar stackId="errorPoints" dataKey="green" fill={autovioColors.green} radius={[5, 5, 0, 0]} />
      <Bar stackId="errorPoints" dataKey="red" fill={autovioColors.red} radius={[5, 5, 0, 0]} />
      <Tooltip
        content={({ active, payload }) => {
          if (!active) {
            return null;
          }
          const dataPoint = (payload as Array<{ payload: DataPoint }>)[0].payload;
          if (dataPoint.noData) {
            return null;
          }
          const { timestamp, errorPoints } = dataPoint;
          return (
            <Card style={{ padding: "5px" }}>
              {timestamp.toFormat("dd.MM.yyyy • HH:mm")}
              <br />
              {`${errorPoints}\u00a0${errorPoints === 1 ? "Fehlerpunkt" : "Fehlerpunkte"}`}
            </Card>
          );
        }}
      />
    </BarChart>
  );
}
