import * as React from "react";
import { SvgIcon } from "@mui/material";

export function InstructorIcon() {
  return (
    <SvgIcon>
      <path d="M19.2439024,16.5 C20.2137683,16.5 21,15.6941125 21,14.7 L21,4.8 C21,3.80588745 20.2137683,3 19.2439024,3 L9.98926829,3 C10.2965854,3.549 10.4634146,4.17 10.4634146,4.8 L19.2439024,4.8 L19.2439024,14.7 L11.3414634,14.7 L11.3414634,16.5 M14.8536585,7.5 L14.8536585,9.3 L9.58536585,9.3 L9.58536585,21 L7.82926829,21 L7.82926829,15.6 L6.07317073,15.6 L6.07317073,21 L4.31707317,21 L4.31707317,13.8 L3,13.8 L3,9.3 C3,8.30588745 3.78623166,7.5 4.75609756,7.5 L14.8536585,7.5 M8.70731707,4.8 C8.70731707,5.79411255 7.92108541,6.6 6.95121951,6.6 C5.98135361,6.6 5.19512195,5.79411255 5.19512195,4.8 C5.19512195,3.80588745 5.98135361,3 6.95121951,3 C7.92108541,3 8.70731707,3.80588745 8.70731707,4.8 Z" />
    </SvgIcon>
  );
}
