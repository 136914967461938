import { DateTime } from "luxon";
import { GetListParams, GetListResult, type GetManyParams, type GetManyResult } from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import type { TaxConsultantExport } from "../generated/backendClient";
import { AbstractProvider } from "./abstractProvider";

export interface TaxConsultantExportRecord {
  id: string;
  drivingSchoolId: string;
  zip: string | null | undefined;
  startDate: DateTime;
  endDate: DateTime;
  serviceTime: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export function parseDate(isoDate: string) {
  return DateTime.fromISO(isoDate, { zone: "utc" });
}

function _exportToExportRecord(taxExport: TaxConsultantExport): TaxConsultantExportRecord {
  if (!taxExport.id) {
    throw new Error("DATEVExport has no ID");
  }
  if (!taxExport.start_date || !taxExport.end_date || !taxExport.service_time) {
    throw new Error("DATEVExport has no start_date, end_date or service_time");
  }
  return {
    id: taxExport.id,
    drivingSchoolId: taxExport.driving_school_id,
    startDate: taxExport.start_date ? parseDate(taxExport.start_date) : DateTime.fromISO("1970-01-01T00:00:00Z"),
    endDate: taxExport.end_date ? parseDate(taxExport.end_date) : DateTime.fromISO("1970-01-01T00:00:00Z"),
    updatedAt: DateTime.fromISO(taxExport.updated_at),
    createdAt: DateTime.fromISO(taxExport.created_at),
    serviceTime: taxExport.service_time,
    zip: taxExport.zip,
  };
}

class TaxConsultantExportsProvider extends AbstractProvider<TaxConsultantExportRecord> {
  async getList(
    resource: string,
    { filter, sort, pagination }: GetListParams,
  ): Promise<GetListResult<TaxConsultantExportRecord>> {
    const backendClient = await getAuthenticatedBackendClient();
    const { drivingSchoolId, ...clientSideFilter } = filter;
    if (!drivingSchoolId) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"drivingSchoolId": ..., ...}`);
    }
    const { results } = await backendClient.taxConsultantExports.taxConsultantExportsList({
      companyDrivingSchoolId: drivingSchoolId,
    });
    const records = results.map(_exportToExportRecord);
    return applyPagination(applySort(applyFilter(records, clientSideFilter), sort), pagination);
  }

  async getMany(resource: string, { ids }: GetManyParams): Promise<GetManyResult<TaxConsultantExportRecord>> {
    const results = await Promise.all(ids.map(async (id) => (await this.getOne(resource, { id: id as string })).data));
    return { data: results };
  }

  async getOne(resource: string, { id }: { id: string }): Promise<{ data: TaxConsultantExportRecord }> {
    const backendClient = await getAuthenticatedBackendClient();
    const datevExport = await backendClient.taxConsultantExports.taxConsultantExportsRetrieve({ id });
    const record = _exportToExportRecord(datevExport);
    return { data: record };
  }
}

export const taxConsultantExportsProvider = new TaxConsultantExportsProvider();
