import { IconButton } from "@mui/material";
import Icon from "@mui/icons-material/RemoveRedEye";
import { autovioColors } from "../misc/backofficeTheme";
import { useRecordContext } from "react-admin";

export function ShowIconButton() {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <IconButton>
      <Icon sx={{ fill: autovioColors.green }} />
    </IconButton>
  );
}
