export function AutovioLogoIcon() {
  return (
    <svg width="20px" height="20px" viewBox="16 16 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M36.5,16 L48.1,48 L39.8,48 L38.4,43.8 C38.3,43.4 38,43.1 37.6,42.9 C37.2,42.6 36.7,42.4 36,42.3 L36.1,42.3 L27.8,42.3 L27.6,42.4 C27.1,42.5 26.6,42.7 26.3,42.9 C26,43.1 25.7,43.3 25.5,43.6 L24.1,48 L15.9,48 L27.5,16 L36.5,16 Z M32.1,30.2 C29.8,30.2 27.9,32 27.9,34.3 C27.9,36.5 29.8,38.4 32.1,38.4 C34.3,38.4 36.2,36.5 36.2,34.3 C36.2,32 34.3,30.2 32.1,30.2 Z"
          fill="#ffffff"
        />
      </g>
    </svg>
  );
}
