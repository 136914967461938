import { collection, getDocsFromServer, onSnapshot, Unsubscribe } from "firebase/firestore";
import {
  CreateParams,
  CreateResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { AbstractProvider } from "./abstractProvider";
import { Note, NoteSchema } from "../model/Note";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { createNote, editNote } from "../api/backoffice.api";
import { t } from "../model/types";

class StudentNotesProvider extends AbstractProvider<Note> {
  private _snapshotPromiseStudentUid: Identifier | undefined;
  private _snapshotPromise: Promise<Array<Note>> | undefined;
  private _unsubscribe: Unsubscribe | undefined;
  private readonly _onUpdateListeners: Array<() => void> = [];

  async getOne(_: string, { id }: GetOneParams<Note>): Promise<GetOneResult<Note>> {
    const notes = await this._snapshotPromise!;
    const note = notes.find((it) => it.id === id);
    if (!note) {
      throw new Error(`Could not find note ${JSON.stringify(id)}`);
    }
    return { data: note };
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Note>> {
    if (target !== "studentUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentUid"`);
    }
    const notes = await this.fetchIfNeeded(id);
    return applyPagination(applySort(applyFilter(notes, filter), sort), pagination);
  }

  async update(_: string, { data }: UpdateParams<Note>): Promise<UpdateResult<Note>> {
    const { id, studentUid, body } = t
      .object({
        id: t.uid(),
        studentUid: t.uid(),
        body: t.string(),
      })
      .parse(data);
    await editNote({ studentUid, noteUid: id, body });
    const notes = await this.fetch(studentUid);
    const note = notes.find((it) => it.id === id)!;
    return { data: note };
  }

  async create(_: string, { data }: CreateParams<{ studentUid: string; body: string }>): Promise<CreateResult<Note>> {
    const { studentUid, body } = data;
    if (typeof studentUid !== "string") throw new Error("Property studentUid must be provided.");
    if (!studentUid) throw new Error("Property studentUid must not be empty.");
    if (typeof body !== "string") throw new Error("Property body must be provided.");
    const knownIds = new Set((await this.fetchIfNeeded(studentUid)).map((note) => note.id));
    await createNote({ studentUid, body });
    const notes = await this.fetch(studentUid);
    const newNote = notes.find((it) => !knownIds.has(it.id))!;
    return { data: newNote };
  }

  fetchIfNeeded(studentUid: Identifier): Promise<Array<Note>> {
    if (this._snapshotPromiseStudentUid === studentUid) {
      return this._snapshotPromise!;
    }
    return this.fetch(studentUid);
  }

  fetch(studentUid: Identifier): Promise<Array<Note>> {
    this._unsubscribe?.();
    this._unsubscribe = undefined;
    this._snapshotPromiseStudentUid = studentUid;
    this._snapshotPromise = getDocsFromServer(collection(firestore, `/users/${studentUid}/notes`)).then(
      async (snapshot) => {
        let notes = snapshot.docs.map((doc) => NoteSchema.parse({ studentUid, ...doc.data() }));
        console.info(`Retrieved ${notes.length} note(s) for student ${studentUid}`);
        this._unsubscribe = onSnapshot(collection(firestore, `/users/${studentUid}/notes`), async (snapshot) => {
          const updatedNotes = await Promise.all(
            snapshot.docs.map((doc) => NoteSchema.parse({ studentUid, ...doc.data() })),
          );
          // Ignore incomplete snapshots by checking their length ...
          if (updatedNotes.length < notes.length) {
            return;
          }
          notes = updatedNotes;
          this._snapshotPromise = Promise.resolve(notes);
          console.info(`Retrieved new snapshot with ${notes.length} note(s) for student ${studentUid}`);
          for (const listener of this._onUpdateListeners) {
            try {
              listener();
            } catch (error) {
              console.error("listener failed", error);
            }
          }
        });
        return notes;
      },
    );
    return this._snapshotPromise!;
  }

  onUpdate(listener: () => any): () => void {
    if (this._onUpdateListeners.indexOf(listener) >= 0) {
      throw new Error("listener already registered");
    }
    this._onUpdateListeners.push(listener);
    return () => {
      const i = this._onUpdateListeners.indexOf(listener);
      if (i < 0) {
        throw new Error("listener already unregistered");
      }
      this._onUpdateListeners.splice(i, 1);
    };
  }
}

export const studentNotesProvider = new StudentNotesProvider();
