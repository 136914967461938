import { deleteField, FieldValue } from "firebase/firestore";

export function updateApplied(updateData: any, data: any): boolean {
  if (updateData === data) {
    return true;
  }
  if (Array.isArray(updateData)) {
    if (!Array.isArray(data) || updateData.length !== data.length) {
      return false;
    }
    return updateData.every((value, index) => updateApplied(value, data[index]));
  }
  if (updateData && typeof updateData === "object") {
    if (!data || typeof data !== "object") {
      return false;
    }
    return Object.entries(updateData).every(([key, value]) => {
      if (value instanceof FieldValue) {
        if (value.isEqual(deleteField())) {
          return Object.keys(data).indexOf(key) < 0;
        } else {
          throw new Error(`Unsupported FieldValue: ${(value as any)._methodName}()`);
        }
      }
      return updateApplied(value, data[key]);
    });
  }
  return false;
}
