/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePerformanceOverviewReport } from '../models/CreatePerformanceOverviewReport';
import type { PaginatedPerformanceOverviewReportList } from '../models/PaginatedPerformanceOverviewReportList';
import type { PerformanceOverviewReport } from '../models/PerformanceOverviewReport';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PerformanceOverviewReportEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedPerformanceOverviewReportList
   * @throws ApiError
   */
  public performanceOverviewReportList({
    companyDrivingSchoolId,
    limit,
    offset,
    ordering,
  }: {
    companyDrivingSchoolId?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string,
  }): CancelablePromise<PaginatedPerformanceOverviewReportList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/performance-overview-report/',
      query: {
        'company__driving_school_id': companyDrivingSchoolId,
        'limit': limit,
        'offset': offset,
        'ordering': ordering,
      },
    });
  }

  /**
   * @returns PerformanceOverviewReport
   * @throws ApiError
   */
  public performanceOverviewReportRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this performance overview report.
     */
    id: string,
  }): CancelablePromise<PerformanceOverviewReport> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/performance-overview-report/{id}/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @returns PerformanceOverviewReport
   * @throws ApiError
   */
  public performanceOverviewReportExportCreate({
    requestBody,
  }: {
    requestBody: CreatePerformanceOverviewReport,
  }): CancelablePromise<PerformanceOverviewReport> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/performance-overview-report/export/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
