import { useGetOne, useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { Typography } from "@mui/material";
import { BooleanInput } from "./BooleanInput";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";

export function DrivingSchoolChangeInput({ submit }: { submit: (formData: { [k: string]: any }) => void }) {
  const student = useRecordContext<Student>();
  const { data: drivingSchool } = useGetOne<DrivingSchool>(
    "drivingSchools",
    { id: student.drivingSchoolId },
    { enabled: !!student.drivingSchoolId },
  );

  if (!student || !drivingSchool) {
    return null;
  }

  if (!student.isSchoolChanger) {
    return (
      <Typography component="span" variant="body2">
        Nein
      </Typography>
    );
  }

  const theoryLearningEnabled = drivingSchool.customizations.includes("theoryLearning");

  return (
    <div>
      <Typography component="span" variant="body2">
        Ja
      </Typography>
      <BooleanInput
        source="featureToggles.canLearnTheoryInMobileApp"
        label="kann in der App Theorie lernen"
        helperText={false}
        size="small"
        sx={{ marginLeft: "1em" }}
        className="whitespace-nowrap"
        disabled={!theoryLearningEnabled}
        onChange={(event) => {
          if (event.target.checked) {
            // UX: if canLearnTheoryInMobileApp is toggled from false to true,
            //     we also set canBookTheoryLessons: true and hasPassedTheoryExam: false ...
            submit({
              featureToggles: {
                canLearnTheoryInMobileApp: true,
                canBookTheoryLessons: true,
              },
              theoryKPIs: { hasPassedTheoryExam: false },
            });
          } else {
            submit({ featureToggles: { canLearnTheoryInMobileApp: false } });
          }
        }}
      />
      <BooleanInput
        source="featureToggles.canBookTheoryLessons"
        label="kann Theorieunterricht buchen"
        helperText={false}
        size="small"
        sx={{ marginLeft: "1em" }}
        className="whitespace-nowrap"
        onChange={(event) => {
          if (event.target.checked) {
            // UX: if canBookTheoryLessons is toggled from false to true,
            //     we also set canLearnTheoryInMobileApp: true and hasPassedTheoryExam: false ...
            submit({
              featureToggles: {
                canLearnTheoryInMobileApp: true,
                canBookTheoryLessons: true,
              },
              theoryKPIs: { hasPassedTheoryExam: false },
            });
          } else {
            submit({ featureToggles: { canBookTheoryLessons: false } });
          }
        }}
      />
      <BooleanInput
        source="theoryKPIs.hasPassedTheoryExam"
        label="hat Theorieprüfung bestanden"
        helperText={false}
        size="small"
        sx={{ marginLeft: "1em" }}
        className="whitespace-nowrap"
        onChange={(event) => {
          if (event.target.checked) {
            submit({ theoryKPIs: { hasPassedTheoryExam: true } });
          } else {
            // UX: if hasPassedTheoryExam is toggled from true to false,
            //     we also set canLearnTheoryInMobileApp: true and canBookTheoryLessons: true ...
            submit({
              featureToggles: {
                canLearnTheoryInMobileApp: true,
                canBookTheoryLessons: true,
              },
              theoryKPIs: { hasPassedTheoryExam: false },
            });
          }
        }}
      />
    </div>
  );
}
