import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { applyFilter, applySort } from "../backoffice.utils";
import { firestore } from "../firebase";
import { ExamLocation, ExamLocationSchema } from "../model/ExamLocation";

class ExamLocationsProvider {
  getList(_resource: string, _params: GetListParams): Promise<GetListResult<ExamLocation>> {
    throw new Error("not implemented");
  }

  async getOne(_resource: string, { id }: GetOneParams<ExamLocation>): Promise<GetOneResult<ExamLocation>> {
    const data = (await getDoc(doc(firestore, id))).data();
    if (!data) {
      throw new Error(`Firestore document ${id} not found`);
    }
    return { data: { id, ...ExamLocationSchema.parse(data) } };
  }

  getMany(_resource: string, _params: GetManyParams): Promise<GetManyResult<ExamLocation>> {
    throw new Error("not implemented");
  }

  async getManyReference(
    _resource: string,
    { target, id, filter, sort }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<ExamLocation>> {
    if (target !== "drivingSchoolId") {
      throw new Error(`Unexpected target: ${target}`);
    }
    const snapshot = await getDocs(collection(firestore, `/driving_schools/${id}/exam_locations`));
    const examLocations: Array<ExamLocation> = [];
    for (const doc of snapshot.docs) {
      try {
        examLocations.push({
          id: doc.ref.path,
          ...ExamLocationSchema.parse(doc.data()),
        });
      } catch (error) {
        console.error(`Failed to parse Firestore document ${doc.ref.path}: ${error}`);
      }
    }
    return { data: applySort(applyFilter(examLocations, filter), sort), total: examLocations.length };
  }

  update(_: string, _params: UpdateParams<ExamLocation>): Promise<UpdateResult<ExamLocation>> {
    throw new Error("not implemented");
  }

  updateMany(_: string, _params: UpdateManyParams<ExamLocation>): Promise<UpdateManyResult<ExamLocation>> {
    throw new Error("not implemented");
  }

  create(_: string, _params: CreateParams<ExamLocation>): Promise<CreateResult<ExamLocation>> {
    throw new Error("not implemented");
  }

  delete(_: string, _params: DeleteParams<ExamLocation>): Promise<DeleteResult<ExamLocation>> {
    throw new Error("not implemented");
  }

  deleteMany(_: string, _params: DeleteManyParams<ExamLocation>): Promise<DeleteManyResult<ExamLocation>> {
    throw new Error("not implemented");
  }
}

export const examLocationsProvider = new ExamLocationsProvider();
