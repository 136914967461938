import { useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { Typography } from "@mui/material";
import { calculateTheoryLearningProgress } from "../backoffice.utils";

export function StudentTheoryLearningProgressField() {
  const student = useRecordContext<Student>();
  const theoryLearningProgress = calculateTheoryLearningProgress(student?.theoryKPIs?.theoryLearningProgress);
  if (!theoryLearningProgress) {
    return <Typography variant="body2">unbekannt</Typography>;
  }

  return <Typography variant="body2">{theoryLearningProgress}%</Typography>;
}
