import { Tooltip } from "@mui/material";
import { DeleteWithConfirmButton, useRecordContext } from "react-admin";
import { UserToBeDeleted } from "../providers/usersToBeDeletedProvider";

export const DeleteUserButton = () => {
  const user = useRecordContext<UserToBeDeleted>();
  const canBeDeleted = user.forbiddenProperties.length === 0;
  return (
    <Tooltip
      title={
        canBeDeleted ? undefined : (
          <>
            User kann nicht gelöscht werden, da folgende Felder gesetzt sind:
            <ul>
              {user.forbiddenProperties.map((property) => (
                <li key={property}>{property}</li>
              ))}
            </ul>
          </>
        )
      }
    >
      <div>
        <DeleteWithConfirmButton
          confirmTitle="User löschen?"
          confirmContent="Willst du wirklich den User und die dazugehörigen Daten löschen?"
          disabled={!canBeDeleted}
        />
      </div>
    </Tooltip>
  );
};
