import { BookedQuote } from "../providers/bookedQuotesProvider";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Bundle, Product } from "../providers/catalogProvider";
import { Money } from "./Money";

export function PricesOverview({ bookedQuote, newBundle }: { bookedQuote: BookedQuote; newBundle?: Bundle }) {
  const oldPrices = Object.fromEntries(
    Object.entries(bookedQuote.prices).map(([label, cents]) => [
      label === "Grundgebühr" ? "Grundbetrag" : label,
      <Money cents={cents} />,
    ]),
  );
  let labels = Object.keys(oldPrices);
  let newPrices: undefined | Record</* label: */ string, any>;
  if (newBundle) {
    newPrices = {
      Grundbetrag: <Money cents={newBundle.baseFee * 100} />,
    };
    if (newBundle.teachingMaterialFee) {
      newPrices["Lehrmittelgebühr"] = <Money cents={newBundle.teachingMaterialFee * 100} />;
    }
    const products = productsOf(newBundle);
    for (const [id, product] of products.entries()) {
      // This is the same code used in the bookedTrainingPrices function in microservices/.../data-sync.service.ts
      let label = product.name;
      if (
        product.type === "Übungsstunde" ||
        product.type === "Autobahnfahrt" ||
        product.type === "Nachtfahrt" ||
        product.type === "Überlandfahrt"
      ) {
        label += " (45 min)";
      }
      const price = newBundle.productPrices[id];
      newPrices[label] = <Money cents={price * 100} />;
    }
    for (const [productId, price] of Object.entries(newBundle.authorityFees)) {
      const product = products.get(productId);
      if (product) {
        // This is the same code used in the bookedTrainingPrices function in microservices/.../data-sync.service.ts
        const label = `Prüfungsgebühr (TÜV/DEKRA) für ${product.type}`;
        newPrices[label] = <Money cents={price * 100} />;
      }
    }
    labels = [...new Set([...labels, ...Object.keys(newPrices)])];
  }
  // "Grundbetrag" should be the first label ...
  {
    const i = labels.indexOf("Grundbetrag");
    if (i > 0) {
      labels.splice(0, 0, ...labels.splice(i, 1));
    }
  }
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">{newPrices ? "alter\u00A0Preis" : "Preis"}</TableCell>
              {newPrices && <TableCell align="right">{"neuer\u00A0Preis"}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {labels.map((label) => (
              <TableRow key={label}>
                <TableCell>{label}</TableCell>
                <TableCell align="right">{oldPrices[label] ?? "---"}</TableCell>
                {newPrices && <TableCell align="right">{newPrices[label] ?? "---"}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function productsOf(bundle: Bundle): Map<string, Product> {
  const result = new Map<string, Product>();
  for (const training of bundle.trainings) {
    for (const product of training.products) {
      result.set(product.id, product);
    }
  }
  for (const product of bundle.products) {
    result.set(product.id, product);
  }
  return result;
}
