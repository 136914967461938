import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Link,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEditContext, useNotify, useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { useState } from "react";
import { reportError } from "../backoffice.utils";
import { setStudentStatus } from "../api/backoffice.api";
import type { StudentStatus } from "../model/StudentStatus";
import { grants } from "../backoffice.access_control";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { useQueryClient } from "react-query";
import { useDialog } from "../hooks/useDialog";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export function ChangeStatusLink({ label }: { label: string }) {
  const student = useRecordContext<Student>();
  const { dialogProps, openDialog } = useDialog();

  if (!student) {
    return null;
  }

  return (
    <>
      <Link component="button" variant="caption" underline="none" onClick={openDialog}>
        {label}
      </Link>
      <ChangeStatusDialog student={student} {...dialogProps} />
    </>
  );
}

interface ChangeStatusDialogProps {
  open: boolean;
  student: Student;
  onClose: () => void;
}

function ChangeStatusDialog({ open, student, onClose: onClose_ }: ChangeStatusDialogProps) {
  // The status "completed" is not included here, because this is a derived state, which is not stored
  // in Firestore but calculated by the studentProvider.
  const [newStatus, setNewStatus] = useState<Exclude<StudentStatus, "completed"> | null>(null);
  const [reason, setReason] = useState<string>("");
  const [highlightError, setHighlightError] = useState(false);
  const [saving, setSaving] = useState(false);
  const notify = useNotify();
  const showErrorToast = (message: string) => notify(message, { type: "error" });
  const { refetch } = useEditContext();
  const queryClient = useQueryClient();

  const onClose = () => {
    setNewStatus(null);
    setReason("");
    setHighlightError(false);
    setSaving(false);
    onClose_();
  };

  const save = async () => {
    setHighlightError(true);
    if (student.status === "completed") {
      if (newStatus === "active") {
        showErrorToast(
          'Der Status eines Fahrschülers, der alle Fahrprüfungen bestanden hat, kann nicht auf "Aktiv" gesetzt werden.',
        );
        return;
      } else if (newStatus === "cancelled") {
        showErrorToast(
          'Der Status eines Fahrschülers, der alle Fahrprüfungen bestanden hat, kann nicht auf "Abgebrochen" gesetzt werden.',
        );
        return;
      } else if (newStatus === "onHold") {
        showErrorToast(
          'Der Status eines Fahrschülers, der alle Fahrprüfungen bestanden hat, kann nicht auf "Pausiert" gesetzt werden.',
        );
        return;
      }
    }
    if (student.status === "outstandingPayments" && !grants.includes("activateStudentWithOutstandingPayments")) {
      showErrorToast("Du bist nicht berechtigt, den Status eines Fahrschülers mit ausstehenden Zahlungen zu ändern.");
      return;
    }
    if (newStatus === "outstandingPayments" && !grants.includes("blockStudent")) {
      showErrorToast('Du bist nicht berechtigt, den Status eines Fahrschülers auf "Offene Zahlungen" zu ändern.');
      return;
    }
    if (!reason.trim()) {
      showErrorToast("Bitte gib noch den Grund für die Statusänderung an.");
      return;
    }
    setSaving(true);
    try {
      await setStudentStatus(student, newStatus!, reason);
      notify(`Status von ${student.name} erfolgreich geändert.`, { type: "success" });
      await queryClient.invalidateQueries(["students"]);
      await queryClient.invalidateQueries(["studentNotes"]);
      if (refetch) {
        await refetch();
      }
    } catch (error) {
      reportError(`Failed to change status of student ${student.id}`, error);
      notify(`Fehler beim Ändern des Status von ${student.name}`, { type: "error" });
    } finally {
      setSaving(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {!saving && `Status von ${student.name ?? "..."} ändern auf:`}
        {saving && `Status von ${student.name} wird geändert ...`}
      </DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent>
        {saving && <LinearProgress />}
        {!saving && (
          <List>
            {student.status !== "active" && (
              <ListItemButton selected={newStatus === "active"} onClick={() => setNewStatus("active")}>
                <ListItemText>Aktiv (active)</ListItemText>
              </ListItemButton>
            )}
            {student.status !== "cancelled" && (
              <ListItemButton selected={newStatus === "cancelled"} onClick={() => setNewStatus("cancelled")}>
                <ListItemText>Abgebrochen (cancelled)</ListItemText>
              </ListItemButton>
            )}
            {student.status !== "inactive" && (
              <ListItemButton selected={newStatus === "inactive"} onClick={() => setNewStatus("inactive")}>
                <ListItemText>Inaktiv (inactive)</ListItemText>
              </ListItemButton>
            )}
            {student.status !== "onHold" && (
              <ListItemButton selected={newStatus === "onHold"} onClick={() => setNewStatus("onHold")}>
                <ListItemText>Pausiert (onHold)</ListItemText>
              </ListItemButton>
            )}
            {student.status !== "outstandingPayments" && grants.includes("blockStudent") && (
              <ListItemButton
                selected={newStatus === "outstandingPayments"}
                onClick={() => setNewStatus("outstandingPayments")}
              >
                <ListItemText>Offene Zahlungen (outstandingPayments)</ListItemText>
              </ListItemButton>
            )}
            <div style={{ height: "16px" }} />
            {newStatus && (
              <TextField
                type="text"
                label="Grund"
                fullWidth
                autoFocus
                error={highlightError && !reason.trim()}
                onChange={(event) => setReason(event.target.value)}
              />
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          loading={saving}
          disabled={!newStatus}
          startIcon={<SaveIcon />}
          onClick={save}
        >
          Speichern
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
