import { Autocomplete, AutocompleteRenderInputParams, TextField as MuiTextField, SxProps, Box } from "@mui/material";
import { Student } from "../providers/studentsProvider";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { useEffect, useState } from "react";
import { autovioColors } from "../misc/backofficeTheme";

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Identifier, useGetManyReference, useGetOne } from "react-admin";
import { PostalAddress } from "../model/PostalAddress";
import { InstructorFavoriteLocation } from "../providers/instructorFavoriteLocationsProvider";

export const DrivingLessonStartLocationSelect = (props: {
  onChange: (startLocation: PostalAddress | null) => void;
  sx?: SxProps;
  studentId: Identifier;
  instructorId: Identifier;
  drivingSchoolId: Identifier;
}) => {
  const { open, setOpen, value, setValue, inputValue, setInputValue, loading, options } = useAutocompleteSearch(
    props.studentId,
    props.instructorId,
    props.drivingSchoolId,
    props.onChange,
  );
  return (
    <Autocomplete
      sx={props.sx}
      blurOnSelect
      openOnFocus
      autoHighlight
      clearOnEscape
      defaultValue={value}
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.displayString}
      options={options}
      loading={loading}
      loadingText="Lädt..."
      noOptionsText="Keine Ergebnisse"
      openText="Öffnen"
      clearText="Löschen"
      closeText="Schließen"
      onChange={(_, value) => {
        props.onChange(value?.postalAddress ?? null);
        return setValue(value);
      }}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.displayString, inputValue, { insideWords: true });
        const parts = parse(option.displayString, matches);

        return (
          <li {...props} key={option.displayString}>
            <Box>
              <Box sx={{ color: autovioColors.grey, fontSize: "10px" }}>{option.name}</Box>
              <Box>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 400 : 700,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Box>
            </Box>
          </li>
        );
      }}
      renderInput={(params) => <SearchInput {...params} />}
    />
  );
};

function SearchInput(props: AutocompleteRenderInputParams) {
  return (
    <MuiTextField
      {...props}
      label="Startpunkt *"
      size="small"
      InputProps={{
        ...props.InputProps,
      }}
    />
  );
}

interface SearchOption {
  name: string;
  displayString: string;
  postalAddress: PostalAddress;
}

function useAutocompleteSearch(
  studentId: Identifier,
  instructorId: Identifier,
  drivingSchoolId: Identifier,
  onChange: (startLocation: PostalAddress | null) => void,
): {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: SearchOption | null;
  setValue: (value: SearchOption | null) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  loading: boolean;
  options: SearchOption[];
} {
  const [open, setOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [value, setValue] = useState<SearchOption | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [lastStudentId, setLastStudentId] = useState<Identifier | null>(null);

  const [options, setOptions] = useState<SearchOption[]>([]);

  const { data: student, isLoading: isLoadingStudent } = useGetOne<Student>("students", {
    id: studentId.toString(),
  });
  const { data: drivingSchool, isLoading: isLoadingDrivingSchool } = useGetOne<DrivingSchool>("drivingSchools", {
    id: drivingSchoolId.toString(),
  });
  const { data: favoriteLocations, isLoading: isLoadingFavoriteLocations } =
    useGetManyReference<InstructorFavoriteLocation>("instructorFavoriteLocations", {
      id: instructorId,
      target: "dummy",
    });

  const loading = isLoadingStudent || isLoadingDrivingSchool || isLoadingFavoriteLocations;
  useEffect(() => {
    if (loading || !student || !drivingSchool || !favoriteLocations) {
      return;
    }
    const searchOptions: SearchOption[] = [
      ...Object.values(drivingSchool.branches).map((branch) => ({
        name: branch.name,
        displayString: postalAddressToString(branch.postalAddress),
        postalAddress: branch.postalAddress,
      })),
      ...(drivingSchool.customizations.includes("pickupFromHomeAddress") && isPostalAddress(student.postalAddress)
        ? [
            {
              name: "Adresse Fahrschüler",
              displayString: postalAddressToString(student.postalAddress),
              postalAddress: student.postalAddress,
            },
          ]
        : []),
      ...favoriteLocations.map((it) => ({
        name: it.name,
        displayString: postalAddressToString(it.postalAddress),
        postalAddress: it.postalAddress,
      })),
    ];
    if ((!isInitialized || lastStudentId !== student.id) && searchOptions.length > 0) {
      // Use the branch assigned to the student (if any) as the initial value ...
      const value =
        searchOptions[
          Math.max(
            0,
            drivingSchool.branches.findIndex((it) => it.uid === student.branchId),
          )
        ];
      setValue(value);
      onChange(value.postalAddress);
      setIsInitialized(true);
    }
    setLastStudentId(student.id);
    setOptions(searchOptions);
  }, [loading, student, drivingSchool, favoriteLocations]);

  return { open, setOpen, value, setValue, inputValue, setInputValue, loading, options };
}

const postalAddressToString = (address?: Partial<PostalAddress>) => {
  if (!address) {
    return "Unbekannte Adresse";
  }
  return `${address.street ?? ""} - ${address.postalCode ?? ""}${address.city ? `, ${address.city}` : ""}`;
};
const isPostalAddress = (address?: Partial<PostalAddress>): address is PostalAddress => {
  return !!address?.city && !!address?.postalCode && !!address?.street;
};
