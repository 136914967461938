import { Resource } from "react-admin";
import { AppliedReferralCode } from "../providers/appliedReferralCodesProvider";
import { AppliedReferralCodeList } from "../lists/AppliedReferralCodeList";

export const appliedReferralCodesResource = (
  <Resource
    key="appliedReferralCodes"
    name="appliedReferralCodes"
    list={AppliedReferralCodeList}
    options={{ label: "Empfehlungsprogramm" }}
    recordRepresentation={(referralCode: AppliedReferralCode) => referralCode.id}
  />
);
