import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  BooleanInput,
  BulkDeleteWithConfirmButton,
  Datagrid,
  FieldProps,
  Labeled,
  List,
  Resource,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { DeleteUserButton } from "../buttons/DeleteUserButton";
import { AvatarField } from "../fields/AvatarField";
import { DateField } from "../fields/DateField";
import { LinkToFirestoreField } from "../fields/LinkToFirestoreField";
import { EmptyState } from "../misc/EmptyState";
import { UserToBeDeleted } from "../providers/usersToBeDeletedProvider";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";

const DeletionRequestDateField = (props: Pick<FieldProps, "label" | "sortBy"> & { source: string }) => {
  const userToBeDeleted = useRecordContext<UserToBeDeleted>();
  const dateField = <DateField source={props.source} />;
  return userToBeDeleted.isOverdue ? <Labeled label="Überfällig">{dateField}</Labeled> : dateField;
};

const filters = [
  <TextInput label="Suche" source="q" alwaysOn resettable style={{ marginBottom: "4px" }} />,
  <BooleanInput
    label="Nur überfällige"
    source="isOverdue"
    alwaysOn
    sx={{ marginLeft: "4px", paddingBottom: "12px" }}
  />,
];

const UsersToBeDeletedList = () => {
  return (
    <List
      title={<BackofficeListTitle />}
      sort={{ field: "accountDeletionRequestedAt", order: "ASC" }}
      perPage={50}
      empty={<EmptyState label="Es gibt keine aktiven Löschanfragen" />}
      filters={filters}
    >
      <Datagrid
        bulkActionButtons={
          <BulkDeleteWithConfirmButton mutationMode="pessimistic" confirmTitle="Mehrere User löschen" />
        }
        isRowSelectable={(record) => record.forbiddenProperties.length === 0}
      >
        <AvatarField source="avatarUrl" label={false} />
        <TextField source="id" label="User-ID" />
        <TextField source="name" emptyText="Keine Angabe" />
        <DeletionRequestDateField
          source="accountDeletionRequestedAt"
          label="Löschanfrage vom"
          sortBy="accountDeletionRequestedAt"
        />
        <LinkToFirestoreField source="id" collection="/users" label="" sortable={false} />
        <DeleteUserButton />
      </Datagrid>
    </List>
  );
};

export const usersToBeDeletedResource = (
  <Resource
    key="usersToBeDeleted"
    name="usersToBeDeleted"
    icon={DeleteForeverIcon}
    list={UsersToBeDeletedList}
    options={{ label: "Löschanfragen" }}
  />
);
