import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { DialogProps } from "../misc/DialogProps";
import { FormProvider, useForm } from "react-hook-form";
import { TextInput } from "react-admin";
import type { ReactNode } from "react";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export interface SendDunningNoticeFormValues {
  emailSubject: string;
  emailText: string;
}

export function SendDunningNoticeDialog({
  title,
  buttonLabel = "Senden",
  open,
  onClose,
  initialFormValues,
  onSubmit,
}: DialogProps & {
  title: ReactNode;
  buttonLabel?: string;
  initialFormValues: SendDunningNoticeFormValues;
  onSubmit: (formValues: SendDunningNoticeFormValues) => Promise<void>;
}) {
  const formProps = useForm<SendDunningNoticeFormValues>({ defaultValues: initialFormValues });

  return (
    <FormProvider {...formProps}>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogCloseButton onClose={onClose} disabled={formProps.formState.isSubmitting} />
        <form>
          <DialogContent>
            <Stack gap="5px">
              <TextInput
                source="emailSubject"
                label="Betreff"
                sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }}
              />
              <TextInput source="emailText" label="Text" multiline />
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<SendIcon />}
              loading={formProps.formState.isSubmitting}
              variant="contained"
              disabled={formProps.formState.isSubmitting}
              onClick={formProps.handleSubmit(onSubmit)}
              autoFocus
            >
              {buttonLabel}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
}
