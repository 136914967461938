import { TextFieldProps, useRecordContext } from "react-admin";
import { Bundle, salesCategories } from "../providers/catalogProvider";
import get from "lodash/get";
import { Typography } from "@mui/material";
import { SalesCategoryEnum } from "../generated/backendClient";

export function SalesCategoryField(props: TextFieldProps & { source: string }) {
  const record = useRecordContext<Bundle>();
  const value = get(record, props.source) as SalesCategoryEnum;
  return (
    <Typography component="span" variant="body2">
      {value ? salesCategories[value] ?? `[${value}]` : ""}
    </Typography>
  );
}
