import { Resource } from "react-admin";
import { InstructorIcon } from "../icons/InstructorIcon";
import { InstructorDetails } from "../details/InstructorDetails";

export const instructorsResource = (
  <Resource
    key="instructors"
    name="instructors"
    icon={InstructorIcon}
    edit={InstructorDetails}
    options={{ label: "Fahrlehrer" }}
    recordRepresentation={(instructor) => instructor.name.replaceAll(" ", "\u00A0")}
  />
);
