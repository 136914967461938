import WavingHandIcon from "@mui/icons-material/WavingHand";
import { LoadingButton } from "@mui/lab";
import { useNotify, useRecordContext } from "react-admin";
import { draftDunningNotice } from "../api/backend.api";
import { LocalizedError } from "../utils/LocalizedError";
import { reportError } from "../backoffice.utils";
import { Student, studentsProvider } from "../providers/studentsProvider";
import { DunningProcess, type EmailDraft } from "../generated/backendClient";
import { useDialog } from "../hooks/useDialog";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { SendDunningNoticeDialog, SendDunningNoticeFormValues } from "../dialogs/SendDunningNoticeDialog";
import { serverAPI } from "../api/server.api";
import { DateTime } from "luxon";

export function HandoverToPairFinanceButton(props: { preventRowClick?: () => () => void }) {
  const record = useRecordContext<Student | DunningProcess>();
  const notify = useNotify();
  const { dialogProps, openDialog, closeDialog } = useDialog(props.preventRowClick);
  const [draftingEmail, setDraftingEmail] = useState(false);
  const [dunningProcessId, setDunningProcessId] = useState<string>();
  const [draft, setDraft] = useState<EmailDraft | undefined>();
  const queryClient = useQueryClient();

  const draftEmailAndOpenDialog = async (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDraftingEmail(true);
    try {
      // Check if the student has a complete postal address ...
      let student: Student;
      if (isDunningProcess(record)) {
        student = (await studentsProvider.getOne("students", { id: record.student })).data;
      } else {
        student = record;
      }
      const { postalAddress } = student;
      if (!(postalAddress && postalAddress.street && postalAddress.postalCode && postalAddress.city)) {
        if (!postalAddress || !(postalAddress.street || postalAddress.postalCode || postalAddress.city)) {
          throw new LocalizedError(`Die Adress des Wohnorts von ${student.name} ist nicht hinterlegt.`);
        } else {
          throw new LocalizedError(`Die Adresse des Wohnorts von ${student.name} ist unvollständig.`);
        }
      }
      // Draft email ...
      const { dunningProcess, draft } = await draftDunningNotice(record, "inkasso_notice");
      if (!dunningProcess.second_dunning_notice) {
        notify("Es wurde noch keine 2. Mahnung verschickt.", { type: "error" });
        return;
      }
      const secondDunningNoticeSentAt = DateTime.fromISO(dunningProcess.second_dunning_notice.email_sent_at);
      const dateDiff = Math.floor(DateTime.now().diff(secondDunningNoticeSentAt, "days").days);
      if (dateDiff < 14) {
        notify("Die Übergabe an PAIR Finance kann erst 14 Tage nach der 2. Mahnung erfolgen.", { type: "error" });
        return;
      }
      setDunningProcessId(dunningProcess.id);
      setDraft(draft);
      openDialog();
    } catch (error) {
      if (error instanceof LocalizedError) {
        notify(error.message, { type: "error" });
      } else {
        reportError("Failed to draft inkasso notice", error);
        notify(
          "Oje, es ist ein Fehler beim Vorbereiten der Übergabe an PAIR Finance aufgetreten. Bitte wende Dich an die Technik.",
          {
            type: "error",
          },
        );
      }
    } finally {
      setDraftingEmail(false);
    }
  };

  const { mutateAsync: handoverToPairFinance } = useMutation(
    async ({ emailSubject, emailText }: SendDunningNoticeFormValues) => {
      if (!dunningProcessId) {
        throw new Error("dunningProcessId not set");
      }
      let student: Student;
      if (isDunningProcess(record)) {
        student = (await studentsProvider.getOne("students", { id: record.student })).data;
      } else {
        student = record;
      }
      await serverAPI.sendDunningNotice({
        student,
        dunningProcessId,
        noticeType: "inkasso_notice",
        emailSubject,
        emailText,
        queryClient,
      });
    },
    {
      onSuccess: async () => {
        notify("Inkasso erfolgreich gestartet.", { type: "success" });
        closeDialog();
      },
      onError: (error) => {
        reportError("Failed to hand over to PAIR Finance", error);
        notify("Fehler bei der Übergabe an PAIR Finance", { type: "error" });
      },
    },
  );

  return (
    <>
      <LoadingButton
        variant="outlined"
        loading={draftingEmail}
        startIcon={<WavingHandIcon />}
        onClick={draftEmailAndOpenDialog}
      >
        Übergabe an PAIR Finance
      </LoadingButton>
      <SendDunningNoticeDialog
        title="Inkasso"
        {...dialogProps}
        initialFormValues={{
          emailSubject: draft?.subject ?? "",
          emailText: draft?.text ?? "",
        }}
        onSubmit={handoverToPairFinance}
      />
    </>
  );
}

function isDunningProcess(x: Student | DunningProcess): x is DunningProcess {
  if (x && typeof x === "object") {
    const { id, student, invoices } = x as any;
    return typeof id === "string" && typeof student === "string" && Array.isArray(invoices);
  }
  return false;
}
