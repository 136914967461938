import { collection, getDocsFromServer, query, where } from "firebase/firestore";
import { CreateParams, CreateResult, GetManyReferenceParams, GetManyReferenceResult, Identifier } from "react-admin";
import { Document } from "../model/Document";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort, escapeHtml, reportError } from "../backoffice.utils";
import { Attachment, AttachmentSchema } from "../model/Attachment";
import { gcs } from "../utils/storage";
import { studentsProvider } from "./studentsProvider";
import { authProvider } from "../backoffice.access_control";
import { t } from "../model/types";
import { SignatureRequestSchema } from "../model/SignatureRequest";
import capitalize from "lodash/capitalize";
import { v4 as uuid } from "uuid";
import { createNote } from "../api/backoffice.api";
import { studentNotesProvider } from "./studentNotesProvider";
import { LocalizedError } from "../utils/LocalizedError";
import { determineMimeType } from "../utils/determineMimeType";
import { getFileNameExtension } from "../utils/getFileNameExtension";
import { AbstractProvider } from "./abstractProvider";
import { getFileNameExtensionForMimeType } from "../utils/getFileNameExtensionForMimeType";

class StudentDocumentsProvider extends AbstractProvider<Document> {
  private _snapshotPromiseStudentUid: Identifier | undefined;
  private _snapshotPromise: Promise<Array<Document>> | undefined;

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Document>> {
    if (target !== "studentUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentUid"`);
    }
    const documents = await this.fetchIfNeeded(id);
    return applyPagination(applySort(applyFilter(documents, filter), sort), pagination);
  }

  async create(
    _: string,
    {
      data,
    }: CreateParams<{
      studentUid: string;
      files: Array<{ rawFile: File }>;
    }>,
  ): Promise<CreateResult<Document>> {
    const { studentUid, files } = data;
    if (!studentUid) {
      throw new LocalizedError("Kein Fahrschüler ausgewählt.");
    }
    if (!files || files.length === 0) {
      throw new LocalizedError("Keine Datei ausgewählt.");
    }
    const emptyFile = files.find((it) => it.rawFile.size === 0);
    if (emptyFile) {
      throw new LocalizedError(`Die Datei ${emptyFile.rawFile.name} ist leer.`);
    }
    const knownIds = new Set((await this.fetchIfNeeded(studentUid)).map((document) => document.id));

    // 1. Determine and validate MIME types ...
    const mimeTypes: Array<string> = [];
    for (let i = 0; i < files.length; ++i) {
      const file = files[i].rawFile;
      const extension = getFileNameExtension(file.name);
      const mimeType = await determineMimeType(file);
      if (extension === "pdf" && mimeType !== "application/pdf") {
        throw new LocalizedError(`Die Datei ${file.name} ist keine gültige PDF-Datei.`);
      }
      mimeTypes[i] = mimeType || file.type || "application/octet-stream";
    }

    // 2. Upload files to Google Cloud Storage ...
    const attachments: Array<Omit<Defined<Attachment, "size">, "id" | "lastChanged">> = [];
    for (let i = 0; i < files.length; ++i) {
      const file = files[i].rawFile;
      const mimeType = mimeTypes[i];
      let fileName = file.name;
      let extension = getFileNameExtension(file.name);
      // Add extension to file name if file name has no extension ...
      if (!extension) {
        extension = getFileNameExtensionForMimeType(mimeType);
        if (extension) {
          fileName = `${fileName}.${extension}`;
        }
      }
      attachments.push({
        name: fileName,
        path: (await gcs.uploadFile(file, `/uploads/${uuid()}/${fileName}`)).ref.fullPath,
        size: file.size,
        mimeType,
      });
    }

    // 3. Link uploads to student ...
    const { fullName } = (await authProvider.getIdentity?.()) ?? {};
    let noteBody: string;
    if (attachments.length === 1) {
      if (fullName) {
        noteBody = `<p>${escapeHtml(fullName)} hat das Dokument ${escapeHtml(attachments[0].name)} hinzugefügt.</p>`;
      } else {
        noteBody = `<p>Das Dokument ${escapeHtml(attachments[0].name)} wurde hinzugefügt.</p>`;
      }
    } else {
      if (fullName) {
        noteBody = `<p>${escapeHtml(fullName)} hat folgende Dokument hinzugefügt:</p><ul>`;
      } else {
        noteBody = "<p>Folgende Dokumente wurden hinzugefügt:</p>";
      }
      noteBody += "<ul>" + attachments.map((it) => `<li>${escapeHtml(it.name)}</li>`).join("") + "</ul>";
    }
    await createNote({ studentUid, body: noteBody, attachments });
    await studentNotesProvider.fetch(studentUid);

    const documents = await this.fetch(studentUid);
    const newDocument = documents.find((it) => !knownIds.has(it.id) && it.fileName === attachments[0].name)!;
    return { data: newDocument };
  }

  fetchIfNeeded(studentUid: Identifier): Promise<Array<Document>> {
    if (this._snapshotPromiseStudentUid === studentUid) {
      return this._snapshotPromise!;
    }
    return this.fetch(studentUid);
  }

  fetch(studentUid: Identifier): Promise<Array<Document>> {
    this._snapshotPromiseStudentUid = studentUid;
    this._snapshotPromise = this._fetch(studentUid);
    return this._snapshotPromise;
  }

  private async _fetch(studentUid: Identifier): Promise<Array<Document>> {
    const documents = (
      await Promise.all([
        retrieveAttachments(studentUid),
        retrieveDocuments(studentUid),
        retrieveSignedDocuments(studentUid),
      ])
    ).flat();
    console.info(`Retrieved ${documents.length} document(s) for student ${studentUid}`);
    return documents;
  }
}

export const studentDocumentsProvider = new StudentDocumentsProvider();

async function retrieveAttachments(studentUid: Identifier): Promise<Array<Document>> {
  try {
    const snapshot = await getDocsFromServer(collection(firestore, `/users/${studentUid}/attachments`));
    const attachments = snapshot.docs.map((doc) => AttachmentSchema.parse(doc.data()));
    return attachments.map((attachment) => ({
      id: attachment.path,
      fileName: attachment.name,
      contentType: attachment.mimeType,
      createdAt: attachment.lastChanged,
      getDownloadUrl: () => gcs.getDownloadUrl(attachment.path),
    }));
  } catch (error) {
    reportError(`retrieveAttachments("${studentUid}") failed`, error);
    return [];
  }
}

async function retrieveDocuments(studentUid: Identifier): Promise<Array<Document>> {
  try {
    const { data: student } = await studentsProvider.getOne("students", { id: studentUid });
    const files = await gcs.listFiles(`/backend/documents/${student.autovioUserId}/${student.drivingSchoolId}`);
    return files
      .filter((it) => it.name.endsWith(".pdf"))
      .map((file) => ({
        id: file.fullPath,
        fileName: file.name,
        contentType: "application/pdf",
        createdAt: t.dateTime().parse(file.timeCreated),
        getDownloadUrl: () => gcs.getDownloadUrl(file),
      }));
  } catch (error) {
    reportError(`retrieveDocuments("${studentUid}") failed`, error);
    return [];
  }
}

async function retrieveSignedDocuments(studentUid: Identifier): Promise<Array<Document>> {
  try {
    const snapshot = await getDocsFromServer(
      query(collection(firestore, "/signature_requests"), where("studentUid", "==", studentUid)),
    );
    const signatureRequest = snapshot.docs.map((doc) => SignatureRequestSchema.parse({ uid: doc.id, ...doc.data() }));
    const completedSignatureRequests = signatureRequest.filter((it) => it.state === "completed");
    return completedSignatureRequests.map((it) => ({
      id: it.signedDocumentStorageRef!,
      fileName: `${capitalize(it.type)}.pdf`,
      contentType: "application/pdf",
      createdAt: it.studentSignedAt!,
      getDownloadUrl: () => gcs.getDownloadUrl(it.signedDocumentStorageRef!),
    }));
  } catch (error) {
    reportError(`retrieveSignedDocuments("${studentUid}") failed`, error);
    return [];
  }
}
