import { Tooltip } from "@mui/material";
import { ReactElement } from "react";
import { FunctionField } from "react-admin";
import { AutovioCalendarEvent } from "../model/autovioCalendarEvents";

export const EventStatusReasonTooltip = (props: {
  event: AutovioCalendarEvent;
  children: ReactElement;
}): JSX.Element => {
  return (
    <Tooltip
      title={isEventCanceled(props.event) ? <FunctionField render={_renderReason} /> : undefined}
      PopperProps={{ placement: "top-start" }}
    >
      <div>{props.children}</div>
    </Tooltip>
  );
};

const _renderReason = (record: AutovioCalendarEvent): string => {
  if (record.deletionReason) {
    return 'Grund: "' + record.deletionReason + '"';
  }
  if (record.student?.cancelReason) {
    return 'Grund: "' + record.student.cancelReason + '"';
  }
  return "Grund: Keine Angabe";
};

function isEventCanceled(event: AutovioCalendarEvent): boolean {
  return (
    event.status === "canceledByStudent" ||
    event.status === "canceledByInstructor" ||
    event.status === "declinedByStudent"
  );
}
