import { useState } from "react";
import { Datagrid, FunctionField, NumberField, TextField, useRecordContext } from "react-admin";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { PageTitle } from "../../misc/PageTitle";
import { InstructorIcon } from "../../icons/InstructorIcon";
import { DateField } from "../../fields/DateField";
import { DateTime } from "luxon";
import { Course } from "../../providers/coursesProvider";
import { Row } from "../../misc/Row";
import { autovioColors } from "../../misc/backofficeTheme";
import { Typography } from "@mui/material";
import { InformationIcon } from "../../icons/InformationIcon";
import { useDialog } from "../../hooks/useDialog";
import { CourseDetailsDialog } from "../../dialogs/CourseDetailsDialog";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";

export function DrivingSchoolCourses() {
  const now = DateTime.now();
  const { dialogProps, openDialog } = useDialog();
  const [selectedCourse, setSelectedCourse] = useState<undefined | Course>();
  const drivingSchool = useRecordContext<DrivingSchool>();

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>Kurse</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  return (
    <>
      <PageTitle>Kurse</PageTitle>
      <ReferenceManyField
        reference="courses"
        target="drivingSchoolId"
        filter={{ end: (end: DateTime) => end > now }}
        sort={{ field: "start", order: "ASC" }}
      >
        <Datagrid
          rowClick={(_, __, record) => {
            setSelectedCourse(record as Course);
            openDialog();
            return false;
          }}
          bulkActionButtons={false}
        >
          <FunctionField label="" render={() => <InstructorIcon />} />
          <TextField label="Kursart" source="localizedType" />
          <NumberField label="Termine" source="numAppointments" textAlign="left" />
          <DateField label="Start" source="start" />
          <DateField label="Ende" source="end" />
          <FunctionField label="Teilnehmer" render={_renderAttendees} />
          <FunctionField label="Status" render={_renderStatus} />
          <Row gap={1} alignItems="center">
            <InformationIcon color={autovioColors.green} />
            <Typography component="div" variant="body2" color={autovioColors.green}>
              Details
            </Typography>
          </Row>
        </Datagrid>
      </ReferenceManyField>
      {selectedCourse && <CourseDetailsDialog course={selectedCourse} {...dialogProps} />}
    </>
  );
}

function _renderAttendees(course: Course) {
  const { numAttendees, maxAttendees } = course;
  const text = typeof maxAttendees === "number" ? `${numAttendees}/${maxAttendees}` : numAttendees.toString();
  return <Typography variant="body2">{text}</Typography>;
}

function _renderStatus(course: Course) {
  const status = course.start < DateTime.now() ? "Aktiv" : "Geplant";
  return (
    <Row gap={1} alignItems="center">
      <div
        style={{
          width: "12.8px",
          height: "12.8px",
          border: `solid 2.4px ${autovioColors.borderGrey}`,
          borderRadius: "6.4px",
          backgroundColor: status === "Aktiv" ? autovioColors.green : autovioColors.white,
        }}
      />
      <Typography component="div" variant="body2">
        {status}
      </Typography>
    </Row>
  );
}
