import { CircularProgress } from "@mui/material";

import { Delayed } from "./Delayed";

export function LoadingIndicator() {
  return (
    <Delayed ms={100}>
      <div
        data-testid="LoadingIndicator"
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          right: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "#FFFFFF",
          zIndex: 50,
          opacity: "50%",
        }}
      >
        <CircularProgress />
      </div>
    </Delayed>
  );
}
