export function FleetIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 21.35v-5.7l1.4-4a1.23 1.23 0 0 1 .362-.463.919.919 0 0 1 .588-.187h7.3a.997.997 0 0 1 .95.65l1.4 4v5.7a.628.628 0 0 1-.188.462.628.628 0 0 1-.462.188h-.7a.628.628 0 0 1-.462-.188.628.628 0 0 1-.188-.462v-.85h-8v.85a.628.628 0 0 1-.188.462.628.628 0 0 1-.462.188h-.7a.628.628 0 0 1-.463-.188.628.628 0 0 1-.187-.462zm2-6.85h8l-.7-2h-6.6l-.7 2zm-.463 1.5v3-3zM13 18.5c.283 0 .52-.096.713-.288A.968.968 0 0 0 14 17.5a.968.968 0 0 0-.287-.712A.968.968 0 0 0 13 16.5a.968.968 0 0 0-.713.288.968.968 0 0 0-.287.712c0 .283.096.52.287.712.192.192.43.288.713.288zm6 0c.283 0 .52-.096.712-.288A.968.968 0 0 0 20 17.5a.968.968 0 0 0-.288-.712A.968.968 0 0 0 19 16.5a.968.968 0 0 0-.712.288.968.968 0 0 0-.288.712c0 .283.096.52.288.712.191.192.429.288.712.288zM6 14v-2h2v2H6zm5-6V6h2v2h-2zM6 18v-2h2v2H6zm0 4v-2h2v2H6zm-4 0V8h5V2h10v7h-2V4H9v6H4v12H2zm9.5-3h9v-3h-9v3z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}
