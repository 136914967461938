import { t } from "./types";
import type { z } from "zod";

export const AttachmentSchema = t.object({
  id: t.string().nonempty(),
  name: t.string().nonempty(),
  path: t.string().nonempty(),
  size: t.optional(t.number().int().positive()),
  mimeType: t.string().nonempty(),
  lastChanged: t.dateTime(),
});

export type Attachment = z.infer<typeof AttachmentSchema>;
