import { Typography } from "@mui/material";

export function EmptyState(props: { label?: string }) {
  return (
    <div className="RaList-main">
      <div style={{ textAlign: "center", flex: 1, paddingBottom: "20px" }}>
        <img src="/time_off.png" alt="Keine Einträge" style={{ maxWidth: "400px", opacity: 0.5 }} />
        <div style={{ opacity: 0.5 }}>
          <Typography>{props.label ?? "Keine Einträge."}</Typography>
        </div>
      </div>
    </div>
  );
}
