import { GetListParams, GetListResult, type GetManyParams, type GetManyResult } from "react-admin";
import { getAuthenticatedBackendClient } from "../api/backend.api";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import type { AutovioPayoutItem, ReportingCategoryEnum } from "../generated/backendClient";
import { Money, MoneySchema } from "../model/Money";
import { AbstractProvider } from "./abstractProvider";

export interface AutovioPayoutItemRecord {
  id: string;
  reportingCategory: ReportingCategoryEnum;
  reportingCategoryText: string;
  invoiceId: string | null;
  creditNoteId: string | null;
  readonly payoutAmount: Money;
  payoutId: string | null | undefined;
}

export function autovioPayoutItemToAutovioPayoutItemRecord(item: AutovioPayoutItem): AutovioPayoutItemRecord {
  if (!item.id) {
    throw new Error("AutovioPayoutItem has no ID");
  }
  return {
    id: item.id ?? "",
    reportingCategory: item.reporting_category,
    reportingCategoryText: item.reporting_category_text,
    invoiceId: item.invoice ?? null,
    creditNoteId: item.credit_note ?? null,
    payoutAmount: MoneySchema.parse(item.payout_amount),
    payoutId: item.autovio_payout,
  };
}

class AutovioPayoutItemProvider extends AbstractProvider<AutovioPayoutItemRecord> {
  async getList(
    resource: string,
    { filter, sort, pagination }: GetListParams,
  ): Promise<GetListResult<AutovioPayoutItemRecord>> {
    const backendClient = await getAuthenticatedBackendClient();
    const { drivingSchoolId, performanceOverviewEntryId, ...clientSideFilter } = filter;
    if (!drivingSchoolId) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"drivingSchoolId": ..., ...}`);
    }
    const { results } = await backendClient.payoutItems.payoutItemsList({
      autovioPayoutCompanyDrivingSchoolId: drivingSchoolId,
      performanceOverviewEntryId: performanceOverviewEntryId,
    });
    const records = results.map(autovioPayoutItemToAutovioPayoutItemRecord);
    return applyPagination(applySort(applyFilter(records, clientSideFilter), sort), pagination);
  }

  async getMany(resource: string, { ids }: GetManyParams): Promise<GetManyResult<AutovioPayoutItemRecord>> {
    const results = await Promise.all(ids.map(async (id) => (await this.getOne(resource, { id: id as string })).data));
    return { data: results };
  }

  async getOne(resource: string, { id }: { id: string }): Promise<{ data: AutovioPayoutItemRecord }> {
    const backendClient = await getAuthenticatedBackendClient();
    const payoutItem = await backendClient.payoutItems.payoutItemsRetrieve({ id });
    const record = autovioPayoutItemToAutovioPayoutItemRecord(payoutItem);
    return { data: record };
  }
}

export const autovioPayoutItemProvider = new AutovioPayoutItemProvider();
