/*
As opposed to the MoneyInput, this component does not have any binding with the react admin
 */

import { Box, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useState } from "react";

type ValidationOptions = {
  maxValue?: number;
  minValue?: number;
};

const validateAmount = (value?: string, options?: ValidationOptions) => {
  if (!options) options = {};
  const { maxValue, minValue } = options;
  value = (value ?? "").replace(",", "."); // <-- allow comma as decimal separator
  if (!value.match(/^[1-9][0-9]*(\.[0-9]{2})?$/)) {
    return "Ungültiger Betrag, bitte geben Sie nur eine Zahl mit genau zwei Nachkommastellen  ein";
  }
  const float = parseFloat(value);
  if (isNaN(float)) {
    return "Ungültiger Betrag";
  }
  if (float <= 0) {
    return "Der Betrag muss größer als 0 sein";
  }
  if (float !== parseFloat(float.toFixed(2))) {
    return "Der Betrag darf maximal 2 Nachkommastellen haben";
  }
  if (maxValue && float > maxValue) {
    return `Der Betrag darf maximal ${maxValue} betragen`;
  }
  if (minValue && float < minValue) {
    return `Der Betrag muss mindestens ${minValue} betragen`;
  }
  return undefined;
};

const StyledTextField = styled(TextField)({
  ".MuiInputBase-input": {
    textAlign: "right",
  },
});

export function RawMoneyInput({
  value,
  onChange,
  label,
  maxValue,
  style,
}: {
  value: string;
  onChange: (value: string) => void;
  label: string;
  maxValue?: number;
  style?: React.CSSProperties;
}): JSX.Element {
  const [error, setError] = useState<string | undefined>(undefined);
  const innerOnChange = (value: string) => {
    const error = validateAmount(value, { maxValue });
    setError(error);
    onChange(value);
  };

  return (
    <Box component="form" sx={{ display: "flex", gap: "10px" }}>
      <StyledTextField
        required
        label={label}
        style={style}
        onChange={(e) => innerOnChange(e.target.value)}
        value={value}
        defaultValue={"0,00"}
        error={!!error}
        helperText={error}
        InputProps={{
          startAdornment: <InputAdornment position="start">Euro</InputAdornment>,
        }}
      />
    </Box>
  );
}
