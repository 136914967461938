import { Stack, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { Money } from "../misc/Money";
import { Bundle } from "../providers/catalogProvider";

export function PriceField({ lessonName }: { lessonName: string; label: string }) {
  const record = useRecordContext<Bundle>();
  return (
    <Stack spacing={1}>
      {record.trainings.map((training) => {
        const product = training.products.find((it) => it.name.startsWith(lessonName));

        if (!product)
          return (
            <Typography key={`${record.id}-${training.id}-${lessonName}`} variant="body2">
              Nicht hinterlegt
            </Typography>
          );

        return <Money key={`${training.id}-price`} cents={record.productPrices[product.id] * 100} />;
      })}
    </Stack>
  );
}
