import { FieldProps, sanitizeFieldRestProps } from "react-admin";
import * as React from "react";
import get from "lodash/get";
import { useRecordContext } from "ra-core";
import { Button, Typography } from "@mui/material";
import { StripeIcon } from "../icons/StripeIcon";
import { firebaseApp } from "../firebase";

interface LinkToStripeFieldProps extends FieldProps {
  source: string;
}

function _LinkToStripeField(props: LinkToStripeFieldProps) {
  const { className, emptyText, source, ...rest } = props;
  const { projectId } = firebaseApp.options;
  const baseUrl = `https://dashboard.stripe.com${projectId !== "autovio-prod" ? "/test" : ""}`;
  const record = useRecordContext(props);
  let url: string;
  const ref = get(record, source);
  if (!ref) {
    return emptyText ? (
      <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
        {emptyText}
      </Typography>
    ) : null;
  } else if (ref.startsWith("cus_")) {
    url = `${baseUrl}/customers/${ref}/`;
  } else if (ref.startsWith("pi_")) {
    url = `${baseUrl}/payments/${ref}/`;
  } else {
    url = `${baseUrl}/search?query=${encodeURIComponent(ref)}`;
  }

  return (
    <Button
      variant="text"
      startIcon={<StripeIcon sx={{ ml: 1, width: "18px", height: "18px" }} />}
      sx={{ minWidth: "32px" }}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        open(url, "_blank");
      }}
    />
  );
}

export const LinkToStripeField = React.memo(_LinkToStripeField);

LinkToStripeField.displayName = "LinkToStripeField";
