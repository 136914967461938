import { z } from "zod";

export const DateStringSchema = z
  .string()
  .refine((val) => /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.exec(val), {
    message: "Must have the format yyyy-MM-dd",
  });

export type DateString = z.infer<typeof DateStringSchema>;

export function isDateString(val: unknown): val is DateString {
  return DateStringSchema.safeParse(val).success;
}
