import { collection, getDocs, query } from "firebase/firestore";
import { GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import { applyFilter, applySort } from "../backoffice.utils";
import { firestore } from "../firebase";
import { PostalAddress } from "../model/PostalAddress";
import { favoriteLocationsConverter } from "./converter/favoriteLocationsConverter";

export interface InstructorFavoriteLocation {
  id: string;
  name: string;
  postalAddress: PostalAddress;
}

class InstructorFavoriteLocationsProvider {
  async getManyReference(
    _: string,
    { id, filter, sort }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<InstructorFavoriteLocation>> {
    const query_ = query(collection(firestore, `/users/${id}/favorite_locations`));
    const snapshot = await getDocs(query_.withConverter(favoriteLocationsConverter));
    const favoriteLocations = snapshot.docs.map((it) => it.data()).flat();
    return {
      data: applySort(applyFilter(favoriteLocations, filter), sort),
      total: favoriteLocations.length,
    };
  }
}

export const instructorFavoriteLocationsProvider = new InstructorFavoriteLocationsProvider();
