import { Stack } from "@mui/material";
import { Pagination, useGetOne, useRecordContext } from "react-admin";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { InstructorMap } from "../../misc/maps";
import { Instructor } from "../../providers/instructorsProvider";
import { StudentsDataGrid } from "../../lists/StudentsDataGrid";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";

export function InstructorStudentsListAndInstructorMap() {
  const instructor = useRecordContext<Instructor>();
  const drivingSchoolId = instructor?.drivingSchoolId;
  const { data: drivingSchool } = useGetOne<DrivingSchool>(
    "drivingSchools",
    { id: drivingSchoolId },
    { enabled: !!drivingSchoolId },
  );

  if (!drivingSchool) {
    return <LoadingIndicator />;
  }

  return (
    <Stack direction="row">
      <div style={{ flex: 3 }}>
        <ReferenceManyField
          label=""
          reference="students"
          filter={{ status: ["active", "onHold", "outstandingPayments"] }}
          target="instructorIds"
          sort={{ field: "name", order: "ASC" }}
          pagination={<Pagination />}
          meta={{ drivingSchoolId }}
        >
          <StudentsDataGrid drivingSchool={drivingSchool} />
        </ReferenceManyField>
      </div>
      <div style={{ flex: 1, marginLeft: "1em", marginTop: "0.5em" }}>
        <InstructorMap instructor={instructor} />
      </div>
    </Stack>
  );
}
