import { Toolbar, ToolbarClasses } from "react-admin";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { StudentBookedQuotesList } from "../../lists/StudentBookedQuotesList";
import { autovioColors } from "../../misc/backofficeTheme";
import { AddBundleButton } from "../../buttons/AddBundleButton";

export function StudentBookedBundles() {
  return (
    <>
      <ReferenceManyField label="" reference="bookedQuotes" target="studentId" sort={{ field: "name", order: "ASC" }}>
        <StudentBookedQuotesList />
      </ReferenceManyField>
      <Toolbar className="no-padding" sx={{ backgroundColor: autovioColors.white }}>
        <div className={ToolbarClasses.defaultToolbar}>
          <div>{/* An empty div as placeholder, so that the following Button is aligned right */}</div>
          <AddBundleButton />
        </div>
      </Toolbar>
    </>
  );
}
