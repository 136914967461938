import { useGetResourceLabel, useResourceContext } from "react-admin";
import { useLocation } from "react-router-dom";
import { PageTitle } from "./PageTitle";

export function BackofficeListTitle() {
  const { pathname } = useLocation();
  const resource = useResourceContext();
  const getResourceLabel = useGetResourceLabel();

  if (pathname.startsWith("/dunning")) {
    return <PageTitle>Mahnwesen</PageTitle>;
  }

  const listTitle = getResourceLabel(resource);
  return <PageTitle>{listTitle}</PageTitle>;
}
