import { ReferenceArrayInput, required, SelectArrayInput, SimpleForm, TextInput } from "react-admin";
import { RecoverToolbar } from "../../misc/RecoverToolbar";
import { EntitledDrivingSchoolsInput } from "../../inputs/EntitledDrivingSchoolsInput";
import { EntitledUsersInput } from "../../inputs/EntitledUsersInput";

export function MotorcycleForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <SimpleForm warnWhenUnsavedChanges toolbar={<RecoverToolbar />}>
      <TextInput type="hidden" source="type" defaultValue="motorcycle" style={{ display: "none" }} />
      <TextInput label="Name" source="name" fullWidth autoFocus={mode === "create"} validate={required()} />
      <TextInput label="Notizen" source="description" fullWidth />
      <EntitledDrivingSchoolsInput />
      <EntitledUsersInput />
      <TextInput label="Kennzeichen" source="motorcycle.plateNumber" validate={required()} />
      <ReferenceArrayInput source="motorcycle.advancedDriverAssistanceSystemsIds" reference="adas">
        <SelectArrayInput
          label="Assistenzsysteme"
          className="fix-SelectArrayInput-label"
          defaultValue={[]}
          sx={{ minWidth: "337px" }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  );
}
