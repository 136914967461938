import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useNotify, useRecordContext } from "react-admin";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDialog } from "../hooks/useDialog";
import { Instructor } from "../providers/instructorsProvider";
import { Student } from "../providers/studentsProvider";
import { resetPassword } from "../api/backoffice.api";
import { LoadingButton } from "@mui/lab";
import { Column } from "../misc/Column";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export function ResetPasswordLink({ label }: { label: string }) {
  const user = useRecordContext<Student | Instructor>();
  const { dialogProps, openDialog } = useDialog();

  if (!user) {
    return null;
  }

  return (
    <>
      <Link variant="caption" underline="none" sx={{ cursor: "pointer" }} onClick={openDialog}>
        {label}
      </Link>
      <ResetPasswordDialog user={user} {...dialogProps} />
    </>
  );
}

interface ResetPasswordDialogProps {
  open: boolean;
  user: Student | Instructor;
  onClose: () => void;
}

/**
 * This dialog is not closed, when the password has been successfully set, so that
 * the current user can still read/copy the new password after it has been set.
 */
function ResetPasswordDialog({ open, user, onClose }: ResetPasswordDialogProps) {
  const [state, setState] = useState<"show form" | "resetting password" | "done">("show form");
  const generatedPassword = useMemo(_generatePassword, []);
  const formProps = useForm<{ newPassword: string }>({ defaultValues: { newPassword: generatedPassword } });
  const notify = useNotify();

  async function setPassword() {
    setState("resetting password");
    try {
      const newPassword = formProps.getValues().newPassword.trim();
      await resetPassword({ userUid: user.id, newPassword });
      notify(`Passwort von ${user.name} erfolgreich gesetzt.`, { type: "success" });
      setState("done");
    } catch (error) {
      console.error("Failed to set password", error);
      notify(`Fehler beim Setzen des Passworts von ${user.name}.`, { type: "error" });
      setState("show form");
    }
  }

  return (
    <FormProvider {...formProps}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{`Passwort von ${user.name} ${state === "done" ? "erfolgreich gesetzt" : "setzen"}`}</DialogTitle>
        <DialogCloseButton onClose={onClose} />
        <form onSubmit={formProps.handleSubmit(setPassword)}>
          <DialogContent>
            {state !== "done" && (
              <FormControl sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }} fullWidth>
                <Controller
                  name="newPassword"
                  control={formProps.control}
                  rules={{
                    validate: (value) => {
                      const effectivePassword = (value ?? "").trim();
                      if (!effectivePassword) {
                        return "Bitte gib das neue Passwort ein.";
                      } else if (effectivePassword.length < 6) {
                        return "Das neue Passwort muss mindestens 6 Zeichen lang sein.";
                      } else {
                        return true;
                      }
                    },
                  }}
                  render={({ field, formState }) => {
                    const error = formState.errors.newPassword?.message;
                    return (
                      <TextField
                        label="Neues Passwort"
                        error={!!error}
                        helperText={error}
                        {...field}
                        fullWidth
                        autoFocus
                        disabled={state !== "show form"}
                      />
                    );
                  }}
                />
              </FormControl>
            )}
            {state === "done" && (
              <Column>
                <Typography variant="body1">{`Bitte teile ${user.firstName} das neue Passwort mit:`}</Typography>
                <Typography variant="body1" data-testid="newPassword" sx={{ textAlign: "center", fontSize: "250%" }}>
                  {formProps.getValues().newPassword}
                </Typography>
              </Column>
            )}
          </DialogContent>
          <DialogActions>
            {state !== "done" && (
              <LoadingButton
                variant="contained"
                loading={formProps.formState.isSubmitting}
                onClick={formProps.handleSubmit(setPassword)}
              >
                Passwort setzen
              </LoadingButton>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
}

// We exclude 'O' and '0', 'I', '1' and 'l', as well as '2' and 'Z' to reduce the possibility of wrong passwords ...
const _commonChars = "ABCDEFGHJKLMNPQRSTUVWXYabcdefghijkmnopqrstuvwxyz3456789";
const _specialChars = "-_:+%!";

function _generatePassword() {
  const chars = [];
  // Generate 8 random letters or numbers ...
  for (let i = 1; i <= 8; ++i) {
    chars.push(_commonChars[Math.floor(Math.random() * _commonChars.length)]);
  }
  // Insert a special char somewhere near the middle ...
  chars.splice(Math.floor(Math.random() * 3) + 3, 0, _specialChars[Math.floor(Math.random() * _specialChars.length)]);
  return chars.join("");
}
