/** Does never throw -- returns `null` in case of an error. */
function getItem<T>(key: string, deserialize: (s: string) => T): T | null {
  try {
    const s = localStorage.getItem(key);
    return s === null ? null : deserialize(s);
  } catch (_) {
    return null;
  }
}

/** Does never throw. */
function setItem(key: string, value: string): void;
function setItem<T>(key: string, value: T, serialize: (item: T) => string): void;
function setItem<T>(key: string, value: T, serialize?: (item: T) => string): void {
  try {
    localStorage.setItem(key, serialize ? serialize(value) : (value as string));
  } catch (_) {
    // Ignored.
  }
}

function removeItem(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (_) {
    // Ignored.
  }
}

export const safeLocalStorage = { getItem, setItem, removeItem };
