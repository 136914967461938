import { useRef } from "react";
import { Identifier, RaRecord } from "ra-core";

type RowClickFunction = (
  id: Identifier,
  resource: string,
  record: RaRecord,
) => string | false | Promise<string | false>;

export function usePreventRowClick(): {
  rowClickWrapper: (wrapped: string | RowClickFunction) => RowClickFunction;
  /** Returns a function, which will allow row clicks again when called. */
  preventRowClick: () => () => void;
} {
  const preventRowClickRef = useRef(false);
  const preventNextRowClickRef = useRef(false);

  function rowClickWrapper(wrapped: string | RowClickFunction) {
    return (id: Identifier, resource: string, record: RaRecord) => {
      if (preventRowClickRef.current || preventNextRowClickRef.current) {
        preventNextRowClickRef.current = false;
        return false;
      }
      return typeof wrapped === "string" ? wrapped : wrapped(id, resource, record);
    };
  }

  function preventRowClick() {
    preventRowClickRef.current = true;
    return () => {
      preventRowClickRef.current = false;
      preventNextRowClickRef.current = true;
    };
  }

  return { rowClickWrapper, preventRowClick };
}
