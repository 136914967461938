import { FormControl, InputLabel, Slider } from "@mui/material";
import { CommonInputProps, useInput } from "react-admin";
import { Row } from "../misc/Row";

type ShareInputProps = CommonInputProps & {
  left: string;
  right: string;
};

export function ShareInput(props: ShareInputProps) {
  const { field } = useInput(props);

  return (
    <FormControl>
      {props.label && <InputLabel shrink>{props.label}</InputLabel>}
      <Row sx={{ m: "1em" }}>
        {/* [UX]: Prevent jumping when displayed percentage changes by rendering a transparent "...: 100%" */}
        <div>
          <div style={{ float: "left" }}>{`${props.left}: ${Math.round((1 - field.value) * 100)}%`}</div>
          <div style={{ color: "transparent" }}>{`${props.left}: 100%`}</div>
        </div>
        <div style={{ width: "2em" }} />
        <Slider defaultValue={props.defaultValue} step={0.05} min={0} max={1} sx={{ width: "200px" }} {...field} />
        <div style={{ width: "2em" }} />
        {`${props.right}: ${Math.round(field.value * 100)}%`}
      </Row>
    </FormControl>
  );
}
