import * as React from "react";
import { useRecordContext } from "ra-core";
import { Button } from "@mui/material";
import { HubspotIcon } from "../icons/HubspotIcon";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { Instructor } from "../providers/instructorsProvider";
import { Student } from "../providers/studentsProvider";
import { useNotify, useResourceContext } from "react-admin";
import { autovioColors, backofficeTheme } from "../misc/backofficeTheme";

const _hubspotBaseUrl = "https://app.hubspot.com/contacts/19951845";

export function OpenInHubspotButton({ showTitle }: { showTitle?: boolean }) {
  const notify = useNotify();
  const resource = useResourceContext();
  const record = useRecordContext<DrivingSchool | Instructor | Student>();
  const { black } = autovioColors;
  if (!record) {
    return null;
  }
  const url =
    resource === "drivingSchools"
      ? `${_hubspotBaseUrl}/company/${(record as DrivingSchool).id}/`
      : `${_hubspotBaseUrl}/contact/${(record as Instructor | Student).hubspotContactId}/`;
  return (
    <Button
      variant="text"
      sx={{
        ...backofficeTheme.linkButtonStyle,
        color: black,
        "&:hover": {
          background: "none",
        },
      }}
      startIcon={<HubspotIcon sx={{ ml: 1, fontSize: 20 }} />}
      title="in HubSpot öffnen"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (resource !== "drivingSchools" && !(record as Instructor | Student).hubspotContactId) {
          notify(`Keine HubSpot-Kontakt-ID für ${record.name} hinterlegt.`, { type: "error" });
        } else {
          open(url, "_blank");
        }
      }}
    >
      {showTitle ? "In HubSpot öffnen" : null}
    </Button>
  );
}
