import { Skeleton, Avatar, Tooltip } from "@mui/material";
import { useGetOne } from "react-admin";
import { Instructor } from "../providers/instructorsProvider";

export const InstructorAvatar = ({
  instructorId,
  size,
  withTooltip,
}: {
  instructorId: string;
  size: "20px" | "40px";
  withTooltip?: boolean;
}) => {
  const { data, isLoading, error } = useGetOne<Instructor>("instructors", { id: instructorId });
  if (isLoading) {
    return <Skeleton variant="circular" width={size} height={size} />;
  }
  if (error || !data) {
    return (
      <Avatar sx={{ bgcolor: "red", width: { size }, height: { size } }}>
        <b>!</b>
      </Avatar>
    );
  }
  const avatar = <Avatar alt={data.name} src={data.avatarUrl} sx={{ width: size, height: size }} />;
  return withTooltip ? (
    <Tooltip title={data.name} arrow placement="bottom">
      {avatar}
    </Tooltip>
  ) : (
    avatar
  );
};
