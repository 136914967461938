import * as React from "react";
import { SvgIcon } from "@mui/material";

export function StudentsIcon() {
  return (
    <SvgIcon>
      <path d="M13.07,10.4101037 C14.3154081,8.6699802 14.3154081,6.33022728 13.07,4.59010374 C13.6385159,4.20205924 14.3116875,3.99627102 15,4.00005116 C16.9329966,4.00005116 18.4999999,5.56710713 18.4999999,7.50010374 C18.4999999,9.43310034 16.9329966,11.0001563 15,11.0001563 C14.3116875,11.0039365 13.6385159,10.7981482 13.07,10.4101037 M5.5,7.50010374 C5.5,5.56710711 7.06700338,4.00010374 9,4.00010374 C10.9329966,4.00010374 12.5,5.56710711 12.5,7.50010374 C12.5,9.43310036 10.9329966,11.0001037 9,11.0001037 C7.06700338,11.0001037 5.5,9.43310036 5.5,7.50010374 M7.5,7.50010374 C7.5,8.32853086 8.17157288,9.00010374 9,9.00010374 C9.82842712,9.00010374 10.5,8.32853086 10.5,7.50010374 C10.5,6.67167661 9.82842712,6.00010374 9,6.00010374 C8.17157288,6.00010374 7.5,6.67167661 7.5,7.50010374 M16,17.0001037 L16,19.0001037 L2,19.0001037 L2,17.0001037 C2,17.0001037 2,13.0001037 9,13.0001037 C16,13.0001037 16,17.0001037 16,17.0001037 M14,17.0001037 C13.86,16.2201037 12.67,15.0001037 9,15.0001037 C5.33,15.0001037 4.07,16.3101037 4,17.0001037 M15.95,13.0001037 C17.1906849,13.9651295 17.941124,15.4294009 18,17.0001037 L18,19.0001037 L22,19.0001037 L22,17.0001037 C22,17.0001037 22,13.3701037 15.94,13.0001037 L15.95,13.0001037 Z" />
    </SvgIcon>
  );
}
