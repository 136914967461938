import { z } from "zod";
import { GeoPoint } from "firebase/firestore";

export const PostalAddressSchema = z.object({
  street: z.optional(z.string()).transform((val) => val ?? ""),
  postalCode: z.optional(z.string()).transform((val) => val ?? ""),
  city: z.optional(z.string()).transform((val) => val ?? ""),
  geoCoordinates: z.optional(
    z.preprocess(
      (val) => (val instanceof GeoPoint ? { lat: val.latitude, lng: val.longitude } : val),
      z.object({ lat: z.number(), lng: z.number() }),
    ),
  ),
});
export type PostalAddress = z.infer<typeof PostalAddressSchema>;
