import { Datagrid, List, NumberField, ReferenceInput, Resource, SelectInput, TextField } from "react-admin";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { useMemo, useState } from "react";
import { DateField } from "../fields/DateField";
import { AppliedReferralCode } from "../providers/appliedReferralCodesProvider";
import { ReferralCodeApplicationsDialog } from "../dialogs/ReferralCodeApplicationsDialog";
import { ShowIconButton } from "../buttons/ShowIconButton";

export function AppliedReferralCodeList() {
  const [selectedRecord, setSelectedRecord] = useState<undefined | AppliedReferralCode>();
  const filters = useMemo(
    () => [
      ...(restrictAccessToDrivingSchoolIds?.length === 1
        ? []
        : [
            <ReferenceInput
              source="drivingSchoolId"
              alwaysOn
              reference="drivingSchools"
              page={1}
              perPage={999}
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput label="Fahrschule" />
            </ReferenceInput>,
          ]),
    ],
    [],
  );

  return (
    <>
      <List
        title={<BackofficeListTitle />}
        filters={filters}
        sort={{ field: "numApplied", order: "DESC" }}
        exporter={false}
      >
        <Datagrid
          rowClick={(_, __, record) => {
            setSelectedRecord(record as AppliedReferralCode);
            return false;
          }}
          bulkActionButtons={false}
        >
          <TextField label="Fahrschüler" source="student.name" sortable={false} />
          <TextField label="Status" source="student.verboseStatus" sortable={false} />
          {restrictAccessToDrivingSchoolIds?.length !== 1 && (
            <TextField label="Fahrschule" source="drivingSchool.name" sortable={false} />
          )}
          <TextField label="Code" source="code" sx={{ whiteSpace: "nowrap" }} sortable={false} />
          <NumberField label="Anzahl Anmeldungen" source="numApplied" sortable={false} />
          <DateField label="Datum letzte Anmeldung" source="lastAppliedAt" showDate="with diff" sortable={false} />
          <ShowIconButton />
        </Datagrid>
      </List>
      <ReferralCodeApplicationsDialog
        appliedReferralCode={selectedRecord}
        onClose={() => setSelectedRecord(undefined)}
      />
    </>
  );
}

export const appliedReferralCodesResource = (
  <Resource
    key="appliedReferralCodes"
    name="appliedReferralCodes"
    list={AppliedReferralCodeList}
    options={{ label: "Empfehlungsprogramm" }}
    recordRepresentation={(referralCode: AppliedReferralCode) => referralCode.id}
  />
);
