import { Student } from "../providers/studentsProvider";
import { BlockStudentAndSendPaymentReminderButton } from "./BlockStudentAndSendPaymentReminderButton";
import { SendFirstDunningNoticeButton } from "./SendFirstDunningNoticeButton";
import { SendSecondDunningNoticeButton } from "./SendSecondDunningNoticeButton";
import { HandoverToPairFinanceButton } from "./HandoverToPairFinanceButton";
import { assertNever } from "../backoffice.utils";
import { DateField } from "../fields/DateField";
import { grants } from "../backoffice.access_control";
import { useRecordContext } from "react-admin";
import { Row } from "../misc/Row";
import { Typography } from "@mui/material";

export function DunningButtons() {
  const student = useRecordContext<Student>();
  if (!student || !grants.includes("sendDunningNotice")) {
    return null;
  }
  const { dunningProcess, balance, status: studentStatus } = student;
  if (!dunningProcess) {
    return balance >= 0 ? null : studentStatus !== "outstandingPayments" ? (
      <BlockStudentAndSendPaymentReminderButton />
    ) : (
      <SendFirstDunningNoticeButton />
    );
  } else if (dunningProcess.status === "warningSent") {
    return (
      <Row spacing={1} alignItems="center">
        <Typography variant="body1">Warn-E-Mail gesendet am:</Typography>
        <DateField source="dunningProcess.warningSentAt" showDate="with diff" variant="body1" />
        {student.status !== "outstandingPayments" ? (
          <BlockStudentAndSendPaymentReminderButton />
        ) : (
          <SendFirstDunningNoticeButton />
        )}
      </Row>
    );
  } else if (dunningProcess.status === "paymentReminderSent") {
    return (
      <Row spacing={1} alignItems="center">
        <Typography variant="body1">Zahlungserinnerung gesendet am:</Typography>
        <DateField source="dunningProcess.paymentReminderSentAt" showDate="with diff" variant="body1" />
        <SendFirstDunningNoticeButton />
      </Row>
    );
  } else if (dunningProcess.status === "firstDunningNoticeSent") {
    return (
      <Row spacing={1} alignItems="center">
        <Typography variant="body1">1. Mahnung gesendet am:</Typography>
        <DateField source="dunningProcess.firstDunningNoticeSentAt" showDate="with diff" variant="body1" />
        <SendSecondDunningNoticeButton />
      </Row>
    );
  } else if (dunningProcess.status === "secondDunningNoticeSent") {
    return (
      <Row spacing={1} alignItems="center">
        <Typography variant="body1">2. Mahnung gesendet am:</Typography>
        <DateField source="dunningProcess.secondDunningNoticeSentAt" showDate="with diff" variant="body1" />
        <HandoverToPairFinanceButton />
      </Row>
    );
  } else if (dunningProcess.status === "handedOverToPairFinance") {
    return (
      <Row spacing={1} alignItems="center">
        <Typography variant="body1">Inkasso gestartet am:</Typography>
        <DateField source="dunningProcess.handedOverToPairFinanceAt" showDate="with diff" variant="body1" />
      </Row>
    );
  } else {
    assertNever(dunningProcess.status);
  }
}
