import RemoveIcon from "@mui/icons-material/Remove";
import { Box, SxProps } from "@mui/material";
import { TheoryLesson } from "../model/autovioCalendarEvents";
import { useFormContext, useWatch } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { useRemoveStudentsFromTheoryLessonMutation } from "../api/backoffice.api";
import { useNotify } from "react-admin";

export function RemoveSelectedStudentsFromTheoryLessonButton({
  sx,
  theoryLesson,
}: {
  sx: SxProps;
  theoryLesson: TheoryLesson;
}) {
  const studentUids = useWatch<{ selectedStudents: Array<string> }>({ name: "selectedStudents" }) as Array<string>;
  const { setValue: setFormValue } = useFormContext();
  const [removingStudents, setRemovingStudents] = useState(false);
  const removeStudentsFromTheoryLessonMutation = useRemoveStudentsFromTheoryLessonMutation();
  const notify = useNotify();

  const removeStudentsFromTheoryLesson = async (studentUids: Array<string>) => {
    try {
      setRemovingStudents(true);
      await removeStudentsFromTheoryLessonMutation.mutateAsync({
        studentUids,
        calendarEventUid: theoryLesson.id,
      });
      notify(`Fahrschüler erfolgreich entfernt.`, { type: "success" });
    } catch (error) {
      console.error(`Failed to remove students ${studentUids.join(", ")} from theory lesson ${theoryLesson.id}`, error);
      if (studentUids.length === 1) {
        notify(`Fehler beim Entfernen des Fahrschülers.`, { type: "error" });
      } else {
        notify(`Fehler beim Entfernen der Fahrschüler.`, { type: "error" });
      }
    } finally {
      setRemovingStudents(false);
      setFormValue("selectedStudents", []);
    }
  };

  return (
    <Box sx={sx}>
      <LoadingButton
        loading={removingStudents}
        variant="outlined"
        sx={{ height: "40px" /* ... same height as other buttons in dialog. */ }}
        startIcon={<RemoveIcon />}
        onClick={() => removeStudentsFromTheoryLesson(studentUids)}
        disabled={!studentUids.length}
      >
        ausgewälte Fahrschüler kostenfrei entfernen
      </LoadingButton>
    </Box>
  );
}
