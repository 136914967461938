import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FieldProps, useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { formatDateDiff } from "./DateField";

export function MostRecentLessonField(_: FieldProps) {
  const student = useRecordContext<Student>();
  if (!student) {
    return null;
  }
  const { mostRecentDrivingLessonDate, mostRecentTheoryLessonDate } = student?.kpis ?? {};
  const mostRecentLessonDate = mostRecentDrivingLessonDate
    ? mostRecentTheoryLessonDate
      ? mostRecentDrivingLessonDate < mostRecentTheoryLessonDate
        ? mostRecentTheoryLessonDate
        : mostRecentDrivingLessonDate
      : mostRecentDrivingLessonDate
    : mostRecentTheoryLessonDate;
  if (!mostRecentLessonDate) {
    return null;
  }
  return (
    <Typography component="span" variant="body2">
      {formatDateDiff(DateTime.now(), mostRecentLessonDate)}
    </Typography>
  );
}
