import { useEffect, useState } from "react";

function _importRecharts() {
  return import("recharts");
}

type _Recharts = Awaited<ReturnType<typeof _importRecharts>>;

let _recharts: undefined | _Recharts;

export function useRecharts() {
  const [recharts, setRecharts] = useState<undefined | _Recharts>(_recharts);
  useEffect(() => {
    if (!_recharts) {
      void import("recharts").then((recharts) => setRecharts((_recharts = recharts)));
    }
  }, []);
  return (recharts ?? {}) as Partial<_Recharts>;
}
