import { ReferenceArrayInput, SelectArrayInput, TextInput } from "react-admin";
import { useIsDisabled } from "../backoffice.utils";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";

export function EntitledDrivingSchoolsInput() {
  if (restrictAccessToDrivingSchoolIds?.length === 1) {
    return (
      <TextInput
        type="hidden"
        source="entitledDrivingSchools"
        defaultValue={[restrictAccessToDrivingSchoolIds[0]]}
        style={{ display: "none" }}
      />
    );
  }
  const disabled = useIsDisabled("entitledDrivingSchools");
  return (
    <ReferenceArrayInput source="entitledDrivingSchools" reference="drivingSchools">
      <SelectArrayInput
        label="zugeordnete Fahrschule(n)"
        className="fix-SelectArrayInput-label"
        fullWidth
        disabled={disabled}
      />
    </ReferenceArrayInput>
  );
}
