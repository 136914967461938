import { Link, Typography } from "@mui/material";
import get from "lodash/get";
import { FieldProps, useRecordContext } from "react-admin";
import { AddressDisplay } from "../../misc/AddressDisplay";
import { PostalAddress } from "../../model/PostalAddress";

export const PostalAddressField = (props: { source: string } & FieldProps) => {
  const { source, emptyText } = props;
  const record = useRecordContext(props);
  const postalAddress: PostalAddress = get(record, source);

  if (!postalAddress || (!postalAddress.city && !postalAddress.street && !postalAddress.geoCoordinates)) {
    return <Typography variant="body2">{emptyText}</Typography>;
  }

  const googleMapsUrl = getGoogleMapsUrl(postalAddress);

  const address = (
    <Typography variant="body2">
      <AddressDisplay address={postalAddress} />
    </Typography>
  );

  if (!googleMapsUrl) {
    return address;
  }

  return (
    <Link
      href={googleMapsUrl}
      underline="none"
      aria-label="Öffnen in Google Maps"
      target="_blank"
      rel="noopener noreferrer"
    >
      {address}
    </Link>
  );
};

function getGoogleMapsUrl(postalAddress: PostalAddress): string | undefined {
  const mapsURL = "https://www.google.com/maps/search/?api=1&query=";
  let query = "";
  if (postalAddress.street) {
    query += postalAddress.street;
  }
  if (postalAddress.postalCode) {
    query += " " + postalAddress.postalCode;
  }
  if (postalAddress.city) {
    query += " " + postalAddress.city;
  }
  if (postalAddress.geoCoordinates && !query) {
    query += "&query_place_id=" + postalAddress.geoCoordinates.lat + "," + postalAddress.geoCoordinates.lng;
  }
  const encodedMapsUrl = query ? encodeURI(mapsURL + query) : undefined;
  return encodedMapsUrl;
}
