import ActivateIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { MouseEvent, useState } from "react";
import { reportError } from "../backoffice.utils";
import { setStudentStatus } from "../api/backoffice.api";
import { grants } from "../backoffice.access_control";

export function ActivateStudentButton() {
  const student = useRecordContext<Student>();
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const showErrorToast = (message: string) => notify(message, { type: "error" });
  const activateStudent = async (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (student.status === "completed") {
      showErrorToast("Ein Fahrschüler, der alle Fahrprüfungen bestanden hat, kann nicht aktiviert werden.");
      return;
    }
    if (student.status === "outstandingPayments" && !grants.includes("activateStudentWithOutstandingPayments")) {
      showErrorToast("Du bist nicht berechtigt, einen Fahrschüler mit ausstehenden Zahlungen zu aktivieren.");
      return;
    }
    setLoading(true);
    try {
      await setStudentStatus(student, "active", /* reason */ "");
      notify(`${student.name} erfolgreich aktiviert.`, { type: "success" });
      refresh();
    } catch (error) {
      reportError(`Failed to activate student ${student.id}`, error);
      notify(`Fehler beim Aktivieren von ${student.name}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <LoadingButton
      startIcon={<ActivateIcon />}
      variant="outlined"
      loading={loading}
      disabled={!student}
      onClick={activateStudent}
    >
      Aktivieren
    </LoadingButton>
  );
}
