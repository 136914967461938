import { Box } from "@mui/material";
import { ListBase, Pagination, useGetRecordId, useRecordContext } from "react-admin";
import { CreateManualInvoiceButton } from "../../buttons/CreateManualInvoiceButton";
import { InvoicesList } from "../../lists/InvoicesList";
import { DunningButtons } from "../../buttons/DunningButtons";
import { Row } from "../../misc/Row";
import type { Student } from "../../providers/studentsProvider.js";
import { LoadingIndicator } from "../../misc/LoadingIndicator.js";

export function StudentInvoicesList() {
  const studentId = useGetRecordId();
  const student = useRecordContext<Student>();
  if (!student) {
    return <LoadingIndicator />;
  }
  return (
    <ListBase resource="b2cInvoices" filter={{ studentId }}>
      <Box position="relative">
        <Box position="absolute" top="10px" right="0px" zIndex={10}>
          <Row spacing={1}>
            <DunningButtons />
            <CreateManualInvoiceButton />
          </Row>
        </Box>
      </Box>
      <div style={{ margin: "20px -20px 0 -20px" }}>
        <InvoicesList student={student} />
        <Pagination />
      </div>
    </ListBase>
  );
}
