import { Avatar } from "@mui/material";
import { Datagrid, FunctionField, Pagination, TextField, useRecordContext, WithRecord } from "react-admin";
import { ShowButton } from "../../buttons/ShowButton";
import { ActiveStudentsField } from "../../fields/ActiveStudentsField";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { Instructor } from "../../providers/instructorsProvider";
import { PageTitle } from "../../misc/PageTitle";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";

export function DrivingSchoolInstructorsList() {
  const drivingSchool = useRecordContext<DrivingSchool>();

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>Fahrlehrer</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  return (
    <>
      <PageTitle>Fahrlehrer</PageTitle>
      <ReferenceManyField
        reference="instructors"
        target="drivingSchoolId"
        label=""
        sort={{ field: "name", order: "ASC" }}
        pagination={<Pagination />}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <WithRecord
            render={(instructor) => <Avatar src={instructor?.avatarOverrideUrl ?? instructor?.avatarUrl} />}
          />
          <TextField source="name" />
          <FunctionField
            label=""
            render={(instructor?: Instructor) => (instructor?.isDrivingSchoolManager ? "Fahrschulleiter" : "")}
          />
          <ActiveStudentsField label="aktive Fahrschüler" />
          <FunctionField
            label="Klassen"
            render={(instructor?: Instructor) => (instructor?.instructing.drivingLicenseClasses ?? []).join(", ")}
          />
          <ShowButton label="" />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}
