import { Resource } from "react-admin";
import { StudentsIcon } from "../icons/StudentsIcon";
import { StudentDetails } from "../details/StudentDetails";

export const studentsResource = (
  <Resource
    key="students"
    name="students"
    icon={StudentsIcon}
    edit={StudentDetails}
    options={{ label: "Fahrschüler" }}
    recordRepresentation={(student) => student.name.replaceAll(" ", "\u00A0")}
  />
);
