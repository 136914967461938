import Download from "@mui/icons-material/Download";
import { Alert, Typography } from "@mui/material";
import { ReactElement, cloneElement } from "react";
import { DatagridConfigurable, FunctionField, List, useRecordContext } from "react-admin";
import { RecordListButton } from "../../buttons/RecordListButton.js";
import { Column } from "../../misc/Column.js";
import { LoadingIndicator } from "../../misc/LoadingIndicator.js";
import { Row } from "../../misc/Row.js";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider.js";
import type { TaxConsultantExportRecord } from "../../providers/taxConsultantsExportsProvider.js";
import { PageTitle } from "../../misc/PageTitle";
import { grants } from "../../backoffice.access_control";
import { AccessDenied } from "../../misc/AccessDenied";

export function TaxConsultantExports({ className }: { className?: string }) {
  const storeKey = "TaxConsultantExports_v1";
  const drivingSchool = useRecordContext<DrivingSchool>();
  const columns: ReactElement[] = [
    <FunctionField
      label="Monat"
      source="startDate"
      render={(record: TaxConsultantExportRecord) =>
        record.startDate.toLocaleString({ year: "numeric", month: "long" }, { locale: "de-DE" })
      }
    />,
    <FunctionField
      label="Aktionen"
      render={(_: TaxConsultantExportRecord) => {
        return (
          <RecordListButton
            source="zip"
            emptyText="Kein Download verfügbar"
            title="Download"
            startIcon={<Download />}
          />
        );
      }}
      sortable={false}
    />,
  ].map((it, index) => cloneElement(it, { key: index }));

  if (!grants.includes("viewPayoutInformation")) {
    return (
      <>
        <PageTitle>Steuerbüro-Exports</PageTitle>
        <AccessDenied />
      </>
    );
  }

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>Steuerbüro-Exports</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  const drivingSchoolId = drivingSchool.uid;

  return (
    <>
      <PageTitle>Steuerbüro-Exports</PageTitle>
      <Row sx={{ m: "20px" }} spacing="50px">
        <Column flex={3}>
          <List
            className={className}
            resource={"taxConsultantExports"}
            storeKey={storeKey}
            title=" " // <-- prevent that the default list title is rendered
            filter={{ drivingSchoolId }}
            sort={{ field: "serviceTime", order: "DESC" }}
            exporter={false}
            empty={
              <Alert severity="info">
                <Typography>
                  Steuerbüro-Exports werden zeitnah hier erscheinen. Wir bitten noch um ein wenig Geduld.
                </Typography>
              </Alert>
            }
          >
            <DatagridConfigurable preferenceKey={storeKey} bulkActionButtons={false}>
              {columns}
            </DatagridConfigurable>
          </List>
        </Column>
        <Column flex={1}>
          <Typography variant="subtitle1">Information</Typography>
          <Typography variant="body2" component="div">
            <p>
              Hier findest du monatliche Exports, die du an deinen Steuerberater weiterleiten kannst. Der Download ist
              eine ZIP Datei mit allen relevanten Dokumenten eines Monats:
            </p>
            <ul>
              <li>Alle Rechnungen, die in dem Monat erstellt wurden</li>
              <li>Liste der offenen Posten</li>
              <li>Alle Auszahlungsberichte, die in dem Monat getätigt wurden</li>
              <li>Einem DATEV Stapel für deinen Steuerberater</li>
              <li>Einer DATEV Belegbilder ZIP-Datei für deinen Steuerberater</li>
            </ul>
          </Typography>
          <Typography variant="subtitle1">DATEV</Typography>
          <Typography variant="body2" component="div">
            <p>
              Die DATEV-Software ist in Deutschland weit verbreitet und wird von vielen Steuerberatern genutzt. Wenn du
              einen Steuerberater hast, der DATEV nutzt, kannst du ihm die Exports zur Verfügung stellen. Damit DATEV
              Exports erstellt werden können, benötigen wir folgende Informationen von dir bzw. von deinem
              Steuerberater. Melde dich dazu am besten bei deinem AUTOVIO Partnerbetreuer.
            </p>
            <ul>
              <li>Beraternummer</li>
              <li>Mandatennummer</li>
            </ul>
          </Typography>
        </Column>
      </Row>
    </>
  );
}
