import type { Student } from "../providers/studentsProvider";
import type { DialogProps } from "../misc/DialogProps";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ActivitiesTimeline } from "../details/student/ActivitiesTimeline";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export function AllActivitiesDialog({ student, ...dialogProps }: { student: Student } & DialogProps) {
  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Alle Aktivitäten</DialogTitle>
      <DialogCloseButton onClose={dialogProps.onClose} />
      <DialogContent sx={{ width: 400, height: 600 }}>
        <ActivitiesTimeline student={student} pagination={{ page: 1, perPage: 10000 }} />
      </DialogContent>
    </Dialog>
  );
}
