import { AbstractProvider } from "./abstractProvider";
import type { GetManyReferenceParams, GetManyReferenceResult } from "react-admin";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";
import { DateTime } from "luxon";
import { z } from "zod";
import { ZodiosPlugin } from "@zodios/core";

export type StudentActivityDto = z.infer<typeof schemas.StudentActivityDto>;
export type StudentActivity = Omit<StudentActivityDto, "dateTime"> & { id: string; dateTime: DateTime };

class StudentActivitiesProvider extends AbstractProvider<StudentActivity> {
  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination: { page, perPage } }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<StudentActivity>> {
    if (target !== "studentUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentUid"`);
    }
    const serverClient = await getAuthenticatedServerClient();
    let total: undefined | number;
    const extractTotalFromContentRangeHeader: ZodiosPlugin = {
      response: async (_api, _config, response) => {
        try {
          const contentRangeHeader = response.headers["content-range"];
          if (contentRangeHeader) {
            total = parseInt(contentRangeHeader.substring(contentRangeHeader.lastIndexOf("/") + 1));
          }
        } catch (error) {
          console.warn("Failed to extract total number of student activities from Content-Range header", error);
        }
        return response;
      },
    };
    serverClient.use(extractTotalFromContentRangeHeader);
    const studentActivities = await serverClient.listStudentActivities({
      params: { id: id.toString() },
      queries: {
        filter: JSON.stringify(filter),
        sort: JSON.stringify([sort.field, sort.order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      },
    });
    return { data: studentActivities.map(_dto2record), total };
  }
}

export const studentActivitiesProvider = new StudentActivitiesProvider();

function _dto2record(dto: StudentActivityDto): StudentActivity {
  const { dateTime, ...rest } = dto;
  return {
    id: "TODO",
    dateTime: DateTime.fromISO(dateTime).setZone("Europe/Berlin"),
    ...rest,
  };
}
