import { Button, Grid, Typography } from "@mui/material";
import { getDownloadURL } from "firebase/storage";
import * as React from "react";
import { useState } from "react";
import { EditBase, SaveButton, SimpleForm, SimpleShowLayout, Toolbar, useNotify, useRecordContext } from "react-admin";
import { setUserAvatarOverride } from "../../api/backoffice.api";
import { ImageUploadField } from "../../fields/ImageUploadField";
import { Instructor } from "../../providers/instructorsProvider";
import { gcs } from "../../utils/storage";
import { FeatureTogglesInput } from "../../inputs/FeatureTogglesInput";
import ContentSave from "@mui/icons-material/Save";
import { InstructorGrantsInput } from "../../inputs/InstructorGrantsInput";

export function InstructorAdminForm() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SimpleShowLayout sx={{ padding: "1em 0" }}>
          <AvatarOverrideEdit />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={8}>
        <EditBase redirect={false}>
          <SimpleForm
            sx={{ padding: "1em 0" }}
            shouldUnregister
            warnWhenUnsavedChanges
            toolbar={
              <Toolbar style={{ padding: 0 }}>
                <SaveButton />
              </Toolbar>
            }
          >
            <h4 style={{ marginTop: 0, marginBottom: "0.35em" }}>Feature Toggles</h4>
            <FeatureTogglesInput />
            <h4 style={{ marginBottom: "0.35em" }}>Berechtigungen</h4>
            <InstructorGrantsInput />
          </SimpleForm>
        </EditBase>
      </Grid>
    </Grid>
  );
}

const AvatarOverrideEdit = () => {
  const instructor = useRecordContext<Instructor>();
  const instructorId = instructor.id;
  const notify = useNotify();
  const [files, setFiles] = useState<File[]>([]);
  return (
    <>
      <Typography fontWeight="bold" gutterBottom>
        Avatar Bild
      </Typography>
      <ImageUploadField defaultImageUrl={instructor.avatarOverrideUrl} onDropImage={(files) => setFiles(files)} />
      <Button
        sx={{ maxWidth: "200px", mt: "20px" }}
        variant="contained"
        disabled={files.length !== 1}
        onClick={async () => {
          const file = files[0];
          try {
            const result = await gcs.uploadFile(
              file,
              (ext) => `/users/${instructorId.toString()}/avatarOverride/avatar_override.${ext}`,
            );
            await setUserAvatarOverride(instructorId.toString(), await getDownloadURL(result.ref));
          } catch (e) {
            notify("Fehler beim Hochladen des Avatars", { type: "error" });
            return;
          }
          notify("Avatar erfolgreich gespeichert", { type: "success" });
        }}
      >
        <ContentSave style={{ width: "18px", height: "18px", marginRight: "8px" }} />
        Speichern
      </Button>
    </>
  );
};
