/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPerformanceOverviewEntryList } from '../models/PaginatedPerformanceOverviewEntryList';
import type { PerformanceOverviewEntry } from '../models/PerformanceOverviewEntry';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PerformanceOverviewEntryEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedPerformanceOverviewEntryList
   * @throws ApiError
   */
  public performanceOverviewEntryList({
    autovioPayoutsId,
    companyDrivingSchoolId,
    excludeServiceTypes,
    instructorIdentifier,
    limit,
    offset,
    ordering,
    paymentState,
    payoutsId,
    serviceAtGt,
    serviceAtGte,
    serviceAtLt,
    serviceAtLte,
    serviceTime,
    studentIdentifier,
    tableType,
  }: {
    autovioPayoutsId?: string,
    companyDrivingSchoolId?: string,
    excludeServiceTypes?: string,
    instructorIdentifier?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    /**
     * Which field to use when ordering the results.
     */
    ordering?: 'service_at' | '-service_at' | 'payment_state' | '-payment_state' | 'instructor_identifier' | '-instructor_identifier' | 'student_identifier' | '-student_identifier' | 'invoice_number' | '-invoice_number' | 'main_item_description' | '-main_item_description' | 'main_item_quantity' | '-main_item_quantity' | 'main_item_single_price_gross' | '-main_item_single_price_gross' | 'main_item_total_price_gross' | '-main_item_total_price_gross' | 'total_credits_amount_used' | '-total_credits_amount_used' | 'prepaid_credits_amount_used' | '-prepaid_credits_amount_used' | 'invoice_paid_amount' | '-invoice_paid_amount' | 'invoice_total' | '-invoice_total' | 'main_item_vat_percentage' | '-main_item_vat_percentage' | 'invoice_total_net' | '-invoice_total_net' | 'applied_application_fee_amount_net' | '-applied_application_fee_amount_net' | 'applied_application_fee_amount' | '-applied_application_fee_amount' | 'partner_turnover_amount_net' | '-partner_turnover_amount_net' | 'partner_turnover_amount' | '-partner_turnover_amount' | 'compensation' | '-compensation' | 'compensation_net' | '-compensation_net' | 'applied_application_fee_percentage' | '-applied_application_fee_percentage',
    paymentState?: string,
    payoutsId?: string,
    serviceAtGt?: string,
    serviceAtGte?: string,
    serviceAtLt?: string,
    serviceAtLte?: string,
    serviceTime?: string,
    studentIdentifier?: string,
    /**
     * * `unpaid` - unpaid
     * * `services` - services
     * * `prepaid` - prepaid
     */
    tableType?: 'prepaid' | 'services' | 'unpaid',
  }): CancelablePromise<PaginatedPerformanceOverviewEntryList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/performance-overview-entry/',
      query: {
        'autovio_payouts__id': autovioPayoutsId,
        'company__driving_school_id': companyDrivingSchoolId,
        'exclude_service_types': excludeServiceTypes,
        'instructor_identifier': instructorIdentifier,
        'limit': limit,
        'offset': offset,
        'ordering': ordering,
        'payment_state': paymentState,
        'payouts__id': payoutsId,
        'service_at__gt': serviceAtGt,
        'service_at__gte': serviceAtGte,
        'service_at__lt': serviceAtLt,
        'service_at__lte': serviceAtLte,
        'service_time': serviceTime,
        'student_identifier': studentIdentifier,
        'table_type': tableType,
      },
    });
  }

  /**
   * @returns PerformanceOverviewEntry
   * @throws ApiError
   */
  public performanceOverviewEntryRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this performance overview entry.
     */
    id: string,
  }): CancelablePromise<PerformanceOverviewEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/performance-overview-entry/{id}/',
      path: {
        'id': id,
      },
    });
  }

}
