import { FieldProps, sanitizeFieldRestProps } from "react-admin";
import * as React from "react";
import get from "lodash/get";
import { Button, Typography } from "@mui/material";
import { useRecordContext } from "ra-core";
import FirestoreIcon from "@mui/icons-material/LocalFireDepartment";
import { firebaseApp } from "../firebase";

interface LinkToFirestoreFieldProps extends FieldProps {
  source: string;
  collection: string;
}

function _LinkToFirestoreField(props: LinkToFirestoreFieldProps) {
  const { className, emptyText, source, collection, ...rest } = props;
  const record = useRecordContext(props);
  const documentId = get(record, source);

  if (!documentId) {
    return (
      <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
        {emptyText}
      </Typography>
    );
  }

  const { projectId } = firebaseApp.options;
  const path = `${collection}/${documentId}`;
  const url = `https://console.firebase.google.com/project/${projectId}/firestore/data/${path.replaceAll("/", "~2F")}`;

  return (
    <Button
      variant="text"
      startIcon={<FirestoreIcon sx={{ ml: 1, width: "18px", height: "18px" }} />}
      sx={{ minWidth: "32px" }}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        open(url, "_blank");
      }}
    />
  );
}

export const LinkToFirestoreField = React.memo(_LinkToFirestoreField);

LinkToFirestoreField.displayName = "LinkToFirestoreField";
