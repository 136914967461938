import EditIcon from "@mui/icons-material/EditOutlined";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { EditBase, Form, required, SaveButton, SelectInput, useGetOne, useNotify, useRecordContext } from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import { setBranch } from "../api/backoffice.api";
import { reportError } from "../backoffice.utils";
import { useDialog } from "../hooks/useDialog";
import { autovioColors } from "../misc/backofficeTheme";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { Student } from "../providers/studentsProvider";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export function SetStudentBranchButton() {
  const { dialogProps, openDialog } = useDialog();
  return (
    <>
      <IconButton onClick={openDialog}>
        <EditIcon sx={{ fill: autovioColors.green }} />
      </IconButton>
      <SetStudentBranchDialog {...dialogProps} />
    </>
  );
}

interface SetStudentBranchDialogProps {
  open: boolean;
  onClose: () => void;
}

function SetStudentBranchDialog({ open, onClose }: SetStudentBranchDialogProps) {
  const student = useRecordContext<Student>();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading: mutationIsLoading } = useMutation(
    (branchUid: string) =>
      setBranch({ studentUid: student.id, branchUid: branchUid === "NONE" ? "" : branchUid }, queryClient),
    {
      onSuccess: () => {
        notify(`Filiale von ${student.name} erfolgreich geändert.`, { type: "success" });
      },
      onError: (error) => {
        reportError(`Failed to set branch of student ${student?.id}`, error);
        notify(`Fehler beim Ändern der Filiale von ${student?.name}.`, { type: "error" });
      },
      onSettled: () => onClose(),
    },
  );

  const {
    data: drivingSchool,
    isLoading: drivingSchoolIsLoading,
    error: drivingSchoolError,
  } = useGetOne<DrivingSchool>("drivingSchools", { id: student.drivingSchoolId });

  const onSubmit = async (data: Partial<Student>) => {
    const newBranchId = data.branchId;
    if (!newBranchId) {
      throw new Error("No branch selected");
    }
    await mutateAsync(newBranchId);
  };

  const choices = drivingSchool?.branches.map((branch) => ({
    id: branch.uid,
    name: branch.name,
  }));
  const choicesWithNone = [{ id: "NONE", name: "Keine feste Filiale" }, ...(choices ?? [])];

  const isLoading = mutationIsLoading || drivingSchoolIsLoading;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filiale ändern</DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <EditBase redirect={false} mutationMode="pessimistic" mutationOptions={{ onSuccess: onClose }}>
        <Form onSubmit={onSubmit}>
          <DialogContent sx={{ width: "360px" }}>
            <SelectInput
              fullWidth
              source="branchId"
              label="Filiale"
              choices={choicesWithNone}
              validate={required()}
              disabled={isLoading}
              helperText={drivingSchoolError ? <Box color="red">Fehler beim Laden der Filialen.</Box> : undefined}
            />
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </EditBase>
    </Dialog>
  );
}
