/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DunningProcess } from '../models/DunningProcess';
import type { EmailDraft } from '../models/EmailDraft';
import type { PaginatedDunningProcessList } from '../models/PaginatedDunningProcessList';
import type { PatchedDunningProcess } from '../models/PatchedDunningProcess';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DunningProcessEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedDunningProcessList
   * @throws ApiError
   */
  public dunningProcessList({
    limit,
    offset,
    studentId,
  }: {
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    studentId?: string,
  }): CancelablePromise<PaginatedDunningProcessList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/dunning-process/',
      query: {
        'limit': limit,
        'offset': offset,
        'student__id': studentId,
      },
    });
  }

  /**
   * @returns DunningProcess
   * @throws ApiError
   */
  public dunningProcessCreate({
    requestBody,
  }: {
    requestBody: DunningProcess,
  }): CancelablePromise<DunningProcess> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/dunning-process/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns DunningProcess
   * @throws ApiError
   */
  public dunningProcessRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this dunning process.
     */
    id: string,
  }): CancelablePromise<DunningProcess> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/dunning-process/{id}/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @returns DunningProcess
   * @throws ApiError
   */
  public dunningProcessPartialUpdate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this dunning process.
     */
    id: string,
    requestBody?: PatchedDunningProcess,
  }): CancelablePromise<DunningProcess> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/dunning-process/{id}/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns EmailDraft
   * @throws ApiError
   */
  public dunningProcessDraftRetrieve({
    id,
    noticeType,
  }: {
    /**
     * A UUID string identifying this dunning process.
     */
    id: string,
    noticeType?: 'first_dunning_notice' | 'inkasso_notice' | 'second_dunning_notice' | 'student_blocked' | 'warning',
  }): CancelablePromise<EmailDraft> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/dunning-process/{id}/draft/',
      path: {
        'id': id,
      },
      query: {
        'notice_type': noticeType,
      },
    });
  }

  /**
   * Determines which invoices should be included in a dunning notice.
   * If there is already a dunning process for the given student, which is not closed yet,
   * this dunning process is updated with the determined invoices and returned,
   * otherwise a new dunning process with the determined invoices is created and returned.
   * @returns DunningProcess
   * @throws ApiError
   */
  public dunningProcessPrepareForDraftRetrieve({
    studentId,
  }: {
    studentId?: string,
  }): CancelablePromise<DunningProcess> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/dunning-process/prepare-for-draft/',
      query: {
        'student_id': studentId,
      },
    });
  }

}
