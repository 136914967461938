import {
  RaRecord,
  useShowController,
  ShowControllerProps,
  ShowContextProvider,
  ResourceContextProvider,
} from "react-admin";

/**
 * Copy of ShowBase.tsx from react-admin with proper typing of children.
 */
export const ShowBase = <RecordType extends RaRecord = any>({
  children,
  ...props
}: { children: React.ReactNode } & ShowControllerProps<RecordType>) => {
  const controllerProps = useShowController<RecordType>(props);
  const body = <ShowContextProvider value={controllerProps}>{children}</ShowContextProvider>;
  return props.resource ? (
    // support resource override via props
    <ResourceContextProvider value={props.resource}>{body}</ResourceContextProvider>
  ) : (
    body
  );
};
