import { z } from "zod";
import { t } from "./types";

export const LessonExtensionSchema = t.object({
  newEnd: t.dateTime(),
  durationInMinutes: t.number(),
  extendedBy: t.string(),
  extendedAt: t.dateTime(),
  processed: t.boolean().optional(),
});

export type LessonExtension = z.infer<typeof LessonExtensionSchema>;
