import { CSSProperties, ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import {
  Create,
  Edit,
  Identifier,
  RecordContextProvider,
  SaveButton,
  SimpleForm,
  TextInput,
  useGetOne,
  useGetRecordId,
  useListContext,
  useNotify,
  useRecordContext,
} from "react-admin";
import { DateField } from "../../fields/DateField";
import { Note } from "../../model/Note";
import { Avatar, Box, Button, IconButton, List, ListItem, Typography } from "@mui/material";
import { Spinner } from "../../misc/Spinner";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { gcs } from "../../utils/storage";
import { reportError } from "../../backoffice.utils";
import { useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import EditIcon from "@mui/icons-material/EditOutlined";
import { autovioColors } from "../../misc/backofficeTheme";
import { User } from "../../providers/usersProvider";

export const StudentNotesList = forwardRef(({ title, style }: { title?: ReactNode; style?: CSSProperties }, ref) => {
  const studentUid = useGetRecordId();
  return (
    <div style={{ position: "relative", ...(style ?? {}) }}>
      <ReferenceManyField
        label=""
        reference="studentNotes"
        target="studentUid"
        sort={{ field: "createdAt", order: "DESC" }}
        // Filter out notes without a body ...
        filter={{ body: (it: Note) => !!it }}
      >
        <_StudentNotesList studentUid={studentUid} title={title} ref={ref} />
      </ReferenceManyField>
    </div>
  );
});

const _StudentNotesList = forwardRef(({ studentUid, title }: { studentUid: Identifier; title?: ReactNode }, ref) => {
  const { data, isLoading, total } = useListContext<Note>();
  const [state, setState] = useState<"show button" | "show form">("show button");

  useImperativeHandle(ref, function () {
    return {
      showForm: () => setState("show form"),
    };
  });

  const titleAndButton = state === "show button" && (
    <div style={{ display: "flex", justifyContent: "space-between" }}>{title ?? <div />}</div>
  );
  const titleAndForm = state === "show form" && (
    <>
      {title}
      <_AddNoteForm studentUid={studentUid} close={() => setState("show button")} />
    </>
  );

  if (isLoading) {
    return (
      <>
        {title}
        <div style={{ flex: 1 }}>
          <Spinner style={{ margin: "20px" }} />
        </div>
      </>
    );
  }

  if (!data || data.length === 0 || total === 0) {
    return (
      titleAndForm || (
        <>
          {titleAndButton}
          <Typography
            variant="body2"
            style={{
              marginTop: "20px",
              height: "40px",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            Keine Notizen vorhanden.
          </Typography>
        </>
      )
    );
  }
  return (
    <>
      {titleAndButton || titleAndForm}
      <List sx={{ padding: 0 }}>
        {data.map((note) => (
          <RecordContextProvider key={note.id} value={note}>
            <_StudentNotesListItem />
          </RecordContextProvider>
        ))}
      </List>
    </>
  );
});

function _Avatar({ note }: { note: Note }) {
  const { data: user } = useGetOne<User>("users", { id: note.createdByUid ?? "" }, { enabled: !!note.createdByUid });

  return <Avatar src={user?.avatarUrl} sx={{ width: 20, height: 20, marginTop: 0.5 }} />;
}

function _StudentNotesListItem() {
  const note = useRecordContext<Note>();
  const notify = useNotify();
  const [showEditButton, setShowEditButton] = useState(false);
  const [mode, setMode] = useState<"show" | "edit">("show");

  if (mode === "edit") {
    return <_EditNoteForm note={note} close={() => setMode("show")} />;
  }

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setShowEditButton(true)}
      onMouseLeave={() => setShowEditButton(false)}
    >
      <ListItem
        disablePadding
        style={{
          marginTop: 0,
          marginBottom: "16px",
          padding: "0 6px",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <_Avatar note={note} />
          <Box>
            <>
              <DateField
                className="RaLabeled-label"
                source="createdAt"
                showTime
                sx={{ fontWeight: "bold", fontSize: "14px !important" }}
              />
              {note.createdByName && (
                <span
                  className="RaLabeled-label"
                  style={{ color: "#9b9b9b", fontSize: 14 }}
                >{` ${note.createdByName}`}</span>
              )}
              {note.updatedAt && note.updatedAt > note.createdAt && (
                <>
                  <span className="RaLabeled-label" style={{ color: "#9b9b9b", fontSize: 14 }}>
                    {" (geändert: "}
                  </span>
                  <DateField
                    className="RaLabeled-label"
                    source="updatedAt"
                    showTime
                    sx={{ color: "#9b9b9b", fontSize: "14px !important" }}
                  />
                  <span className="RaLabeled-label" style={{ color: "#9b9b9b", fontSize: 14 }}>
                    {note.updatedByName ? `, ${note.updatedByName})` : ")"}
                  </span>
                </>
              )}
            </>
            <_NoteText>{note.body ?? ""}</_NoteText>
          </Box>
        </Box>
      </ListItem>
      {showEditButton && (
        <IconButton
          sx={{ position: "absolute", top: "3px", right: "3px", width: 40, height: 40 }}
          onClick={() => setMode("edit")}
        >
          <EditIcon style={{ fill: autovioColors.green }} />
        </IconButton>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div />
        <div>
          {(note.attachments ?? []).map((it) => (
            <IconButton
              sx={{ marginTop: -2 }}
              key={it.id}
              onClick={async () => {
                try {
                  const downloadUrl = await gcs.getDownloadUrl(it.path);
                  window.open(downloadUrl, "_blank");
                } catch (error) {
                  notify("Fehler beim Öffnen der angehängten Datei.", { type: "error" });
                  reportError(`Failed to get download URL for attachment ${it.id}`, error);
                }
              }}
            >
              <AttachFileIcon />
            </IconButton>
          ))}
        </div>
      </div>
    </div>
  );
}

function _NoteText({ children: text, maxLines = 5 }: { children: string; maxLines?: number }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const { green } = autovioColors;

  useEffect(() => {
    const checkIfTruncated = () => {
      const textElement = textRef.current;
      if (textElement) {
        const lineHeight = parseFloat(window.getComputedStyle(textElement).lineHeight);
        const maxHeight = lineHeight * maxLines + 1;
        setIsTruncated(textElement.scrollHeight > maxHeight);
      }
    };

    checkIfTruncated();

    window.addEventListener("resize", checkIfTruncated);
    return () => window.removeEventListener("resize", checkIfTruncated);
  }, [text, maxLines]);

  return (
    <>
      <Typography
        ref={textRef}
        sx={{
          maxHeight: isExpanded ? "none" : `${maxLines * 1.2}em`,
          overflow: "hidden",
          lineHeight: "1.2em",
          textOverflow: "ellipsis",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          wordBreak: "break-word",
          fontSize: 14,
          padding: 0,
        }}
      >
        {text}
      </Typography>
      {isTruncated && !isExpanded && (
        <span style={{ color: green, cursor: "pointer", fontSize: 14 }} onClick={() => setIsExpanded(true)}>
          mehr anzeigen
        </span>
      )}
    </>
  );
}

function _AddNoteForm({ studentUid, close }: { studentUid: Identifier; close: () => void }) {
  const queryClient = useQueryClient();

  return (
    <Create
      className="edit-note"
      sx={{
        marginTop: 0,
        marginBottom: "8px", // ... not 16px because the <ul> below this form already has a top padding of 8px
        border: "1px solid #D8D8D8",
      }}
      record={{ studentUid }}
      mutationOptions={{
        onSuccess: async () => {
          await queryClient.invalidateQueries(["studentNotes"]);
          close();
        },
      }}
    >
      <_NoteForm close={close} />
    </Create>
  );
}

function _EditNoteForm({ note, close }: { note: Note; close: () => void }) {
  return (
    <Edit
      resource="studentNotes"
      id={note.id}
      redirect={false}
      className="edit-note"
      mutationMode="optimistic"
      sx={{
        marginTop: 0,
        marginBottom: "16px",
        border: "1px solid #D8D8D8",
      }}
      mutationOptions={{ onSuccess: close }}
    >
      <_NoteForm close={close} />
    </Edit>
  );
}

function _NoteForm({ close }: { close: () => void }) {
  const validate = (values: Record<string, any>) => {
    const errors: { [field: string]: string } = {};
    if (!values.body || values.body.trim().length === 0) {
      errors.body = "Eine Notiz darf nicht leer sein.";
    }
    return errors;
  };

  return (
    <SimpleForm sx={{ padding: "6px" }} validate={validate} toolbar={<_NoteActions close={close} />}>
      <TextInput
        source="body"
        label={false}
        rows={4}
        fullWidth
        multiline
        sx={{
          ".MuiOutlinedInput-notchedOutline": { borderColor: "transparent !important" },
        }}
      />
    </SimpleForm>
  );
}

function _NoteActions({ close }: { close: () => void }) {
  const bodyText = useWatch({ name: "body" });
  return (
    <div style={{ padding: "0 6px 6px 6px", display: "flex", justifyContent: "flex-end" }}>
      <Button variant="outlined" size="small" onClick={close} style={{ marginRight: "6px" }}>
        Abbrechen
      </Button>
      <SaveButton disabled={!bodyText || bodyText.trim().length === 0} />
    </div>
  );
}
