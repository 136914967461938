export function getFileNameExtension(fileName: string): string {
  const i = Math.max(fileName.lastIndexOf("/"), fileName.lastIndexOf("\\"));
  if (i >= 0) {
    fileName = fileName.substring(i + 1);
  }
  while (fileName.startsWith(".")) {
    fileName = fileName.substring(1);
  }
  const j = fileName.lastIndexOf(".");
  return j >= 0 ? fileName.substring(j + 1).toLowerCase() : "";
}
