import { SvgIconProps } from "@mui/material";
import { CarIcon } from "./CarIcon";
import { MotorcycleIcon } from "./MotorcycleIcon";
import { TrailerIcon } from "./TrailerIcon";

interface VehicleIconProps extends SvgIconProps {
  type: "car" | "motorcycle" | "trailer";
}

export function VehicleIcon({ type, ...svgIconProps }: VehicleIconProps) {
  switch (type) {
    case "car":
      return <CarIcon {...svgIconProps} />;
    case "motorcycle":
      return <MotorcycleIcon {...svgIconProps} />;
    case "trailer":
      return <TrailerIcon {...svgIconProps} />;
  }
}
