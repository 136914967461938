import { CommonInputProps, ReferenceInput, SelectInput, TextInput } from "react-admin";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";

type DrivingSchoolInputProps = CommonInputProps & {
  label?: string;
};

export function DrivingSchoolInput(props: DrivingSchoolInputProps) {
  const { label, validate, isRequired, ...rest } = props;
  if (restrictAccessToDrivingSchoolIds?.length === 1) {
    return (
      <TextInput
        type="hidden"
        source="entitledDrivingSchools"
        defaultValue={[restrictAccessToDrivingSchoolIds[0]]}
        style={{ display: "none" }}
      />
    );
  }
  return (
    <ReferenceInput {...rest} reference="drivingSchools" page={1} perPage={999} sort={{ field: "name", order: "ASC" }}>
      <SelectInput
        label={label}
        sx={{ minWidth: "337px" }}
        optionText="name"
        validate={validate}
        isRequired={isRequired}
      />
    </ReferenceInput>
  );
}
