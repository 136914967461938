import { z } from "zod";
import { MoneySchema } from "./Money";

const CancellationPolicyPreconditionSchema = z
  .object({
    var: z.union([z.literal("timeSinceBooked"), z.literal("timeBeforeStart")]),
    op: z.literal("<"),
    value: z.string().regex(new RegExp("^([1-9][0-9]*)([hd])$")),
  })
  .strict();

const CancellationPolicyFeeSchema = z.union([
  z.object({ fixed: MoneySchema }).strict(),
  z.object({ percentage: z.number().int().gte(0).lte(100) }).strict(),
]);

const CancellationPolicyRuleSchema = z.object({
  precondition: CancellationPolicyPreconditionSchema,
  fee: CancellationPolicyFeeSchema,
});

export const CancellationPolicySchema = z.object({
  rules: z.array(CancellationPolicyRuleSchema),
});

export type CancellationPolicy = z.infer<typeof CancellationPolicySchema>;
