import getTree from "magic-bytes.js/dist/model/pattern-tree";
import type { Node } from "magic-bytes.js/dist/model/tree";

const _tree = getTree();

function _visitNode(node: Node, mimeType: string): string | undefined {
  if (node.matches) {
    for (const entry of node.matches) {
      if (entry.mime === mimeType) {
        return entry.extension;
      }
    }
  }
  for (const childNode of Object.values(node.bytes)) {
    const extension = _visitNode(childNode, mimeType);
    if (extension) {
      return extension;
    }
  }
  return "";
}

export function getFileNameExtensionForMimeType(mimeType: string): string {
  // Fast path for some common MIME types ...
  if (mimeType === "application/octet-stream") {
    return "";
  } else if (mimeType === "application/pdf") {
    return "pdf";
  } else if (mimeType === "image/jpeg") {
    return "jpg";
  } else if (mimeType === "image/png") {
    return "png";
  }
  for (const node of [_tree.noOffset!, ...Object.values(_tree.offset)]) {
    const extension = _visitNode(node, mimeType);
    if (extension) {
      return extension;
    }
  }
  return "";
}
