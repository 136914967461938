import { getAuthenticatedServerClient } from "../api/server.api";
import { auth } from "../firebase";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { grants } from "../backoffice.access_control";

type View =
  | "AUTOVIO-PartnerDetails/Kundenverteilung"
  | "Ramp-UpDashboard/PartnerDashboard" // V1 (deprecated) RampUp Dashboard
  | "AUTOVIO-Ramp-Up-AllDashBoards/UtilizationDashboard-Overview"; // V2 (current) RampUp Dashboard

export function TableauView({ view, drivingSchoolUid }: { view: View; drivingSchoolUid?: string }) {
  if (drivingSchoolUid) {
    if (!grants.includes("viewDrivingSchoolBusinessIntelligenceData")) return null;
    if (restrictAccessToDrivingSchoolIds && !restrictAccessToDrivingSchoolIds.includes(drivingSchoolUid)) return null;
  } else {
    if (!grants.includes("viewAutovioBusinessIntelligenceData")) return null;
  }
  const ref = (div: HTMLDivElement) => {
    void (async () => {
      const serverClient = await getAuthenticatedServerClient();
      const tableauJwtPromise = serverClient.getTableauJwt({ drivingSchoolUid });
      const { TableauViz } = await import(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2307
        "https://eu-west-1a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"
      );
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        return <span></span>;
      }
      const tableauJwt = await tableauJwtPromise;
      const viz = new TableauViz() as Node & { src: string; toolbar: string; token: string };
      viz.src = `https://eu-west-1a.online.tableau.com/t/autoviode/views/${view}`;
      viz.toolbar = "hidden";
      viz.token = tableauJwt.jwt;
      if (div) {
        div.replaceChildren(viz);
      }
    })();
  };
  return <div ref={ref} style={{ height: "100%", minHeight: 1024 }}></div>;
}
