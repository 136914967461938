import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { Student } from "../providers/studentsProvider";
import { BookedTraining } from "../model/BookedTraining";
import { SelectInput } from "react-admin";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";

export function BookedTrainingSelect(props: {
  sx?: SxProps<Theme>;
  value: BookedTraining;
  student: Student;
  onChange: (selectedBookedTraining: BookedTraining) => void;
}) {
  const methods = useForm({
    defaultValues: {
      bookedTrainingId: props.value.id,
    },
  });
  const { bookedTrainings } = props.student;
  const selection = props.value;
  useEffect(() => {
    methods.setValue("bookedTrainingId", selection.id);
  }, [selection.id]);
  return (
    <FormProvider {...methods}>
      <SelectInput
        sx={props.sx}
        label="Ausbildung"
        source="bookedTrainingId"
        choices={bookedTrainings.map(({ id, drivingLicenseClass, bundleName }) => ({
          id,
          name: `${drivingLicenseClass} • ${bundleName}`,
        }))}
        onChange={(event) => {
          const selectedBookedTrainingId = event.target.value;
          const selectedBookedTraining = bookedTrainings.find((it) => it.id === selectedBookedTrainingId)!;
          props.onChange(selectedBookedTraining);
        }}
        isRequired
      />
    </FormProvider>
  );
}
