import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import { useNotify } from "react-admin";

export const CopyToClipboardButton = (props: { data: string }) => {
  const notify = useNotify();
  return (
    <IconButton
      onClick={async () => {
        await navigator.clipboard.writeText(props.data);
        notify("In die Zwischenablage kopiert");
      }}
    >
      <ContentCopyIcon />
    </IconButton>
  );
};
