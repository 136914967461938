import { GetOneParams, GetOneResult } from "react-admin";
import { AbstractProvider } from "./abstractProvider";
import { instructorsProvider } from "./instructorsProvider";
import { studentsProvider } from "./studentsProvider";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";

export interface User {
  id: string;
  name: string;
  avatarUrl?: string;
}

class UsersProvider extends AbstractProvider<User> {
  async getOne(_resource: string, { id }: GetOneParams): Promise<GetOneResult<User>> {
    if (id === "backend") {
      return { data: { id, name: "AUTOVIO" } };
    }
    const cachedUser = instructorsProvider.getOneFromCache(id) || studentsProvider.getOneFromCache(id);
    if (cachedUser) {
      return { data: { id, name: cachedUser.name, avatarUrl: cachedUser.avatarUrl } };
    }
    const snapshot = await getDoc(doc(firestore, `public_profiles/${id}`));
    if (snapshot.exists()) {
      const { displayName: name, avatarUrl } = snapshot.data();
      return { data: { id, name, avatarUrl } };
    } else {
      throw new Error(`Could not find public profile of user ${id}`);
    }
  }
}

export const usersProvider = new UsersProvider();
