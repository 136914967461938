import type { FullMetadata, UploadResult } from "@firebase/storage";

import { storage } from "../firebase";
import { getDownloadURL, getMetadata, list, ref, uploadBytes } from "firebase/storage";

class GoogleCloudStorage {
  async uploadFile(file: File, pathOrPathBuilder: string | ((ext: string) => string)): Promise<UploadResult> {
    let path: string;
    if (typeof pathOrPathBuilder === "string") {
      path = pathOrPathBuilder;
    } else {
      const i = file.name.lastIndexOf(".");
      const ext = i < 1 /* ... not i < 0! -- ext for ".hidden_file" should be: "" */ ? "" : file.name.substring(i + 1);
      path = pathOrPathBuilder(ext);
    }
    const storageRef = ref(storage, path);
    const result = await uploadBytes(storageRef, await file.arrayBuffer(), {
      contentType: file.type,
    });
    if (result.metadata.size !== file.size) {
      throw new Error("Incomplete upload");
    }
    return result;
  }

  async listFiles(path: string): Promise<Array<FullMetadata>> {
    const storageRef = ref(storage, path);
    const { items } = await list(storageRef);
    return await Promise.all(items.map(getMetadata));
  }

  getDownloadUrl(fileOrPath: FullMetadata | string): Promise<string> {
    const storageRef =
      typeof fileOrPath === "string" ? ref(storage, fileOrPath) : fileOrPath.ref ?? ref(storage, fileOrPath.fullPath);
    return getDownloadURL(storageRef);
  }
}

export const gcs = new GoogleCloudStorage();
