import { CommonInputProps, useInput, useResourceContext } from "react-admin";
import { RadioGroup, FormControl, FormControlLabel, Radio } from "@mui/material";

type YesNoInputProps = CommonInputProps & { onChange?: (newValue: boolean) => void };

export function YesNoInput(props: YesNoInputProps) {
  const { source, onChange, ...rest } = props;
  const resource = useResourceContext();
  const { field } = useInput({ resource, source });

  return (
    <FormControl {...rest}>
      <RadioGroup
        row
        value={field.value ?? false}
        onChange={(e) => {
          field.onChange(e);
          onChange?.(!field.value);
        }}
      >
        <FormControlLabel value={true} control={<Radio />} label="ja" />
        <FormControlLabel value={false} control={<Radio />} label="nein" />
      </RadioGroup>
    </FormControl>
  );
}
