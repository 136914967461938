import { z } from "zod";
import { PostalAddressSchema } from "./PostalAddress";
import { WorkflowSchema } from "./Workflow";

export const TheoryExamWorkflowSchema = WorkflowSchema.extend({
  type: z.literal("theoryExamSignUp"),
  workflowData: z.object({
    studentUid: z.string().nonempty(),
    assignedInstructorUid: z.string().nonempty(),
    examLocation: z.object({
      uid: z.string().nonempty(),
      name: z.string(),
      postalAddress: PostalAddressSchema,
    }),
    preferredDates: z.array(z.string().regex(/^\d{4}-\d{2}-\d{2}$/)).min(1),
    preferredTimeOfDay: z.array(z.enum(["morning", "afternoon"])).min(1),
  }),
});
export type TheoryExamWorkflow = z.infer<typeof TheoryExamWorkflowSchema> & { id: string };
