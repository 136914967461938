import { Box, Typography } from "@mui/material";
import { autovioColors } from "../misc/backofficeTheme";
import { Invoice, InvoiceStateEnum } from "../generated/backendClient";
import { Tooltip } from "@mui/material";
import { MoneyCurrency } from "../misc/Money";

export const INVOICE_STATE_LABEL_AND_COLOR: { [K in InvoiceStateEnum]: { label: string; color: string } } = {
  UNPAID: { label: "️Warte auf Zahlung", color: autovioColors.greyUltraLight },
  UNPAID_WITH_PREPAID_CREDITS: { label: "️Guthaben nicht anwendbar", color: autovioColors.orange },
  AWAITING_PAYMENT: { label: "In Arbeit", color: autovioColors.greyUltraLight },
  PAID: { label: "Bezahlt", color: autovioColors.greenLight },
  REFUNDED: { label: "Erstattet", color: autovioColors.greyUltraLight },
  PAYMENT_REVOKED: { label: "Angefochten", color: autovioColors.orange },
  PARTIALLY_REFUNDED: { label: "Teilw. rückerstattet", color: autovioColors.greyUltraLight },
};

export function InvoiceStateBadge({ invoice }: { invoice: Invoice }) {
  const { label, color } = INVOICE_STATE_LABEL_AND_COLOR[invoice.state] ?? {
    label: `⚠ ${invoice.state}`,
    color: autovioColors.yellowUltraLight,
  };
  const badge = (
    <Box style={{ backgroundColor: color, padding: "0 10px", borderRadius: "5px" }}>
      <Typography variant="overline" style={{ whiteSpace: "nowrap" }}>
        {label}
      </Typography>
    </Box>
  );
  if (invoice.state === "PARTIALLY_REFUNDED") {
    return (
      <Tooltip
        title={
          <>
            <Typography>
              Rückerstattet:
              <MoneyCurrency {...invoice.refunded_amount} />
            </Typography>
          </>
        }
        PopperProps={{ placement: "top-start" }}
      >
        <div>{badge}</div>
      </Tooltip>
    );
  }
  return badge;
}
