import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { PaginatedUserList, User, UserEndpoint } from "../generated/backendClient";
import { getAuthenticatedBackendClient } from "../api/backend.api";

export interface BackendUserRecord extends RaRecord {
  payload: User;
}

const backendPageToRaPage = (
  backendPage: PaginatedUserList,
  getListParams: GetListParams,
): GetListResult<BackendUserRecord> => {
  const { page, perPage } = getListParams.pagination;
  const start = Math.min((page - 1) * perPage, backendPage.results.length);
  const end = Math.min(start + perPage, backendPage.results.length);
  return {
    // we use the firebase auth user id as the id for the ra record
    data: backendPage.results.slice(start, end).map((user) => ({ id: user.firebase_auth_users[0], payload: user })),
    total: backendPage.count,
  };
};

export type FundingInstructionsWithId = Awaited<ReturnType<UserEndpoint["userFundingInstructionsRetrieve"]>> & {
  id: string;
};

export class BackendUserProvider {
  async getList(_: string, { pagination, sort, filter }: GetListParams): Promise<GetListResult<BackendUserRecord>> {
    const backendResult = await (
      await getAuthenticatedBackendClient()
    ).user.userList({
      firebaseAuthUsersId: filter.firebaseAuthUsersId,
      limit: 1000,
      offset: 0,
    });
    return backendPageToRaPage(backendResult, { pagination, sort, filter });
  }

  async getFundingInstructions(studentId: string): Promise<FundingInstructionsWithId> {
    const fundingInstructions = await (
      await getAuthenticatedBackendClient()
    ).user.userFundingInstructionsRetrieve({ id: studentId });
    return {
      id: studentId,
      ...fundingInstructions,
    };
  }

  async giveMoneyToUser(
    userId: string,
    drivingSchoolId: string,
    amount: string,
    currency: string,
    reason: string,
    hqContributionPercentage: number,
  ): Promise<void> {
    await (
      await getAuthenticatedBackendClient()
    ).user.userGiveFreeCreditsCreate({
      id: userId,
      requestBody: {
        money: { amount, currency },
        driving_school_id: drivingSchoolId,
        reason,
        hq_contribution_percentage: hqContributionPercentage.toFixed(2),
      },
    });
  }

  async getOne(resource: "fundingInstructions", params: GetOneParams): Promise<GetOneResult<FundingInstructionsWithId>>;
  async getOne(resource: "backendUser", params: GetOneParams): Promise<GetOneResult<BackendUserRecord>>;
  async getOne(
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult<BackendUserRecord | FundingInstructionsWithId>> {
    if (resource === "fundingInstructions") {
      return { data: await this.getFundingInstructions(params.id) };
    }
    if (resource === "backendUser") {
      const backendResult = await (await getAuthenticatedBackendClient()).user.userRetrieve({ id: params.id });
      return { data: { id: params.id, payload: backendResult } };
    }
    throw new Error("Not implemented");
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<BackendUserRecord>> {
    if (params.ids.length > 1) {
      console.warn("getMany called with more than one id, only the first one will be used");
    }
    return this.getList(resource, {
      filter: { firebaseAuthUsersId: params.ids[0] },
      pagination: { page: 1, perPage: 1 },
      sort: { field: "id", order: "ASC" },
    });
  }

  async getManyReference(
    resource: string,
    { id, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<BackendUserRecord>> {
    return this.getList(resource, { filter: { firebaseAuthUsersId: id }, sort, pagination });
  }

  async create(_: string, __: CreateParams<any>): Promise<CreateResult<BackendUserRecord>> {
    throw new Error("Not implemented");
  }

  async update(_: string, __: UpdateParams<BackendUserRecord>): Promise<UpdateResult<BackendUserRecord>> {
    throw new Error("Not implemented");
  }

  async updateMany(_: string, __: UpdateManyParams<any>): Promise<UpdateManyResult> {
    throw new Error("Not implemented");
  }

  async delete(_: string, __: DeleteParams): Promise<DeleteResult<BackendUserRecord>> {
    throw new Error("Not implemented");
  }

  async deleteMany(_: string, __: DeleteManyParams): Promise<DeleteManyResult> {
    throw new Error("Not implemented");
  }
}

export const backendUserProvider = new BackendUserProvider();
