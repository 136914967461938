import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginLeft: "16px",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  width: 100,
  height: 100,
  padding: "5px",
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

interface ImageFieldProps {
  readonly defaultImageUrl?: string;
  readonly onDropImage: (files: File[]) => void;
}

const getHeightAndWidthFromDataUrl = (dataURL: string): Promise<{ height: number; width: number }> =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

export const ImageUploadField = (props: ImageFieldProps) => {
  const [files, setFiles] = useState<{ file: File; preview: string }[]>([]);
  const [hasError, setHasError] = useState(false);
  const [defaultImage, setDefaultImage] = useState(props.defaultImageUrl);
  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/png", "image/jpeg", "image/webp"],
    maxFiles: 1,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      const previews = acceptedFiles.map((it) => URL.createObjectURL(it));
      setHasError(false);
      const isNotSquare = await Promise.all(
        previews.map(async (it) => {
          const { height, width } = await getHeightAndWidthFromDataUrl(it);
          return height !== width;
        }),
      );
      if (isNotSquare.some((it) => it)) {
        setHasError(true);
        return;
      }
      setFiles(acceptedFiles.map((file, index) => ({ file, preview: previews[index] })));
      props.onDropImage(acceptedFiles);
    },
  });

  let thumbs = files.map((file) => <ImageThumbnail key={file.file.name} url={file.preview} />);
  if (thumbs.length === 0 && defaultImage) {
    thumbs = [<ImageThumbnail key="defaultImage" url={defaultImage} />];
  }

  useEffect(() => {
    if (props.defaultImageUrl?.startsWith("gs://")) {
      void (async () => {
        const downloadPath = await getDownloadURL(ref(storage, props.defaultImageUrl));
        setDefaultImage(downloadPath);
      })();
    }
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section style={{ display: "flex", alignItems: "center" }}>
      <div
        {...getRootProps({
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#eeeeee",
            borderStyle: "dashed",
            backgroundColor: "#fafafa",
            color: "#bdbdbd",
            outline: "none",
            transition: "border .24s ease-in-out",
            cursor: "pointer",
          },
        })}
      >
        <input {...getInputProps()} />
        <p style={{ textAlign: "center" }}>
          Bild-Datei hier hineinziehen oder
          <br />
          hier klicken, um ein Bild-Datei auszuwählen ...
        </p>
      </div>
      <Box component="aside" sx={thumbsContainer}>
        {hasError ? <Box sx={{ color: "red" }}>Bild ist nicht quadratisch</Box> : thumbs}
      </Box>
    </section>
  );
};

interface ImageThumbnailProps {
  readonly url: string;
}

const ImageThumbnail = (props: ImageThumbnailProps) => {
  return (
    <Box sx={thumb}>
      <Box sx={thumbInner}>
        <img
          src={props.url}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(props.url);
          }}
        />
      </Box>
    </Box>
  );
};
