import { AbstractProvider } from "./abstractProvider";
import { GetListParams, GetListResult } from "react-admin";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";
import { ZodiosPlugin } from "@zodios/core";
import { z } from "zod";

type AppliedReferralCodeDto = z.infer<typeof schemas.AppliedReferralCodeDto>;
export type AppliedReferralCode = { id: string; code: string } & Omit<AppliedReferralCodeDto, "referralCode">;

class AppliedReferralCodesProvider extends AbstractProvider<AppliedReferralCode> {
  async getList(_: string, { filter, sort, pagination }: GetListParams): Promise<GetListResult<AppliedReferralCode>> {
    const serverClient = await getAuthenticatedServerClient();
    let total: undefined | number;
    const extractTotalFromContentRangeHeader: ZodiosPlugin = {
      response: async (_api, _config, response) => {
        try {
          const contentRangeHeader = response.headers["content-range"];
          if (contentRangeHeader) {
            total = parseInt(contentRangeHeader.substring(contentRangeHeader.lastIndexOf("/") + 1));
          }
        } catch (error) {
          console.warn("Failed to extract total number of applied referral codes from Content-Range header", error);
        }
        return response;
      },
    };
    serverClient.use(extractTotalFromContentRangeHeader);
    const data = await serverClient.listAppliedReferralCodes({
      queries: {
        filter: JSON.stringify(filter),
        sort: JSON.stringify([sort.field, sort.order]),
        range: JSON.stringify([(pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage - 1]),
      },
    });
    return {
      data: data.map((it) => ({ id: it.referralCode, code: _formatReferralCode(it.referralCode), ...it })),
      total,
    };
  }
}

export const appliedReferralCodesProvider = new AppliedReferralCodesProvider();

function _formatReferralCode(referralCode: string): string {
  return `${referralCode.substring(0, 3)}-${referralCode.substring(3)}`;
}
