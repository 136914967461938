import { RecordContextProvider, TextField, useGetOne } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { TypographyProps } from "@mui/material/Typography";

export function StudentDisplayName({
  studentId,
  ...props
}: { studentId: string; emptyText?: string } & Omit<TypographyProps, "textAlign">) {
  const { data: student } = useGetOne<Student>("students", { id: studentId });
  return (
    <RecordContextProvider value={student}>
      <TextField source="name" overflow={"hidden"} {...props} />
    </RecordContextProvider>
  );
}
