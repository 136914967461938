import { useNotify, useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { MouseEvent, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { reportError } from "../backoffice.utils";
import { updateDunningProcess } from "../api/backend.api";
import { useQueryClient } from "react-query";

export function ParkOutButton() {
  const student = useRecordContext<Student>();
  const notify = useNotify();
  const [parking, setParking] = useState(false);
  const queryClient = useQueryClient();

  async function parkOut(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    setParking(true);
    try {
      await updateDunningProcess(student, { paused: false }, queryClient);
    } catch (error) {
      reportError(`Failed to unpause dunning process of student ${student.id}`, error);
      notify(`Fehler beim Ausparken von ${student.name}`, { type: "error" });
    } finally {
      setParking(false);
    }
  }

  return (
    <LoadingButton variant="outlined" loading={parking} disabled={!student?.dunningProcess} onClick={parkOut}>
      Ausparken
    </LoadingButton>
  );
}
