import { z } from "zod";

export const DrivingLicenseClassEnum = z.enum([
  "A",
  "A1",
  "A2",
  "AM",
  "B",
  "B196",
  "B197",
  "B78",
  "B96",
  "BE",
  "L",
  "M",
]);

export type DrivingLicenseClass = z.infer<typeof DrivingLicenseClassEnum>;

export const MOTORCYCLE_DRIVING_LICENSE_CLASSES: ReadonlySet<DrivingLicenseClass> = new Set<DrivingLicenseClass>([
  "A",
  "A1",
  "A2",
  "AM",
  "B196",
  "M",
]);

export function isForMotorcycle(x: DrivingLicenseClass | { drivingLicenseClass: DrivingLicenseClass }): boolean {
  return MOTORCYCLE_DRIVING_LICENSE_CLASSES.has(typeof x === "string" ? x : x.drivingLicenseClass);
}

export const CAR_DRIVING_LICENSE_CLASSES: ReadonlySet<DrivingLicenseClass> = new Set<DrivingLicenseClass>([
  "B",
  "B197",
  "B78",
  "L",
]);

export const TRAILER_DRIVING_LICENSE_CLASSES: ReadonlySet<DrivingLicenseClass> = new Set<DrivingLicenseClass>([
  "B96",
  "BE",
]);

export function isForTrailer(x: DrivingLicenseClass | { drivingLicenseClass: DrivingLicenseClass }): boolean {
  return TRAILER_DRIVING_LICENSE_CLASSES.has(typeof x === "string" ? x : x.drivingLicenseClass);
}
