import { DateInput, maxValue, minValue } from "react-admin";
import { isDateString } from "../model/DateString";
import { useFormContext } from "react-hook-form";
import { DateTime } from "luxon";

export function ExaminationAssignmentReceivedAtInput() {
  const { getValues, setValue } = useFormContext();

  return (
    <DateInput
      sx={{ width: "15em" }}
      label="Prüfauftrag eingegangen am"
      source="examinationAssignmentReceivedAt"
      validate={[minValue("2020-01-01"), maxValue("2099-12-31")]}
      onBlur={({ target: { value } }) => {
        if (isDateString(value) && !isDateString(getValues().examinationAssignmentExpiresAt)) {
          const date = DateTime.fromISO(value);
          setValue("examinationAssignmentExpiresAt", date.plus({ years: 1 }).toISODate());
        }
      }}
    />
  );
}
