import { Datagrid, List, ReferenceField, ReferenceInput, Resource, SelectInput, TextField } from "react-admin";
import { MoneyField } from "../fields/MoneyField";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { ReferenceDrivingSchoolField } from "../fields/ReferenceDrivingSchoolField";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";

export const invoiceCustomersResource = (
  <Resource
    key="invoiceCustomers"
    name="invoiceCustomers"
    list={InvoiceCustomersList}
    options={{ label: "Rechnungskunden" }}
  />
);

function InvoiceCustomersList() {
  return (
    <List
      title={<BackofficeListTitle />}
      filter={
        restrictAccessToDrivingSchoolIds?.length === 1
          ? { drivingSchoolId: restrictAccessToDrivingSchoolIds[0] }
          : undefined
      }
      filters={
        restrictAccessToDrivingSchoolIds?.length === 1
          ? []
          : [
              <ReferenceInput
                source="drivingSchoolId"
                alwaysOn
                reference="drivingSchools"
                page={1}
                perPage={999}
                sort={{ field: "name", order: "ASC" }}
              >
                <SelectInput label="Fahrschule" />
              </ReferenceInput>,
            ]
      }
      exporter={false}
    >
      <Datagrid rowClick={(studentId) => `/students/${studentId}/invoices`} bulkActionButtons={false}>
        <ReferenceField label="Fahrschüler" reference="students" source="id" />
        <ReferenceDrivingSchoolField label="Fahrschule" source="drivingSchoolId" link />
        <TextField label="Status" source="verboseStatus" />
        <MoneyField label="Summe offene Rechnungen" source="sumUnpaidInvoices" />
        <MoneyField label="Summe bezahlte Rechnungen" source="sumPaidInvoices" />
        <MoneyField label="Budget" source="budget" />
        <MoneyField label="restliches Budget" source="remainingBudget" redForNegativeValues />
      </Datagrid>
    </List>
  );
}
