import { Stack, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { Bundle } from "../providers/catalogProvider";

export function DrivingLicenseClassesField(_props: { label: string }) {
  const record = useRecordContext<Bundle>();
  return (
    <Stack spacing={1}>
      {record.drivingLicenseClasses.map((it) => (
        <Typography key={it} variant="body2">
          {it}
        </Typography>
      ))}
    </Stack>
  );
}
