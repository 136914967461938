import { Typography } from "@mui/material";
import get from "lodash/get";
import { DateTime, Duration } from "luxon";
import { FieldProps, useRecordContext } from "react-admin";

export function AgeField(props: FieldProps & { source: string }) {
  const { source } = props;
  const record = useRecordContext(props);
  if (!record) return null;
  const value = get(record, source);
  if (!value || value === "") {
    return (
      <Typography component="span" variant="body2">
        Nicht hinterlegt
      </Typography>
    );
  }

  const dateOfBirth = value instanceof DateTime ? value : DateTime.fromISO(value);
  // Providing the unit arguments to diffNow() is important to get the correct age.
  // shiftToAll() gives us clean `years`, `months` values without fractional digits by shifting the fractions to the lower units.
  const age = dateOfBirth.startOf("day").diffNow(["years", "months"]).negate().shiftToAll();

  // Create new durations so that we can use toHuman() which gives us pluralization
  const years = Duration.fromObject({ years: age.years }, { locale: "de" });
  const months = Duration.fromObject({ months: age.months }, { locale: "de" });
  return (
    <Typography component="span" variant="body2">
      {`(Alter: ${years.toHuman()}${months.months !== 0 ? `, ${months.toHuman()}` : ""})`}
    </Typography>
  );
}
