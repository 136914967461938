/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { PaginatedCompanyList } from '../models/PaginatedCompanyList';
import type { PatchedCompany } from '../models/PatchedCompany';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompanyEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns PaginatedCompanyList
   * @throws ApiError
   */
  public companyList({
    limit,
    offset,
  }: {
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
  }): CancelablePromise<PaginatedCompanyList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/company/',
      query: {
        'limit': limit,
        'offset': offset,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns Company
   * @throws ApiError
   */
  public companyCreate({
    requestBody,
  }: {
    requestBody: Company,
  }): CancelablePromise<Company> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/company/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns Company
   * @throws ApiError
   */
  public companyRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this company.
     */
    id: string,
  }): CancelablePromise<Company> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/company/{id}/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns Company
   * @throws ApiError
   */
  public companyUpdate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this company.
     */
    id: string,
    requestBody: Company,
  }): CancelablePromise<Company> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/company/{id}/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns Company
   * @throws ApiError
   */
  public companyPartialUpdate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this company.
     */
    id: string,
    requestBody?: PatchedCompany,
  }): CancelablePromise<Company> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/company/{id}/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
