import { BooleanField, useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { Typography } from "@mui/material";
import { Row } from "../misc/Row";
import { Column } from "../misc/Column";

export function DrivingSchoolChangeField() {
  const student = useRecordContext<Student>();

  if (!student) {
    return null;
  }

  if (!student.isSchoolChanger) {
    return (
      <Typography component="span" variant="body2">
        Nein
      </Typography>
    );
  }

  return (
    <div>
      <Typography component="span" variant="body2">
        Ja
      </Typography>
      <Column sx={{ marginLeft: "1em" }}>
        <Row>
          <BooleanField source="featureToggles.canLearnTheoryInMobileApp" looseValue />
          <Typography variant="body2" className="whitespace-nowrap">
            kann in der App Theorie lernen
          </Typography>
        </Row>
        <Row>
          <BooleanField source="featureToggles.canBookTheoryLessons" looseValue />
          <Typography variant="body2" className="whitespace-nowrap">
            kann Theorieunterricht buchen
          </Typography>
        </Row>
        <Row>
          <BooleanField source="theoryKPIs.hasPassedTheoryExam" looseValue />
          <Typography variant="body2" className="whitespace-nowrap">
            hat Theorieprüfung bestanden
          </Typography>
        </Row>
      </Column>
    </div>
  );
}
