import { AbstractProvider } from "./abstractProvider";
import { PostalAddress } from "../model/PostalAddress";
import { GetListParams, GetListResult, GetOneParams, GetOneResult } from "react-admin";
import { applyFilter, applyPagination } from "../backoffice.utils";
import { isInstructor } from "./instructorsProvider";
import { drivingSchoolsProvider } from "./drivingSchoolsProvider";
import { isStudent } from "./studentsProvider";
import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../firebase";
import { FavoriteLocationSchema } from "../model/FavoriteLocation";

export interface StartingPoint {
  id: string;
  name: string;
  postalAddress: PostalAddress;
}

class StartingPointsProvider extends AbstractProvider<StartingPoint> {
  private _cache = new Map<string, StartingPoint>();

  async getOne(_: string, { id }: GetOneParams<StartingPoint>): Promise<GetOneResult<StartingPoint>> {
    const startingPoint = this._cache.get(id);
    if (!startingPoint) {
      throw new Error(`Starting point with id "${id}" not found in cache`);
    }
    return { data: startingPoint };
  }

  async getList(_: string, params: GetListParams): Promise<GetListResult<StartingPoint>> {
    const { filter, pagination, sort } = params;
    const meta = params?.meta ?? {};
    if (!("instructor" in meta)) {
      throw new Error("Missing instructor property in meta");
    }
    const instructor = meta?.instructor;
    if (!isInstructor(instructor)) {
      throw new Error("meta.instructor is not an Instructor");
    }
    if (!("student" in meta)) {
      throw new Error("Missing student property in meta");
    }
    const student = meta?.student;
    if (!isStudent(student)) {
      throw new Error("meta.student is not a Student");
    }
    const { data: drivingSchool } = await drivingSchoolsProvider.getOne("drivingSchools", {
      id: instructor.drivingSchoolId,
    });
    if (sort.field !== "__branches__student_address__favorite_locations__" || sort.order !== "ASC") {
      throw new Error(
        `Unexpected sort: ${JSON.stringify(
          sort,
        )} -- expected: {field: "__branches__student_address__favorite_locations__", order: "ASC"}`,
      );
    }
    const startingPoints: Array<StartingPoint> = [];
    // 1. Add branch address(es) ...
    if (student.branchId) {
      const branch = drivingSchool.branches.find((branch) => branch.uid === student.branchId);
      if (!branch) {
        throw new Error(
          `Student ${student.id} has unexpected branchId: ${student.branchId} -- no such branch in driving school ${drivingSchool.id}`,
        );
      }
      startingPoints.push({
        id: `address of branch ${branch.uid}`,
        name: branch.name,
        postalAddress: branch.postalAddress,
      });
    } else
      for (const branch of drivingSchool.branches) {
        startingPoints.push({
          id: `branch:${branch.uid}`,
          name: branch.name,
          postalAddress: branch.postalAddress,
        });
      }
    // 2. Add student address ...
    if (drivingSchool.customizations.includes("pickupFromHomeAddress")) {
      startingPoints.push({
        id: `address of student ${student.id}`,
        name: "Fahrschüler",
        postalAddress: student.postalAddress,
      });
    }
    // 3. Add favorite locations of instructor ...
    const snapshot = await getDocs(query(collection(firestore, `/users/${instructor.id}/favorite_locations`)));
    for (const doc of snapshot.docs) {
      const parseResult = FavoriteLocationSchema.safeParse(doc.data());
      if (parseResult.success) {
        const favoriteLocation = parseResult.data;
        startingPoints.push({
          id: `address of favorite location ${doc.id}`,
          name: favoriteLocation.name,
          postalAddress: favoriteLocation.postalAddress,
        });
      } else {
        console.warn(`Failed to parse ${doc.ref.path} -- ignoring it`, parseResult.error.errors);
      }
    }
    this._cache.clear();
    for (const startingPoint of startingPoints) {
      this._cache.set(startingPoint.id, startingPoint);
    }
    return applyPagination(applyFilter(startingPoints, filter), pagination);
  }
}

export const startingPointsProvider = new StartingPointsProvider();
