import {
  BooleanField,
  Datagrid,
  FunctionField,
  TextField,
  Toolbar,
  ToolbarClasses,
  useRecordContext,
} from "react-admin";

import { grants } from "../../backoffice.access_control";
import { AddVehicleButton } from "../../buttons/AddVehicleButton";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { autovioColors } from "../../misc/backofficeTheme";
import type { Vehicle } from "../../providers/resourceProviders";
import { VehicleIcon } from "../../icons/VehicleIcon";
import { PageTitle } from "../../misc/PageTitle";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";

export function DrivingSchoolFleet() {
  const drivingSchool = useRecordContext<DrivingSchool>();

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>Fuhrpark</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  return (
    <>
      <PageTitle>Fuhrpark</PageTitle>
      <ReferenceManyField reference="vehicles" target="entitledDrivingSchoolId" label="">
        <Datagrid
          rowClick={(vehicleId) => `/vehicles/${vehicleId}?disabled=["entitledDrivingSchools"]`}
          bulkActionButtons={false}
        >
          <FunctionField label="" render={_renderVehicleIcon} />
          <TextField label="Name" source="name" />
          <FunctionField label="Kennzeichen" render={_renderPlateNumber} />
          <BooleanField label="Geteilt" source="isShared" />
          {grants.includes("viewDeletedVehicles") && <TextField label="Gelöscht am" source="deletedAt" />}
        </Datagrid>
      </ReferenceManyField>
      <Toolbar className="no-padding" sx={{ backgroundColor: autovioColors.white }}>
        <div className={ToolbarClasses.defaultToolbar}>
          <div>{/* An empty div as placeholder, so that the following buttons are aligned right */}</div>
          <div>
            <AddVehicleButton type="car" style={{ marginRight: "1em" }} />
            <AddVehicleButton type="motorcycle" style={{ marginRight: "1em" }} />
            <AddVehicleButton type="trailer" />
          </div>
        </div>
      </Toolbar>
    </>
  );
}

function _renderVehicleIcon(vehicle: Vehicle) {
  return <VehicleIcon type={vehicle.type} />;
}

function _renderPlateNumber(vehicle: Vehicle) {
  const { type } = vehicle;
  return vehicle[type].plateNumber ?? "";
}
