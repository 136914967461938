import {
  collection,
  DocumentData,
  getDocs,
  orderBy,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import {
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
} from "react-admin";
import { deleteUser, deleteUsers } from "../api/backoffice.api";
import { applyFilter, applyPagination, applySort, fromFirestore, reportError } from "../backoffice.utils";
import { firestore } from "../firebase";

const TIME_IN_DAYS_UNTIL_DELETION_IS_OVERDUE = 21;

export interface UserToBeDeleted {
  id: string;
  name?: string;
  avatarUrl?: string;
  accountDeletionRequestedAt: DateTime;
  isOverdue: boolean;
  // If any of these properties are defined, the user cannot be deleted
  forbiddenProperties: string[];
}

interface UserDocumentData extends DocumentData {
  accountDeletionRequestedAt: Timestamp;
  publicProfile?: {
    displayName?: string;
    avatarUrl?: string;
  };
  // Include relevant properties to make sure that they are not defined
  hubspotContactId?: string;
  stripeCustomerId?: string;
  myInstructors?: string[];
  myDrivingSchools?: string[];
  training?: Record<string, unknown>;
}

class UsersToBeDeletedProvider {
  async getList(_: string, { pagination, sort, filter }: GetListParams): Promise<GetListResult<UserToBeDeleted>> {
    const usersToBeDeletedRef = query(
      collection(firestore, "/users"),
      where("accountDeletionRequestedAt", "!=", ""),
      orderBy("accountDeletionRequestedAt"),
    ).withConverter(usersToBeDeletedConverter);

    const snapshot = await getDocs(usersToBeDeletedRef);
    const usersToBeDeleted = snapshot.docs.map((doc) => doc.data());
    if (filter.isOverdue !== undefined && filter.isOverdue === false) {
      delete filter.isOverdue;
    }
    return applyPagination(applySort(applyFilter(usersToBeDeleted, filter), sort), pagination);
  }

  async delete(_: string, params: DeleteParams<UserToBeDeleted>): Promise<DeleteResult<UserToBeDeleted>> {
    const userUid = params.id;
    const user = params.previousData;
    if (!user) {
      throw new Error("User not found");
    }
    if (user.forbiddenProperties.length) {
      throw new Error(`User cannot be deleted because of the following properties: ${user.forbiddenProperties}`);
    }
    try {
      await deleteUser(userUid);
      return Promise.resolve({ data: user });
    } catch (error) {
      reportError(`Failed to delete user ${user.id}`, error);
      return Promise.reject(error);
    }
  }

  async deleteMany(_: string, params: DeleteManyParams<UserToBeDeleted>): Promise<DeleteManyResult<UserToBeDeleted>> {
    const userUids = params.ids;
    try {
      const result = await deleteUsers(userUids);
      return Promise.resolve(result);
    } catch (error) {
      reportError(`Error while deleting users ${userUids}`, error);
      return Promise.reject(error);
    }
  }
}

const usersToBeDeletedConverter = {
  toFirestore: (_: UserToBeDeleted): DocumentData => {
    throw new Error("Not implemented");
  },

  fromFirestore: (doc: QueryDocumentSnapshot<UserDocumentData>): UserToBeDeleted => {
    const data = doc.data();
    return {
      id: doc.id,
      name: data.publicProfile?.displayName,
      avatarUrl: data.publicProfile?.avatarUrl,
      accountDeletionRequestedAt: fromFirestore(data.accountDeletionRequestedAt),
      isOverdue:
        fromFirestore(data.accountDeletionRequestedAt).diffNow().as("days") <= -TIME_IN_DAYS_UNTIL_DELETION_IS_OVERDUE,
      forbiddenProperties: [
        data.hubspotContactId ? "hubspotContactId" : undefined,
        data.stripeCustomerId ? "stripeCustomerId" : undefined,
        data.myInstructors?.length ? "myInstructors" : undefined,
        data.myDrivingSchools?.length ? "myDrivingSchools" : undefined,
        data.training ? "training" : undefined,
      ].filter((property) => property !== undefined) as string[],
    };
  },
};

export const usersToBeDeletedProvider = new UsersToBeDeletedProvider();
