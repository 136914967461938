import { PaginationPayload } from "react-admin";
import { useRecoilState } from "recoil";
import { useCallback, useState } from "react";
import { persistentPaginationStateFamily } from "../persistent_state";

export interface PaginationHookResult {
  page: number;
  perPage: number;
  pagination: PaginationPayload;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setPagination: (pagination: PaginationPayload) => void;
}

export function usePersistentPaginationState({ storageKey }: { storageKey: string }): PaginationHookResult {
  // We don't persist `pagination.page` anymore, only `pagination.perPage` -- this fixes bug APP-4259
  const [page, setPage] = useState(1);
  const [persistentPagination, setPersistentPagination] = useRecoilState(persistentPaginationStateFamily(storageKey));
  const { perPage } = persistentPagination;
  const setPerPage = useCallback((perPage: number) => setPersistentPagination({ perPage }), [setPersistentPagination]);
  const setPagination = useCallback(
    ({ page, perPage }: PaginationPayload) => {
      setPage(page);
      setPerPage(perPage);
    },
    [setPage, setPerPage],
  );
  return {
    page,
    perPage,
    pagination: { page, perPage },
    setPage,
    setPerPage,
    setPagination,
  };
}
