import { doc, getDoc } from "firebase/firestore";
import { GetOneParams, GetOneResult } from "react-admin";
import { firestore } from "../firebase";
import { z } from "zod";

export const WorkTimeDaysEnum = z.enum(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]);

const TimeOfDaySchema = z.object({
  hour: z.number().int().min(0).max(23),
  minute: z.number().int().min(0).max(59),
});

const TimeRangeSchema = z.object({
  start: TimeOfDaySchema,
  end: TimeOfDaySchema,
});

export type TimeRange = z.infer<typeof TimeRangeSchema>;

const WorkTimesSchema = z.object({
  monday: z.optional(z.array(TimeRangeSchema)).transform((val) => val ?? []),
  tuesday: z.optional(z.array(TimeRangeSchema)).transform((val) => val ?? []),
  wednesday: z.optional(z.array(TimeRangeSchema)).transform((val) => val ?? []),
  thursday: z.optional(z.array(TimeRangeSchema)).transform((val) => val ?? []),
  friday: z.optional(z.array(TimeRangeSchema)).transform((val) => val ?? []),
  saturday: z.optional(z.array(TimeRangeSchema)).transform((val) => val ?? []),
  sunday: z.optional(z.array(TimeRangeSchema)).transform((val) => val ?? []),
});

export type WorkTimes = z.infer<typeof WorkTimesSchema> & { id: string };

class InstructorWorkTimesProvider {
  async getOne(_: string, { id }: GetOneParams): Promise<GetOneResult<WorkTimes>> {
    const snapshot = await getDoc(doc(firestore, `/users/${id}/preferences/work_times`));
    return {
      data: {
        id,
        ...WorkTimesSchema.parse(snapshot.data() ?? {}),
      },
    };
  }
}

export const instructorWorkTimesProvider = new InstructorWorkTimesProvider();
