import { atom, useSetRecoilState } from "recoil";

export const rerenderCounterState = atom<number>({
  key: "rerenderCounter",
  default: 0,
});

export function useRerender() {
  const setRerenderCounter = useSetRecoilState(rerenderCounterState);
  return () => {
    setRerenderCounter((val) => val + 1);
  };
}
