import { ListControllerResult } from "ra-core/dist/cjs/controller/list/useListController";
import { RaRecord, SortPayload } from "react-admin";
import { applySort } from "../backoffice.utils";

export function listContextFromArray<T>(
  array: Array<T>,
  options?: {
    sort?: SortPayload;
  },
): ListControllerResult {
  if (array.length > 0 && !(array[0] as any).id) {
    array = array.map((it, index) => ({ id: index, ...it }));
  }
  let data = array as Array<RaRecord>;
  // Only change the order of the given array, if options.sort was specified ...
  let { sort } = options ?? {};
  if (sort) {
    data = applySort(array as Array<RaRecord>, sort);
  } else {
    sort = { field: "id", order: "ASC" };
  }
  const listContext: ListControllerResult = {
    sort,
    data,
    displayedFilters: {},
    filterValues: {},
    hideFilter: () => {},
    isFetching: false,
    isLoading: false,
    onSelect: () => {},
    onToggleItem: () => {},
    onUnselectItems: () => {},
    page: 1,
    perPage: array.length,
    refetch: () => {},
    resource: "",
    selectedIds: [],
    setFilters: () => {},
    setPage: () => () => {},
    setPerPage: () => () => {},
    setSort: () => () => {},
    showFilter: () => {},
    total: array.length,
    hasNextPage: false,
    hasPreviousPage: false,
  };
  return listContext;
}
