import { Typography } from "@mui/material";
import { LinearProgress, useGetRecordId } from "react-admin";
import { useQuery } from "react-query";
import { studentsProvider } from "../providers/studentsProvider";

export function AuthProvidersField() {
  const studentId = useGetRecordId().toString();
  const { data, isLoading, error } = useQuery(["students", "fetchAuthUser", { id: studentId }], () =>
    studentsProvider.fetchAuthUser(studentId),
  );

  if (isLoading) return <LinearProgress timeout={0} />;
  if (error) return <Typography variant="body2">Fehler beim Abrufen</Typography>;
  if (!data) return null;

  const providerLabels: Record<string, string> = {
    "google.com": "Google",
    "apple.com": "Apple",
    "facebook.com": "Facebook",
    password: "E-Mail",
  };

  return (
    <>
      {data.data.providerData.map((it) => (
        <Typography variant="body2" key={it.providerId}>
          {it.email} ({providerLabels[it.providerId] ?? it.providerId})
        </Typography>
      ))}
    </>
  );
}
