import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { InstructorFavoriteLocation } from "../instructorFavoriteLocationsProvider";

export const favoriteLocationsConverter = {
  toFirestore: (_: InstructorFavoriteLocation): DocumentData => {
    throw new Error("Not implemented");
  },

  fromFirestore: (doc: QueryDocumentSnapshot): InstructorFavoriteLocation => {
    const docData = doc.data();
    const id = doc.id;
    return {
      id,
      name: docData.name,
      postalAddress: docData.postalAddress,
    };
  },
};
